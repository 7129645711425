import { ColumnState } from "utils/types";

const defaultColumns: readonly ColumnState[] = [
  {
    field: "group",
    hide: false,
    index: 0,
    sortable: true,
  },
  {
    field: "updatedDt",
    hide: false,
    index: 0,
    sortable: true,
  },
  {
    field: "updatedBy",
    hide: false,
    index: 0,
    sortable: true,
  },
  {
    field: "name",
    hide: false,
    index: 0,
    sortable: true,
  },
  {
    field: "oldValue",
    hide: false,
    index: 0,
    sortable: true,
  },
  {
    field: "newValue",
    hide: false,
    index: 0,
    sortable: true,
  },
];

export default defaultColumns;
