import { Theme, styled } from "@mui/material/styles";
import {
  DataGridPro,
  DataGridProProps,
  GridLogicOperator,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { Colors } from "theme/colors";
import { dataGridStyle } from "theme/styles";

export const StyledDataGrid = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-columnSeparator": {
    visibility: "visible",
  },
  ...dataGridStyle,
}));

export const TT_SLOT_PROPS = {
  filterPanel: {
    // Force usage of "And" operator
    logicOperators: [GridLogicOperator.And],
    // Display columns by ascending alphabetical order
    columnsSort: "asc" as "asc" | "desc" | undefined,
    filterFormProps: {
      // Customize inputs by passing props
      logicOperatorInputProps: {
        variant: "standard",
        size: "small",
      },
      columnInputProps: {
        variant: "standard",
        size: "small",
        sx: { mt: "auto" },
      },
      operatorInputProps: {
        variant: "standard",
        size: "small",
        sx: { mt: "auto" },
      },
      valueInputProps: {
        InputComponentProps: {
          variant: "standard",
          size: "small",
        },
      },
      deleteIconProps: {
        sx: {
          "& .MuiSvgIcon-root": { color: Colors.ttPersianRed },
        },
      },
    },
    sx: {
      // Customize inputs using css selectors
      "& .MuiDataGrid-filterForm": { p: 2 },
      "& .MuiDataGrid-filterForm:nth-child(even)": {
        backgroundColor: (theme: Theme) =>
          theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
      },
      "& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
      "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
      "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
      "& .MuiDataGrid-filterFormValueInput": { width: 200 },
    },
  },
};

export const TTDataGridPro = (props: DataGridProProps) => {
  return (
    <StyledDataGrid
      {...props}
      hideFooter
      slots={{
        toolbar: GridToolbar,
        // Use custom FilterPanel only for deep modification
        // FilterPanel: MyCustomFilterPanel,
      }}
    />
  );
};
