import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";
import { enqueueSnackbar } from "notistack";
import { useAppDispatch } from "redux/store/hooks";

import { UsersOrgs, UsersRow } from "models/users";
import {
  deleteMembership,
  getUserDetails,
  updateMembership,
} from "redux/actions/users";

const ActionsMenu = (props: { org: UsersOrgs; user: UsersRow }) => {
  const { org, user } = props;
  const dispatch = useAppDispatch();
  const { getRawIdToken, hasAccess } = useAuthData();

  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMembershipClick = () => {
    handleClose();
    setShowRemoveConfirmation(true);
  };

  const handleConfirmDeleteMembership = () => {
    setShowRemoveConfirmation(false);
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        deleteMembership({
          token: idToken,
          organizationId: org.organization_id,
          userId: user.user_id,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(`User removed from organization!`, {
            variant: "default",
          });
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to remove user from organization!", {
            variant: "error",
          });
        }
        dispatch(
          getUserDetails({
            token: idToken,
            userId: user.user_id?.toString(),
          })
        );
      });
    })();
  };

  const handleChangeMembershipClick = () => {
    handleClose();
    const isAdmin = org?.is_admin;
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        updateMembership({
          token: idToken,
          organizationId: org.organization_id,
          userId: user.user_id,
          isAdmin: !isAdmin,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(
            `User was changed to ${isAdmin ? "member" : "admin"} role`,
            { variant: "default" }
          );
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to update user membership!", {
            variant: "error",
          });
        }
        dispatch(
          getUserDetails({
            token: idToken,
            userId: user.user_id?.toString(),
          })
        );
      });
    })();
  };
  if (
    !hasAccess(Constants.ACTIONS.PROMOTE_DEMOTE) &&
    !hasAccess(Constants.ACTIONS.DELETE_USER_FROM_ORG)
  ) {
    return null;
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="action" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {hasAccess(Constants.ACTIONS.PROMOTE_DEMOTE) && (
          <MenuItem onClick={handleChangeMembershipClick}>{`Make ${
            org?.is_admin ? "Member" : "Admin"
          }`}</MenuItem>
        )}
        {hasAccess(Constants.ACTIONS.DELETE_USER_FROM_ORG) && (
          <MenuItem onClick={handleDeleteMembershipClick}>
            <DeleteOutlineOutlinedIcon color="error" />
            <span>
              {" "}
              <Typography color="error.main">Remove</Typography>
            </span>
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={showRemoveConfirmation}
        onClose={() => setShowRemoveConfirmation(false)}
      >
        <DialogTitle>{`Remove ${user.first_name} ${user.last_name} from ${org.organization_name}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing the user will disable information shared and the user will
            no longer have access to the Organization's sites.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowRemoveConfirmation(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDeleteMembership}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionsMenu;
