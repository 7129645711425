import { dateToUserLocalTimezone } from "utils/datetime";
import { Constants } from "utils/constants";

export const TimestampFormatter = ({
  timestamp,
  showTime,
}: {
  timestamp: string;
  showTime?: boolean;
}) => {
  const format = showTime
    ? Constants.DATE_FORMATS.DATE_TIME_SEC
    : Constants.DATE_FORMATS.DATE;
  return <>{timestamp ? dateToUserLocalTimezone(timestamp, format) : ""}</>;
};
