/*This is a custome chip to fecilitate ToolTip for chips */
import * as React from "react";
import { Box, Chip, Tooltip } from "@mui/material";
import { userCustomerTypeChipStyles } from "./customertypechip/styles";
import { Constants } from "utils/constants";
import { pick } from "lodash";

const TTToolTipChip = (props: any) => {
  const ref = React.useRef();
  const { classes } = userCustomerTypeChipStyles();
  let type = props?.type;
  let chipClass = null;

  if (type) {
    type = type.toUpperCase();
    if (type === Constants.ORGANIZATION_TYPE.INSTALLER) {
      chipClass = classes.installerChip;
    } else if (type === Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER) {
      chipClass = classes.channelPartnerChip;
    } else {
      chipClass = classes.customerChip;
    }
  }

  return (
    <Box
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
      }}
      ref={ref}
    >
      <Tooltip
        arrow
        data-tooltip-id={"cell-tooltip"}
        title={props?.toolTipText ? props.toolTipText : props?.label}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8], // bring the arrow closer to the chip
              },
            },
          ],
        }}
      >
        <Chip
          {...pick(props, [
            "color",
            "icon",
            "label",
            "size",
            "style",
            "type",
            "variant",
            "onDelete",
          ])}
          className={[chipClass].join(" ")}
        />
      </Tooltip>
    </Box>
  );
};

export default TTToolTipChip;
