import { useAuth0 } from "@auth0/auth0-react";

import {
  setIdToken,
  setIsGreensightAdmin,
  setRawIdToken,
} from "redux/reducers/authDataSlice";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { asyncThrottle } from "utils/asyncThrottle";
import { Constants } from "utils/constants";

export const useAuthData = () => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const { rawIdToken, idToken, isGreensightAdmin, actions, ready } =
    useAppSelector((state) => state.authData);

  const getRawIdToken = asyncThrottle(async () => {
    const expiration = (idToken?.exp || 0) * 1000;
    if (Date.now() > expiration) {
      await getAccessTokenSilently({
        ignoreCache: true,
        audience: window.APP_CONFIG.AUTH0_AUDIENCE,
      });
      const claims: any = await getIdTokenClaims();
      const claimsToCheck =
        claims !== undefined ? claims[Constants.ROLE_CLAIM_TYPE] : [];
      dispatch(
        setIsGreensightAdmin(claimsToCheck.includes("greensight-admin"))
      );
      dispatch(setIdToken(claims));
      dispatch(setRawIdToken(claims?.__raw));
      return claims?.__raw;
    }

    return rawIdToken;
  });
  const hasAccess = (action: string) => {
    return isGreensightAdmin ? true : actions?.indexOf(action) > -1;
  };
  return { isGreensightAdmin, getRawIdToken, hasAccess, ready };
};
