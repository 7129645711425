import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import {
  getJobsList,
  getJobsListWithFilters,
  getJobDetails,
} from "redux/actions/jobs";
import { normalizeJobList } from "utils/jobs";

interface JobsState {
  data?: any;
  jobDetail?: any;
  totalCount: number;
  loading: boolean;
  error?: string;
}

const initialState: JobsState = {
  data: undefined,
  jobDetail: undefined,
  totalCount: 0,
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    /** Get Job List Without filters - Default */
    builder.addCase(getJobsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobsList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = normalizeJobList(action.payload?.result.data);
      state.totalCount = action.payload?.result.total_count ?? 0;
    });
    builder.addCase(getJobsList.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    /** Get Job List with filters */
    builder.addCase(getJobsListWithFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobsListWithFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.data = normalizeJobList(action.payload?.result.data);
      state.totalCount = action.payload?.result.total_count ?? 0;
    });
    builder.addCase(getJobsListWithFilters.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    /** Get Job Details */
    builder.addCase(getJobDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.jobDetail = action.payload;
    });
    builder.addCase(getJobDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});
export default slice.reducer;
