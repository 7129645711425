import { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { usePromiseTracker } from "react-promise-tracker";
import { useQuery, useApolloClient } from "@apollo/client";
import { enqueueSnackbar } from "notistack";

import GridView from "../common/gridview/gridView";
import { uploadCSV } from "redux/actions/netsuite";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";
import { SurveyProcessingStatus } from "utils/types";

import {
  getUploadsParams,
  getUploadsQuery,
  uploadRowsFromQueryData,
} from "./uploadDataUtil";
import {
  defaultColumns,
  definedColumns,
  initialColumnStates,
  supportedSortColumns,
} from "./uploadColumns";
import {
  initialState,
  resetNetSuiteUploadsGrid,
  setColumns,
  setDirtyState,
  updateFiltersState,
  updateGridPropertyState,
  updateGridState,
} from "redux/reducers/netSuiteUploadsViewSlice";
import { FileUpload } from "components/common/FileUpload";

const MIN_POLL_INTERVAL = 2000;
const MAX_POLL_INTERVAL = 30000;

const FileImport = () => {
  const { getRawIdToken, hasAccess, ready } = useAuthData();
  const dispatch = useAppDispatch();
  const { promiseInProgress } = usePromiseTracker();
  const viewState = useAppSelector((state) => state.netSuiteUploadsView);
  const grid = useAppSelector((state) => state.netSuiteUploadsView.grid);
  const size: number = grid?.rowsPerPage || 50;
  const [page, setPage] = useState(grid?.pageNumber ?? 0);
  const columns = useAppSelector((state) => {
    return Array.isArray(state.netSuiteUploadsView?.columns)
      ? state.netSuiteUploadsView.columns
      : [];
  });
  const client = useApolloClient();
  const [pollInterval, setPollInterval] = useState(0);

  const { data, loading, error } = useQuery(
    getUploadsQuery(getUploadsParams(grid, "", [], [], page, size)),
    {
      skip: !ready, // attempt to prevent a canceled request during initial page load or refresh
      variables: { offset: page * size, limit: size },
      pollInterval: pollInterval, // refresh to check for ew import failures
    }
  );
  const uploads: any[] = uploadRowsFromQueryData(data);

  const [totalCount, setTotalCount] = useState(
    data?.netSuiteUploads?.totalCount || 0
  );
  useEffect(() => {
    if (loading === false) {
      const count = data?.netSuiteUploads?.totalCount || 0;
      setTotalCount(data?.netSuiteUploads?.totalCount);
      const status =
        (count && data.netSuiteUploads.edges[0].uploadRecord.importStatus) ||
        SurveyProcessingStatus.PENDING;
      if (
        pollInterval &&
        (status === SurveyProcessingStatus.COMPLETED ||
          status === SurveyProcessingStatus.FAILED)
      ) {
        enqueueSnackbar(
          `Salesforce Extended Warranty import ${
            status === SurveyProcessingStatus.COMPLETED ? "succeeded" : "failed"
          }`,
          {
            variant:
              status === SurveyProcessingStatus.COMPLETED ? "success" : "error",
          }
        );
        setPollInterval(0); // stop polling
      }
    }
    if (error) {
      enqueueSnackbar(
        `Error loading upload history: ${error.message.slice(0, 40)}`,
        {
          variant: "error",
        }
      );
      setPollInterval(Math.min(pollInterval * 2, MAX_POLL_INTERVAL)); // back off polling
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (typeof grid?.pageNumber === "number") {
      setPage(grid?.pageNumber);
    }
  }, [grid?.pageNumber, grid?.rowsPerPage]);

  const handleUpload = async (file: File | null) => {
    const idToken = await getRawIdToken();
    dispatch(
      uploadCSV({
        token: idToken,
        file: file,
      })
    ).then((resp) => {
      if (resp?.payload?.success) {
        enqueueSnackbar(`Successfully uploaded ${file?.name}`, {
          variant: "success",
        });
        client.refetchQueries({
          include: ["UploadHistory"],
        });
        setPollInterval(MIN_POLL_INTERVAL);
        if (resp?.payload?.crosswalks) {
          enqueueSnackbar(
            `${resp?.payload?.crosswalks} new relationships detected`,
            {
              variant: "warning",
            }
          );
        }
      } else {
        enqueueSnackbar(`Error uploading file`, {
          variant: "error",
        });
      }
    });
  };

  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "1080px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Grid container direction="row">
        <Grid xs={6} item>
          <Typography variant="h4" gutterBottom>
            Upload History
          </Typography>
        </Grid>
        <Grid
          xs={6}
          item
          justifyContent="flex-end"
          textAlign="right"
          alignItems="flex-end"
        >
          {(hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) ||
            hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)) && (
            <FileUpload
              value={null}
              onChange={handleUpload}
              sx={{ marginRight: "0px" }}
              name="equipment_sheet"
              disabled={false}
            />
          )}
        </Grid>
      </Grid>
      <Stack pt={1} sx={{ overflow: "auto" }}>
        <GridView
          columns={columns}
          defaultColumns={defaultColumns}
          definedColumns={definedColumns}
          filters={initialState.filters}
          initialColumnStates={initialColumnStates}
          loading={loading && !promiseInProgress}
          resetAll={resetNetSuiteUploadsGrid}
          rows={uploads}
          setColumns={setColumns}
          setDirtyState={setDirtyState}
          supportedSortColumns={supportedSortColumns}
          totalCount={totalCount}
          updateFiltersState={updateFiltersState}
          updateGridPropertyState={updateGridPropertyState}
          updateGridState={updateGridState}
          useLoadingBackdrop={false}
          viewState={viewState}
          yOffset={265} // Encompasses App bar height, tabs, and tab title
        />
      </Stack>
    </Stack>
  );
};

export default FileImport;
