import { useState } from "react";
import { Typography } from "@mui/material";

import TTTextField from "./TTTextField";

const TTEditableText = (props: {
  editable: boolean;
  value: string | number;
  type?: string;
  inputProps?: any;
  multiline?: boolean;
  onChange?: (value: string) => void;
}) => {
  const { editable, value, type, inputProps, multiline, onChange } = props;
  const [text, setText] = useState<string | number>(value);

  const handleTextChange = (event: { target: { value: any } }) => {
    setText(event.target.value);
    onChange?.(event.target.value);
  };

  return editable ? (
    <TTTextField
      value={text}
      onChange={handleTextChange}
      type={type || "text"}
      inputProps={inputProps}
      multiline={multiline}
      rows={multiline ? 4 : 1}
      sx={{ width: multiline ? "50%" : "auto" }}
    />
  ) : (
    <Typography>{value}</Typography>
  );
};

export default TTEditableText;
