import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { isMobile } from "react-device-detect";
import Divider from "@mui/material/Divider";

import { useAppDispatch } from "../../../redux/store/hooks";
import { clearFilter } from "redux/reducers/filtersSlice";
import SiteFilter from "./siteFilter";
import OrgsFilter from "./orgsFilter";
import DateFilter from "./dateFilter";
import TemplateFilter from "./templateFilter";
import AdditionalFilter from "./additionalFilter";
import FilterChips from "./filterChips";

const JobsFilter = (props: {
  onApply?: () => void;
  onClear?: () => void;
  exportData?: any;
  disableExport?: boolean;
}) => {
  const { onApply, onClear /*exportData, disableExport*/ } = props;
  const dispatch = useAppDispatch();

  const handleClearFilter = () => {
    dispatch(clearFilter());
    onClear?.();
  };

  const handleApplyFilter = () => {
    onApply?.();
  };

  return (
    <Box marginBottom={2}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 6, sm: 8, md: 12 }}
          >
            <Grid item sx={{ marginBottom: isMobile ? 2 : 0 }}>
              <SiteFilter onApplyFilter={handleApplyFilter} />
            </Grid>
            <Grid item>
              <OrgsFilter onApplyFilter={handleApplyFilter} />
            </Grid>
            <Grid item>
              <DateFilter
                title="Date Started"
                dateType="dateStarted"
                onApplyFilter={handleApplyFilter}
              />
            </Grid>
            <Grid item>
              <DateFilter
                title="Date Completed"
                dateType="dateCompleted"
                onApplyFilter={handleApplyFilter}
              />
            </Grid>
            <Grid item>
              <TemplateFilter onApplyFilter={handleApplyFilter} />
            </Grid>
            <Grid item>
              <AdditionalFilter onApplyFilter={handleApplyFilter} />
            </Grid>
            <Grid item>
              <Divider
                orientation="vertical"
                sx={{ marginTop: "2px", marginBottom: "6px" }}
              />
            </Grid>

            <Grid item>
              <Button variant="text" onClick={handleClearFilter}>
                CLEAR
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Right action buttons */}
        {/* Export is currently disabled
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item>
              <TTExportToCSV exportData={exportData} disabled={disableExport} /> 
            </Grid>
          </Grid>
        </Grid>
        */}
      </Grid>

      {/* FIlter Chips */}
      <FilterChips onApplyFilter={handleApplyFilter} />
    </Box>
  );
};

export default JobsFilter;
