import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const UserMenuComponent = () => {
  const { user, logout } = useAuth0();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    logout({
      returnTo: `${window.location.origin}${"/logout"}`,
    });
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="User Options">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {/* Use picture from Auth0 profile if present or use first 2 letters of name
          Fallback to default user icon if both fails */}
          {user?.picture ? (
            <Avatar src={user.picture} />
          ) : user?.name ? (
            <Avatar>{user.name.slice(0, 2)}</Avatar>
          ) : (
            <Avatar>
              <PersonIcon />
            </Avatar>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleCloseUserMenu}>{user?.name}</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export const UserMenu = UserMenuComponent;
