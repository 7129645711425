import React, { useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Tooltip } from "react-tooltip";
import {
  Asset,
  Drive,
  DriveEdge,
  Maybe,
  PropertyChange,
} from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";
import { sortDeviceEdges } from "./AssetDetailsUtil";
import TTToolTipText from "components/common/TTToolTipText";
import TreeDataGrid, {
  Column,
  FormatterProps,
  SortColumn,
} from "react-data-grid";
import { dataGridStyle } from "theme/styles";
import {
  DETAIL_GRID_ROW_HEIGHT,
  DETAIL_GRID_HEADER_HEIGHT,
} from "./AssetDetailsUtil";
import { dateToUserLocalTimezone } from "utils/datetime";
import { groupBy as rowGrouper, snakeCase } from "lodash";
import { getCompareFn, stringComparator } from "utils/comparator";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import {
  sortColumnForGridState,
  updateGridState,
} from "components/common/gridview/gridState";
import {
  setGrid,
  setGrouped,
  DEFAULT_SORT,
} from "redux/reducers/assetConfigHistoryGridSlice";

function getRowKey(row: PropertyChange) {
  return `${row.integrationKey}-${row.updatedDt}-${row.newValue}`;
}

function getRowDate(row: PropertyChange) {
  return row.updatedDt;
}

const formatRowDate = (row: PropertyChange) => {
  const dt = getRowDate(row);
  return dt ? dateToUserLocalTimezone(dt) : "";
};

const createColumns = [
  {
    key: "updatedDt",
    name: "Time",
    formatter(
      props: React.PropsWithChildren<FormatterProps<PropertyChange, unknown>>
    ) {
      const dateLabel = formatRowDate(props.row);
      return <TTToolTipText text={dateLabel} tooltip={dateLabel} />;
    },
  },
  {
    key: "updatedBy",
    name: "Completed By",
    formatter(
      props: React.PropsWithChildren<FormatterProps<PropertyChange, unknown>>
    ) {
      return (
        <TTToolTipText
          text={props.row.updatedBy || ""}
          link={props.row.updatedBy ? `mailto:${props.row.updatedBy}` : ""}
        />
      );
    },
  },
  {
    key: "name",
    name: "Property",
    formatter(
      props: React.PropsWithChildren<FormatterProps<PropertyChange, unknown>>
    ) {
      return (
        <TTToolTipText
          text={props.row.name || ""}
          tooltip={props.row.description || props.row.name || ""}
        />
      );
    },
  },
  {
    key: "oldValue",
    name: "Old Value",
    formatter(
      props: React.PropsWithChildren<FormatterProps<PropertyChange, unknown>>
    ) {
      return <TTToolTipText text={props.row.oldValue || ""} />;
    },
  },
  {
    key: "newValue",
    name: "New Value",
    formatter(
      props: React.PropsWithChildren<FormatterProps<PropertyChange, unknown>>
    ) {
      return <TTToolTipText text={props.row.newValue || ""} />;
    },
  },
];

const formatGroupLabel = (row: PropertyChange) =>
  `${formatRowDate(row)} - ${row.updatedBy || ""}`;

// Replace the first two columns above with a grouping column
const groupedColumns = [
  {
    key: "group",
    name: `${createColumns[0].name} - ${createColumns[1].name}`,
    minWidth: 380,
    formatter(
      props: React.PropsWithChildren<FormatterProps<PropertyChange, unknown>>
    ) {
      const dateLabel = formatRowDate(props.row);
      return (
        <Grid container direction={"row"}>
          <Grid item pr={2}>
            <TTToolTipText text={dateLabel} tooltip={dateLabel} />
          </Grid>
          <Grid item>
            <TTToolTipText
              text={props.row.updatedBy || ""}
              link={props.row.updatedBy ? `mailto:${props.row.updatedBy}` : ""}
            />
          </Grid>
        </Grid>
      );
    },
  },
  ...createColumns.slice(2),
];

// Sort by the first column, then by the first entry in sortColumns
const getGroupCompareFn =
  (sortColumns: readonly SortColumn[]) => (a: any, b: any) => {
    if (a.group === b.group) {
      const compareFn = getCompareFn(sortColumns);
      return compareFn(a, b);
    }
    return stringComparator("group")(a, b);
  };

const filterHistory = (
  propertyFilter: string,
  history?: Maybe<PropertyChange[]> | undefined
) => {
  if (!propertyFilter || !history) {
    return history;
  }
  return history.filter((change) =>
    change.name
      ?.toLocaleLowerCase()
      .includes(propertyFilter.toLocaleLowerCase())
  );
};

interface HistoryCardProps {
  drive: Drive;
  isGrouped: boolean;
  propertyFilter: string;
  onChangeData: (sortColumns?: readonly SortColumn[]) => void;
  sortColumn?: SortColumn;
}

const HistoryCard = (props: HistoryCardProps) => {
  const { drive, isGrouped, propertyFilter, onChangeData, sortColumn } = props;

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>(
    sortColumn ? [sortColumn] : []
  );
  const columns = React.useMemo(
    (): readonly Column<PropertyChange>[] =>
      isGrouped ? groupedColumns : createColumns,
    [isGrouped]
  );
  const selectedOptions = isGrouped ? ["group"] : [];
  const [expandedGroupIds, setExpandedGroupIds] = useState(
    (): ReadonlySet<unknown> => new Set<unknown>([])
  );

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    onChangeData?.(sortColumns);
    setSortColumns(sortColumns);
  };

  const [groupCount, setGroupCount] = useState(0);

  // history comes back sorted descending by date
  const sortedRows = useMemo((): readonly PropertyChange[] => {
    const rows = filterHistory(propertyFilter, drive?.history?.slice()) || [];
    if (isGrouped) {
      const groupRows = rows.map((row) => ({
        group: formatGroupLabel(row),
        ...row,
      }));
      const groupNames = new Set(groupRows.map((row) => row.group));
      setGroupCount(groupNames.size);
      if (sortColumns.length === 0) return groupRows;
      return groupRows.sort(getGroupCompareFn(sortColumns));
    }
    setGroupCount(0);
    if (sortColumns.length === 0) return rows;
    return rows.sort(getCompareFn(sortColumns));
  }, [drive?.history, isGrouped, propertyFilter, sortColumns]);

  return (
    <Stack m={1}>
      <Typography
        variant="body2"
        mt={2}
        sx={{
          fontWeight: "bold",
        }}
      >
        {`${drive?.driveModel} — ${drive?.serialNumber}`}
      </Typography>
      <Box
        style={{
          width: "100%",
        }}
        className="sites-data-grid"
        mt={1}
      >
        <TreeDataGrid
          style={{
            ...dataGridStyle,
            cursor: "pointer",
            border: "none",
            boxShadow: "none",
            minHeight:
              DETAIL_GRID_ROW_HEIGHT +
              DETAIL_GRID_HEADER_HEIGHT +
              5 +
              groupCount * DETAIL_GRID_ROW_HEIGHT,
            height: isGrouped
              ? "auto"
              : DETAIL_GRID_ROW_HEIGHT * sortedRows.length +
                DETAIL_GRID_HEADER_HEIGHT +
                5,
          }}
          className={"rdg-light"}
          columns={columns}
          rows={sortedRows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowKeyGetter={getRowKey}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          rowGrouper={rowGrouper}
          rowHeight={DETAIL_GRID_ROW_HEIGHT}
          headerRowHeight={DETAIL_GRID_HEADER_HEIGHT}
          groupBy={selectedOptions}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
        />
        <Tooltip
          id="cell-tooltip"
          place="left"
          style={{ maxWidth: "250px", zIndex: 999 }}
        />
      </Box>
    </Stack>
  );
};

const columnNameToField = (name: string) => {
  return snakeCase(name);
};

const fieldToColumnName = (name: string) => {
  return name;
};

const collectPropertyKeys = (driveEdges?: DriveEdge[]) => {
  if (!driveEdges) {
    return [];
  }
  const keys = new Set<string>();
  for (const edge of driveEdges) {
    if (edge?.drive?.history) {
      for (const change of edge.drive.history) {
        if (change?.name) {
          keys.add(change.name);
        }
      }
    }
  }
  return Array.from(keys);
};

const AssetConfigHistoryCard = (props: { asset?: Asset }) => {
  const { asset } = props;
  const gridState = useAppSelector(
    (state) => state.assetConfigHistoryGrid.grid
  );
  const isGrouped = useAppSelector(
    (state) => state.assetConfigHistoryGrid.isGrouped
  );
  const dispatch = useAppDispatch();
  const options = collectPropertyKeys(asset?.drives?.edges);
  const [propertyFilter, setPropertyFilter] = useState<string>("");

  // sort the asset's drives by installed date
  const drives = sortDeviceEdges(asset?.drives?.edges) as DriveEdge[];
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGrouped(event.target.checked));
  };

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    if (sortColumns) {
      const updatedGridState = updateGridState(
        gridState,
        columnNameToField,
        DEFAULT_SORT,
        undefined,
        sortColumns
      );
      dispatch(setGrid(updatedGridState));
    }
  };

  if (drives?.length) {
    return (
      <Paper
        elevation={1}
        sx={{
          padding: 3,
          border: "1px solid #E0E0E0",
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item xs>
            <Typography
              variant="body1"
              sx={{
                wordWrap: "break-word",
                paddingRight: 2,
                fontWeight: "bold",
              }}
            >
              Asset Config History
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              filterOptions={(o) => o}
              options={options}
              value={propertyFilter}
              autoComplete
              includeInputInList
              getOptionLabel={(option: any) => option}
              filterSelectedOptions
              noOptionsText="No locations"
              onChange={(event: any, newValue: any) => {
                setPropertyFilter(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setPropertyFilter(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Properties" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        sx={{
                          width: "calc(100% - 44px)",
                          wordWrap: "break-word",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {option}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
              freeSolo
              forcePopupIcon
            />
          </Grid>
          <Grid item xs={2} pl={1}>
            <FormControlLabel
              key={"group"}
              control={
                <Checkbox
                  size="small"
                  checked={isGrouped}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange?.(event);
                  }}
                />
              }
              label={"Group by Job"}
            />
          </Grid>
        </Grid>
        {drives.map((driveEdge) => (
          <HistoryCard
            key={driveEdge.drive.id}
            drive={driveEdge.drive}
            isGrouped={isGrouped}
            propertyFilter={propertyFilter}
            onChangeData={handleChangeData}
            sortColumn={sortColumnForGridState(gridState, fieldToColumnName)}
          />
        ))}
      </Paper>
    );
  }
  return null;
};

export default createFragmentContainer(AssetConfigHistoryCard, {
  asset: gql`
    fragment AssetConfigHistoryCard_asset on Asset {
      id
      drives {
        edges {
          drive {
            history(assetId: $assetId) {
              integrationKey
              name
              description
              updatedBy
              updatedDt
              newValue
              oldValue
            }
          }
        }
      }
    }
  `,
});
