/**Returns file extension for attachment URL's */
const getAttachmentFileExtension = (url: string): string | undefined => {
  const re = /(?:([^.]+))?$/;
  /**Original URL contains qury string
   * Split using ? before executing regex
   */
  const ext = re.exec(url?.split("?")?.[0]);
  return ext?.[1]?.toLowerCase();
};

export default getAttachmentFileExtension;
