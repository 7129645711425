import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState, SyntheticEvent } from "react";
import OrganizationSitesDataGrid from "../sitesDataGrid";
import { useCustomersStyles } from "./styles";
const OrgCustomers = (props: {
  rows: any[];
  showTitle?: boolean;
  className?: any;
  canDelete?: boolean;
  onRemove?: any | undefined;
  isGreensightAdmin?: any | undefined;
}) => {
  const { classes } = useCustomersStyles();
  const {
    rows,
    className,
    showTitle = true,
    canDelete = false,
    onRemove,
    isGreensightAdmin,
  } = props;
  const [expandedAccordion, setExpandedAccordion] = useState<string>("");
  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : "");
    };
  return (
    <Box sx={{ width: "100%", marginBottom: "32px" }} className={className}>
      {showTitle ? (
        <Typography variant="h6" gutterBottom>
          Customers
        </Typography>
      ) : (
        <></>
      )}
      {rows?.length ? (
        <>
          {rows.map((row) => {
            return (
              <Accordion
                className={classes.customerAccordion}
                key={row.organization_id}
                expanded={expandedAccordion === row.organization_id}
                onChange={handleAccordionChange(row.organization_id)}
              >
                <AccordionSummary
                  className={classes.customerAccordionSummary}
                  sx={{
                    flexDirection: "row-reverse",
                    display: "flex",
                    paddingRight: "8px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid container direction="row">
                    <Grid item xs={9}>
                      <Typography>{row.organization_name}</Typography>
                    </Grid>
                    <Grid item xs={3} alignItems="flex-end">
                      <Box justifyContent="flex-end" display={"flex"}>
                        {" "}
                        {canDelete && isGreensightAdmin && (
                          <IconButton
                            aria-label="delete"
                            size="small"
                            style={{ padding: 0 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onRemove(row);
                            }}
                          >
                            {" "}
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {row?.sites?.length ? (
                    <OrganizationSitesDataGrid
                      height={"auto"}
                      rows={row.sites}
                      dynamicPaging={true}
                    ></OrganizationSitesDataGrid>
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        paddingBottom: "5px",
                      }}
                    >
                      No sites found
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : (
        <Box sx={{ height: "100%", paddingLeft: "10px", paddingBottom: "5px" }}>
          No assigned customers.
        </Box>
      )}
    </Box>
  );
};

export default OrgCustomers;
