import { makeStyles } from "tss-react/mui";

const margins = { marginTop: 10, marginLeft: 10 };

export const useTplEditorStyles = makeStyles()((theme) => {
  return {
    editorInputFullWidth: { width: "100%", ...margins },
    editorInput: { width: "350px", ...margins },
    editorInputSmall: { width: "80px", ...margins },
    editorInputNoMargin: { marginLeft: 0 },
    textField: margins,
    flexRow: { display: "flex", flexDirection: "row" },
  };
});
