import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { GridColDef, DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  getSimpleUnassignedSurveyTemplatesList,
  loadSurveyTemplatesOrganizationsSummary,
} from "../../services/surveytemplates";
import { sortBy } from "lodash";
import { useAuthData } from "utils/hooks/useAuthData";
const SurveyTemplateOrganizationsSummary = () => {
  const { getRawIdToken, ready } = useAuthData();
  const [byTplRows, setByTplRows] = useState<any[]>([]);
  useEffect(() => {
    if (ready) {
      const getSummaryData = async () => {
        const idToken = await getRawIdToken();
        let unassigned = [];
        let byTpl = [];
        const unassignedTemplates =
          await getSimpleUnassignedSurveyTemplatesList(idToken);
        if (unassignedTemplates && unassignedTemplates.result) {
          unassigned = unassignedTemplates.result.map((item: any) => {
            return {
              template_name: item.name,
              template_id: item.template_id,
              id: item.template_id,
              organizations: "",
              organizations_count: 0,
            };
          });
        }
        const summary = await loadSurveyTemplatesOrganizationsSummary(idToken);
        if (summary && summary.result && summary.result.by_template) {
          byTpl = summary.result.by_template.map((item: any) => {
            return {
              template_name: item.template_name,
              template_id: item.template_id,
              id: item.template_id,
              organizations:
                item.organizations.length > 5
                  ? "More than 5"
                  : item.organizations
                      .map((org: any) => {
                        return org.organization_name;
                      })
                      .join(", "),
              organizations_count: item.organizations.length,
            };
          });
        }

        setByTplRows([...byTpl, ...unassigned]);
        if (summary && summary.result && summary.result.by_organization) {
          summary.result.by_organization.forEach(
            (org: any) =>
              (org.templates = sortBy(org.templates, (tpl) => {
                return tpl.template_name;
              }))
          );
        }
      };
      getSummaryData();
    }
  }, [ready]);

  const byTplCols: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <Button
              size="small"
              variant="text"
              component={Link}
              to={`../template/edit/${params.row.template_id}`}
            >
              Edit
            </Button>
          </div>
        );
      },
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "template_name",
      headerName: "Template Name",
      flex: 1,
    },
  ];

  return (
    <Box style={{ width: "100%" }}>
      <Box display={"flex"} flexDirection={"row"} mb={2}>
        <Typography variant="h4" gutterBottom flex={1}>
          Templates
        </Typography>
        <Button
          variant="outlined"
          size="medium"
          sx={{ height: 40, mt: 1 }}
          component={Link}
          to={`../template/edit/`}
        >
          New template
        </Button>
      </Box>
      <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
        <DataGrid
          rows={byTplRows}
          columns={byTplCols}
          rowHeight={35}
          initialState={{
            sorting: { sortModel: [{ field: "template_name", sort: "asc" }] },
          }}
        />
      </div>
    </Box>
  );
};
export default SurveyTemplateOrganizationsSummary;
