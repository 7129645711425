import * as React from "react";
import { Box } from "@mui/material";
import { take } from "lodash";
import Stack from "@mui/material/Stack";
import { UsersOrgs } from "models/users";
import TTToolTipChip from "./TTToolTipChip";
import { userCustomerTypeChipStyles } from "./customertypechip/styles";
const MAX_COL_WIDTH = 400;

const TTMultiOrgChip = (props: { chips: Array<UsersOrgs> }) => {
  const { chips } = props;
  const { classes } = userCustomerTypeChipStyles();
  const ref = React.useRef();
  const numOfVisibleChips = chips?.length > 3 ? 3 : chips?.length;

  const RenderChip = (props: { chip: UsersOrgs }) => {
    const { chip } = props;
    let type = chip.types[0];

    return (
      <TTToolTipChip
        key={chip.organization_id}
        label={chip.organization_name}
        color="primary"
        variant="outlined"
        size="small"
        type={type}
        style={{
          maxWidth: MAX_COL_WIDTH / numOfVisibleChips,
        }}
      />
    );
  };

  return (
    <Box style={{ overflow: "hidden" }} ref={ref}>
      <Stack direction="row" spacing={1}>
        {take(chips, 3).map((chip: UsersOrgs) => (
          <RenderChip key={chip.organization_id} chip={chip} />
        ))}
        {chips?.length > 3 && (
          <TTToolTipChip
            label={`${chips?.length - numOfVisibleChips} more`}
            toolTipText={[...chips]
              .splice(3)
              .map((chip) => chip.organization_name)
              .join(",")}
            variant="outlined"
            size="small"
            className={[classes.mutedChip]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default TTMultiOrgChip;
