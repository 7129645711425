import PropertyDetail from "components/common/PropertyDetail";

const JobHeaderInfo = (props: { value: string; label: string }) => {
  return (
    <PropertyDetail
      value={props.value}
      label={props.label}
      titleTextTransform="uppercase"
    />
  );
};

export default JobHeaderInfo;
