import { useEffect } from "react";
import { Box, Typography, Grid, Button, Dialog } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import {
  addUserTtRoles,
  getUserDetails,
  getUserTtRoles,
  removeUserTtRole,
} from "redux/actions/users";
import { UsersRow } from "models/users";
import { RenderBackToUsersListButton } from "..";
import UsersTtRolesGrid from "./datagrid";
import { useUserRolesStyles } from "./styles";
import * as assignTtRolesToUserModal from "redux/reducers/assignTtRolesToUserSlice";
import { getRolesForOrgs } from "redux/actions/orgs";
import TTAutoComplete from "components/common/TTAutoComplete/TTAutoComplete";
import { enqueueSnackbar } from "notistack";
import { useAuthData } from "utils/hooks/useAuthData";
import {
  sortColumnForGridState,
  updateGridState,
} from "components/common/gridview/gridState";
import { cloneDeep, snakeCase } from "lodash";
import { setGrid, DEFAULT_SORT } from "redux/reducers/userRolesGridSlice";
import { SortColumn } from "react-data-grid";

const columnNameToField = (name: string) => {
  return snakeCase(name);
};

const fieldToColumnName = (name: string) => {
  return name;
};

const UserRoles = () => {
  const { classes } = useUserRolesStyles();
  const { getRawIdToken } = useAuthData();
  const { userId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user: UsersRow = useAppSelector((state) => state.users.userDetail);
  const assignTtRolesToUser = useAppSelector(
    (state) => state.assignTtRolesToUser
  );
  const userRoles = useAppSelector((state) => state.userTtRoles);
  const loading = useAppSelector((state) => state.users.loading);
  const gridState = useAppSelector((state) => state.userRolesGrid.grid);

  useEffect(() => {
    (async () => {
      const idToken = await getRawIdToken();
      dispatch(
        getUserDetails({
          token: idToken,
          userId: userId,
        })
      );
      dispatch(
        getUserTtRoles({
          token: idToken,
          userId: userId,
        })
      );
    })();
  }, [userId]);

  const handleAssignTtRoleDialogOpen = () => {
    (async () => {
      const orgIds = user.organizations.map((org: any) => {
        return org.organization_id;
      });
      const idToken = await getRawIdToken();
      dispatch(
        assignTtRolesToUserModal.setCurrentRoles(
          userRoles?.roles?.map((ur: any) => {
            return ur.role_id;
          })
        )
      );
      dispatch(
        getRolesForOrgs({
          token: idToken,
          organizationIds: orgIds,
        })
      ).then(() => {
        dispatch(assignTtRolesToUserModal.setOpen(true));
        dispatch(assignTtRolesToUserModal.setSelectedRoles([]));
      });
    })();
  };

  const handleAssignTtRoleDialogClose = () => {
    dispatch(assignTtRolesToUserModal.setOpen(false));
  };

  const handleRoleSelected = (newValue: any) => {
    dispatch(assignTtRolesToUserModal.setSelectedRoles(newValue));
  };

  const handleAssignRolesClick = () => {
    (async () => {
      const idToken = await getRawIdToken();
      dispatch(
        addUserTtRoles({
          token: idToken,
          userId: userId,
          roleIds: assignTtRolesToUser.selectedRoles.map((r) => {
            return r.role_id;
          }),
        })
      )
        .then((resp) => {
          dispatch(
            getUserTtRoles({
              token: idToken,
              userId: userId,
            })
          );
          enqueueSnackbar("Role(s) assigned", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Failed to assign roles", { variant: "error" });
        })
        .finally(() => {
          dispatch(assignTtRolesToUserModal.setOpen(false));
          dispatch(assignTtRolesToUserModal.setSelectedRoles([]));
        });
    })();
  };

  const handleDeleteUserTtRoleClick = (data: any) => {
    (async () => {
      const idToken = await getRawIdToken();
      dispatch(
        removeUserTtRole({
          token: idToken,
          userId: userId,
          roleId: data.role_id,
        })
      )
        .then((resp: any) => {
          if (resp && resp.payload) {
            dispatch(
              getUserTtRoles({
                token: idToken,
                userId: userId,
              })
            );
            enqueueSnackbar("Role unassigned", { variant: "success" });
          } else {
            enqueueSnackbar("Failed to unassign role", { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to unassign role", { variant: "error" });
        });
    })();
  };

  const handleChangeData = (
    rows: any,
    orderedColumns: any,
    sortColumns?: readonly SortColumn[]
  ) => {
    if (orderedColumns || sortColumns) {
      const updatedGridState = updateGridState(
        gridState,
        columnNameToField,
        DEFAULT_SORT,
        orderedColumns,
        sortColumns
      );
      dispatch(setGrid(updatedGridState));
    }
  };

  const handleChangeView = (index: number, width: number) => {
    const updatedGridState = cloneDeep(gridState);
    updatedGridState.columnStates[index].width = width;
    dispatch(setGrid(updatedGridState));
  };

  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get("from");
  const label = searchParams.get("label");
  return user && !loading ? (
    <Box
      style={{
        width: "100%",
        maxWidth: "1080px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <RenderBackToUsersListButton
        path={from ?? undefined}
        label={label ?? undefined}
      />
      <Box component={"div"} className={classes.headerBox}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.headerGrid}
        >
          <Grid item>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginBottom: "0px",
              }}
            >
              {user.activated_at
                ? `${user.first_name} ${user.last_name}`
                : "- -"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleAssignTtRoleDialogOpen}
            >
              Assign roles
            </Button>
          </Grid>
        </Grid>
      </Box>
      {userRoles?.roles?.length > 0 ? (
        <UsersTtRolesGrid
          rows={userRoles?.roles}
          onRemove={handleDeleteUserTtRoleClick}
          onChangeData={handleChangeData}
          onChangeView={handleChangeView}
          sortColumn={sortColumnForGridState(gridState, fieldToColumnName)}
          reorderedColumns={gridState.columnStates}
        />
      ) : (
        <Box sx={{ height: "100%", marginTop: "15px" }}>
          User has no roles assigned
        </Box>
      )}
      <Dialog
        open={assignTtRolesToUser.open}
        onClose={handleAssignTtRoleDialogClose}
      >
        <Box
          style={{
            width: "543px",
            height: "214px",
            padding: "16px 24px 24px 20px",
          }}
        >
          <Typography variant="h6">
            Assign roles to{" "}
            {user.activated_at ? `${user.first_name} ${user.last_name}` : "- -"}
          </Typography>

          <TTAutoComplete
            options={
              assignTtRolesToUser?.availableRoles
                ? assignTtRolesToUser?.availableRoles
                : []
            }
            label="start typing to search available roles..."
            primaryField="role_name"
            primaryKey="role_id"
            onChange={handleRoleSelected}
            value={assignTtRolesToUser.selectedRoles}
            classes={{ root: classes.roleSelectorAutocomplete }}
            size="medium"
            filterSelectedOptions={true}
          />
          <Grid
            container
            direction="row"
            justifyContent={"flex-end"}
            marginTop="33px"
          >
            <Grid item>
              <Button
                size="medium"
                onClick={handleAssignTtRoleDialogClose}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                onClick={handleAssignRolesClick}
                disabled={assignTtRolesToUser.selectedRoles.length === 0}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  ) : null;
};

export default UserRoles;
