import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import { getSimpleTemplateList } from "../actions/templates";
import { getGroupedTemplateList } from "utils/jobs";

interface SitesState {
  data?: any;
  groupedData?: any;
  loading: boolean;
  error?: string;
}

const initialState: SitesState = {
  data: undefined,
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getSimpleTemplateList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSimpleTemplateList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.groupedData = getGroupedTemplateList(action.payload);
    });
    builder.addCase(getSimpleTemplateList.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
