import { Box, Grid, Typography, Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { deleteRole, listRoles } from "redux/actions/roles";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import RolesListDataGrid from "./dataGrid";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "utils/hooks/useAuthData";
import { enqueueSnackbar } from "notistack";
import {
  sortColumnForGridState,
  updateGridState,
} from "components/common/gridview/gridState";
import { cloneDeep, snakeCase } from "lodash";
import { setGrid, DEFAULT_SORT } from "redux/reducers/rolesGridSlice";
import { SortColumn } from "react-data-grid";

const columnNameToField = (name: string) => {
  return snakeCase(name);
};

const fieldToColumnName = (name: string) => {
  return name;
};

export const Roles = () => {
  const rolesData = useAppSelector((state) => state.rolesList);
  const gridState = useAppSelector((state) => state.rolesGrid.grid);
  const dispatch = useAppDispatch();
  const { getRawIdToken, ready } = useAuthData();
  const navigate = useNavigate();
  const [removeRole, setRemoveRole] = useState<{
    open: boolean;
    role: any | undefined;
  }>({ open: false, role: {} });
  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          listRoles({
            token: idToken,
          })
        );
      })();
    }
  }, [ready]);
  const handleRemoveRolePromptOpen = (role: any) => {
    const rp = { ...removeRole };
    rp.open = true;
    rp.role = role;
    setRemoveRole(rp);
  };
  const handleRemoveRolePromptClose = () => {
    const rp = { ...removeRole };
    rp.open = false;
    rp.role = undefined;
    setRemoveRole(rp);
  };
  const handleRemoveRole = () => {
    (async () => {
      const idToken = await getRawIdToken();
      const role = { ...removeRole.role };
      handleRemoveRolePromptClose();
      dispatch(
        deleteRole({
          token: idToken,
          roleId: role.role_id,
        })
      )
        .then((resp) => {
          if (resp) {
            enqueueSnackbar("Role removed!", { variant: "default" });
          } else {
            enqueueSnackbar("Failed to remove role", { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to remove role", { variant: "error" });
        })
        .finally(() => {
          dispatch(
            listRoles({
              token: idToken,
            })
          );
        });
    })();
  };

  const handleChangeData = (
    rows: any,
    orderedColumns: any,
    sortColumns?: readonly SortColumn[]
  ) => {
    if (orderedColumns || sortColumns) {
      const updatedGridState = updateGridState(
        gridState,
        columnNameToField,
        DEFAULT_SORT,
        orderedColumns,
        sortColumns
      );
      dispatch(setGrid(updatedGridState));
    }
  };

  const handleChangeView = (index: number, width: number) => {
    const updatedGridState = cloneDeep(gridState);
    updatedGridState.columnStates[index].width = width;
    dispatch(setGrid(updatedGridState));
  };

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: "1080px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Grid container direction="row">
        <Grid xs={6} item>
          <Typography variant="h4" gutterBottom>
            Roles (Alpha)
          </Typography>
        </Grid>
        <Grid xs={6} item alignItems="flex-end">
          <Box marginTop="5px" justifyContent="flex-end" display={"flex"}>
            <Button
              size="small"
              onClick={() => {
                navigate("/roles/edit");
              }}
              className={"back-to-list-button"}
            >
              {" "}
              <AddIcon />
              Add role
            </Button>
          </Box>
        </Grid>
      </Grid>
      <>
        {rolesData?.roles?.length ? (
          <RolesListDataGrid
            rows={rolesData?.roles}
            onRemove={handleRemoveRolePromptOpen}
            onChangeData={handleChangeData}
            onChangeView={handleChangeView}
            sortColumn={sortColumnForGridState(gridState, fieldToColumnName)}
            reorderedColumns={gridState.columnStates}
          />
        ) : rolesData?.loading ? (
          <></>
        ) : (
          <Box sx={{ height: "100%" }}>No roles found</Box>
        )}
      </>
      <Dialog open={removeRole.open} onClose={handleRemoveRolePromptClose}>
        <Box
          style={{
            width: "486px",
            height: "155px",
            padding: "16px 24px 24px 20px",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 500 }}>
            Remove role
          </Typography>{" "}
          <Typography
            variant="body1"
            style={{ marginTop: "12px", marginBottom: "12px" }}
          >
            Are you sure you want to remove role{" "}
            <b>{removeRole?.role?.role_name}</b>?
          </Typography>
          <Grid container direction="row" justifyContent={"flex-end"}>
            <Grid item>
              <Button
                size="medium"
                onClick={handleRemoveRolePromptClose}
                style={{ marginRight: "8px", color: "#000", fontWeight: 500 }}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                color="error"
                onClick={handleRemoveRole}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};
