import * as React from "react";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { isEmpty, debounce } from "lodash";

import { Constants } from "utils/constants";
import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import { updateFilter } from "redux/reducers/filtersSlice";

const FilterChips = (props: { onApplyFilter?: () => void }) => {
  const { onApplyFilter } = props;
  const dispatch = useAppDispatch();

  const jobFilters = useAppSelector((state) => state.filters?.jobFilters);

  const extraFilters = useAppSelector(
    (state) => state.filters?.jobFilters.extra
  );

  const [filterChips, setFilterChips] = React.useState<any>(false);

  const channelPartnerFilter = useAppSelector(
    (state) => state.filters?.jobFilters.channelPartner
  );

  const customerFilter = useAppSelector(
    (state) => state.filters?.jobFilters.customer
  );

  const installerFilter = useAppSelector(
    (state) => state.filters?.jobFilters.installer
  );

  const dateStartedFilter = useAppSelector(
    (state) => state.filters?.jobFilters.dateStarted
  );

  const dateCompletedFilter = useAppSelector(
    (state) => state.filters?.jobFilters.dateCompleted
  );

  const templatesFilter = useAppSelector(
    (state) => state.filters?.jobFilters.template
  );

  const templatesObjects = useAppSelector((state) => state.templates);

  const templatesData = () => {
    const _data = [] as any[];
    if (templatesFilter) {
      templatesObjects?.data.forEach((template: any) => {
        if (
          template?.template_id &&
          templatesFilter.includes(template?.template_id)
        ) {
          _data.push(template);
        }
      });
    }
    return _data;
  };

  const handleTemplateFilters = (template_id: string) => {
    let _selected = [...jobFilters["template"]];
    _selected = _selected.filter((item) => item !== template_id);
    dispatch(updateFilter({ filterType: "template", value: [..._selected] }));
    setFilterChips({ filterType: "template", value: [..._selected] });
  };

  const handleDeleteDateStartedFilter = (filterType: string) => {
    const updatedRange = {
      ...dateStartedFilter.range,
      [filterType as keyof typeof dateStartedFilter.range]: null,
    };
    const updatedFilter = { ...dateStartedFilter, range: updatedRange };
    const isSelected =
      typeof updatedFilter?.range?.from === "string" ||
      typeof updatedFilter?.range?.to === "string";
    if (!isSelected) {
      updatedFilter.option = -1;
    }
    dispatch(
      updateFilter({
        filterType: "dateStarted",
        value: {
          ...updatedFilter,
        },
      })
    );
    setFilterChips({
      filterType: "dateStarted",
      value: {
        ...updatedFilter,
      },
    });
  };

  const handleDeleteDateCompletedFilter = (filterType: string) => {
    const updatedRange = {
      ...dateCompletedFilter.range,
      [filterType as keyof typeof dateCompletedFilter.range]: null,
    };
    const updatedFilter = { ...dateCompletedFilter, range: updatedRange };
    const isSelected =
      typeof updatedFilter?.range?.from === "string" ||
      typeof updatedFilter?.range?.to === "string";
    if (!isSelected) {
      updatedFilter.option = -1;
    }
    dispatch(
      updateFilter({
        filterType: "dateCompleted",
        value: {
          ...updatedFilter,
        },
      })
    );
    setFilterChips({
      filterType: "dateCompleted",
      value: {
        ...updatedFilter,
      },
    });
  };

  const handleDeleteOrgFilters = (orgType: string, orgId: string) => {
    let _currentList = [...jobFilters[orgType]];
    _currentList = _currentList.filter(
      (item) => item.organization_id !== orgId
    );
    dispatch(
      updateFilter({
        filterType: orgType,
        value: [..._currentList],
      })
    );
    setFilterChips({ filterType: orgType, value: [..._currentList] });
  };

  const handleDeleteExtraFilters = (extraFilterType: string) => {
    let _extraFilters = { ...extraFilters };
    delete _extraFilters[extraFilterType];
    dispatch(
      updateFilter({
        filterType: "extra",
        value: {
          ..._extraFilters,
        },
      })
    );
    setFilterChips({
      filterType: "extra",
      value: {
        ..._extraFilters,
      },
    });
  };

  React.useEffect(() => {
    if (filterChips) debouncedSearch(onApplyFilter);
  }, [filterChips]);

  // Debouce search on delete
  const debouncedSearch = React.useRef(
    debounce((_onApplyFilter) => {
      _onApplyFilter?.();
    }, 1500)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      marginTop={
        channelPartnerFilter?.length ||
        customerFilter?.length ||
        installerFilter?.length ||
        (extraFilters && !isEmpty(extraFilters))
          ? "20px"
          : 0
      }
    >
      {channelPartnerFilter?.map(
        (
          cp: { organization_id: string; organization_name: string },
          index: number
        ) => (
          <Grid item key={index}>
            <Chip
              label={cp?.organization_name}
              size="small"
              variant="outlined"
              color="primary"
              onDelete={() =>
                handleDeleteOrgFilters("channelPartner", cp?.organization_id)
              }
            />
          </Grid>
        )
      )}

      {customerFilter?.map(
        (
          c: { organization_id: string; organization_name: string },
          index: number
        ) => (
          <Grid item key={index}>
            <Chip
              key={index}
              label={c?.organization_name}
              size="small"
              variant="outlined"
              color="primary"
              onDelete={() =>
                handleDeleteOrgFilters("customer", c?.organization_id)
              }
            />{" "}
          </Grid>
        )
      )}

      {installerFilter?.map(
        (
          i: { organization_id: string; organization_name: string },
          index: number
        ) => (
          <Grid item key={index}>
            <Chip
              key={index}
              label={i?.organization_name}
              size="small"
              variant="outlined"
              color="primary"
              onDelete={() =>
                handleDeleteOrgFilters("installer", i?.organization_id)
              }
            />
          </Grid>
        )
      )}

      {dateStartedFilter?.range?.from && (
        <Grid item>
          <Chip
            label={`Started From: ${dateStartedFilter?.range?.from}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteDateStartedFilter("from")}
          />
        </Grid>
      )}

      {dateStartedFilter?.range?.to && (
        <Grid item>
          <Chip
            label={`Started To: ${dateStartedFilter?.range?.to}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteDateStartedFilter("to")}
          />
        </Grid>
      )}

      {dateCompletedFilter && dateCompletedFilter?.range?.from !== null && (
        <Grid item key={"from"}>
          <Chip
            key={"from"}
            label={`Compl. From: ${dateCompletedFilter?.range?.from}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteDateCompletedFilter("from")}
          />
        </Grid>
      )}

      {dateCompletedFilter && dateCompletedFilter?.range?.to !== null && (
        <Grid item key={"to"}>
          <Chip
            key={"to"}
            label={`Compl. To: ${dateCompletedFilter?.range?.to}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteDateCompletedFilter("to")}
          />
        </Grid>
      )}

      {extraFilters?.completedBy && (
        <Grid item>
          <Chip
            label={`Completed by: ${extraFilters?.completedBy}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteExtraFilters("completedBy")}
          />
        </Grid>
      )}

      {extraFilters?.lastUpdatedBy && (
        <Grid item>
          <Chip
            label={`Last udated by: ${extraFilters?.lastUpdatedBy}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteExtraFilters("lastUpdatedBy")}
          />
        </Grid>
      )}

      {templatesData().map(
        (c: { template_id: any; name: string }, index: number) => (
          <Grid item key={index}>
            <Chip
              key={index}
              label={c?.name}
              size="small"
              variant="outlined"
              color="primary"
              onDelete={() => handleTemplateFilters(c?.template_id)}
            />
          </Grid>
        )
      )}

      {extraFilters?.[Constants.SURVEY_STATUS.COMPLETED] && (
        <Grid item>
          <Chip
            label={"Completed"}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() =>
              handleDeleteExtraFilters(Constants.SURVEY_STATUS.COMPLETED)
            }
          />
        </Grid>
      )}
      {extraFilters?.[Constants.SURVEY_STATUS.INCOMPLETE] && (
        <Grid item>
          <Chip
            label={"Incomplete"}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() =>
              handleDeleteExtraFilters(Constants.SURVEY_STATUS.INCOMPLETE)
            }
          />
        </Grid>
      )}
      {extraFilters?.dateModified && (
        <Grid item>
          <Chip
            label={`Modified: ${extraFilters?.dateModified}`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={() => handleDeleteExtraFilters("dateModified")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FilterChips;
