import * as React from "react";
import { Box, Typography } from "@mui/material";

const TTStatsText = (props: { text: string | number; label: string }) => {
  const { text, label } = props;

  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography gutterBottom variant="h5" component="div">
        {text}
      </Typography>
    </Box>
  );
};

export default TTStatsText;
