import { createSlice } from "@reduxjs/toolkit";
import { ColumnState, GridState, Sort, SortOrder } from "utils/types";
import defaultColumns from "./assetConfigHistoryColumns";
import { createInitialColumnStates } from "components/common/gridview/gridState";

export const DEFAULT_SORT: SortOrder = {
  by: "email",
  dir: Sort.ASC,
};

const initialColumnStates: ColumnState[] =
  createInitialColumnStates(defaultColumns);

const initialGridState: GridState = {
  sortField: DEFAULT_SORT.by,
  sortDirection: DEFAULT_SORT.dir,
  pageNumber: 0,
  totalPages: 0,
  rowsPerPage: 50,
  columnStates: initialColumnStates,
};

/* "version" key prevents any conflicts in localstorage especially in case of any
changes in source data. Change like change of column, filter etc
Use version key to forcibly delete any local storage if conflicts exists */
interface UsersGridState {
  version: Number;
  grid: GridState;
  isGrouped: boolean;
}

const initialState: UsersGridState = {
  version: 1,
  grid: initialGridState,
  isGrouped: false,
};

const slice = createSlice({
  name: "assetConfigHistoryGrid",
  initialState,
  reducers: {
    setGrid(state, action) {
      state.grid = action.payload;
    },
    setGrouped(state, action) {
      state.isGrouped = action.payload;
    },
    resetAssetConfigHistoryGrid(state) {
      state.grid = initialGridState;
      state.isGrouped = initialState.isGrouped;
    },
  },
});

export const { setGrid, setGrouped, resetAssetConfigHistoryGrid } =
  slice.actions;
export default slice.reducer;
