import React, { useState } from "react";
import DataGrid from "react-data-grid";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Tooltip } from "react-tooltip";
import type { Column, SortColumn } from "react-data-grid";
import { debounce, groupBy as rowGrouper } from "lodash";
import { TimestampFormatter } from "components/common/TTTimeStampFormatter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router";

import ActionsMenu from "./actionsMenu";
import TTToolTipText from "components/common/TTToolTipText";
import TTMultiOrgChip from "components/common/TTMultiOrgChip";
import { UsersRow } from "models/users";
import { dataGridStyle, dataGridParentStyle } from "theme/styles";
import { reorderColumns } from "components/common/gridview/gridState";
import { GridStateProps } from "utils/types";

const createColumns: readonly Column<UsersRow>[] = [
  {
    key: "first_name",
    name: "First",
    maxWidth: 130,
    formatter(props) {
      return <TTToolTipText text={props.row.first_name} />;
    },
  },
  {
    key: "last_name",
    name: "Last",
    maxWidth: 120,
    formatter(props) {
      return <TTToolTipText text={props.row.last_name} />;
    },
  },
  {
    key: "email",
    name: "Contact Email",
    formatter(props) {
      return (
        <TTToolTipText
          text={props.row.email}
          link={`mailto:${props.row.email}`}
        />
      );
    },
  },
  {
    key: "channelPartner",
    name: "Assigned Organization",
    formatter(props) {
      return props.row.organizations ? (
        <TTMultiOrgChip chips={props.row.organizations} />
      ) : null;
    },
    sortable: false,
  },
  {
    key: "created_at",
    name: "Created",
    width: 130,
    formatter(props) {
      return (
        <TimestampFormatter timestamp={props.row.created_at} showTime={true} />
      );
    },
  },
  {
    key: "activated_at",
    name: "Accepted",
    width: 130,
    formatter(props) {
      return (
        <>
          {props.row.activated_at ? (
            <TimestampFormatter
              timestamp={props.row.activated_at}
              showTime={true}
            />
          ) : (
            <Chip
              label="Pending"
              variant="outlined"
              color="warning"
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    key: "actions",
    name: "Actions",
    width: 40,
    formatter(props) {
      return <ActionsMenu user={props.row} />;
    },
  },
];

function rowKeyGetter(row: UsersRow) {
  return row.user_id;
}

const isSearchEnabled: boolean = false;

// Try to adjust the scroll height of the grid to make the header act "sticky".
const selectHeaderHeight = (
  isLarge: boolean,
  isMedium: boolean,
  isSmall: boolean
) => {
  if (isSmall) {
    return 320;
  }
  if (isMedium) {
    return 295;
  }
  if (isLarge) {
    return 235;
  }
  return 235; // XL
};

const UsersDataGrid = (props: GridStateProps) => {
  const { rows, onChangeView, reorderedColumns } = props;
  const navigate = useNavigate();

  const [columns, setColumns] = useState(
    reorderedColumns && reorderedColumns.length >= createColumns.length
      ? reorderColumns<UsersRow>(createColumns, reorderedColumns)
      : createColumns
  );

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>(
    props.sortColumn ? [props.sortColumn] : []
  );

  const handleRowClick = (user: UsersRow) => {
    navigate("/users/view/" + user.user_id);
  };

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    props.onChangeData?.(rows, null, sortColumns);
  };

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    handleChangeData(sortColumns);
    setSortColumns(sortColumns);
  };

  const onColumnResize = debounce((idx: number, width: number) => {
    onChangeView?.(idx, width);
  }, 500);
  // Try to adjust the scroll height of the grid to make the header, filters, and chips act "sticky".
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down("xl"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const headerHeight = selectHeaderHeight(isLarge, isMedium, isSmall);

  return (
    <Box
      style={{
        width: "100%",
      }}
      sx={dataGridParentStyle}
    >
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          style={{
            ...dataGridStyle,
            height: `calc(100vh - ${headerHeight}px)`,
            cursor: "pointer",
          }}
          className={
            isSearchEnabled
              ? "rdg-light data-grid-with-column-search"
              : "rdg-light"
          }
          columns={columns}
          rows={rows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowKeyGetter={rowKeyGetter}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          rowGrouper={rowGrouper}
          rowHeight={40}
          headerRowHeight={44}
          onRowClick={handleRowClick}
          onColumnResize={onColumnResize}
        />
        <Tooltip
          id="cell-tooltip"
          place="left"
          style={{ maxWidth: "250px", zIndex: 999 }}
        />
      </DndProvider>
    </Box>
  );
};
export default UsersDataGrid;
