import { SortColumn } from "react-data-grid";

export enum Sort {
  ASC = "asc",
  DESC = "desc",
}

export interface SortOrder {
  by: string;
  dir: Sort;
}

export type Comparator = (a: any, b: any) => number;

export interface ColumnState {
  field: string;
  hide: boolean;
  width?: number;
  index: number;
  hideable?: boolean;
  sortable?: boolean;
}

export interface ColumnsState {
  sortField: string;
  sortDirection: Sort;
  columnStates: any[];
}

export interface PaginationState {
  pageNumber: number;
  totalPages: number;
  rowsPerPage: number;
}

export interface GridSAccessibilityState {
  density?: string;
}

export interface GridStateProps {
  rows: any;
  onRemove?: any;
  onChangeData?: any;
  onChangeView?: any;
  sortColumn?: SortColumn;
  reorderedColumns?: any;
}

export interface GridState
  extends ColumnsState,
    PaginationState,
    GridSAccessibilityState {}

export enum PropertyDataType {
  INTEGER = "INTEGER",
  PHOTO = "PHOTO",
  PICKLIST = "PICKLIST",
  TEXT = "TEXT",
}

export interface FilterItem {
  key: any;
  label: string;
  type: string;
}

export interface FiltersState {
  searchField: string;
  orgs: FilterItem[];
  installationType: FilterItem[];
  columnFilters: any;
}

export type GridViewState = {
  version: string;
  isDirty: boolean;
  assets?: any;
  columns?: any;
  filters: FiltersState;
  grid: GridState;
  selectedView: any;
};

export type SavedViewsDataState = {
  savedViews: any;
  savedViewUsers: any;
};

export type ValueOptions = { value: any; label: string };

export enum WarrantyStatus {
  IN_WARRANTY = "IN_WARRANTY",
  EXPIRED = "EXPIRED",
  REPLACED = "REPLACED",
  FAILED = "FAILED",
}

export enum SurveyProcessingStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}
