import { makeStyles } from "tss-react/mui";

export const useCustomersStyles = makeStyles()((theme) => {
  return {
    customerAccordion: {
      "&.Mui-expanded": {
        margin: 0,
      },
    },
    customerAccordionSummary: {
      minHeight: "52px",
      maxHeight: "52px",
      height: "52px",
      "&.Mui-expanded": {
        minHeight: "52px",
        maxHeight: "52px",
        height: "52px",
      },
      ".MuiAccordionSummary-content.Mui-expanded": {
        marginTop: "6px",
        marginBottom: "6px",
      },
      ".MuiAccordionSummary-expandIconWrapper": {
        marginRight: "30px",
      },
    },
  };
});
