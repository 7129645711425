import * as React from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const TTPickList = (props: {
  editable: boolean;
  defaultValue: string;
  rawValue: string;
  options: Array<any>;
  itemId: string;
  onChange?: (value: string) => void;
}) => {
  const { editable, defaultValue, rawValue, options, itemId, onChange } = props;
  const [value, setValue] = React.useState<string>(rawValue);

  const handleChange = (event: any) => {
    setValue(event.target.value as string);
    onChange?.(event.target.value as string);
  };

  return editable ? (
    <Box>
      <FormControl>
        {options.length > 4 ? (
          <Select
            value={value}
            size="small"
            onChange={handleChange}
            sx={{ minWidth: 120 }}
          >
            {options?.map((option, index) => (
              <MenuItem value={option.alias} key={index}>
                {option.display_value}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <RadioGroup value={value} name={itemId}>
            {options?.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.alias}
                label={option.display_value}
                control={<Radio />}
                onChange={(event) => handleChange(event)}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    </Box>
  ) : (
    <Typography>{defaultValue}</Typography>
  );
};

export default TTPickList;
