import React, { useState, useMemo, useEffect } from "react";
import DataGrid from "react-data-grid";
import { Box } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "react-tooltip";
import type { Column, SortColumn } from "react-data-grid";
import { groupBy as rowGrouper, chunk } from "lodash";
import { useNavigate } from "react-router";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Link from "@mui/material/Link";

import ActionsMenu from "./actionsMenu";
import { UsersOrgs, UsersRow } from "models/users";
import CustomerTypeChip from "components/common/customertypechip";
import TTToolTipChip from "components/common/TTToolTipChip";
import { ADMIN_USER_TOOLTIP, MEMBER_USER_TOOLTIP } from "utils/strings";
import { dataGridStyle, dataGridParentStyle } from "theme/styles";
import { Comparator } from "utils/types";

function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case "organization_name":
      return (a, b) => {
        return a?.[sortColumn]?.localeCompare(b?.[sortColumn]);
      };
    case "types":
      return (a, b) => {
        return a?.[sortColumn][0]?.localeCompare(b?.[sortColumn][0]);
      };
    case "roles":
      return (a, b) => {
        return a - b;
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

function rowKeyGetter(row: UsersOrgs) {
  return row.organization_id;
}

const isSearchEnabled: boolean = false;

const UsersAssignmentGrid = (props: { rows: any; user: UsersRow }) => {
  const { rows, user } = props;
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const createColumns: readonly Column<UsersOrgs>[] = [
    {
      key: "organization_name",
      name: "Assigned Organization",
      formatter(props) {
        return (
          <Link
            underline="none"
            onClick={() => handleOrganizationClick(props.row.organization_id)}
            sx={{ cursor: "pointer" }}
          >
            {props.row.organization_name}
          </Link>
        );
      },
    },
    {
      key: "types",
      name: "Organization Type",
      formatter(props) {
        return (
          <>
            {props.row.types?.length > 0
              ? props.row.types.map((type) => (
                  <CustomerTypeChip key={type} type={type} />
                ))
              : "-"}
          </>
        );
      },
    },
    {
      key: "roles",
      name: "BOS Access",
      formatter(props) {
        return (
          <>
            {props.row.is_admin ? (
              <TTToolTipChip
                label="Admin"
                size="small"
                icon={<InfoOutlinedIcon />}
                toolTipText={ADMIN_USER_TOOLTIP}
              />
            ) : (
              <TTToolTipChip
                label="Member"
                size="small"
                icon={<InfoOutlinedIcon />}
                toolTipText={MEMBER_USER_TOOLTIP}
              />
            )}
          </>
        );
      },
    },
    {
      key: "actions",
      name: "",
      width: 10,
      formatter(props) {
        return <ActionsMenu org={props.row} user={user} />;
      },
    },
  ];

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    setChunks(chunk(rows, _rowsPerPage));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
    setChunks(chunk(rows, rowsPerPage));
  }, [rows, rowsPerPage]);

  const sortedRows = useMemo((): readonly UsersOrgs[] => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const _a =
          a[sort.columnKey] === undefined ||
          a[sort.columnKey] === null ||
          a[sort.columnKey] === ""
            ? undefined
            : a[sort.columnKey];
        const _b =
          b[sort.columnKey] === undefined ||
          b[sort.columnKey] === null ||
          b[sort.columnKey] === ""
            ? undefined
            : b[sort.columnKey];
        if (_a === undefined && _b === undefined) {
          return 0;
        } else if (_a === undefined) {
          return 1;
        } else if (_b === undefined) {
          return -1;
        }

        const comparator = getComparator(sort.columnKey);
        let compResult = comparator(a, b);
        if (compResult !== undefined && compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const [chunks, setChunks] = useState<Array<Array<UsersOrgs>>>(
    chunk(sortedRows, rowsPerPage)
  );

  const handleOrganizationClick = (organization_id: string) => {
    navigate(`/organizations/view/${organization_id}`);
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "calc(100vh - 360px)",
      }}
      sx={dataGridParentStyle}
    >
      <DataGrid
        style={{
          ...dataGridStyle,
          width: "100%",
          height: "calc(100vh - 360px)",
        }}
        className={
          isSearchEnabled
            ? "rdg-light data-grid-with-column-search"
            : "rdg-light"
        }
        columns={createColumns}
        rows={chunks[page] ? chunks[page] : chunks[0]}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        rowKeyGetter={rowKeyGetter}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        rowGrouper={rowGrouper}
        rowHeight={40}
      />
      {rows?.length > 50 && (
        <TablePagination
          component="div"
          count={rows?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Tooltip
        id="cell-tooltip"
        place="left"
        style={{ maxWidth: "250px", zIndex: 999 }}
      />
    </Box>
  );
};
export default UsersAssignmentGrid;
