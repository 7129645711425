import { makeStyles } from "tss-react/mui";

export const useUserRolesStyles = makeStyles()((theme) => {
  return {
    headerBox: {
      boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    },
    headerGrid: {
      filter: "none",
      boxShadow: "0px 0px 0px 1px #E0E0E0",
      borderRadius: "4px",
      padding: "24px 40px",
    },
    roleSelectorOrgsAutocomplete: {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "rgba(2, 136, 209, 0.04)!important",
        border: "1px solid rgb(2, 136, 209)!important",
      },
    },
    roleSelectorAutocomplete: {
      marginTop: "24px",
      width: "100%",
    },
  };
});
