import { Grid } from "@mui/material";
import PropertyDetail from "components/common/PropertyDetail";
import { PropertyDataType } from "utils/types";

const AssetCardDetail = (props: {
  value: string | React.ReactElement;
  label: string;
  tooltip?: string;
  onClick?: () => void;
}) => {
  return (
    <Grid item minWidth="20%" m={1}>
      <PropertyDetail
        value={props.value}
        label={props.label}
        titleTextTransform="none"
        tooltip={props.tooltip}
        onClick={props.onClick}
      />
    </Grid>
  );
};

const NOT_AVAILABLE = ""; // might want "N/A" instead?

export { NOT_AVAILABLE, PropertyDataType, AssetCardDetail };
