import React, { useMemo, useState } from "react";
import DataGrid, { SortColumn } from "react-data-grid";
import { Box, IconButton } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "react-tooltip";
import type { Column } from "react-data-grid";
import { chunk, debounce } from "lodash";
import { useNavigate } from "react-router";
import TTToolTipText from "components/common/TTToolTipText";
import { TimestampFormatter } from "components/common/TTTimeStampFormatter";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { dataGridParentStyle } from "theme/styles";
import { reorderColumns } from "components/common/gridview/gridState";
import { getComparator, getCompareFn } from "utils/comparator";
import { GridStateProps } from "utils/types";

interface RolesListRow {
  role_name: string;
  role_id: string;
  description: string;
  is_system: boolean;
  organizations: string[];
  last_updated_dt: Date;
}

function rowKeyGetter(row: RolesListRow) {
  return row.role_id;
}

const createColumns = (props: GridStateProps) => {
  const { onRemove } = props;
  const columns: readonly Column<RolesListRow>[] = [
    {
      key: "role_name",
      name: "Role Name",
      minWidth: 100,
    },
    {
      key: "description",
      name: "Role description",
      minWidth: 100,
    },
    {
      key: "organizations",
      name: "Organizations",
      formatter(props: any) {
        const orgNames: string[] = props.row.organizations.map((org: any) => {
          return org.organization_name;
        });

        return <TTToolTipText text={orgNames.join(", ")} />;
      },
    },
    {
      key: "is_system",
      name: "Is system",
      minWidth: 100,
      formatter(props: any) {
        return <>{props.row.is_system ? "Yes" : "No"}</>;
      },
    },
    {
      key: "last_updated_dt",
      name: "Last updated",
      formatter(props: any) {
        return (
          <TimestampFormatter
            showTime={true}
            timestamp={props.row.last_updated_dt}
          />
        );
      },
    },
    {
      key: "actions",
      name: "Actions",
      width: 40,
      formatter(props) {
        return !props.row.is_system ? (
          <IconButton
            aria-label="delete"
            size="small"
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(props.row);
            }}
          >
            {" "}
            <DeleteOutlineIcon />
          </IconButton>
        ) : (
          <></>
        );
      },
    },
  ];
  return columns;
};

const getInitialColumns = (props: GridStateProps) => {
  const defaultColumns = createColumns(props);
  const reorderedColumns = props.reorderedColumns;
  if (reorderedColumns && reorderedColumns.length >= createColumns.length) {
    return reorderColumns<RolesListRow>(defaultColumns, reorderedColumns);
  }
  return defaultColumns;
};

const RolesListDataGrid = (props: GridStateProps) => {
  const { rows } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const navigate = useNavigate();

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>(
    props.sortColumn ? [props.sortColumn] : []
  );
  const sortedRows = useMemo((): readonly RolesListRow[] => {
    const userRows = rows.slice();
    if (sortColumns.length === 0) {
      // by default, sort the role name, ascending
      return userRows.sort((a: any, b: any) =>
        getComparator("role_name")(a, b)
      );
    }
    return userRows.sort(getCompareFn(sortColumns));
  }, [rows, sortColumns]);

  let chunks: Array<Array<any>> = chunk(sortedRows, rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    chunks = chunk(sortedRows, _rowsPerPage);
    setPage(0);
  };
  const handleRowClick = (row: any, column: any) => {
    navigate("/roles/edit/" + row.role_id);
  };

  const [columns, setColumns] = useState(getInitialColumns(props));

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    props.onChangeData?.(rows, null, sortColumns);
  };

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    handleChangeData(sortColumns);
    setSortColumns(sortColumns);
    chunks = chunk(sortedRows, rowsPerPage);
  };

  const onColumnResize = debounce((idx: number, width: number) => {
    props.onChangeView?.(idx, width);
  }, 500);

  return (
    <Box
      style={{
        height: "calc(100vh - 250px)",
        width: "100%",
      }}
      sx={dataGridParentStyle}
    >
      <DataGrid
        style={{
          height: "calc(100vh - 250px)",
          width: "100%",
          borderRadius: 4,
        }}
        className="rdg-light"
        columns={columns}
        rows={chunks[page]}
        rowKeyGetter={rowKeyGetter}
        defaultColumnOptions={{ resizable: true, sortable: true }}
        rowHeight={52}
        onRowClick={handleRowClick}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
        onColumnResize={onColumnResize}
      />
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Tooltip
        id="cell-tooltip"
        place="left"
        style={{ maxWidth: "250px", zIndex: 999 }}
      />
    </Box>
  );
};
export default RolesListDataGrid;
