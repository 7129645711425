import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  BusinessOutlined,
  Description,
  CorporateFare,
  AccountCircleOutlined,
  WorkOutline,
  AdminPanelSettingsOutlined,
  InsertChartOutlined,
  HvacOutlined,
  Inventory2Outlined,
} from "@mui/icons-material";
import { Colors } from "../../theme/colors";
import { NavLink } from "react-router-dom";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";

const useAppStyles: any = makeStyles<any>()((theme) => {
  return {
    activelink: {
      background: "#660000",
    },
    linkButton: {
      "&.active": {
        background: Colors.ttBlueLightest,
      },
      "&:hover": {
        background: Colors.ttBlueLightest,
      },
    },
  };
});

const SideBar = (props: { width: number; collapsed: Boolean }) => {
  const { width, collapsed } = props;
  const { classes } = useAppStyles();
  const { isGreensightAdmin, hasAccess } = useAuthData();

  return (
    <Drawer
      sx={{
        width: width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: width,
          boxSizing: "border-box",
          zIndex: 0,
          marginTop: "64px",
          borderRight: 0,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        {isGreensightAdmin && (
          <ListItem
            key="Dashboard"
            component={NavLink}
            to="/templates"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <Description color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Templates" />}
          </ListItem>
        )}

        {/* NetSuite */}
        {hasAccess(Constants.ACTIONS.EDIT_NETSUITE) && (
          <ListItem
            key="NetSuite"
            component={NavLink}
            to="/netsuite/0"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <BusinessOutlined color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="NetSuite" />}
          </ListItem>
        )}

        {/* Organizations */}
        {hasAccess(Constants.ACTIONS.VIEW_ORGS) && (
          <ListItem
            key="Organizations"
            component={NavLink}
            to="/organizations"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <CorporateFare color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Organizations" />}
          </ListItem>
        )}

        {/* Jobs */}
        {hasAccess(Constants.ACTIONS.VIEW_JOBS) && (
          <ListItem
            key="Jobs"
            component={NavLink}
            to="/jobs"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <WorkOutline color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Jobs" />}
          </ListItem>
        )}
        {/* Assets */}
        {hasAccess(Constants.ACTIONS.VIEW_ASSET) && (
          <ListItem
            key="Assets"
            component={NavLink}
            to="/assets"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <HvacOutlined color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Assets" />}
          </ListItem>
        )}

        {/* Inventory */}
        {hasAccess(Constants.ACTIONS.VIEW_INVENTORY) && (
          <ListItem
            key="Inventory"
            component={NavLink}
            to="/inventory"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <Inventory2Outlined color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Inventory" />}
          </ListItem>
        )}

        {/* Fan Charts */}
        {hasAccess(Constants.ACTIONS.VIEW_FAN_CHARTS) && (
          <ListItem
            key="Fancharts"
            component={NavLink}
            to="/fancharts"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <InsertChartOutlined color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Fan Charts" />}
          </ListItem>
        )}

        {/* Users */}
        {hasAccess(Constants.ACTIONS.VIEW_USERS) && (
          <ListItem
            key="Users"
            component={NavLink}
            to="/users"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <AccountCircleOutlined color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Users" />}
          </ListItem>
        )}

        {/* Roles */}
        {isGreensightAdmin && (
          <ListItem
            key="Roles"
            component={NavLink}
            to="/roles"
            className={classes.linkButton}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <AdminPanelSettingsOutlined color="primary" />
            </ListItemIcon>
            {!collapsed && <ListItemText primary="Roles (Alpha)" />}
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
