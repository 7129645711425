import { createAsyncThunk } from "@reduxjs/toolkit";

import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export interface IJobsQuery {
  token?: string;
  data: any;
}

export interface IJobDetailsQuery {
  token?: string;
  jobId: string;
  jobData?: any;
  templateItemId?: string;
}

export interface IFileUploadItem {
  token?: string;
  jobId: string;
  templateItemId?: string;
  file?: any;
}
export interface IRegenerateSurveyThumbnailsQuery {
  token: string;
  jobId: string;
}

export const getJobsList = createAsyncThunk(
  "jobs/getJobsList",
  async (payload: IJobsQuery, thunkAPI) => {
    const { token, data } = payload;

    const config = setCallConfig(
      "/api/surveyresults/search",
      "POST",
      token,
      data
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const getJobsListWithFilters = createAsyncThunk(
  "jobs/getJobsListWithFilters",
  async (payload: IJobsQuery, thunkAPI) => {
    const { token, data } = payload;

    const config = setCallConfig(
      "/api/surveyresults/search",
      "POST",
      token,
      data
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const getJobDetails = createAsyncThunk(
  "jobs/getJobDetails",
  async (payload: IJobDetailsQuery, thunkAPI) => {
    const { token, jobId } = payload;

    const config = setCallConfig(
      `/api/tta/surveyresult/${jobId}`,
      "GET",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;

    return {
      ...response.data?.result,
    };
  }
);

export const saveJobDetails = createAsyncThunk(
  "jobs/saveJobDetails",
  async (payload: IJobDetailsQuery, thunkAPI) => {
    const { token, jobId, jobData } = payload;

    const config = setCallConfig(
      `/api/tta/surveyresult/${jobId}`,
      "PUT",
      token,
      jobData
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const generateJobPDF = createAsyncThunk(
  "jobs/generateJobPDF",
  async (payload: IJobDetailsQuery, thunkAPI) => {
    const { token, jobId } = payload;

    const config = setCallConfig(
      `/api/surveyresult/${jobId}/pdf`,
      "PUT",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const addAttachment = createAsyncThunk(
  "jobs/addAttachment",
  async (payload: IFileUploadItem, thunkAPI) => {
    const { token, jobId, templateItemId, file } = payload;

    const config = setCallConfig(
      `/api/surveyresultattachment/${jobId}/${templateItemId}`,
      "POST",
      token,
      file
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);
export const regenerateSurveyThumbnails = createAsyncThunk(
  "jobs/regenerateSurveyThumbnails",
  async (payload: IRegenerateSurveyThumbnailsQuery, thunkAPI) => {
    const { token, jobId } = payload;

    const config = setCallConfig(
      `/api/regenerate_survey_thumbnails/${jobId}`,
      "POST",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);
