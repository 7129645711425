import { SyntheticEvent } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { TabPanel, a11yProps } from "components/layout/tabpanel";
import { useAuthData } from "utils/hooks/useAuthData";
import Relationships from "./relationships";
import FileImport from "./fileImport";

const NetSuite = () => {
  const { isGreensightAdmin } = useAuthData();
  const { tab } = useParams();
  const navigate = useNavigate();
  const tabIndex = parseInt(tab || "0");

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    navigate(`/netsuite/${newValue}`);
  };

  return (
    <Grid>
      {isGreensightAdmin && (
        <Box marginTop={1}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Relationships" {...a11yProps(0)}></Tab>
            <Tab label="File Import" {...a11yProps(1)}></Tab>
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Relationships />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <FileImport />
          </TabPanel>
        </Box>
      )}
    </Grid>
  );
};
export default NetSuite;
