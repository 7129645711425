import React, { useState, useMemo } from "react";
import DataGrid from "react-data-grid";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import { Tooltip } from "react-tooltip";
import type { Column, HeaderRendererProps, SortColumn } from "react-data-grid";
import { debounce, groupBy as rowGrouper, initial, isEmpty } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router";
import { useAuthData } from "utils/hooks/useAuthData";

import { useAppSelector } from "redux/store/hooks";
import ActionsMenu from "./actionsMenu";
import TTToolTipText from "components/common/TTToolTipText";
import TTToolTipChip from "components/common/TTToolTipChip";
import { DraggableHeaderRenderer } from "components/datagrid/draggableHeaderRenderer";
import ColumnSelector from "components/datagrid/columnSelector";
import { JobsRow } from "models/jobs";
import { Constants } from "utils/constants";
import { TimestampFormatter } from "components/common/TTTimeStampFormatter";
import { dataGridStyle, dataGridParentStyle, tinyToolTip } from "theme/styles";
import LocationMap from "components/jobs/locationMap";
import { reorderColumns } from "components/common/gridview/gridState";
import { GridStateProps } from "utils/types";
import { getDefaultVisibleColumns } from "redux/reducers/jobsColumns";

const SelectableCell = ({ text }: { text: string }) => (
  <div
    style={{ userSelect: "text" }}
    onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => {
      const range = document.createRange();
      const selection = window.getSelection();
      if (selection) {
        range.selectNodeContents(e.currentTarget);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }}
  >
    {text}
  </div>
);

const createColumns: readonly Column<JobsRow>[] = [
  {
    key: "customerName",
    name: "Customer Name",
    minWidth: 100,
    formatter(props) {
      return <TTToolTipText text={props.row.customerName} />;
    },
  },
  {
    key: "siteName",
    name: "Site Name",
    formatter(props) {
      return <TTToolTipText text={props.row.siteName} />;
    },
  },
  {
    key: "siteAddress",
    name: "Address",
    formatter(props) {
      return <TTToolTipText text={props.row.siteAddress} />;
    },
  },
  {
    key: "equipment",
    name: "Equipment",
    width: 120,
    formatter(props) {
      return <TTToolTipText text={props.row.equipment} />;
    },
  },
  {
    key: "survey_template_name",
    name: "Job Template",
    formatter(props) {
      return props.row.survey_template_name ? (
        <TTToolTipChip label={props.row.survey_template_name} size="small" />
      ) : null;
    },
  },

  {
    key: "installer",
    name: "Installer",
    formatter(props) {
      return props.row.installer ? (
        <TTToolTipChip
          label={props.row.installer}
          color="success"
          size="small"
          type="I"
        />
      ) : null;
    },
  },
  {
    key: "channelPartner",
    name: "Channel Partner",
    formatter(props) {
      return props.row.channelPartner ? (
        <TTToolTipChip
          label={props.row.channelPartner}
          color="primary"
          size="small"
          type="CP"
        />
      ) : null;
    },
  },
  {
    key: "completed_dt",
    name: "Date Completed",
    formatter(props) {
      return (
        <TimestampFormatter
          timestamp={props.row.completed_dt}
          showTime={true}
        />
      );
    },
  },
  {
    key: "status",
    name: "Status",
    width: 120,
    formatter(props) {
      const status = Constants.STATUS_TEXT[props.row.status];
      return <TTToolTipText text={status} />;
    },
  },
  {
    key: "location",
    name: "Location",
    formatter(props: { row: any }) {
      const location = props.row.location as {
        lat: number;
        lon: number;
      } | null;

      if (
        location &&
        typeof location === "object" &&
        location.lat &&
        location.lon
      ) {
        const formattedLocation = `${location.lat},${location.lon}`;

        const handleCopy = (event: React.MouseEvent) => {
          event.stopPropagation();
          navigator.clipboard.writeText(formattedLocation);
          const toolTip = document.createElement("span");
          toolTip.textContent = "Copied!";
          Object.assign(toolTip.style, tinyToolTip, { display: "inline" });

          const container = event.currentTarget.parentElement;
          if (container) {
            container.appendChild(toolTip);
            setTimeout(() => {
              container.removeChild(toolTip);
            }, 700);
          }
        };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CopyAllRoundedIcon
              style={{
                marginRight: "5px",
                marginTop: "4px",
                cursor: "pointer",
                color: "#717171",
                fontSize: "22px",
              }}
              onClick={handleCopy}
            />
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {formattedLocation}
            </div>
          </div>
        );
      } else {
        return null;
      }
    },
  },
  {
    key: "created_dt",
    name: "Date Started",
    width: 120,
    formatter(props) {
      return (
        <TimestampFormatter timestamp={props.row.created_dt} showTime={true} />
      );
    },
  },
  {
    key: "last_updated_dt",
    name: "Last Updated",
    width: 120,
    formatter(props) {
      return (
        <TimestampFormatter
          timestamp={props.row.last_updated_dt}
          showTime={true}
        />
      );
    },
  },
  {
    key: "created_by",
    name: "Created By",
    formatter(props) {
      return <TTToolTipText text={props.row.created_by} />;
    },
  },
  {
    key: "last_updated_by",
    name: "Last Updated By",
    formatter(props) {
      return <TTToolTipText text={props.row.last_updated_by} />;
    },
  },
  {
    key: "completed_by",
    name: "Completed By",
    formatter(props) {
      return <TTToolTipText text={props.row.completed_by} />;
    },
  },
  {
    key: "id",
    name: "Job ID",
    width: 275,
    formatter(props) {
      return <SelectableCell text={props.row.id.toString()} />;
    },
  },
  {
    key: "actions",
    name: "Actions",
    width: 60,
    formatter(props) {
      return (
        <ActionsMenu
          jobId={props.row.id.toString()}
          pdfURL={props.row.pdf_url}
          status={props.row.status}
        />
      );
    },
    sortable: false,
  },
];

const MAX_COLUMNS = createColumns.length;

function rowKeyGetter(row: JobsRow) {
  return row.id;
}

const isSearchEnabled: boolean = false;

const getRenderColumns = (
  columns: any,
  headerRenderer: (props: HeaderRendererProps<JobsRow>) => JSX.Element
) =>
  columns.map((c: { key: string }) => {
    if (c?.key === "id") return c;
    return { ...c, headerRenderer };
  });

// Try to adjust the scroll height of the grid to make the header, filters, and chips act "sticky".
// XXX: MUI breakpoint fidelity doesn't lets us cover all cases perfectly
const selectHeaderHeight = (
  isLarge: boolean,
  isMedium: boolean,
  isSmall: boolean,
  hasFilters: boolean
) => {
  if (isSmall) {
    if (hasFilters) {
      return 400;
    }
    return 430;
  }
  if (isMedium) {
    if (hasFilters) {
      return 460;
    }
    return 404;
  }
  if (isLarge) {
    if (hasFilters) {
      return 380;
    }
    return 329;
  }
  // XL
  if (hasFilters) {
    return 331;
  }
  return 280;
};

const JobsDataGrid = (props: GridStateProps) => {
  const { rows, onChangeData, onChangeView, reorderedColumns } = props;
  const navigate = useNavigate();
  const filters = useAppSelector((state) => state.filters.jobFilters);
  const { hasAccess } = useAuthData();

  const [expandedGroupIds, setExpandedGroupIds] =
    useState<ReadonlySet<unknown>>();
  const selectedColumns: any = useAppSelector(
    (state) => state.filters?.jobSelectedColumns || getDefaultVisibleColumns()
  );
  const [columns, setColumns] = useState(
    reorderedColumns && reorderedColumns.length >= MAX_COLUMNS
      ? reorderColumns<JobsRow>(createColumns, reorderedColumns)
      : createColumns
  );

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>(
    props.sortColumn ? [props.sortColumn] : []
  );

  const headerRenderer = (props: HeaderRendererProps<JobsRow>) => {
    return (
      <DraggableHeaderRenderer
        {...props}
        onColumnsReorder={handleColumnsReorder}
        excludeSearchKeys={["actions"]}
        onSearch={handleColumnSearch}
      />
    );
  };

  const handleColumnsReorder = (sourceKey: string, targetKey: string) => {
    const sourceColumnIndex = columns.findIndex(
      (c: { key: string }) => c.key === sourceKey
    );
    const targetColumnIndex = columns.findIndex(
      (c: { key: string }) => c.key === targetKey
    );
    const _reorderedColumns = [...columns];

    _reorderedColumns.splice(
      targetColumnIndex,
      0,
      _reorderedColumns.splice(sourceColumnIndex, 1)[0]
    );

    setColumns(_reorderedColumns);
    onChangeData?.(rows, _reorderedColumns);
  };

  const draggableColumns = useMemo(() => {
    const renderColumns = getRenderColumns(columns, headerRenderer);
    return renderColumns.filter(
      (c: { key: string }) => selectedColumns[c.key] || c.key === "actions"
    );
  }, [columns, selectedColumns]);

  const handleColumnSearch = (columnKey: string, query: string) => {
    /* TODO: Currently column search is disabled */
  };

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    onChangeData?.(rows, undefined, sortColumns);
  };

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    handleChangeData(sortColumns);
    setSortColumns(sortColumns);
  };

  const onColumnResize = debounce((idx: number, width: number) => {
    onChangeView?.(idx, width);
  }, 500);

  const handleViewJob = (row: JobsRow) => {
    if (
      hasAccess(Constants.ACTIONS.VIEW_JOB_DETAILS) ||
      hasAccess(Constants.ACTIONS.EDIT_JOBS)
    ) {
      navigate("/jobs/edit/" + row.id);
    }
  };

  const [locationData, setLocationData] = useState({
    lat: 0,
    lon: 0,
  });
  const [locationMapOpen, setLocationMapOpen] = useState<boolean>(false);

  const handleLocationMapOpen = (row: JobsRow) => {
    if (!row.location || !row.location.lat || !row.location.lon) {
      return;
    }
    setLocationData(row.location);
    setLocationMapOpen(true);
  };

  const handleLocationMapClose = () => {
    setLocationMapOpen(false);
  };

  // Try to adjust the scroll height of the grid to make the header, filters, and chips act "sticky".
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down("xl"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const hasFilters = !isEmpty(filters);
  const headerHeight = selectHeaderHeight(
    isLarge,
    isMedium,
    isSmall,
    hasFilters
  );

  return (
    <Box
      sx={{
        ...dataGridParentStyle,
      }}
    >
      <ColumnSelector columns={initial([...createColumns])} />
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          style={{
            ...dataGridStyle,
            blockSize: `calc(100vh - ${headerHeight}px)`,
          }}
          className={
            isSearchEnabled
              ? "rdg-light data-grid-with-column-search"
              : "rdg-light"
          }
          columns={draggableColumns}
          rows={rows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowKeyGetter={rowKeyGetter}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          onColumnResize={onColumnResize}
          rowGrouper={rowGrouper}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
          rowHeight={40}
          onRowClick={(row, column) => {
            if (column.key === "location") {
              handleLocationMapOpen(row);
            } else if (column.key !== "id") {
              handleViewJob(row);
            }
          }}
          headerRowHeight={44}
        />
        <Tooltip
          id="cell-tooltip"
          place="left"
          style={{ maxWidth: "250px", zIndex: 999 }}
        />
      </DndProvider>
      {locationMapOpen && (
        <LocationMap
          open={locationMapOpen}
          locationData={locationData}
          onClose={handleLocationMapClose}
        />
      )}
    </Box>
  );
};
export default JobsDataGrid;
