import { useEffect, useState } from "react";
import { Chip, Dialog, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Grid, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import {
  getUserDetails,
  inviteUser,
  reinviteUser,
  updateRoles,
} from "redux/actions/users";
import { UsersRow } from "models/users";
import { RolesIcon } from "assets/svg/roles";
import UsersAssignmentGrid from "./assignmentGrid";
import { enqueueSnackbar } from "notistack";
import TTAutoComplete from "components/common/TTAutoComplete/TTAutoComplete";
import { getOrganizationsList } from "redux/actions/orgs";
import { useViewOrganizationStyles } from "components/organizations/view/styles";
import { OrgItem } from "models/organizations/assignorgtoorgitem";
import { RenderBackToUsersListButton } from "..";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";

import { startCase, upperCase, xor } from "lodash";
import { AUTH0_ROLES_LABEL } from "utils/strings";

const UserDetails = () => {
  const { getRawIdToken, isGreensightAdmin, hasAccess, ready } = useAuthData();
  const { classes } = useViewOrganizationStyles();

  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const user: UsersRow = useAppSelector((state) => state.users.userDetail);
  const loading = useAppSelector((state) => state.users.loading);
  const [showGlobalRoles, setShowGlobalRoles] = useState<boolean>(false);
  const [globalRoles, setGlobalRoles] = useState<string[]>([]);
  const [showAssignDialog, setShowAssignDialog] = useState<boolean>(false);

  const channelPartners = useAppSelector(
    (state) => state.orgs.data?.channelPartners
  );
  const customers = useAppSelector((state) => state.orgs.data?.customers);
  const installers = useAppSelector((state) => state.orgs.data?.installers);
  let organizations = channelPartners || [];
  organizations = customers ? [...organizations, ...customers] : organizations;
  organizations = installers
    ? [...organizations, ...installers]
    : organizations;
  organizations = organizations.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.organization_id === value.organization_id)
  );
  organizations = organizations.sort((a: OrgItem, b: OrgItem) =>
    a.organization_name > b.organization_name
      ? 1
      : b.organization_name > a.organization_name
      ? -1
      : 0
  );

  const [selectedOrgs, setSelectedOrgs] = useState<OrgItem[]>([]);

  const currentOrgIds = user?.organizations?.map((org) => org.organization_id);

  const UserName = ({ user }: { user: UsersRow }) => (
    <>
      {user.activated_at ? (
        `${user.first_name} ${user.last_name}`
      ) : (
        <em>Unknown</em>
      )}
    </>
  );

  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        // Get user details
        dispatch(
          getUserDetails({
            token: idToken,
            userId: userId,
          })
        );
      })();
    }
  }, [userId, ready]);

  useEffect(() => {
    setGlobalRoles(user?.global_roles);
  }, [user]);

  const navigate = useNavigate();
  const location = useLocation();

  if (!loading && !user) {
    return (
      <>
        <Box sx={{ height: "100%" }}>
          <RenderBackToUsersListButton />
          <Typography sx={{ padding: 2 }}>User not found</Typography>
        </Box>
      </>
    );
  }

  const toggleGlobalRolesDropDown = () => {
    if (!isGreensightAdmin) {
      return;
    }
    setShowGlobalRoles(true);
  };

  const handleChange = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    setGlobalRoles(typeof value === "string" ? value.split(",") : value);
  };

  const handleSaveRoles = () => {
    setShowGlobalRoles(false);
    const removedRoles = user?.global_roles?.filter(
      (role) => !globalRoles.includes(role)
    );
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        updateRoles({
          token: idToken,
          userId: user.user_id,
          roles: removedRoles,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(`Product Access updated!`, {
            variant: "default",
          });
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to update product access!", {
            variant: "error",
          });
        }

        dispatch(
          getUserDetails({
            token: idToken,
            userId: userId,
          })
        );
      });
    })();
  };

  const handleAssignOrgDialogOpen = async () => {
    const idToken = await getRawIdToken();
    setSelectedOrgs([]);
    setShowAssignDialog(true);
    dispatch(getOrganizationsList({ token: idToken }));
  };

  const handleAssignDialogClose = () => {
    setSelectedOrgs([]);
    setShowAssignDialog(false);
  };

  const handleSelectedOrgsChange = (value: OrgItem[]) => {
    setSelectedOrgs(value);
  };

  const handleSaveOrgs = () => {
    (async () => {
      const idToken = await getRawIdToken();
      setShowAssignDialog(false);
      const newOrgs = selectedOrgs.map((org: OrgItem) => org.organization_id);
      dispatch(
        inviteUser({
          token: idToken,
          organizationIds: [...newOrgs],
          email: user.email,
          roles: user.global_roles,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar("New organizations assigned successfully!", {
            variant: "default",
          });
        } else {
          enqueueSnackbar("Failed to assign organizations!", {
            variant: "error",
          });
        }
        dispatch(
          getUserDetails({
            token: idToken,
            userId: userId,
          })
        );
      });
    })();
  };

  const handleManageRolesClick = () => {
    navigate(
      `/users/roles/${user.user_id}?from=${location.pathname}&label=${user.first_name} ${user.last_name}`
    );
  };

  return user && !loading ? (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1080px",
        marginX: "auto",
      }}
    >
      <Grid container justifyContent={"space-between"} mb={1}>
        <RenderBackToUsersListButton />
        {!user.activated_at && (
          <Button
            variant="outlined"
            onClick={async () => {
              const idToken = await getRawIdToken();
              dispatch(
                reinviteUser({
                  token: idToken,
                  userId: userId,
                })
              ).then((resp) => {
                if (resp && resp.payload && resp.payload.success) {
                  enqueueSnackbar(`Invite re-sent!`, {
                    variant: "default",
                  });
                } else if (resp && resp.payload && !resp.payload.success) {
                  enqueueSnackbar(resp.payload.error, { variant: "error" });
                } else {
                  enqueueSnackbar("Failed to re-invite user!", {
                    variant: "error",
                  });
                }
              });
            }}
          >
            Resend Invite
          </Button>
        )}
      </Grid>
      <Paper
        elevation={1}
        sx={{
          padding: 3,
          border: "1px solid #E0E0E0",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            container
            alignItems={"center"}
            justifyContent="stretch"
          >
            <Typography
              variant="h4"
              sx={{
                wordWrap: "break-word",
                paddingRight: 2,
              }}
            >
              <UserName user={user} />
            </Typography>
            {!user.activated_at && (
              <Chip
                label="Pending"
                variant="outlined"
                color="warning"
                size="small"
              />
            )}
          </Grid>

          <Grid item xs={12} md={7} lg={6} container spacing={1}>
            <Grid item container xs={12} md={5} lg={6} alignItems="center">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"stretch"}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <ContactsOutlinedIcon />
                <Link
                  href={`mailto:${user.email}`}
                  underline="none"
                  sx={{
                    padding: "10px 10px 10px 12px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {user.email}
                </Link>
              </Stack>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={7}
              lg={6}
              alignItems="center"
              justifyContent={"flex-end"}
            >
              {showGlobalRoles ? (
                <FormControl fullWidth>
                  <InputLabel sx={{ top: -5 }}>{AUTH0_ROLES_LABEL}</InputLabel>
                  <Select
                    multiple
                    size="small"
                    value={globalRoles}
                    onChange={handleChange}
                    input={<OutlinedInput label={AUTH0_ROLES_LABEL} />}
                    renderValue={(selected) =>
                      selected.map(startCase).join(", ")
                    }
                    onClose={() => {
                      const changed = xor(
                        user?.global_roles || [],
                        globalRoles
                      );
                      if (changed.length) {
                        handleSaveRoles();
                      }
                    }}
                  >
                    {user?.global_roles?.map((name) => (
                      <MenuItem key={name} value={name} disabled={true}>
                        <Checkbox checked={globalRoles.indexOf(name) > -1} />
                        <ListItemText primary={startCase(name)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : user?.global_roles?.length > 0 ? (
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={toggleGlobalRolesDropDown}
                >
                  <Grid item xs="auto">
                    <RolesIcon
                      style={{
                        marginTop: "6px",
                        marginRight: "2px",
                        minWidth: "24px",
                        minHeight: "21px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{
                      maxWidth: 200,
                      ":hover": { backgroundColor: "rgba(0, 0, 0, 0.06)" },

                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "10px",
                      borderRadius: 4,
                    }}
                  >
                    {user.global_roles.map(startCase).join()}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  xs={12}
                  md={"auto"}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={toggleGlobalRolesDropDown}
                >
                  <Button
                    variant="outlined"
                    disabled={!isGreensightAdmin}
                    onClick={toggleGlobalRolesDropDown}
                  >
                    ASSIGN {upperCase(AUTH0_ROLES_LABEL)}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        marginY={4}
      >
        <Grid xs item>
          <Typography
            variant="h5"
            sx={{
              marginLeft: 1,
              wordWrap: "break-word",
              fontFamily: "Barlow",
              fontSize: "24px",
              fontWeight: 600,
            }}
            gutterBottom
          >
            User Assignments
          </Typography>
        </Grid>
        <Grid item container xs={12} sm="auto" spacing={1}>
          <Grid xs={12} sm="auto" item>
            {(hasAccess(Constants.ACTIONS.MANAGE_USER_ROLES) ||
              isGreensightAdmin) && (
              <Button
                size="medium"
                variant="contained"
                onClick={handleManageRolesClick}
              >
                Manage Roles
              </Button>
            )}
          </Grid>
          <Grid xs={12} sm="auto" item>
            {(hasAccess(Constants.ACTIONS.INVITE_USER_TO_ORG) ||
              isGreensightAdmin) && (
              <Button
                size="medium"
                variant="contained"
                onClick={handleAssignOrgDialogOpen}
              >
                <AddIcon style={{ marginRight: "12px" }} />
                Assign Organization
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Assignments Grid */}
      {user?.organizations?.length > 0 ? (
        <UsersAssignmentGrid rows={user?.organizations} user={user} />
      ) : (
        <Paper sx={{ paddingTop: 3, paddingBottom: 2, paddingLeft: 1 }}>
          <Typography fontSize={14} sx={{ marginLeft: 1 }}>
            No assigned organizations
          </Typography>
        </Paper>
      )}
      <Dialog open={showAssignDialog} onClose={handleAssignDialogClose}>
        <Box
          style={{
            width: "593px",
            height: "250px",
            padding: "16px 24px 24px 20px",
          }}
        >
          <Typography variant="h6">
            Assign <UserName user={user} /> to an Organization
          </Typography>

          <Grid container direction="column">
            <Grid item>
              <TTAutoComplete
                options={organizations.filter(
                  (org) => !currentOrgIds.includes(org.organization_id)
                )}
                label="start typing to search organizations..."
                primaryField="organization_name"
                primaryKey="organization_id"
                onChange={handleSelectedOrgsChange}
                value={selectedOrgs}
                classes={{ root: classes.orgsAutoComplete }}
                size="medium"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.assignModalButtons}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                size="medium"
                onClick={handleAssignDialogClose}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                onClick={handleSaveOrgs}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  ) : null;
};

export default UserDetails;
