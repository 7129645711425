import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Button, DialogTitle, Grid, List, ListItem } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { enqueueSnackbar } from "notistack";

import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import TTAutoComplete from "components/common/TTAutoComplete/TTAutoComplete";
import { useAuthData } from "utils/hooks/useAuthData";
import { getUserSitesWithParents } from "redux/actions/sites";

import {
  saveJobDetails,
  getJobDetails,
  generateJobPDF,
} from "redux/actions/jobs";
import { Constants } from "utils/constants";
import { SiteItem } from "models/site";

const EMPTY_ORG = { organization_id: "", organization_name: "" };

const EditJobHeader = (props: {
  open: boolean;
  jobDetail: any;
  onClose?: () => void;
}) => {
  const { jobDetail, open, onClose } = props;
  const dispatch = useAppDispatch();
  const { getIdTokenClaims } = useAuth0();
  const { getRawIdToken } = useAuthData();

  const siteList = useAppSelector((state) => state.sites.sitesWithParents);

  const [selectedSite, setSelectedSite] = useState<any>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedChannelPartner, setSelectedChannelPartner] = useState<any>();
  const [selectedInstaller, setSelectedInstaller] = useState<any>();

  useEffect(() => {
    if (!siteList) return;
    let _selectedSite: SiteItem = siteList?.filter(
      (site: { site_id: any }) => site?.site_id === jobDetail?.site?.site_id
    )[0];

    if (!_selectedSite) {
      /** If current site is not part of sitelist  Assign site info from survey details **/
      _selectedSite = {
        ...jobDetail?.site,
        customer: {
          organization_name: jobDetail.survey_result.organization_name || "",
          organization_id: jobDetail.survey_result.organization_id || "",
        },
        channel_partners: [
          {
            organization_name:
              jobDetail.survey_result.channel_partner_name || "",
            organization_id: jobDetail.survey_result.channel_partner_id || "",
          },
        ],
        installers: [
          {
            organization_name: jobDetail.survey_result.installer_name || "",
            organization_id: jobDetail.survey_result.installer_id || "",
          },
        ],
      };
      setSelectedSite(_selectedSite);
      setSelectedCustomer(_selectedSite.customer);
      setSelectedChannelPartner(_selectedSite.channel_partners[0]);
      setSelectedInstaller(_selectedSite.installers[0]);
    } else {
      setSelectedSite(_selectedSite);
      setSelectedCustomer(_selectedSite?.customer);
      setSelectedChannelPartner(
        _selectedSite?.channel_partners?.filter(
          (cp: any) =>
            cp?.organization_id === jobDetail?.survey_result?.channel_partner_id
        )[0]
      );
      setSelectedInstaller(
        _selectedSite?.installers?.filter(
          (installer: any) =>
            installer?.organization_id ===
            jobDetail?.survey_result?.installer_id
        )[0]
      );
    }
  }, [siteList]);

  useEffect(() => {
    if (!open) {
      return;
    }

    (async () => {
      const idToken = await getRawIdToken();

      // Get user sites
      dispatch(
        getUserSitesWithParents({
          token: idToken,
        })
      );
    })();
  }, [open]);

  const handleOnClose = () => {
    onClose?.();
  };

  const updateSite = () => {
    (async () => {
      let jobData: any = {};
      jobData.site_id = selectedSite?.encoded_site_id;
      jobData.channel_partner_id =
        selectedChannelPartner?.organization_id || null;
      jobData.organization_id = selectedCustomer?.organization_id || null;
      jobData.installer_id = selectedInstaller?.organization_id || null;

      const idToken = await getIdTokenClaims();

      dispatch(
        saveJobDetails({
          token: idToken?.__raw,
          jobId: jobDetail.survey_result.id,
          jobData,
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          enqueueSnackbar(`Job Saved Successfully!`, {
            variant: "default",
          });
        } else {
          enqueueSnackbar("Failed to save job!", {
            variant: "error",
          });
        }
        onClose?.();
        // Regenerate pdf for COMPLETED jobs
        if (
          jobDetail?.survey_result?.status === Constants.SURVEY_STATUS.COMPLETED
        ) {
          generatePDF();
        } else {
          dispatch(
            getJobDetails({
              token: idToken?.__raw,
              jobId: jobDetail?.survey_result?.id,
            })
          );
        }
      });
    })();
  };

  const generatePDF = () => {
    (async () => {
      const idToken = await getIdTokenClaims();

      dispatch(
        generateJobPDF({
          token: idToken?.__raw,
          jobId: jobDetail.survey_result.id,
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          console.log("PDF Generated");
        } else {
          enqueueSnackbar("Failed to generate pdf!", {
            variant: "error",
          });
        }
        dispatch(
          getJobDetails({
            token: idToken?.__raw,
            jobId: jobDetail.survey_result.id,
          })
        );
      });
    })();
  };

  if (!siteList) {
    return null;
  }

  return (
    <Dialog open={open}>
      <Box
        sx={{
          width: "593px",
        }}
      >
        <DialogTitle>Select Site</DialogTitle>
        <Box sx={{ padding: 3 }}>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <TTAutoComplete
                options={siteList}
                label="Search for site by name or address"
                multiple={false}
                primaryField="address"
                secondaryField="name"
                primaryKey="site_id"
                limit={1}
                value={[selectedSite] || []}
                placeHolder="Search"
                filterSelectedOptions={false}
                clearable={false}
                onChange={(value: any) => {
                  setSelectedSite(value);
                  setSelectedCustomer(value?.customer);
                  setSelectedChannelPartner(value.channel_partners?.[0]);
                  setSelectedInstaller(value.installers?.[0]);
                }}
              />
            </ListItem>
            <ListItem disableGutters>
              <TTAutoComplete
                options={[selectedSite?.customer]}
                label={"Customer"}
                multiple={false}
                primaryField="organization_name"
                primaryKey="organization_id"
                limit={1}
                value={[selectedCustomer]}
                disabled={true}
                filterSelectedOptions={false}
              />
            </ListItem>
            <ListItem disableGutters>
              <TTAutoComplete
                options={selectedSite?.channel_partners}
                label={"Channel Partner"}
                multiple={false}
                primaryField="organization_name"
                primaryKey="organization_id"
                limit={1}
                value={
                  selectedChannelPartner
                    ? [selectedChannelPartner]
                    : [EMPTY_ORG]
                }
                filterSelectedOptions={false}
                onChange={(value: any) => {
                  setSelectedChannelPartner(value !== null ? value : EMPTY_ORG);
                }}
              />
            </ListItem>
            <ListItem disableGutters>
              <TTAutoComplete
                options={selectedSite?.installers}
                label={"Installer"}
                multiple={false}
                primaryField="organization_name"
                primaryKey="organization_id"
                limit={1}
                value={selectedInstaller ? [selectedInstaller] : [EMPTY_ORG]}
                filterSelectedOptions={false}
                onChange={(value: any) => {
                  setSelectedInstaller(value !== null ? value : EMPTY_ORG);
                }}
              />
            </ListItem>
          </List>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              mt={"10px"}
            >
              <Button sx={{ mr: 1 }} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={updateSite}>
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};
export default EditJobHeader;
