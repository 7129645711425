import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Clear as ClearIcon } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { Grid } from "@mui/material";
import { Constants } from "utils/constants";
import { useState } from "react";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import { updateFilter } from "redux/reducers/filtersSlice";
import TTDatePicker from "components/common/TTDatePicker";
import TextField from "@mui/material/TextField";

import Autocomplete from "@mui/material/Autocomplete";
import { getUsers } from "redux/actions/users";
import { useAuthData } from "utils/hooks/useAuthData";
import { debounce } from "lodash";
import { UsersRow } from "models/users";
import { useEffect } from "react";

const AdditionalFilter = (props: { onApplyFilter?: () => void }) => {
  const { onApplyFilter } = props;
  const extraFilters = useAppSelector(
    (state) => state.filters?.jobFilters.extra
  );
  const totalFilters = extraFilters
    ? Object.keys(extraFilters).filter((k) => extraFilters[k])
    : [];
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDirty, setDirty] = React.useState<Boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (isDirty) {
      setDirty(false);
      onApplyFilter?.();
    }
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    extraFilterType: string
  ) => {
    setDirty(true);
    dispatch(
      updateFilter({
        filterType: "extra",
        value: {
          ...extraFilters,
          [extraFilterType]: event.target.checked,
        },
      })
    );
  };

  const handleDateChange = (date: Dayjs | null, extraFilterType: string) => {
    setDirty(true);
    dispatch(
      updateFilter({
        filterType: "extra",
        value: {
          ...extraFilters,
          [extraFilterType]: date?.format("L"),
        },
      })
    );
  };

  const [completedByValue, setCompletedByValue] = useState<string | null>(
    extraFilters?.completedBy || null
  );
  const [lastUpdatedByValue, setLastUpdatedByValue] = useState<string | null>(
    extraFilters?.lastUpdatedBy || null
  );
  const { getRawIdToken } = useAuthData();
  const [users, setUsers] = useState<string[]>([]);
  const [clearFiltersClicked, setClearFiltersClicked] = React.useState(false);

  useEffect(() => {
    debouncedApplyFilter(onApplyFilter);
  }, [clearFiltersClicked]);

  const debouncedApplyFilter = React.useRef(
    debounce((_onApplyFilter) => {
      _onApplyFilter?.();
      setClearFiltersClicked(false);
    }, 500)
  ).current;

  const handleCompletedByOrLastUpdatedByChange = (
    value: string | null,
    extraFilterType: string
  ) => {
    setDirty(true);
    setCompletedByOrLastUpdatedBy(value, extraFilterType);
    dispatch(
      updateFilter({
        filterType: "extra",
        value: {
          ...extraFilters,
          [extraFilterType]: value,
        },
      })
    );
  };

  const setCompletedByOrLastUpdatedBy = (
    value: string | null,
    extraFilterType: string
  ) => {
    if (extraFilterType === "completedBy") {
      setCompletedByValue(value);
    } else if (extraFilterType === "lastUpdatedBy") {
      setLastUpdatedByValue(value);
    }
  };

  useEffect(() => {
    setCompletedByValue(extraFilters?.completedBy);
    setLastUpdatedByValue(extraFilters?.lastUpdatedBy);
  }, [extraFilters]);

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async () => {
    const idToken = await getRawIdToken();
    const responseAction = await dispatch(
      getUsers({
        token: idToken,
        page: 1,
        size: 50,
        orderBy: "email",
        dir: "asc",
        filter: "",
        includeInactiveUsers: false,
        listVisibleOrgUsers: true,
      })
    );
    if (getUsers.fulfilled.match(responseAction)) {
      const responseData = responseAction.payload?.result?.data;
      if (responseData !== null && responseData !== undefined) {
        const userEmails = responseData.map((user: UsersRow) =>
          user.email.toLowerCase()
        );
        setUsers(userEmails);
      }
    }
  };

  const handleResetFilter = () => {
    dispatch(updateFilter({ filterType: "extra", value: undefined }));
    setClearFiltersClicked(true);
  };

  return (
    <Box sx={{ minWidth: 80 }}>
      <Button
        id="basic-button"
        variant="text"
        color="inherit"
        startIcon={<FilterListIcon />}
        sx={{
          textTransform: "none",
        }}
        aria-controls={open ? "additional-filters-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {"Filters "}
        {totalFilters.length > 0 ? (
          <ClearIcon
            sx={{
              ml: "7px",
              mr: "-7px",
              padding: "1px",
              bgcolor: "#f1f1f1",
              borderRadius: "50%",
              "&:hover": { bgcolor: "#81B5EA", color: "#ffffff" },
            }}
            aria-label="delete"
            fontSize="small"
            onClick={(e) => {
              e.stopPropagation();
              handleResetFilter();
            }}
          />
        ) : (
          <i style={{ padding: "0 11px" }}></i>
        )}
      </Button>
      <Menu
        id="additional-filters-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ whiteSpace: "unset", wordBreak: "break-all" }}
      >
        <Box sx={{ padding: 2, maxHeight: 500, width: 400 }}>
          <Typography variant="button" display="block" gutterBottom>
            STATUS
          </Typography>
          <FormGroup sx={{ minWidth: 200, marginBottom: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={
                    extraFilters?.[Constants.SURVEY_STATUS.COMPLETED] || false
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, Constants.SURVEY_STATUS.COMPLETED)
                  }
                />
              }
              label="Completed"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={
                    extraFilters?.[Constants.SURVEY_STATUS.INCOMPLETE] || false
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, Constants.SURVEY_STATUS.INCOMPLETE)
                  }
                />
              }
              label="Incomplete"
            />
          </FormGroup>

          <Typography variant="button" display="block" gutterBottom>
            COMPLETED BY
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnSpacing={1}
            sx={{ mb: 3 }}
          >
            <Grid container direction="column" sx={{ paddingX: 1 }}>
              <Autocomplete
                options={users}
                value={completedByValue || null}
                disablePortal
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event, email) => {
                  setCompletedByValue(email);
                  handleCompletedByOrLastUpdatedByChange(email, "completedBy");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search by user email" />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="button" display="block" gutterBottom>
            LAST UPDATED BY
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnSpacing={1}
            sx={{ mb: 3 }}
          >
            <Grid container direction="column" sx={{ paddingX: 1 }}>
              <Autocomplete
                options={users}
                value={lastUpdatedByValue || null}
                disablePortal
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event, email) => {
                  setLastUpdatedByValue(email);
                  handleCompletedByOrLastUpdatedByChange(
                    email,
                    "lastUpdatedBy"
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search by user email" />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="button" display="block" gutterBottom>
            LAST UPDATED
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item>
              <TTDatePicker
                label="Last Updated"
                value={
                  extraFilters?.dateModified
                    ? dayjs(extraFilters.dateModified)
                    : null
                }
                onChange={(date: dayjs.Dayjs | null) =>
                  handleDateChange(date, "dateModified")
                }
                disableFuture
              />
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                onClick={() => handleDateChange(null, "dateModified")}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </Box>
  );
};

export default AdditionalFilter;
