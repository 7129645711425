import { ColumnState } from "utils/types";

const defaultColumns: readonly ColumnState[] = [
  {
    field: "role_name",
    width: 140,
    hide: false,
    index: 0,
  },
  {
    field: "description",
    width: 200,
    hide: false,
    index: 0,
  },
  {
    field: "organizations",
    hide: false,
    index: 0,
  },
  {
    field: "is_system",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "last_updated_dt",
    width: 180,
    hide: false,
    index: 0,
  },
  {
    field: "actions",
    width: 40,
    hide: false,
    index: 0,
    sortable: false,
  },
];

export default defaultColumns;
