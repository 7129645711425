import { Grid, Button, Typography } from "@mui/material";
import CheckboxList, {
  CheckboxListItem,
  intersection,
  not,
} from "components/common/checkboxlist";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { loadAvailableOrganizations } from "../../services/organizations";
import {
  changeTemplateOrganizationsAssignment,
  loadSurveyTemplateOrganizationsSummary,
} from "../../services/surveytemplates";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAuthData } from "utils/hooks/useAuthData";
const AssignOrgToTemplate = () => {
  let { templateId } = useParams();
  const { getRawIdToken } = useAuthData();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [checked, setChecked] = useState<CheckboxListItem[]>([]);
  const [inTemplate, setInTemplate] = useState<CheckboxListItem[]>([]);
  const [outTemplate, setOutTemplate] = useState<CheckboxListItem[]>([]);
  const [templateName, setTemplateName] = useState<string>("...");
  useEffect(() => {
    const getEditData = async () => {
      const idToken = await getRawIdToken();
      const templateOrganizations =
        await loadSurveyTemplateOrganizationsSummary(idToken, templateId);
      let inItems: CheckboxListItem[] = [];
      if (templateOrganizations && templateOrganizations.result) {
        inItems = templateOrganizations.result.organizations.map(
          (item: any) => {
            return {
              id: item.organization_id,
              value: item.organization_name,
            };
          }
        );
        setInTemplate(inItems);
        setTemplateName(templateOrganizations.result.template_name);
      }
      const availableOrganizations = await loadAvailableOrganizations(idToken);
      if (availableOrganizations && availableOrganizations.result) {
        let items = availableOrganizations.result.map((item: any) => {
          return {
            id: item.organization_id,
            value: item.organization_name,
          };
        });
        let filteredOut = items.filter(
          (i: CheckboxListItem) => !inItems.find(({ id }) => i.id === id)
        );
        setOutTemplate(filteredOut);
      }
    };
    getEditData();
  }, [templateId]);
  const outTemplateChecked = intersection(checked, outTemplate);
  const inTemplateChecked = intersection(checked, inTemplate);
  const handleOutTemplateChecked = () => {
    setOutTemplate(outTemplate.concat(inTemplateChecked));
    setInTemplate(not(inTemplate, inTemplateChecked));
    setChecked(not(checked, inTemplateChecked));
  };

  const handleInTemplateChecked = () => {
    setInTemplate(inTemplate.concat(outTemplateChecked));
    setOutTemplate(not(outTemplate, outTemplateChecked));
    setChecked(not(checked, outTemplateChecked));
  };
  const changeOrgsAssignment = async () => {
    const inTpl = inTemplate.map((inTpl) => {
      return inTpl.id;
    });
    const outTpl = outTemplate.map((outTpl) => {
      return outTpl.id;
    });
    const idToken = await getRawIdToken();
    await changeTemplateOrganizationsAssignment(idToken, templateId, {
      in: inTpl,
      out: outTpl,
    });
    enqueueSnackbar("Organizations assignment updated!", {
      variant: "success",
    });
    navigate("../templates");
  };
  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs={6} sx={{ pl: 1 }}>
            <Typography variant="subtitle1">
              Assign Organizations to {templateName}
            </Typography>
          </Grid>
          <Grid item xs sx={{ pt: 1, pr: 1 }}>
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                component={Link}
                to="../templates"
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={changeOrgsAssignment}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item alignItems="stretch">
            <Grid container direction="column" alignItems="stretch">
              {CheckboxList(
                "Assigned to template",
                inTemplate,
                checked,
                setChecked
              )}
            </Grid>
          </Grid>
          <Grid item alignItems="center" justifyContent="center">
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleOutTemplateChecked}
                disabled={inTemplateChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleInTemplateChecked}
                disabled={outTemplateChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item alignItems="stretch">
            <Grid container direction="column" alignItems="stretch">
              {CheckboxList(
                "Not assigned to template",
                outTemplate,
                checked,
                setChecked
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AssignOrgToTemplate;
