import { useMemo, useState } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Asset, Job } from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";

import DataGrid, { Column, SortColumn } from "react-data-grid";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";

import { useAuthData } from "utils/hooks/useAuthData";
import { dataGridStyle } from "theme/styles";
import TTToolTipText from "components/common/TTToolTipText";
import { Constants } from "utils/constants";
import { groupBy as rowGrouper } from "lodash";
import {
  DETAIL_GRID_ROW_HEIGHT,
  DETAIL_GRID_HEADER_HEIGHT,
} from "./AssetDetailsUtil";
import { dateToUserLocalTimezone } from "utils/datetime";
import { getComparator, getCompareFn } from "utils/comparator";

interface FlatJob extends Job {
  templateName?: string;
}

function getRowKey(row: FlatJob) {
  return row.id;
}

function getRowDate(row: FlatJob) {
  return row.completedDt || row.createdDt;
}

function getRowLink(row: FlatJob) {
  return "/jobs/edit/" + getRowKey(row);
}

const JobsListCard = (props: { asset?: Asset }) => {
  const { asset } = props;
  const { hasAccess } = useAuthData();
  const navigate = useNavigate();
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const createColumns = useMemo(
    (): readonly Column<FlatJob>[] => [
      {
        key: "templateName",
        name: "Job Type",
        formatter(props) {
          return (
            <TTToolTipText
              text={props.row.templateName || ""}
              link={
                hasAccess(Constants.ACTIONS.VIEW_JOBS)
                  ? getRowLink(props.row)
                  : ""
              }
              underline="hover"
            />
          );
        },
      },
      {
        key: "completedDt",
        name: "Date",
        formatter(props) {
          const dt = getRowDate(props.row);
          return (
            <TTToolTipText text={dateToUserLocalTimezone(dt)} tooltip={dt} />
          );
        },
      },
      {
        key: "completedBy",
        name: "Completed By",
        formatter(props) {
          return (
            <TTToolTipText
              text={props.row.completedBy || ""}
              link={
                props.row.completedBy ? `mailto:${props.row.completedBy}` : ""
              }
            />
          );
        },
      },
    ],
    [hasAccess]
  );

  const handleRowClick = (row: FlatJob) => {
    if (hasAccess(Constants.ACTIONS.VIEW_JOBS)) {
      navigate(getRowLink(row));
    }
  };

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    setSortColumns(sortColumns);
  };

  const sortedRows = useMemo((): readonly FlatJob[] => {
    const rows: FlatJob[] =
      asset?.jobs?.edges?.map((e) => ({
        ...e.job,
        templateName: e.job?.template?.name || "",
      })) || ([] as FlatJob[]);
    if (sortColumns.length === 0) {
      // by default, sort the asset's jobs by date, descending
      return rows.sort((a, b) => -getComparator("completedDt")(a, b));
    }
    return rows.sort(getCompareFn(sortColumns));
  }, [asset?.jobs?.edges, sortColumns]);

  return (
    <Paper
      elevation={1}
      sx={{
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      <Stack>
        <Typography
          variant="body1"
          sx={{
            wordWrap: "break-word",
            paddingRight: 2,
            fontWeight: "bold",
          }}
        >
          Jobs
        </Typography>
        <Box
          style={{
            width: "100%",
          }}
          className="sites-data-grid"
          mt={1}
        >
          <DataGrid
            style={{
              ...dataGridStyle,
              cursor: "pointer",
              border: "none",
              boxShadow: "none",
              height:
                DETAIL_GRID_ROW_HEIGHT * sortedRows.length +
                DETAIL_GRID_HEADER_HEIGHT,
            }}
            className={"rdg-light"}
            columns={createColumns}
            rows={sortedRows}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            rowKeyGetter={getRowKey}
            sortColumns={sortColumns}
            onSortColumnsChange={onSortColumnsChange}
            rowGrouper={rowGrouper}
            rowHeight={DETAIL_GRID_ROW_HEIGHT}
            headerRowHeight={DETAIL_GRID_HEADER_HEIGHT}
            onRowClick={handleRowClick}
          />
          <Tooltip
            id="cell-tooltip"
            place="left"
            style={{ maxWidth: "250px", zIndex: 999 }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default createFragmentContainer(JobsListCard, {
  asset: gql`
    fragment JobsListCard_asset on Asset {
      id
      jobs {
        edges {
          job {
            id
            surveyTemplateId
            createdDt
            createdBy
            lastUpdatedDt
            lastUpdatedBy
            status
            site
            siteId
            surveyTemplateVersion
            deviceId
            completedDt
            completedBy
            template {
              id
              name
              description
              createdDt
              createdBy
              lastUpdatedDt
              lastUpdatedBy
              publishedDt
              publishedBy
              isPublished
              isCurrent
              isDeleted
              version
            }
          }
        }
      }
    }
  `,
});
