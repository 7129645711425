import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackToJobListButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ArrowBackIcon />}
      sx={{ textTransform: "none" }}
      onClick={() => {
        navigate("/jobs");
      }}
    >
      Back to Jobs
    </Button>
  );
};

export default BackToJobListButton;
