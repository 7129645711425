import TTToolTipText from "components/common/TTToolTipText";
import GppGoodIcon from "@mui/icons-material/GppGood";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Colors } from "theme/colors";
import {
  EXPIRED,
  FAILED,
  REPLACED,
  UNDER_WARRANTY,
  UNKNOWN,
} from "utils/strings";
import { WarrantyStatus } from "utils/types";
import { Grid } from "@mui/material";

export const WarrantyStatusBadge = (props: {
  value: string;
  alignItems?: string;
}) => {
  return (
    <Grid container alignItems={props.alignItems || "center"}>
      <Grid item>
        <>
          {props?.value === UNDER_WARRANTY ? (
            <GppGoodIcon
              style={{
                color: Colors.ttBlue,
                marginRight: "4px",
              }}
            />
          ) : (
            <NewReleasesIcon
              style={{
                color: Colors.ttPersianRed,
                marginRight: "4px",
              }}
            />
          )}
        </>
      </Grid>
      <Grid item>
        <TTToolTipText text={props?.value} />
      </Grid>
    </Grid>
  );
};

export const warrantyStatusLabel = (status: any) => {
  switch (status) {
    case WarrantyStatus.IN_WARRANTY:
      return UNDER_WARRANTY;
    case WarrantyStatus.EXPIRED:
      return EXPIRED;
    case WarrantyStatus.REPLACED:
      return REPLACED;
    case WarrantyStatus.FAILED:
      return FAILED;
  }
  return UNKNOWN;
};
