import { useEffect, useState } from "react";
import { Box, TablePagination, Typography } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../redux/store/hooks";
import { getJobsList, getJobsListWithFilters } from "redux/actions/jobs";
import { getUserSites } from "redux/actions/sites";
import { getOrganizationsList } from "redux/actions/orgs";
import { getSimpleTemplateList } from "redux/actions/templates";

import JobsDataGrid from "./dataGrid";
import { Constants } from "utils/constants";
import JobsFilter from "./filter/filter";
import isDeepEmpty from "utils/isDeepEmpty";
import { useAuthData } from "utils/hooks/useAuthData";
import { SortColumn } from "react-data-grid";
import { cloneDeep, snakeCase } from "lodash";
import { setGrid, DEFAULT_SORT } from "redux/reducers/jobsGridSlice";
import {
  sortColumnForGridState,
  updateGridState,
} from "components/common/gridview/gridState";

const columnNameToField = (name: string) => {
  switch (name) {
    case "equipment":
      return "equipment_id";
    case "installer":
      return "installer_name";
    case "channelPartner":
      return "channel_partner_name";
    default:
      return snakeCase(name);
  }
};

const fieldToColumnName = (name: string) => {
  switch (name) {
    case "equipment_id":
      return "equipment";
    case "installer_name":
      return "installer";
    case "channel_partner_name":
      return "channelPartner";
    default:
      return name;
  }
};

const Jobs = () => {
  const data = useAppSelector((state) => state.jobs.data);
  const totalCount = useAppSelector((state) => state.jobs.totalCount);
  const loading = useAppSelector((state) => state.jobs.loading);
  const siteList = useAppSelector((state) => state.sites.data);
  const templates = useAppSelector((state) => state.templates.data);
  const filters = useAppSelector((state) => state.filters.jobFilters);
  const gridState = useAppSelector((state) => state.jobsGrid.grid);

  const dispatch = useAppDispatch();
  const { getRawIdToken, ready } = useAuthData();

  const [page, setPage] = useState(0);

  const [exportData, setExportData] = useState({});
  const [initialCall, setInitialCall] = useState(true);

  useEffect(() => {
    if (ready) {
      if (initialCall) {
        setInitialCall(false);
      } else {
        return;
      }
      (async () => {
        const idToken = await getRawIdToken();

        if (isDeepEmpty(filters)) {
          getDefaultList();
        } else {
          getFilteredList();
        }

        // Get user sites
        dispatch(
          getUserSites({
            token: idToken,
          })
        );

        // Get orgs list
        dispatch(
          getOrganizationsList({
            token: idToken,
          })
        );

        // Get template list
        dispatch(
          getSimpleTemplateList({
            token: idToken,
          })
        );
      })();
    }
  }, [initialCall, ready]);

  const getDefaultList = () => {
    (async () => {
      const idToken = await getRawIdToken();
      dispatch(
        getJobsList({
          token: idToken,
          data: {
            order: {
              by: gridState.sortField,
              dir: gridState.sortDirection,
            },
            paging: {
              page: 1,
              size: gridState.rowsPerPage,
            },
          },
        })
      );
    })();
  };

  const getFilteredList = () => {
    (async () => {
      const idToken = await getRawIdToken();
      if (!idToken) {
        return;
      }
      const statusFilter = [];
      if (filters?.extra?.[Constants.SURVEY_STATUS.COMPLETED]) {
        statusFilter.push(Constants.SURVEY_STATUS.COMPLETED);
      }
      if (filters?.extra?.[Constants.SURVEY_STATUS.INCOMPLETE]) {
        statusFilter.push(
          Constants.SURVEY_STATUS.INCOMPLETE,
          Constants.SURVEY_STATUS.IN_PROGRESS
        );
      }
      dispatch(
        getJobsListWithFilters({
          token: idToken,
          data: {
            order: {
              by: gridState.sortField,
              dir: gridState.sortDirection,
            },
            paging: {
              page: page + 1,
              size: gridState.rowsPerPage,
            },
            status: statusFilter,
            site: {
              ids: filters?.site?.map(
                (site: { site_id: any }) => site?.site_id
              ),
            },
            organization: {
              channel_partners: filters?.channelPartner?.map(
                (org: { organization_id: any }) => org?.organization_id
              ),
              customers: filters?.customer?.map(
                (org: { organization_id: any }) => org?.organization_id
              ),
              installers: filters?.installer?.map(
                (org: { organization_id: any }) => org?.organization_id
              ),
            },
            completed_dt: {
              from: filters?.dateCompleted?.range?.from,
              to: filters?.dateCompleted?.range?.to,
            },
            completed_by: filters?.extra?.completedBy,
            last_updated_by: filters?.extra?.lastUpdatedBy,
            created_dt: {
              from: filters?.dateStarted?.range?.from,
              to: filters?.dateStarted?.range?.to,
            },
            modified_dt: {
              from: filters?.extra?.dateModified,
              to: filters?.extra?.dateModified,
            },
            template: {
              ids: filters?.template,
            },
          },
        })
      );
    })();
  };

  const handleClearFilter = () => {
    setPage(0);
    getDefaultList();
  };

  const handleChangeData = (
    rows: any,
    orderedColumns: any,
    sortColumns?: readonly SortColumn[]
  ) => {
    setExportData({ rows, orderedColumns });
    if (orderedColumns || sortColumns) {
      const updatedGridState = updateGridState(
        gridState,
        columnNameToField,
        DEFAULT_SORT,
        orderedColumns,
        sortColumns
      );
      dispatch(setGrid(updatedGridState));
    }
  };

  const handleChangeView = (index: number, width: number) => {
    const updatedGridState = cloneDeep(gridState);
    updatedGridState.columnStates[index].width = width;
    dispatch(setGrid(updatedGridState));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    dispatch(setGrid({ ...gridState, rowsPerPage: _rowsPerPage }));
    setPage(0);
  };

  useEffect(() => {
    if (initialCall) {
      return;
    }
    getFilteredList();
  }, [
    gridState.sortField,
    gridState.sortDirection,
    page,
    gridState.rowsPerPage,
  ]);

  const handleApplyFilter = () => {
    setPage(0);
    getFilteredList();
  };

  return (
    <Box style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Jobs
      </Typography>
      {siteList?.length && templates && (
        <>
          <JobsFilter
            onApply={handleApplyFilter}
            onClear={handleClearFilter}
            exportData={exportData}
            disableExport={data?.length <= 0}
          />
          {data?.length ? (
            <>
              <JobsDataGrid
                rows={data}
                onChangeData={handleChangeData}
                onChangeView={handleChangeView}
                sortColumn={sortColumnForGridState(
                  gridState,
                  fieldToColumnName
                )}
                reorderedColumns={gridState.columnStates}
              />
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={gridState.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : !loading ? (
            <Box sx={{ height: "100%" }}>No jobs matched your search</Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default Jobs;
