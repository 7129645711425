import * as React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

const MotorSettingsInfo = (props: { value: any }) => {
  const { value } = props;
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="status-info-table">
        <TableBody>
          {value?.data?.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ verticalAlign: "top" }}
              >
                {
                  <Grid
                    container
                    flexDirection="row"
                    alignContent="space-between"
                    width="100%"
                  >
                    <Grid item xs={12} md={6}>
                      <Typography fontWeight="bold">{row.type}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} textAlign="right">
                      <Typography>{row.ts}</Typography>
                    </Grid>
                  </Grid>
                }

                {/* Row info data */}
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {row.data && (
                    <TableBody>
                      {Object.keys(row.data).map((key) => (
                        <TableRow
                          key={key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {" "}
                          <TableCell scope="row" sx={{ verticalAlign: "top" }}>
                            {key}
                          </TableCell>
                          <TableCell
                            scope="row"
                            align="left"
                            sx={{ maxWidth: "300px", verticalAlign: "top" }}
                          >
                            <Typography sx={{ wordWrap: "break-word" }}>
                              {typeof row.data[key] === "boolean"
                                ? row.data[key].toString()
                                : typeof row.data[key] === "object"
                                ? JSON.stringify(row.data[key])
                                : row.data[key]}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
                {/* EOF Row info data */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MotorSettingsInfo;
