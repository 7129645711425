import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import FilterView from "../common/gridview/filtersView";
import GridView, { GridViewRef } from "../common/gridview/gridView";
import { useQuery } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import { usePromiseTracker } from "react-promise-tracker";
import { FilterItem, FiltersState } from "utils/types";
import { getSavedViews } from "redux/actions/savedviews";
import { getAllOrganizations } from "redux/actions/orgs";
import {
  getRelationshipsQuery,
  getRelationshipsParams,
  relationshipRowsFromQueryData,
} from "./relationshipDataUtil";
import { validColumnFilterModel } from "../common/gridview/assetFiltersUtil";
import { debounce } from "lodash";
import { useAuthData } from "utils/hooks/useAuthData";
import {
  defaultColumns,
  definedColumns,
  initialColumnStates,
  supportedSortColumns,
} from "./relationshipColumns";
import {
  initialState,
  resetNetSuiteRelationshipsGrid,
  resetColumnsState,
  resetFiltersState,
  resetGridState,
  setColumns,
  setDirtyState,
  setView,
  updateFiltersState,
  updateGridPropertyState,
  updateGridState,
} from "redux/reducers/netSuiteRelationshipsViewSlice";
import { cloneDeep } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useUpdateNetSuiteRelationship } from "./updateNetSuiteRelationship";
import {
  GridCellParams,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import Measure, { BoundingRect } from "react-measure";

const addOrgsToColumns = (organizations: any[], columns: any[]) => {
  const enhancedColumns = cloneDeep(columns);
  const orgNameCol = enhancedColumns.find(
    (col) => col.field === "techAdminOrgName"
  );
  if (orgNameCol) {
    orgNameCol.valueOptions = organizations.map(
      (value: any) => value.organization_name
    );
  }
  return enhancedColumns;
};

const Relationships = () => {
  const dispatch = useAppDispatch();
  const { getRawIdToken, ready } = useAuthData();
  const { promiseInProgress } = usePromiseTracker();
  const viewState = useAppSelector((state) => state.netSuiteRelationshipsView);
  const grid = useAppSelector((state) => state.netSuiteRelationshipsView.grid);
  const filters = useAppSelector(
    (state) => state.netSuiteRelationshipsView.filters
  );
  const size: number = grid?.rowsPerPage || 50;
  const [page, setPage] = useState(grid?.pageNumber ?? 0);

  const searchField = useAppSelector(
    (state) => state.netSuiteRelationshipsView?.filters?.searchField
  );

  const selectedOrgFilterItems = useAppSelector((state) => {
    const orgs = state.netSuiteRelationshipsView?.filters?.orgs;
    return Array.isArray(orgs) ? (orgs as FilterItem[]) : [];
  });

  const emptyColumns: any[] = []; // prevent app selector returning different values for the same input
  const columns = useAppSelector((state) => {
    return Array.isArray(state.netSuiteRelationshipsView?.columns)
      ? state.netSuiteRelationshipsView.columns
      : emptyColumns;
  });
  const organizations = useAppSelector((state) => state.orgsList.data?.orgs);

  const [columnFilterModel, setColumnFilterModel] = useState(
    Array.isArray(filters?.columnFilters?.items) ? filters.columnFilters : []
  );

  const debounceColumnFilterModel = debounce((model: any) => {
    setColumnFilterModel(model);
  }, 300);
  useEffect(() => {
    debounceColumnFilterModel(
      Array.isArray(filters?.columnFilters?.items)
        ? filters.columnFilters
        : { items: [] }
    );
    return () => {
      debounceColumnFilterModel.cancel();
    };
  }, [debounceColumnFilterModel, filters.columnFilters]);

  const { data, loading } = useQuery(
    getRelationshipsQuery(
      getRelationshipsParams(
        grid,
        searchField,
        selectedOrgFilterItems,
        columnFilterModel,
        page,
        size
      )
    ),
    {
      skip: !ready, // attempt to prevent a canceled request during initial page load or refresh
      fetchPolicy: "network-only", // Refetch each page load to pick up changes after delta upload
      nextFetchPolicy: "cache-first", // Used for subsequent executions
    }
  );
  const relationships: any[] = relationshipRowsFromQueryData(data);

  const [totalCount, setTotalCount] = useState(
    data?.netSuiteRelationships?.totalCount || 0
  );
  useEffect(() => {
    if (loading === false) {
      setTotalCount(data?.netSuiteRelationships?.totalCount);
    }
  }, [loading, data]);

  const [gridViewRef, setGridViewMethods] = useState<GridViewRef | null>(null);
  const handleFiltersAction = (action: string, data?: any) => {
    if (action === "ViewChanged") {
      if (data?.view_data?.filters) {
        const viewFilters: FiltersState = data.view_data.filters;
        gridViewRef?.setColumnFilterModel?.(
          validColumnFilterModel(viewFilters?.columnFilters, definedColumns),
          definedColumns
        );
      }
      if (data?.view_data?.grid) {
        const viewData: any = data.view_data.grid;
        gridViewRef?.updateCurrentGridState?.(viewData, initialState);
      }
    }
  };

  useEffect(() => {
    if (typeof grid?.pageNumber === "number") {
      setPage(grid?.pageNumber);
    }
  }, [grid?.pageNumber, grid?.rowsPerPage]);

  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          getSavedViews({
            token: idToken,
            page: "netSuiteRelationships",
          })
        );
        dispatch(
          getAllOrganizations({
            token: idToken,
          })
        );
      })();
    }
  }, [ready]);

  useEffect(() => {
    if (organizations?.length && columns?.length) {
      dispatch(setColumns(addOrgsToColumns(organizations, columns)));
    }
  }, [organizations]);

  const { updateNetSuiteRelationship, updateResult } =
    useUpdateNetSuiteRelationship();
  // Display error when value changes
  const error = updateResult.error?.message;
  useEffect(() => {
    error &&
      enqueueSnackbar(`Error updating relationship: ${error}`, {
        variant: "error",
      });
  }, [error]);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, originalRow: GridRowModel) => {
      if (newRow.techAdminOrgName === originalRow.techAdminOrgName) {
        return originalRow;
      }

      // Map dropdown value, the org name, to the org ID
      const newTechAdminOrg = organizations.find(
        (org) => org.organization_name === newRow.techAdminOrgName
      );
      if (!newTechAdminOrg) {
        enqueueSnackbar(`Organization ${newRow.techAdminOrgName} not found`, {
          variant: "error",
        });
        return originalRow;
      }

      // Make the HTTP request to save in the backend
      const response = await updateNetSuiteRelationship({
        id: newRow.id,
        techAdminOrgId: newTechAdminOrg.organization_id,
      });

      enqueueSnackbar(`Saved organization mapping`, {
        variant: "success",
      });

      return (
        response?.data?.updateNetSuiteRelationship?.relationship || originalRow
      );
    },
    [organizations, updateNetSuiteRelationship]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    enqueueSnackbar(error.message, { variant: "error" });
  }, []);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [editRowId, setEditRowId] = useState<GridRowId>();

  const handleRowClick = useCallback(
    (params: GridCellParams) => {
      const newRowModesModel: GridRowModesModel = {};
      if (editRowId) {
        newRowModesModel[editRowId] = { mode: GridRowModes.View };
      }
      newRowModesModel[params.id] = { mode: GridRowModes.Edit };
      setRowModesModel(newRowModesModel);
      setEditRowId(params.id);
    },
    [editRowId]
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const [dimensions, setDimensions] = useState<BoundingRect | undefined>();

  return (
    <Box>
      <Measure
        bounds={true}
        onResize={(contentRect) => {
          setDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <FilterView
            columns={columns}
            defaultColumns={defaultColumns}
            definedColumns={definedColumns}
            fileContentLabel="netSuiteRelationships"
            filters={filters}
            initialColumnStates={initialColumnStates}
            initialState={initialState}
            resetAll={resetNetSuiteRelationshipsGrid}
            resetColumnsState={resetColumnsState}
            resetFiltersState={resetFiltersState}
            resetGridState={resetGridState}
            setDirtyState={setDirtyState}
            setView={setView}
            updateFiltersState={updateFiltersState}
            updateGridPropertyState={updateGridPropertyState}
            viewState={viewState}
            getQuery={getRelationshipsQuery}
            getQueryParams={getRelationshipsParams}
            onFiltersAction={handleFiltersAction}
            rowsFromQueryData={relationshipRowsFromQueryData}
            ref={measureRef}
          />
        )}
      </Measure>
      <GridView
        columns={columns}
        defaultColumns={defaultColumns}
        definedColumns={definedColumns}
        filters={filters}
        initialColumnStates={initialColumnStates}
        loading={loading && !promiseInProgress}
        resetAll={resetNetSuiteRelationshipsGrid}
        rows={relationships}
        setColumns={setColumns}
        setDirtyState={setDirtyState}
        supportedSortColumns={supportedSortColumns}
        totalCount={totalCount}
        updateFiltersState={updateFiltersState}
        updateGridPropertyState={updateGridPropertyState}
        updateGridState={updateGridState}
        useLoadingBackdrop={false}
        viewState={viewState}
        onRef={(methods: GridViewRef) => {
          setGridViewMethods(methods);
        }}
        yOffset={200 + (dimensions?.height || 0)}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        onRowClick={handleRowClick}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
      />
    </Box>
  );
};

export default Relationships;
