import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCallConfig, trackedApiCall } from "services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export const listRoles = createAsyncThunk(
  "roles/listRoles",
  async (payload: any) => {
    const { token } = payload;
    const listRolesResult = (await trackedApiCall(
      setCallConfig(`api/roles/list`, "GET", token, {})
    )) as ApiResponse;
    return listRolesResult?.data?.result;
  }
);
export const getRole = createAsyncThunk(
  "roles/getRole",
  async (payload: any) => {
    const { token, roleId } = payload;
    const getRoleResult = (await trackedApiCall(
      setCallConfig(`api/role/${roleId}`, "GET", token, {})
    )) as ApiResponse;
    return getRoleResult?.data?.result;
  }
);
export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async (payload: any) => {
    const { token, roleId, editData } = payload;
    const getRoleResult = (await trackedApiCall(
      setCallConfig(`api/role/${roleId}`, "PUT", token, editData)
    )) as ApiResponse;
    return getRoleResult?.data?.result;
  }
);
export const addRole = createAsyncThunk(
  "roles/addRole",
  async (payload: any) => {
    const { token, addData } = payload;
    const addRoleResult = (await trackedApiCall(
      setCallConfig(`api/role`, "POST", token, addData)
    )) as ApiResponse;
    return addRoleResult?.data?.result;
  }
);

export const getRoleActions = createAsyncThunk(
  "roles/getRoleActions",
  async (payload: any) => {
    const { token, roleId } = payload;
    const getRoleActionsResult = (await trackedApiCall(
      setCallConfig(`api/role/${roleId}/actions`, "GET", token, {})
    )) as ApiResponse;
    return getRoleActionsResult?.data?.result;
  }
);

export const getRoleOrgs = createAsyncThunk(
  "roles/getRoleOrgs",
  async (payload: any) => {
    const { token, roleId } = payload;
    const getRoleOrgsResult = (await trackedApiCall(
      setCallConfig(`api/role/${roleId}/organizations`, "GET", token, {})
    )) as ApiResponse;
    return getRoleOrgsResult?.data?.result;
  }
);
export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (payload: any) => {
    const { token, roleId } = payload;
    const deleteRoleResult = (await trackedApiCall(
      setCallConfig(`api/role/${roleId}`, "DELETE", token, {})
    )) as ApiResponse;
    return deleteRoleResult?.data?.result;
  }
);
