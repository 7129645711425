import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import { getUsers, getUserDetails } from "../actions/users";
import { Sort } from "utils/types";

interface UsersState {
  data?: any;
  userDetail?: any;
  totalCount?: number;
  loading: boolean;
  error?: string;
  sortField: string;
  sortDirection: Sort;
  filter: string;
  includeInactiveUsers: boolean;
}

const initialState: UsersState = {
  data: undefined,
  totalCount: 0,
  userDetail: undefined,
  loading: false,
  error: undefined,
  sortField: "email",
  sortDirection: Sort.ASC,
  filter: "",
  includeInactiveUsers: false,
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSortOrder(state, action) {
      state.sortField = action.payload?.by ?? "";
      state.sortDirection = action.payload?.dir ?? "";
    },
    setFilter(state, action) {
      state.filter = action.payload ?? "";
    },
    setIncludeInactiveUsers(state, action) {
      state.includeInactiveUsers = action.payload ?? false;
    },
  },
  extraReducers(builder): void {
    /**User List Reducer */
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.result.data;
      state.totalCount = action.payload?.result.total_count;
    });

    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    /**User Details Reducer */
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetail = action.payload?.result;
    });

    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export const { setSortOrder, setFilter, setIncludeInactiveUsers } =
  slice.actions;
export default slice.reducer;
