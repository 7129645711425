import { Fragment, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { dateToUserLocalTimezone } from "utils/datetime";
import { findIndex, isEmpty } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit";
import { enqueueSnackbar } from "notistack";
import { NotAllowed } from "components/notallowed";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import {
  generateJobPDF,
  getJobDetails,
  saveJobDetails,
} from "redux/actions/jobs";
import { useAuthData } from "utils/hooks/useAuthData";
import { TabPanel, a11yProps } from "components/layout/tabpanel";
import JobFieldItem from "./jobFieldItem";
import { JobGroupItem, JobItem } from "models/jobs";
import { Constants } from "utils/constants";
import EditJobHeader from "./editHeader";
import JobHeaderInfo from "./jobHeaderInfo";
import BackToJobListButton from "./backToJobListButton";

const EditJob = () => {
  const { hasAccess, isGreensightAdmin, getRawIdToken, ready } = useAuthData();
  const { jobId } = useParams();
  const dispatch = useAppDispatch();
  const { getIdTokenClaims } = useAuth0();

  const jobDetail: any = useAppSelector((state) => state.jobs.jobDetail);
  const loading = useAppSelector((state) => state.jobs.loading);
  const [tabIndex, setTabIndex] = useState(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showEditJobHeader, setShowEditJobHeader] = useState<boolean>(false);
  const [editedJob, setEditedJob] = useState<any>(jobDetail?.survey_result);
  const [showDiscardEditDialog, setShowDiscardEditDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        // Get job details
        dispatch(
          getJobDetails({
            token: idToken,
            jobId: jobId || "",
          })
        );
      })();
    }
  }, [jobId, ready]);

  useEffect(() => {
    if (jobDetail?.survey_result) setEditedJob({ ...jobDetail.survey_result });
  }, [jobDetail]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleEdit = (event: SyntheticEvent) => {
    setEditMode(true);
  };

  const allowEditJobs = useMemo(() => {
    return isGreensightAdmin || hasAccess(Constants.ACTIONS.EDIT_JOBS);
  }, [isGreensightAdmin, hasAccess]);

  const allowViewJobDetails = hasAccess(Constants.ACTIONS.VIEW_JOB_DETAILS);

  const handleChangeValue = (
    itemId: string,
    value: string,
    templateItemId: string,
    fieldItem?: string
  ) => {
    const newItems = structuredClone(editedJob?.items);
    const changeIndex: number = findIndex(
      newItems,
      (item: JobItem) => item.id === itemId
    );

    // Add Template item_id while saving edited survey items
    newItems[changeIndex].item_id = templateItemId;
    if (fieldItem) {
      newItems[changeIndex][fieldItem] = value;
    } else {
      newItems[changeIndex].value = value;
      newItems[changeIndex].raw_value = value;
    }

    setEditedJob({ ...editedJob, items: newItems });
  };

  const handleSaveEdit = (event: SyntheticEvent) => {
    setShowDiscardEditDialog(false);

    (async () => {
      const idToken = await getIdTokenClaims();

      /**Filter only edited items. Edited items have (template)item_id */
      const editedItems = editedJob?.items?.filter(
        (item: JobItem) => item.item_id
      );

      dispatch(
        saveJobDetails({
          token: idToken?.__raw,
          jobId: jobDetail?.survey_result?.id,
          jobData: { ...editedJob, items: editedItems },
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          enqueueSnackbar(`Job Saved Successfully!`, {
            variant: "default",
          });
        } else {
          enqueueSnackbar("Failed to save job!", {
            variant: "error",
          });
        }
        setEditMode(false);
        // Regenerate pdf for COMPLETED jobs
        if (
          jobDetail?.survey_result?.status === Constants.SURVEY_STATUS.COMPLETED
        ) {
          generatePDF();
        } else {
          dispatch(
            getJobDetails({
              token: idToken?.__raw,
              jobId: jobDetail?.survey_result?.id,
            })
          );
        }
      });
    })();
  };

  const generatePDF = () => {
    (async () => {
      const idToken = await getIdTokenClaims();

      dispatch(
        generateJobPDF({
          token: idToken?.__raw,
          jobId: jobDetail?.survey_result?.id,
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          console.log("PDF Generated");
        } else {
          enqueueSnackbar("Failed to generate pdf!", {
            variant: "error",
          });
        }
        dispatch(
          getJobDetails({
            token: idToken?.__raw,
            jobId: jobDetail?.survey_result?.id,
          })
        );
      });
    })();
  };

  const handleDiscardEdit = () => {
    setEditMode(false);
    setShowDiscardEditDialog(false);
  };

  if (jobDetail && isEmpty(jobDetail) && !loading) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1080px",
          marginX: "auto",
        }}
      >
        <BackToJobListButton />
        <Typography sx={{ padding: 2 }}>Job Not Found</Typography>
      </Box>
    );
  }

  const pdfCanBeDownloaded = jobDetail?.survey_result?.pdf_url
    ? jobDetail?.survey_result?.status === Constants.SURVEY_STATUS.COMPLETED
    : false;

  const openPDF = () => {
    if (pdfCanBeDownloaded) {
      window.open(jobDetail.survey_result.pdf_url, "_blank");
    }
  };

  return jobDetail && !loading && (allowViewJobDetails || allowEditJobs) ? (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1080px",
        marginX: "auto",
      }}
    >
      <Grid container justifyContent={"space-between"} mb={1}>
        <Grid item>
          <BackToJobListButton />
        </Grid>
        {allowEditJobs ? (
          <Fragment>
            {editMode ? (
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                width="auto"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => setShowDiscardEditDialog(true)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={handleSaveEdit}>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                width="auto"
              >
                {pdfCanBeDownloaded && (
                  <Grid item>
                    <Button variant="outlined" onClick={() => openPDF()}>
                      Download PDF
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="contained" onClick={handleEdit}>
                    Edit
                  </Button>
                </Grid>
              </Grid>
            )}
          </Fragment>
        ) : (
          pdfCanBeDownloaded && (
            <Fragment>
              <Grid item>
                <Button variant="outlined" onClick={() => openPDF()}>
                  Download PDF
                </Button>
              </Grid>
            </Fragment>
          )
        )}
      </Grid>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Paper
          elevation={1}
          sx={{
            padding: 3,
            border: "1px solid #E0E0E0",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            container
            alignItems={"center"}
            justifyContent="stretch"
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Stack>
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="h5"
                    sx={{
                      wordWrap: "break-word",
                      paddingRight: 2,
                    }}
                  >
                    {jobDetail.site?.name}
                  </Typography>
                  <Chip
                    label={jobDetail?.survey_template?.name}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{ background: "rgba(2, 136, 209, 0.04)" }}
                  />
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    wordWrap: "break-word",
                    paddingRight: 2,
                  }}
                >
                  {jobDetail?.site?.address}
                </Typography>
              </Stack>
              {allowEditJobs && (
                <Tooltip
                  arrow
                  data-tooltip-id="edit-header-cell-tooltip"
                  title="Edit Header"
                >
                  <IconButton
                    aria-label="Edit Header"
                    onClick={() => setShowEditJobHeader(true)}
                    sx={{
                      padding: 1,
                      "&:hover": {
                        background: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Grid>
        </Paper>

        {/* Tab Switch */}
        <Box sx={{ marginTop: 1 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {jobDetail?.survey_template?.groups?.map(
              (group: JobGroupItem, index: number) => (
                <Tab key={index} label={group.name} {...a11yProps(index)}></Tab>
              )
            )}
          </Tabs>
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 0, overflow: "auto" }}>
          <Grid item width="300px">
            <Paper
              elevation={1}
              sx={{
                padding: 3,
                border: "1px solid #E0E0E0",
              }}
            >
              <Stack>
                <Typography
                  variant="body1"
                  sx={{
                    wordWrap: "break-word",
                    paddingRight: 2,
                    fontWeight: "bold",
                  }}
                >
                  Job Details
                </Typography>
              </Stack>
              <JobHeaderInfo
                label="Completed By"
                value={jobDetail?.survey_result.completed_by}
              />
              <JobHeaderInfo
                label="Installer"
                value={jobDetail?.survey_result.installer_name}
              />
              <JobHeaderInfo
                label="Equipment"
                value={jobDetail?.survey_result.attributes?.eqmnt_id}
              />
              <JobHeaderInfo
                label="Date Started"
                value={
                  jobDetail?.survey_result.created_dt
                    ? dateToUserLocalTimezone(
                        jobDetail?.survey_result.created_dt
                      )
                    : "N/A"
                }
              />
              <JobHeaderInfo
                label="Date Completed"
                value={
                  jobDetail?.survey_result.completed_dt
                    ? dateToUserLocalTimezone(
                        jobDetail?.survey_result.completed_dt
                      )
                    : "N/A"
                }
              />
              <JobHeaderInfo
                label="Last Updated"
                value={
                  jobDetail?.survey_result.last_updated_dt
                    ? dateToUserLocalTimezone(
                        jobDetail?.survey_result.last_updated_dt
                      )
                    : "N/A"
                }
              />
              <JobHeaderInfo
                label="Customer"
                value={jobDetail?.survey_result.organization_name}
              />
              <JobHeaderInfo
                label="Channel Partner"
                value={jobDetail?.survey_result.channel_partner_name}
              />
            </Paper>
          </Grid>
          <Grid item sx={{ width: "calc(100% - 300px)", marginBottom: 5 }}>
            {jobDetail?.survey_template?.groups?.map(
              (group: JobGroupItem, index: number) => (
                <TabPanel
                  key={index}
                  value={tabIndex}
                  index={index}
                  style={{
                    height: "calc(100vh - 339px)",
                    overflow: "auto",
                  }}
                >
                  <Stack sx={{ marginLeft: 2, paddingRight: 5 }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", marginBottom: 1 }}
                    >
                      {group.name}
                    </Typography>
                    {group.items?.map((item: any, itemIndex: number) => {
                      const currentItem = jobDetail?.survey_result.items.filter(
                        (field: JobItem) =>
                          field.survey_item_id === item.item_id
                      );
                      return currentItem?.length > 0 ? (
                        <Box key={itemIndex} sx={{ my: 2 }}>
                          <JobFieldItem
                            surveyResultId={jobId || ""}
                            item={currentItem[0]}
                            templateItem={item}
                            editMode={editMode && item.is_editable}
                            onChange={handleChangeValue}
                          />
                        </Box>
                      ) : null;
                    })}
                  </Stack>
                </TabPanel>
              )
            )}
          </Grid>
        </Grid>
        <EditJobHeader
          open={showEditJobHeader}
          onClose={() => setShowEditJobHeader(false)}
          jobDetail={jobDetail}
        />

        <Dialog
          open={showDiscardEditDialog}
          onClose={() => setEditMode(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Any unsaved changes will be lost. Would you like to save before
              closing?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDiscardEdit}>Discard</Button>
            <Button variant="contained" onClick={handleSaveEdit} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  ) : !loading ? (
    <NotAllowed />
  ) : null;
};

export default EditJob;
