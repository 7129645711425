import * as React from "react";
import { Box, Skeleton, Typography } from "@mui/material";

export const CardSkeleton = () => {
  return (
    <Box>
      <Typography component="div" variant="h4" sx={{ width: "400px" }}>
        <Skeleton />
      </Typography>
      <Typography component="div" sx={{ width: "300px" }}>
        <Skeleton />
      </Typography>
      <Typography component="div" sx={{ width: "300px" }}>
        <Skeleton />
      </Typography>
    </Box>
  );
};
