import React, { useCallback } from "react";
import {
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useTplEditorStyles } from "./styles";
import { Box } from "@mui/system";
import {
  addPicklistOption,
  changePicklistOption,
  deletePicklistOption,
} from "redux/reducers/templateEditorSlice";
import { Clear } from "@mui/icons-material";
import useItemSelector from "utils/hooks/useItemSelector";
import TranslatableTextField from "./TranslatableTextField";

export interface TplEditorPicklistOptionsProps {
  groupIndex: number;
  itemIndex: number;
}

const TplEditorPicklistOptions = (props: TplEditorPicklistOptionsProps) => {
  const dispatch = useDispatch();

  const options = useItemSelector(
    props.groupIndex,
    props.itemIndex,
    (item) => item.picklist_options
  );
  const addOption = () =>
    dispatch(
      addPicklistOption({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex="1">
      <Paper sx={{ m: 2, p: 3 }}>
        <Typography variant="subtitle1" fontWeight={"bold"} mb={2}>
          Picklist options
        </Typography>
        {options?.map((option: any, index: number) => {
          return (
            <Option
              key={index}
              groupIndex={props.groupIndex}
              itemIndex={props.itemIndex}
              optionIndex={index}
              option={option}
            />
          );
        })}
        {!options?.length && (
          <Box
            border="2px dashed #dadada"
            borderRadius={3}
            m={2}
            p={3}
            width={600}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Typography>No options</Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={addOption}>Add option</Button>
        </Box>
      </Paper>
    </Box>
  );
};

interface OptionProps {
  option: any;
  groupIndex: number;
  itemIndex: number;
  optionIndex: number;
}

const Option = ({
  option,
  groupIndex,
  itemIndex,
  optionIndex,
}: OptionProps) => {
  const dispatch = useDispatch();
  const { classes } = useTplEditorStyles();

  const deleteOption = () =>
    dispatch(
      deletePicklistOption({
        groupIndex: groupIndex,
        itemIndex: itemIndex,
        optionIndex: optionIndex,
      })
    );

  const handleDisplayValueChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    language: string
  ) => {
    dispatch(
      changePicklistOption({
        groupIndex: groupIndex,
        itemIndex: itemIndex,
        optionIndex: optionIndex,
        value: { display_value: e.target.value },
        language,
      })
    );
  };

  const handleAliasChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      changePicklistOption({
        groupIndex: groupIndex,
        itemIndex: itemIndex,
        optionIndex: optionIndex,
        value: { alias: e.target.value },
      })
    );
  };
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      changePicklistOption({
        groupIndex: groupIndex,
        itemIndex: itemIndex,
        optionIndex: optionIndex,
        value: {
          sort_order: e.target.value,
        },
      })
    );
  };
  const optionSelector = useCallback((state: any) => option, [option]);
  return (
    <Box>
      <TranslatableTextField
        selector={optionSelector}
        field="display_value"
        required
        size="small"
        label="Option"
        onChange={handleDisplayValueChange}
        className={classes.textField}
        sx={{ width: "300px" }}
      ></TranslatableTextField>
      <TextField
        required
        size="small"
        label="Alias"
        value={option.alias}
        onChange={handleAliasChange}
        className={classes.textField}
        sx={{ width: "200px" }}
      ></TextField>
      <TextField
        required
        size="small"
        label="Order"
        value={option.sort_order}
        onChange={handleSortOrderChange}
        className={classes.textField}
        sx={{ width: "80px" }}
      ></TextField>
      <IconButton
        size="small"
        aria-label="Add option"
        sx={{ marginLeft: "5px", marginTop: "13px" }}
        onClick={deleteOption}
      >
        <Clear />
      </IconButton>
    </Box>
  );
};

export default TplEditorPicklistOptions;
