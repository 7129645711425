import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ColumnState,
  FilterItem,
  FiltersState,
  GridState,
  GridViewState,
  SavedViewsDataState,
  Sort,
  SortOrder,
} from "utils/types";
import { initialColumnStates } from "../../components/assets/assetColumns";
import { columnSettings } from "components/common/gridview/gridState";
import {
  getSavedViews,
  saveView,
  shareView,
  deleteView,
} from "../actions/savedviews";

const DEFAULT_SORT: SortOrder = {
  by: "assetUpdatedDt",
  dir: Sort.DESC,
};

const initialGridState: GridState = {
  sortField: DEFAULT_SORT.by,
  sortDirection: DEFAULT_SORT.dir,
  pageNumber: 0,
  totalPages: 0,
  rowsPerPage: 50,
  columnStates: initialColumnStates,
  density: "standard",
};

const initialFiltersState: FiltersState = {
  searchField: "",
  orgs: [],
  installationType: [],
  columnFilters: { items: [] },
};

export const initialState: GridViewState = {
  version: columnSettings.VERSION,
  isDirty: false,
  assets: undefined,
  columns: undefined,
  filters: initialFiltersState,
  grid: initialGridState,
  selectedView: null,
};

const assetsViewSlice = createSlice({
  name: "assetsView",
  initialState: initialState,
  reducers: {
    setDirtyState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isDirty: action.payload,
      };
    },
    setAssets: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        assets: action.payload,
      };
    },
    setColumns: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    setView: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedView: action.payload,
      };
    },
    updateFiltersState(
      state,
      action: PayloadAction<{
        property: keyof FiltersState;
        value: string | FilterItem[] | any[];
      }>
    ) {
      const { property, value } = action.payload;
      state.filters = {
        ...state.filters,
        [property]: value,
      };
    },
    updateGridState: (state, action: PayloadAction<GridState>) => {
      return {
        ...state,
        grid: action.payload,
      };
    },
    updateGridPropertyState(
      state,
      action: PayloadAction<{
        property: keyof GridState;
        value: string | number | boolean | Sort | ColumnState[];
      }>
    ) {
      const { property, value } = action.payload;
      if (property === "sortField") {
        state.grid.sortField = value as string;
      } else if (property === "sortDirection") {
        state.grid.sortDirection = value as Sort;
      } else if (property === "columnStates") {
        if (Array.isArray(value)) {
          state.grid.columnStates = value as ColumnState[];
        }
      } else if (property === "density") {
        state.grid.density = value as string;
      } else if (
        property === "pageNumber" ||
        property === "totalPages" ||
        property === "rowsPerPage"
      ) {
        state.grid[property] =
          typeof value === "number" ? value : parseInt(value as string);
      }
    },
    resetAll: (state) => {
      return initialState;
    },
    resetFiltersState: (state) => {
      return {
        ...state,
        filters: initialFiltersState,
      };
    },
    resetGridState: (state) => {
      return {
        ...state,
        grid: initialGridState,
      };
    },
    resetColumnsState: (state) => {
      return {
        ...state,
        grid: {
          ...state.grid,
          columnStates: initialGridState.columnStates,
        },
      };
    },
  },
});

const initialSavedViewsDataState: SavedViewsDataState = {
  savedViews: null,
  savedViewUsers: null,
};

const assetsSavedViewSlice = createSlice({
  name: "assetsSavedViewsData",
  initialState: initialSavedViewsDataState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getSavedViews.fulfilled, (state, action) => {
      state.savedViews = action.payload?.result?.savedviews || [];
    });
    builder.addCase(saveView.fulfilled, (state, action) => {
      if (action?.payload) {
        state.savedViews = action.payload?.savedviews || [];
      }
    });
    builder.addCase(shareView.fulfilled, (state, action) => {
      if (action?.payload) {
        state.savedViews = action.payload?.savedviews || [];
      }
    });
    builder.addCase(deleteView.fulfilled, (state, action) => {
      if (action?.payload) {
        state.savedViews = action.payload?.savedviews || [];
      }
    });
  },
});

export const {
  setDirtyState,
  setAssets,
  setColumns,
  setView,
  updateGridState,
  updateGridPropertyState,
  updateFiltersState,
  resetAll,
  resetGridState,
  resetFiltersState,
  resetColumnsState,
} = assetsViewSlice.actions;

export const assetsViewReducer = assetsViewSlice.reducer;
export const assetsSavedViewsReducer = assetsSavedViewSlice.reducer;
