import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCallConfig, trackedApiCall } from "services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export const uploadCSV = createAsyncThunk(
  "netsuite/uploadCSV",
  async (payload: any) => {
    const { token, file } = payload;
    const formData = new FormData();
    formData.append("file", file);
    const uploadResult = (await trackedApiCall(
      setCallConfig("api/netsuite/uploads", "POST", token, formData)
    )) as ApiResponse;
    return uploadResult?.data?.result;
  }
);
