import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import TplEditorGroup from "./TplEditorGroup";
import { useTplEditorStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addLanguage,
  addNewGroup,
  changeTemplateDescription,
  changeTemplateGroup,
  changeTemplateName,
  deleteCurrentLanguage,
  setSelectLanguage,
} from "redux/reducers/templateEditorSlice";
import React, { useCallback, useMemo } from "react";
import { RootState } from "redux/store";
import TranslatableTextField from "./TranslatableTextField";
import ConfirmDialog from "./ConfirmDialog";

export interface TplEditorTemplateProps {
  isNew: boolean;
}

const TplEditorTemplate = (props: TplEditorTemplateProps) => {
  const { classes } = useTplEditorStyles();

  const dispatch = useDispatch();

  const templateId = useSelector(
    (state: any) => state.templateEditor.template.template_id
  );
  const templateGroupId = useSelector(
    (state: any) => state.templateEditor.template.group_id
  );
  const templateGroupList = useSelector(
    (state: any) => state.templateEditor.templateGroupList
  );

  const handleTemplateNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    language: string
  ) => {
    dispatch(changeTemplateName({ value: e.target.value, language }));
  };
  const handleTemplateDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    language: string
  ) => {
    dispatch(changeTemplateDescription({ value: e.target.value, language }));
  };
  const handleNewGroupClick = () => {
    dispatch(addNewGroup({}));
  };
  const handleGroupChange = (e: SelectChangeEvent) => {
    dispatch(changeTemplateGroup({ value: e.target.value }));
  };

  const templateSelector = useCallback(
    (state: RootState) => state.templateEditor.template,
    []
  );
  const languages = useSelector((state: any) => state.templateEditor.languages);
  const selectedLanguage = useSelector(
    (state: any) => state.templateEditor.selectedLanguage
  );
  const [languageField, setLanguageField] = React.useState("");
  const deleteLanguageButtonVisible =
    languages.length > 1 && selectedLanguage !== "en";
  const handleAddLanguageClick = () => {
    const isDuplicateLanguage = languages.includes(languageField);
    if (isDuplicateLanguage) {
      alert("Language already exists. Please choose a different language.");
      return;
    }
    dispatch(addLanguage(languageField));
    setLanguageField("");
  };
  const handleLanguageFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLanguageField(e.target.value?.toLowerCase());
  };

  const handleDeleteLanguageClick = () => {
    setDeleteVisible(true);
  };

  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const hideDeleteModal = () => {
    setDeleteVisible(false);
  };
  const handleDeleteConfirm = () => {
    dispatch(deleteCurrentLanguage());
    hideDeleteModal();
  };

  return (
    <Box component="form">
      <Grid container direction={"column"}>
        <Box>
          {!props.isNew && (
            <TextField
              disabled
              size="small"
              label="Template id"
              value={templateId}
              className={classes.editorInput}
            ></TextField>
          )}
          <FormControl size="small" className={classes.editorInput}>
            <InputLabel id="template-group-select-label">
              Template group
            </InputLabel>
            <Select
              labelId="template-group-select-label"
              value={templateGroupId || ""}
              label="Template group"
              onChange={handleGroupChange}
            >
              {templateGroupList.map((dt: any) => {
                return (
                  <MenuItem key={dt.group_id} value={dt.group_id}>
                    {dt.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TranslatableTextField
            selector={templateSelector}
            field={"name"}
            required
            size="small"
            label="Template name"
            onChange={handleTemplateNameChange}
            className={classes.editorInput}
          ></TranslatableTextField>
        </Box>
        <Box display={"flex"} width={360 * 3}>
          <TranslatableTextField
            selector={templateSelector}
            field={"description"}
            size="small"
            rows={4}
            label="Description"
            onChange={handleTemplateDescriptionChange}
            className={classes.editorInputFullWidth}
          ></TranslatableTextField>
        </Box>

        <Grid item mt={3}>
          <Paper sx={{ m: 1.5, p: 3 }}>
            <Typography>Translation:</Typography>
            <Box display={"flex"} mt={2}>
              <Box flex={1}>
                {languages.map((language: string) => {
                  return (
                    <Button
                      onClick={() => dispatch(setSelectLanguage(language))}
                      sx={{ mr: 2 }}
                      variant={
                        selectedLanguage === language ? "contained" : "outlined"
                      }
                      key={language}
                    >
                      {language}
                    </Button>
                  );
                })}
                {deleteLanguageButtonVisible && (
                  <Button
                    sx={{ mb: 1, ml: 2, mt: 0.7 }}
                    variant={"outlined"}
                    onClick={handleDeleteLanguageClick}
                    color={"error"}
                  >
                    Delete current language
                  </Button>
                )}
              </Box>

              <TextField
                size="small"
                label={"New Language"}
                onChange={handleLanguageFieldChange}
                value={languageField}
                sx={{ ml: 3 }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Prevents form submission if the TextField is within a form
                    handleAddLanguageClick();
                  }
                }}
              ></TextField>
              <Button
                sx={{ mb: 1, ml: 2 }}
                variant={"outlined"}
                onClick={handleAddLanguageClick}
              >
                Add Language
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item mt={3}>
          <Typography variant="h5">Groups:</Typography>
        </Grid>

        <Grid item pl={2}>
          <TemplateGroupList isNew={props.isNew} />
        </Grid>
        <Grid
          container
          mt={4}
          mb={5}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Button
            variant="outlined"
            onClick={handleNewGroupClick}
            sx={{ width: "200px" }}
          >
            New group
          </Button>
        </Grid>
        {deleteVisible && (
          <ConfirmDialog
            open={deleteVisible}
            onClose={hideDeleteModal}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Grid>
    </Box>
  );
};

const TemplateGroupList = React.memo((props: any) => {
  const groupsLength = useSelector(
    (state: any) => state.templateEditor.template.groups?.length
  );
  const groups = useMemo(
    () => Array.from({ length: groupsLength }, (_, index) => index),
    [groupsLength]
  );
  return (
    <>
      {groups?.map?.((group: number) => {
        return (
          <TplEditorGroup
            isNew={props.isNew}
            key={group + "_group"}
            groupIndex={group}
          ></TplEditorGroup>
        );
      })}
    </>
  );
});

export default TplEditorTemplate;
