import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCallConfig, trackedApiCall } from "services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export const listActions = createAsyncThunk(
  "actions/listActions",
  async (payload: any) => {
    const { token } = payload;
    const listActionsResult = (await trackedApiCall(
      setCallConfig(`api/actions/list`, "GET", token, {})
    )) as ApiResponse;
    return listActionsResult?.data?.result;
  }
);
