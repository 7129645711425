import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
} from "@mui/material";
import Map, { Marker, ViewState, ViewStateChangeEvent } from "react-map-gl";
import { debounce } from "lodash";
import LocationPin from "assets/svg/locationPin";
import { createSite, editSite, getUserSites } from "redux/actions/sites";
import { useAppDispatch } from "redux/store/hooks";
import { enqueueSnackbar } from "notistack";
import { getOrganizationDetails } from "redux/actions/orgs";
import { SiteItem } from "models/site";
import { useAuthData } from "utils/hooks/useAuthData";
import { Site } from "generated-gql-types/graphql";

const drawerWidth = 350;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const MAPBOX_API_URL = process.env.REACT_APP_MAPBOX_API_URL;
const MAPBOX_LOCAL_SEARCH_QUERY =
  "?language=en-US,en-UK,es-CO,es-CL&proximity=4.7204384,-74.053557&autocomplete=true";
const MAPBOX_GEOCODING_QUERY = "?types=address";
const DEFAULT_VIEWPORT = {
  longitude: -50,
  latitude: 37,
  zoom: 3,
};

const isLatLng = (str: string) => {
  return str.match(/^\s*-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?\s*$/);
};

const fakeViewState = {
  longitude: -66.5010555011682,
  latitude: 38.45445264459141,
  zoom: 16,
  pitch: 0,
  bearing: 0,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const AddSite = (props: {
  open: boolean;
  organizationId: string | undefined;
  organizationName: string | undefined;
  selectedSite?: Site;
  allSites?: Array<string> | undefined;
  editMode?: boolean;
  onUpdateSite?: (site: SiteItem) => void;
  onClose?: () => void;
}) => {
  const {
    open,
    onClose,
    selectedSite,
    organizationId,
    organizationName,
    allSites,
    editMode,
    onUpdateSite,
  } = props;

  const { getIdTokenClaims } = useAuth0();
  const dispatch = useAppDispatch();
  const { getRawIdToken } = useAuthData();

  const [value, setValue] = React.useState<any>("");
  const [inputValue, setInputValue] = React.useState("");
  const [siteName, setSiteName] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [viewport, setViewport] = React.useState(DEFAULT_VIEWPORT);
  const [duplicateSiteName, setDuplicateSiteName] =
    React.useState<boolean>(false);
  const [allSitesState, setAllSites] = React.useState<
    Array<string> | undefined
  >(allSites);

  React.useEffect(() => {
    if (editMode && selectedSite) {
      const currentValue: any = {};
      currentValue.place_name = selectedSite.address;
      currentValue.geometry = {
        coordinates: [selectedSite.longitude, selectedSite.latitude],
      };
      setValue(selectedSite.address);
      setViewport({
        longitude: currentValue.geometry.coordinates[0],
        latitude: currentValue.geometry.coordinates[1],
        zoom: 16,
      });
      setSiteName(selectedSite.name);
    }
  }, [editMode, open, selectedSite]);

  const getSelectedAddress = (searchTerm: string) =>
    options.find((o: any) => o.place_name === searchTerm);

  const debouncedSearch = React.useRef(
    debounce((_inputValue) => {
      if (isLatLng(_inputValue)) {
        const inputStr = _inputValue.trim();
        const commaIndex = inputStr.indexOf(",");
        const latitude = parseFloat(inputStr.substring(0, commaIndex));
        const longitude = parseFloat(inputStr.substring(commaIndex + 1).trim());
        if (Math.abs(latitude) > 90) {
          enqueueSnackbar("Latitude must be between -90 and 90 degrees", {
            variant: "error",
          });
        } else if (Math.abs(longitude) > 180) {
          enqueueSnackbar("Longitude must be between -180 and 180 degrees", {
            variant: "error",
          });
        } else {
          applyViewState({
            ...fakeViewState,
            latitude,
            longitude,
          });
        }
      } else {
        getLocations(_inputValue);
      }
    }, 500)
  ).current;

  React.useEffect(() => {
    // only search if we don't already have this item
    // because searching on a picked item might not return that same item
    if (inputValue && !getSelectedAddress(inputValue)) {
      debouncedSearch(inputValue);
    }
  }, [debouncedSearch, inputValue]);

  const getLocations = async (query: string) => {
    const response = await fetch(
      `${MAPBOX_API_URL}/${encodeURIComponent(
        query
      )}.json/${MAPBOX_LOCAL_SEARCH_QUERY}&access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    debouncedSearch.cancel();
    const result = await response.json();
    setOptions(result.features);
  };

  const handleAddSite = () => {
    const address: any = getSelectedAddress(value);

    (async () => {
      const idToken = await getIdTokenClaims();
      dispatch(
        createSite({
          token: idToken?.__raw,
          organizationId: organizationId,
          name: siteName,
          address: address?.place_name,
          lon: address?.geometry?.coordinates[0],
          lat: address?.geometry?.coordinates[1],
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          enqueueSnackbar(`Added a new site!`, {
            variant: "default",
          });
        } else {
          enqueueSnackbar("Failed to add a new site!", {
            variant: "error",
          });
        }
        handleClose();
        dispatch(
          getOrganizationDetails({
            token: idToken?.__raw,
            organizationId: organizationId,
          })
        );
      });
    })();
  };

  const handleEditSite = () => {
    const address: any = options.find((o: any) => o.place_name === value);
    (async () => {
      const idToken = await getIdTokenClaims();
      dispatch(
        editSite({
          token: idToken?.__raw,
          siteId: selectedSite?.id,
          name: siteName,
          address: address?.place_name,
          lon: address?.geometry?.coordinates[0],
          lat: address?.geometry?.coordinates[1],
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          enqueueSnackbar(`Successfully edited site "${siteName}"`, {
            variant: "default",
          });
          onUpdateSite?.(resp.payload.result?.site);
        } else {
          enqueueSnackbar("Failed to edit site!", {
            variant: "error",
          });
        }
        handleClose();
        dispatch(
          getOrganizationDetails({
            token: idToken?.__raw,
            organizationId: organizationId,
          })
        );
      });
    })();
  };

  const handleClose = () => {
    setViewport(DEFAULT_VIEWPORT);
    setValue(null);
    setSiteName("");
    setDuplicateSiteName(false);
    onClose?.();
  };
  const handleEditSiteName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSiteName = e.currentTarget.value;
    if (newSiteName === selectedSite?.name) {
      setDuplicateSiteName(false);
    } else if (allSitesState?.includes(newSiteName)) {
      setDuplicateSiteName(true);
    } else {
      setDuplicateSiteName(false);
    }
    setSiteName(newSiteName);
  };

  const handleGeocoderViewportChange = (selectedLocation: any) => {
    setValue(selectedLocation?.place_name || "");
    if (!selectedLocation) return;
    setViewport({
      longitude: selectedLocation.geometry.coordinates[0],
      latitude: selectedLocation.geometry.coordinates[1],
      zoom: 16,
    });
  };

  const handleMoveMap = (evt: ViewStateChangeEvent) => {
    setViewport(evt.viewState);
  };

  const applyViewState = async (viewState: ViewState) => {
    setViewport(viewState);
    const response = await fetch(
      `${MAPBOX_API_URL}/${viewState.longitude},${viewState.latitude}.json/${MAPBOX_GEOCODING_QUERY}&access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    const result = await response.json();
    setOptions(result.features);
    setValue(result.features[0]?.place_name);
  };

  const handleMoveEnd = async (evt: ViewStateChangeEvent) => {
    await applyViewState(evt.viewState);
  };

  React.useEffect(() => {
    open &&
      !allSitesState?.length &&
      (async () => {
        const idToken = await getRawIdToken();

        /**TODO: Get site related info via graphQL end-point once implemented
         * Currently loading the site list via get user sites API
         * and retrieving selected site deatils from the list
         */

        dispatch(
          getUserSites({
            token: idToken,
          })
        ).then((resp) => {
          if (resp?.payload?.result) {
            setAllSites(resp.payload.result);
          } else {
            enqueueSnackbar(resp?.payload?.error || "Could not list sites", {
              variant: "error",
            });
          }
        });
      })();
  }, [allSitesState?.length, dispatch, getRawIdToken, open]);

  return (
    <Dialog fullScreen open={open}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box style={{ width: "100%", padding: 10 }}>
          <Box style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignContent="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="h6">
                  {editMode ? (
                    "Edit Site"
                  ) : (
                    <Box
                      sx={{
                        width: 250,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >{`Add Site to ${organizationName}`}</Box>
                  )}
                </Typography>
              </Grid>
              <Grid item alignItems="flex-end">
                <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
              </Grid>
            </Grid>
          </Box>
          <Grid container direction="column" spacing={3} marginTop={2}>
            {duplicateSiteName && (
              <Grid item>
                <Alert severity="error">
                  A site with that name already exists.
                </Alert>
              </Grid>
            )}
            <Grid item>
              <TextField
                label="Site Number or Identifier"
                placeholder="i.e. 1234 Airport or #1234"
                value={siteName}
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleEditSiteName}
              />
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                margin={1}
              >
                This value will help you set each site apart from one another or
                provide additional context i.e. “1234” or “Airport”
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                size="small"
                filterOptions={(o) => o}
                options={options.map((o: any) => o.place_name)}
                autoComplete
                includeInputInList
                getOptionLabel={(option: any) => option}
                filterSelectedOptions
                value={value}
                noOptionsText="No locations"
                onChange={(event: any, newValue: any) => {
                  handleGeocoderViewportChange(
                    options.find((o: any) => o.place_name === newValue)
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Site Address or Lat,Lng"
                    fullWidth
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            {option}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                freeSolo
                forcePopupIcon
              />
            </Grid>
            <Grid xs={6} item alignItems="flex-end">
              <Box marginTop={2} justifyContent="flex-end" display={"flex"}>
                <Button
                  variant="text"
                  sx={{ marginRight: 2 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  disabled={
                    !siteName ||
                    !value ||
                    duplicateSiteName ||
                    value !== inputValue
                  }
                  onClick={editMode ? handleEditSite : handleAddSite}
                >
                  {editMode ? "Update" : "Add"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Box style={{ width: "100%", height: "100%", marginLeft: drawerWidth }}>
        <Map
          {...viewport}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
          onMove={(evt) => handleMoveMap(evt)}
          onMoveEnd={(evt) => handleMoveEnd(evt)}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        >
          <Marker
            longitude={viewport.longitude}
            latitude={viewport.latitude}
            anchor="bottom"
          >
            <LocationPin />
          </Marker>
        </Map>
      </Box>
    </Dialog>
  );
};
export default AddSite;
