import * as React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Asset } from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";
import ConnectivityStatusChip from "components/common/connectivityStatusChip";
import { CardSkeleton } from "components/common/CardSkeleton";

const AssetTitleCard = (props: { asset?: Asset }) => {
  const { asset } = props;

  return (
    <Paper
      elevation={1}
      sx={{
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      {asset?.name ? (
        <Stack>
          <Stack direction="row" alignItems="center" mb={1}>
            <Typography
              variant="h4"
              style={{
                marginRight: 10,
              }}
            >
              {asset?.name}
            </Typography>
            <ConnectivityStatusChip
              connected={asset?.connected ? true : false}
            />
          </Stack>
          {asset?.site?.name && (
            <Typography color="primary" gutterBottom>
              {asset.site.name}
            </Typography>
          )}
          {asset?.site?.address && (
            <Typography color="primary">{asset.site.address}</Typography>
          )}
        </Stack>
      ) : (
        <CardSkeleton />
      )}
    </Paper>
  );
};

export default createFragmentContainer(AssetTitleCard, {
  asset: gql`
    fragment AssetTitleCard_asset on Asset {
      id
      name
      connected
      site {
        id
        name
        address
      }
    }
  `,
});
