import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { theme } from "./theme";
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0,
} from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { GlobalStyles } from "tss-react";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { globalstyles } from "global-styles";
import Loader from "components/layout/loader";
import store from "redux/store";
import "react-tooltip/dist/react-tooltip.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import possibleTypes from "./generated-gql-types/fragmentTypes.json";
import urljoin from "url-join";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjAen6eORw9qvQEMIEyV5a37FW1W1lOHE",
  authDomain: "technician-admin.firebaseapp.com",
  projectId: "technician-admin",
  storageBucket: "technician-admin.appspot.com",
  messagingSenderId: "681159978245",
  appId: "1:681159978245:web:e8615a8c9d6e4cd29d1b6c",
  measurementId: "G-5WPCLTLJZ5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_GRID_PRO_LICENSEKEY || "");

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export interface ServerContext {
  auth0Domain?: string;
  auth0ClientId?: string;
  auth0Audience?: string;
  apiHost?: string;
  graphqlHost?: string;
  environment?: string;
  release?: string;
  sentryDsn?: string;
  buildId?: string;
}

const context: ServerContext = {
  apiHost: window.APP_CONFIG.TECHNICIAN_API_DOMAIN,
  graphqlHost: window.APP_CONFIG.TECHNICIAN_API_DOMAIN,

  auth0Domain: window.APP_CONFIG.AUTH0_DOMAIN,
  auth0ClientId: window.APP_CONFIG.AUTH0_CLIENT_ID,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

const apiHost =
  context.apiHost || `${window.location.protocol}//${window.location.host}`;
const graphqlUrl = urljoin(context.graphqlHost || apiHost, "/graphql");

const cache = new InMemoryCache({
  possibleTypes,
  // Configure Merge for connection types to avoid an apollo error
  typePolicies: {
    Asset: {
      fields: {
        drives: {
          // Equivalent to options.mergeObjects(existing, incoming).
          merge: true,
        },
        rmks: {
          merge: true,
        },
        motors: {
          merge: true,
        },
        jobs: {
          merge: true,
        },
        assets: {
          merge: true,
        },
        inventory: {
          merge: true,
        },
      },
    },
  },
});

// Don't try to fetch /graphql until auth0 settles
const customFetch = (uri: URL, options?: RequestInit) => {
  const ready = store.getState().authData.ready;
  if (ready) {
    return fetch(uri, options);
  }
  return Promise.resolve(new Response());
};

const httpLink = new HttpLink({
  uri: graphqlUrl,
  fetch: customFetch,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Consolidate errors, to reduce Sentry spend
  const errorMessage =
    graphQLErrors?.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator +
        "\n\n" +
        (currentValue?.message || currentValue?.toString() || ""),
      networkError?.message || "" // if we have graphQLErrors, prepend the network error (if any)
    ) ||
    networkError?.message || // presumably, if no graphQLErrors, there's a network error
    "GraphQL Error reported by Error Link"; // just in case

  // TODO: Sentry logging would go here
  console.log(errorMessage);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error: any, _operation: any) => !!error,
  },
});

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || "/");
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

function useToken() {
  const { getIdTokenClaims, isAuthenticated, isLoading } = useAuth0();

  const [token, setToken] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated) {
          const claims: any = await getIdTokenClaims();
          setToken(claims?.__raw);

          // XXX: This is not kosher by GDPR standards
          logEvent(analytics, "login", {
            user: claims
              ? claims["https://turntide.com/internal_id"]
              : "unknown",
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [getIdTokenClaims, isAuthenticated, isLoading]);

  return token;
}

// https://stackoverflow.com/questions/65884597/how-to-add-auth0-access-token-to-react-apollo
function TTApolloProvider({ children }: { children?: ReactNode | undefined }) {
  const token = useToken();
  const [hasAuth, setHasAuth] = useState(false);
  // useRef instead of useMemo to make sure client is not re-created randomly
  const clientRef = useRef<ApolloClient<NormalizedCacheObject>>(
    // Hack: start with an unauthenticated apollo client,
    // so we don't break the page while the redux auth0 code signs in.
    // This can cause unauthenticated gql calls, which 401 on the server.
    // TODO: something smarter
    new ApolloClient<NormalizedCacheObject>({
      cache,
      link: from([retryLink, errorLink, httpLink]),
      connectToDevTools: true,
    })
  );

  if (token && !hasAuth) {
    // This code should only be executed once.
    const header = `Bearer ${token}`;
    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: header,
        },
      };
    });

    // replace the unauthenticated client with an authenticated one
    clientRef.current = new ApolloClient<NormalizedCacheObject>({
      cache,
      link: from([authLink, retryLink, errorLink, httpLink]),
      connectToDevTools: true,
    });
    setHasAuth(!!clientRef.current);
  }

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <GlobalStyles styles={globalstyles} />
              <BrowserRouter>
                <Auth0ProviderWithRedirectCallback
                  domain={window.APP_CONFIG.AUTH0_DOMAIN || ""}
                  clientId={window.APP_CONFIG.AUTH0_CLIENT_ID || ""}
                  redirectUri={`${window.location.origin}${"/oauth2/callback"}`}
                >
                  <TTApolloProvider>
                    <SnackbarProvider>
                      <App />
                      <Loader />
                    </SnackbarProvider>
                  </TTApolloProvider>
                </Auth0ProviderWithRedirectCallback>
              </BrowserRouter>
            </CssBaseline>
          </ThemeProvider>
        </CacheProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
