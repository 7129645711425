import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { FanChartsFullRow } from "models/fancharts";
import { useAuthData } from "utils/hooks/useAuthData";
import { useNavigate } from "react-router-dom";
import { Constants } from "utils/constants";

const ActionsMenu = (props: { fanchart: FanChartsFullRow }) => {
  const { fanchart } = props;

  const { hasAccess } = useAuthData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(
      "/fancharts/edit/" + fanchart?.fanchart_id + "/" + fanchart?.model_id
    );
  };

  return (
    <div>
      {hasAccess(Constants.ACTIONS.EDIT_FAN_CHARTS) && (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon color="action" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={(e: any) => handleEditClick(e)}>Edit</MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default ActionsMenu;
