import * as React from "react";
import Button from "@mui/material/Button";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import { setSelectedColumns } from "redux/reducers/filtersSlice";
import { getDefaultVisibleColumns } from "redux/reducers/jobsColumns";

const ColumnSelector = (props: { columns: Array<any> }) => {
  const { columns } = props;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedColumns: any = useAppSelector(
    (state) => state.filters?.jobSelectedColumns || getDefaultVisibleColumns()
  );
  const jobColumns = useAppSelector((state) => state.filters?.jobColumns);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let _selectedColumns = { ...selectedColumns };
    _selectedColumns[event.target.name] = event.target.checked;
    dispatch(setSelectedColumns(_selectedColumns));
  };

  const handleReset = () => {
    let colKeyObj: any = {};
    jobColumns?.forEach((k) => (colKeyObj[k] = true));
    dispatch(setSelectedColumns(colKeyObj));
    handleClose();
  };

  return (
    <Box>
      <Button
        id="basic-button"
        variant="text"
        size="small"
        sx={{
          padding: "7px",
          textTransform: "none",
        }}
        startIcon={<ViewColumnIcon />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Columns
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ padding: 1 }}>
          <FormGroup>
            <List>
              {columns.map((column) => (
                <ListItem key={column.key}>
                  <FormControlLabel
                    control={
                      <Switch
                        name={column.key}
                        checked={selectedColumns[column.key]}
                        size="small"
                        onChange={handleChange}
                      />
                    }
                    label={column.name}
                  />
                </ListItem>
              ))}
              <ListItem>
                <Button variant="text" size="small" onClick={handleReset}>
                  Reset To Default
                </Button>
              </ListItem>
            </List>
          </FormGroup>
        </Box>
      </Menu>
    </Box>
  );
};

export default ColumnSelector;
