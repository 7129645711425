import { createSlice } from "@reduxjs/toolkit";

interface CreateOrgState {
  open: boolean;
  type: string;
  name: string;
  validation: any;
  channelPartner: any;
  customer: any;
}

const initialState: CreateOrgState = {
  open: false,
  type: "",
  name: "",
  validation: {
    valid: true,
  },
  channelPartner: [],
  customer: [],
};

const slice = createSlice({
  name: "createOrg",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setChannelPartner(state, action) {
      state.channelPartner = action.payload;
    },
    setCustomer(state, action) {
      state.customer = action.payload;
    },

    setValidation(state, action) {
      state.validation = action.payload;
    },
  },
});
export const {
  setOpen,
  setName,
  setType,
  setValidation,
  setChannelPartner,
  setCustomer,
} = slice.actions;
export default slice.reducer;
