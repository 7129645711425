import { ColumnState } from "utils/types";

const defaultColumns: readonly ColumnState[] = [
  {
    field: "first_name",
    width: 130,
    hide: false,
    index: 0,
  },
  {
    field: "last_name",
    width: 120,
    hide: false,
    index: 0,
  },
  {
    field: "email",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "channelPartner",
    hide: false,
    index: 0,
    sortable: false,
  },
  {
    field: "created_at",
    width: 130,
    hide: false,
    index: 0,
  },

  {
    field: "activated_at",
    width: 130,
    hide: false,
    index: 0,
  },
  {
    field: "actions",
    width: 40,
    hide: false,
    index: 0,
    sortable: false,
  },
];

export default defaultColumns;
