import { IdToken } from "@auth0/auth0-react";
import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUserActions } from "redux/actions/users";

interface AuthDataState {
  idToken: IdToken | undefined;
  rawIdToken: string;
  isGreensightAdmin: boolean;
  actions: string[];
  ready: boolean;
}

const initialState: AuthDataState = {
  idToken: undefined,
  rawIdToken: "",
  isGreensightAdmin: false,
  actions: [],
  ready: false,
};

const slice = createSlice({
  name: "authData",
  initialState,
  reducers: {
    setIdToken(state, action) {
      state.idToken = action.payload;
    },
    setRawIdToken(state, action) {
      state.rawIdToken = action.payload;
    },
    setIsGreensightAdmin(state, action) {
      state.isGreensightAdmin = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getCurrentUserActions.fulfilled, (state, action) => {
      state.actions = action.payload;
      state.ready = true;
    });
    builder.addCase(getCurrentUserActions.pending, (state) => {});
    builder.addCase(getCurrentUserActions.rejected, (state, action) => {});
  },
});
export const { setIdToken, setRawIdToken, setIsGreensightAdmin } =
  slice.actions;
export default slice.reducer;
