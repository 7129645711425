import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import TTEditableDateTime from "components/common/TTEditableDateTime";
import { useSetFailureDate } from "./setFailureDate";
import { enqueueSnackbar } from "notistack";

interface FailureDateProps {
  open: boolean;
  serialNumber?: string;
  failureDate?: string;
  onClose: () => void;
}

const FailureDateDialog: React.FC<FailureDateProps> = ({
  open,
  serialNumber,
  failureDate,
  onClose,
}) => {
  const [updatedFailureDate, setUpdatedFailureDate] = useState(failureDate);
  const handleChange = (newValue: string) => {
    setUpdatedFailureDate(newValue);
  };

  const { setFailureDate, updateResult } = useSetFailureDate();

  // Display error when result changes
  const mutationError = updateResult.error?.message;
  useEffect(() => {
    mutationError &&
      enqueueSnackbar(`Error setting failure date: ${mutationError}`, {
        variant: "error",
      });
  }, [mutationError]);

  const handleChangeFieldValue = async (newValue?: string) => {
    if (serialNumber) {
      const response = await setFailureDate({
        failureDt: newValue,
        serialNumber: serialNumber,
      });
      if (response?.data?.setInventoryFailureDate?.success) {
        enqueueSnackbar(`Saved organization mapping`, {
          variant: "success",
        });
        onClose();
      }
    }
  };
  return (
    <Dialog open={open} maxWidth="xs">
      <Grid container p={2} spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs>
            <Typography variant="h6">Enter date of failure</Typography>
          </Grid>
          <Grid item xs="auto">
            <CloseIcon onClick={onClose} />
          </Grid>
        </Grid>

        <Grid item xs={12} justifyContent={"stretch"}>
          <TTEditableDateTime
            defaultValue={updatedFailureDate || ""}
            editable={true}
            type="date"
            onChange={handleChange}
            autoFocus={true}
            onKeyDown={(event: any) => {
              if (event.key === "Escape") {
                // Cancel
                if (updatedFailureDate !== failureDate) {
                  setUpdatedFailureDate(failureDate);
                }
                onClose();
              }
              // Submit
              if (event.key === "Enter" && updatedFailureDate !== failureDate) {
                handleChangeFieldValue(updatedFailureDate);
                onClose();
              }
            }}
          />
        </Grid>
        <Grid item container xs={12} justifyContent={"stretch"}>
          <Grid item xs></Grid>
          <Grid item container xs="auto">
            <Grid item>
              <Button
                size="medium"
                onClick={onClose}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                variant="contained"
                onClick={() => handleChangeFieldValue(updatedFailureDate)}
                disabled={updatedFailureDate === failureDate}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default FailureDateDialog;
