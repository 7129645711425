import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { Clear as ClearIcon } from "@mui/icons-material";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { updateFilter } from "redux/reducers/filtersSlice";

const TemplateFilter = (props: { onApplyFilter?: () => void }) => {
  const { onApplyFilter } = props;
  const templates = useAppSelector((state) => state.templates.data);
  const groupedTemplates = useAppSelector(
    (state) => state.templates.groupedData
  );
  const filters = useAppSelector((state) => state.filters.jobFilters);
  const totalFilters = filters?.template?.length;
  const dispatch = useAppDispatch();
  const selected = filters?.template || [];
  const [selectAll, setSelectAll] = React.useState(
    templates.length === selected.length
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDirty, setDirty] = React.useState<Boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (isDirty) {
      setDirty(false);
      onApplyFilter?.();
    }
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    template: any
  ) => {
    setDirty(true);
    let _selected = [...selected];
    const index = _selected?.indexOf(template.template_id);
    if (index > -1 && !event.target.checked) {
      _selected.splice(index, 1);
    } else if (event.target.checked) {
      _selected.push(template.template_id);
    }
    dispatch(updateFilter({ filterType: "template", value: _selected }));
  };

  const handleSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    template: any
  ) => {
    setDirty(true);
    if (selectAll) {
      setSelectAll(false);
      dispatch(
        updateFilter({
          filterType: "template",
          value: [],
        })
      );
    } else {
      setSelectAll(true);
      dispatch(
        updateFilter({
          filterType: "template",
          value: templates.map((t: { template_id: any }) => t.template_id),
        })
      );
    }
  };

  const handleResetFilter = () => {
    dispatch(updateFilter({ filterType: "template", value: [] }));
    onApplyFilter?.();
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Button
        id="basic-button"
        variant="outlined"
        size="medium"
        sx={{
          borderColor: "#c4c4c4",
          padding: "7px",
          color: "#6f6f6f",
          textTransform: "none",
        }}
        endIcon={<ArrowDropDownIcon />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {"Job Template "}
        {totalFilters > 0 ? (
          <ClearIcon
            sx={{
              ml: "7px",
              mr: "-7px",
              padding: "1px",
              bgcolor: "#f1f1f1",
              borderRadius: "50%",
              "&:hover": { bgcolor: "#81B5EA", color: "#ffffff" },
            }}
            aria-label="delete"
            fontSize="small"
            onClick={(e) => {
              e.stopPropagation();
              handleResetFilter();
            }}
          />
        ) : (
          <i style={{ padding: "0 11px" }}></i>
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ padding: 2, maxHeight: 500 }}>
          <FormGroup sx={{ marginBottom: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={handleSelectAll}
                  checked={
                    templates.length > 0 && selected.length === templates.length
                  }
                  indeterminate={
                    selected.length > 0 && selected.length < templates.length
                  }
                />
              }
              label={selectAll ? "Deselect All" : "Select All"}
            />
            {Object.keys(groupedTemplates)
              .reverse()
              .map((groupName, index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    sx={{ marginTop: 2 }}
                  >
                    {groupName}
                  </Typography>
                  {groupedTemplates[groupName].map(
                    (template: any, i: number) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            size="small"
                            checked={
                              selected?.indexOf(template.template_id) > -1
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(event, template)}
                          />
                        }
                        label={template?.name}
                      />
                    )
                  )}
                </React.Fragment>
              ))}
          </FormGroup>
        </Box>
      </Menu>
    </Box>
  );
};

export default TemplateFilter;
