import {
  GridColDef,
  GridRenderCellParams,
  getGridStringOperators,
  getGridNumericOperators,
  getGridDateOperators,
} from "@mui/x-data-grid-pro";
import TTToolTipText from "components/common/TTToolTipText";
import { TimestampFormatter } from "components/common/TTTimeStampFormatter";
import { dateToUserLocalTimezone, utcDate } from "utils/datetime";
import { columnSettings } from "./gridState";
import ActionsMenu from "./actionsMenu";

const valueGetter = (col: any) => {
  if (col?.type === "datetime" || col?.type === "date") {
    return (params: any) => {
      const value = params?.value;
      if (!value || isNaN(new Date(value).getTime())) {
        return undefined;
      }
      if (col?.type === "datetime") {
        return new Date(dateToUserLocalTimezone(value));
      }
      return new Date(utcDate(value));
    };
  }
  return (params: any) => {
    return params?.value;
  };
};

const renderCell = (col: any) => {
  if (col?.type === "datetime" || col?.type === "date") {
    return (params: GridRenderCellParams<any>) => {
      return (
        <TimestampFormatter
          timestamp={params?.value}
          showTime={col?.type === "datetime"}
        />
      );
    };
  }
  return (params: GridRenderCellParams<any>) => {
    return <TTToolTipText text={params?.value} />;
  };
};

const renderMenuCell = (
  onMenuItemClick: (value: string, row: any) => void,
  col: any
) => {
  if (col?.type === "menu") {
    return (params: GridRenderCellParams<any>) => (
      <ActionsMenu
        options={col.options}
        params={params}
        onClick={onMenuItemClick}
      />
    );
  }
  return renderCell(col);
};

const stringOperators = getGridStringOperators();
const numberOperators = getGridNumericOperators();
const dateTimeOperators = getGridDateOperators(true);

const columnFilterOperators = (col: any) => {
  if (col?.field === "id") {
    return stringOperators.filter((op) => ["equals"].includes(op.value));
  } else if (["assetLatitude", "assetLongitude"].includes(col?.field)) {
    return numberOperators.filter((op) => ["="].includes(op.value));
  } else if (col?.type === "dateTime") {
    return dateTimeOperators.filter(
      (op) =>
        !["isAnyOf"].includes(op.value) &&
        !["not"].includes(op.value) &&
        !["after"].includes(op.value) &&
        !["before"].includes(op.value)
    );
  } else if (col?.type === "number") {
    return numberOperators.filter(
      (op) =>
        !["isAnyOf"].includes(op.value) &&
        !["!="].includes(op.value) &&
        ![">"].includes(op.value) &&
        !["<"].includes(op.value)
    );
  }
  return stringOperators.filter((op) => !["isAnyOf"].includes(op.value));
};

const validWidth = (width: any, defaultValue: number = -1) => {
  return typeof width === "number" && width > columnSettings.COLUMN_MIN_WIDTH
    ? width
    : defaultValue;
};
const validIndex = (index: any, defaultValue: number = -1) => {
  return typeof index === "number" && index > -1 ? index : defaultValue;
};
const validBool = (visibility: any, defaultValue: boolean = false) => {
  return typeof visibility === "boolean" ? visibility : defaultValue;
};

/**
  Apply defined column items default properties to the items of the columns state array.

    Current columns state data, also data in saved views, contain as minimum data as possible 
    to save resources and backend database size. This function creates column array that combines 
    all the necessary properties from columns state data and from pre defined columns, 
    also performs a final normalization of the GridColDef array to avoid a datagrid component error.
 */
const syncColumns = (
  columns: GridColDef[],
  defaultColumns: any[],
  onMenuItemClick?: (value: string, row: any) => void
) => {
  return columns.map((colState) => {
    const col = defaultColumns.find((item) => item.field === colState.field);
    const width =
      typeof colState.width === "number" &&
      colState.width >= columnSettings.COLUMN_MIN_WIDTH
        ? colState.width
        : typeof col?.width === "number" &&
          col?.width >= columnSettings.COLUMN_MIN_WIDTH
        ? col?.width
        : columnSettings.COLUMN_DEFAULT_WIDTH;
    const renderCellMethod =
      onMenuItemClick && col?.type === "menu"
        ? renderMenuCell(onMenuItemClick, col)
        : col?.renderCell;
    return {
      ...colState,
      // Include if exists.
      ...(col?.headerName && { headerName: col.headerName }),
      // Disable if hideable exists and is explicitly set to true.
      ...(typeof col?.hideable === "boolean" &&
        col.hideable === false && {
          hideable: false,
        }),
      // Disable if does not exist or is not explicitly set to true.
      ...((typeof col?.sortable !== "boolean" || col.sortable !== true) && {
        sortable: false,
      }),
      // Disable if does not exist or is not explicitly set to true.
      ...((typeof col?.filterable !== "boolean" || col.filterable !== true) && {
        filterable: false,
      }),
      // Include if width not exists or invalid.
      ...(width && { width: width }),
      // Include optional properties.
      ...(col?.type && { type: col.type }),
      ...(col?.align && { align: col.align }),
      ...(col?.headerAlign && { headerAlign: col.headerAlign }),
      ...(col?.filterOperators && { filterOperators: col.filterOperators }),
      ...(col?.valueGetter && { valueGetter: col.valueGetter }),
      ...(col?.renderCell && { renderCell: renderCellMethod }),
      ...(col?.editable && { editable: col.editable }),
      ...(col?.options && { options: col.options }),
    };
  });
};

const columnGraphQLName = (
  fieldName: string,
  definedColumns: any[],
  type: string = ""
) => {
  if (fieldName) {
    const column = definedColumns.find((col) => col.field === fieldName);
    if (type === "sorting" && column?.sortingKey) {
      return column.sortingKey;
    }
    if (type === "filtering" && column?.filteringKey) {
      return column.filteringKey;
    }
    if (column?.key) {
      return column.key;
    }
  }
  return "";
};

export {
  valueGetter,
  renderCell,
  columnFilterOperators,
  validWidth,
  validIndex,
  validBool,
  syncColumns,
  columnGraphQLName,
};
