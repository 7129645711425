import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import { withRoleBasedRedirect } from "./RoleBasedAccess";
import MainLayoutSkeleton from "components/loader/layout";

interface ProtectedRouteProps {
  component: ComponentType;
  forGreensightAdminOnly?: boolean;
  action?: string;
}
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  forGreensightAdminOnly = false,
  action = undefined,
}) => {
  const Component = withAuthenticationRequired(
    withRoleBasedRedirect(component, {
      forGreensightAdminOnly: forGreensightAdminOnly,
      action: action,
    }),
    {
      onRedirecting: () => <MainLayoutSkeleton />,
    }
  );

  return <Component />;
};
