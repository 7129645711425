import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import {
  getFanChartDetails,
  getFanChartsModel,
  createFanChart,
} from "redux/actions/fancharts";
import { useAppDispatch } from "../../redux/store/hooks";
import { enqueueSnackbar } from "notistack";
import { useAuthData } from "utils/hooks/useAuthData";
import { toPlainFormatArray } from "components/fancharts";

type DataGroup = {
  groupIndex: string | number;
  columnHeaders: string;
  pressures: string;
  data: string;
  dataWithWatts: boolean;
  blankValuesMarker: string;
};

type Validation = {
  valid: boolean;
  message: string;
};

type FanChartFormData = {
  editMode: boolean;
  fanChartNotFoundError: boolean;
  fanChartModelNotFoundError: boolean;
  fanchart_id: string;
  fanchartmodel_id: string;
  make_display: string;
  make: string;
  model: string;
  tonnage: string;
  max_pressure: string;
  fanchart_name: string;
  dataGroups: DataGroup[];
  validation: Validation;
  formTitle: string;
  formDescr: string;
};

const initialFormData = {
  editMode: false,
  fanChartNotFoundError: true,
  fanChartModelNotFoundError: true,
  fanchart_id: "",
  fanchartmodel_id: "",
  make_display: "",
  make: "",
  model: "",
  tonnage: "",
  max_pressure: "",
  fanchart_name: "",
  dataGroups: [
    {
      groupIndex: 1,
      columnHeaders: "",
      pressures: "",
      data: "",
      dataWithWatts: true,
      blankValuesMarker: "--",
    },
  ],
  validation: { valid: true, message: "" },
  formTitle: "Fan Chart",
  formDescr: "",
};

const FanchartDataEditor = () => {
  const { getRawIdToken, ready } = useAuthData();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fanchartId, fanchartmodelId } = useParams();
  const [fanChartFormData, setFanChartFormData] =
    useState<FanChartFormData>(initialFormData);

  useEffect(() => {
    if (!fanchartId) {
      setFanChartFormData(initialFormData);
      updateFormData("formTitle", "Add Fan Chart");
      updateFormData("formDescr", "Create new fan chart.");
      updateFormData("editMode", false);
      updateFormData("fanchart_id", "");
    } else if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        const resp = await dispatch(
          getFanChartDetails({
            token: idToken,
            fanchartId: fanchartId || "",
          })
        );

        if (resp?.payload?.result?.success) {
          const fanchart = resp.payload.result.fanchart;

          setFanChartFormData(initialFormData);
          updateFormData("editMode", true);
          updateFormData("fanChartNotFoundError", false);
          updateFormData("formTitle", "Edit Fan Chart");
          updateFormData("formDescr", "Fan chart: " + fanchart.fanchart_name);
          updateFormData("fanchart_id", fanchart.fanchart_id);
          updateFormData("fanchart_name", fanchart.fanchart_name);
          updateFormData("make", fanchart.make);
          updateFormData("make_display", fanchart.make_display);

          const plainDataArray = toPlainFormatArray(
            resp.payload.result.fanchart.fanchartdetails
          );
          updateFormGroupsData(0, "groupIndex", 1);
          updateFormGroupsData(0, "columnHeaders", plainDataArray.headerRow);
          updateFormGroupsData(
            0,
            "pressures",
            plainDataArray.pressures.join(" ")
          );
          updateFormGroupsData(0, "data", plainDataArray.data.join("\n"));

          if (!fanchartmodelId) {
            updateFormData("fanchartmodel_id", "");
          } else {
            (async () => {
              const idToken = await getRawIdToken();
              const resp = await dispatch(
                getFanChartsModel({
                  token: idToken,
                  fanchartmodelId: fanchartmodelId || "",
                })
              );

              if (resp?.payload?.result?.success) {
                const fanchartmodel = resp.payload.result.fanchartmodel;

                updateFormData("fanChartModelNotFoundError", false);
                updateFormData("fanchartmodel_id", fanchartmodel.id);
                updateFormData("model", fanchartmodel.model);
                updateFormData("tonnage", fanchartmodel.tonnage);
                updateFormData("max_pressure", fanchartmodel.max_pressure);
              } else {
                updateFormData("fanChartModelNotFoundError", true);
                updateFormData("fanchartmodel_id", "");
                updateFormData("validation", {
                  valid: false,
                  message:
                    "Fan Chart Medel not found by ID. You can continue, " +
                    "the fanchart data will update normally, but the model will not update.",
                });
              }
            })();
          }
        } else {
          updateFormData("fanChartNotFoundError", true);
          updateFormData("fanchart_id", "");
          updateFormData("validation", {
            valid: false,
            message: "Fan Chart not found by ID.",
          });
        }
      })();
    }
  }, [fanchartId, fanchartmodelId, ready]);

  const scrollToRef = React.useRef<HTMLElement | null>(null);
  const scrollTopPosition = () => {
    setTimeout(() => {
      if (scrollToRef.current) {
        const y =
          scrollToRef.current.getBoundingClientRect().top +
          window.scrollY -
          100;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 100);
  };

  const scrollBottomToRef = React.useRef<HTMLElement | null>(null);
  const scrollBottomPosition = () => {
    setTimeout(() => {
      if (scrollBottomToRef.current) {
        scrollBottomToRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleAddData = () => {
    const updatedDataGroups = [
      ...fanChartFormData.dataGroups,
      {
        groupIndex: fanChartFormData.dataGroups.length + 1,
        columnHeaders: "",
        pressures: "",
        data: "",
        dataWithWatts: true,
        blankValuesMarker: "--",
      },
    ];
    setFanChartFormData((prevData) => ({
      ...prevData,
      dataGroups: updatedDataGroups,
    }));
    scrollBottomPosition();
  };

  const handleRemoveData = (index: number) => {
    const updatedDataGroups = [...fanChartFormData.dataGroups];
    updatedDataGroups.splice(index, 1);
    for (let i = 0; i < updatedDataGroups.length; i++) {
      updatedDataGroups[i].groupIndex = i + 1;
    }
    setFanChartFormData((prevData) => ({
      ...prevData,
      dataGroups: updatedDataGroups,
    }));
  };

  const updateFormData = (
    field: string,
    value: string | boolean | Validation | DataGroup
  ) => {
    setFanChartFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    if (field === "make_display" && typeof value === "string") {
      const make_value = value.trim().toLowerCase().replace(/\s+/g, "_");
      setFanChartFormData((prevData) => ({
        ...prevData,
        make: make_value,
      }));
    }
  };

  const updateGroupsHeaderColumn = (
    index: number,
    pressures: string,
    dataWithWatts: boolean
  ) => {
    const columns = dataWithWatts ? "RPM BHP WATTS" : "RPM BHP";
    const headerRow =
      "CFM " +
      pressures
        .trim()
        .replace(/\s{2,}/g, " ")
        .split(" ")
        .map((pressure) => columns)
        .join(" ");
    updateFormGroupsData(index, "columnHeaders", headerRow);
  };

  const processDataValue = (index: number, value: string) => {
    const cleanData = value
      .replaceAll(",", "")
      .replaceAll(fanChartFormData.dataGroups[index].blankValuesMarker, "--");
    return cleanData;
  };

  const updateFormGroupsData = (
    index: number,
    field: string,
    value: string | number | boolean | Validation
  ) => {
    if (field === "blankValuesMarker" && value === "") {
      value = "--";
    }
    setFanChartFormData((prevData) => {
      const updatedDataGroups = prevData.dataGroups.map((dataGroup, i) => {
        if (i === index) {
          return {
            ...dataGroup,
            [field]: value,
          };
        }
        return dataGroup;
      });
      return {
        ...prevData,
        dataGroups: updatedDataGroups,
      };
    });
    if (field === "pressures" && typeof value === "string") {
      updateGroupsHeaderColumn(
        index,
        value,
        fanChartFormData.dataGroups[index].dataWithWatts
      );
    }
    if (field === "dataWithWatts" && typeof value === "boolean") {
      updateGroupsHeaderColumn(
        index,
        fanChartFormData.dataGroups[index].pressures,
        value
      );
    }
  };

  const isFormGroupValid = (dataGroup: DataGroup) => {
    if (!dataGroup.columnHeaders) {
      return (
        "Column Headers field cannot be empty, in group: " +
        dataGroup.groupIndex
      );
    } else if (dataGroup.pressures && !dataGroup.data) {
      return (
        "'Data' field cannot be empty when 'Pressures' field is not empty, in data group: " +
        dataGroup.groupIndex
      );
    } else if (!dataGroup.pressures && dataGroup.data) {
      return (
        "'Pressures' field cannot be empty when 'Data' field is not empty, in data group: " +
        dataGroup.groupIndex
      );
    }
    return "";
  };

  const isFormGroupDataValid = () => {
    for (let i = 0; i < fanChartFormData.dataGroups.length; i++) {
      const group = fanChartFormData.dataGroups[i];
      const error = isFormGroupValid(group);
      if (error) {
        return error;
      }
    }
    return "";
  };

  const handleSaveFanChartClick = () => {
    updateFormData("validation", { valid: true, message: "" });
    let error = "";
    if (fanChartFormData.editMode && !fanChartFormData.fanchart_id) {
      error =
        "Fan chart ID is missing. In edit mode, the fan chart ID is required.";
    } else if (
      fanChartFormData.editMode === true &&
      fanChartFormData.fanChartNotFoundError
    ) {
      error = "Fan Chart not found by ID.";
    } else if (!fanChartFormData.make_display) {
      error = "Make Display field cannot be empty.";
    } else if (!fanChartFormData.make) {
      error = "Make field cannot be empty.";
    } else if (
      fanChartFormData.fanchartmodel_id &&
      fanChartFormData.fanChartModelNotFoundError
    ) {
      error = "Fan Chart Model not found by ID";
    } else if (
      (fanChartFormData.editMode === false ||
        fanChartFormData.fanchartmodel_id) &&
      !fanChartFormData.model
    ) {
      error = "Fan Chart Model field cannot be empty.";
    } else if (!fanChartFormData.fanchart_name) {
      error = "Fan Chart Name field cannot be empty.";
    } else {
      const formGroupValidationError = isFormGroupDataValid();
      if (formGroupValidationError !== "") {
        error = formGroupValidationError;
      }
    }

    if (error) {
      updateFormData("validation", { valid: false, message: error });
      scrollTopPosition();
    } else {
      (async () => {
        const idToken = await getRawIdToken();

        dispatch(
          createFanChart({
            token: idToken,
            fanChartFormData: fanChartFormData,
          })
        ).then((resp) => {
          if (resp?.payload?.success) {
            enqueueSnackbar("Fan chart saved!", { variant: "default" });
            updateFormData("validation", { valid: true, message: "" });
            navigate("/fancharts");
          } else {
            error = "Failed to save Fan chart!";
            updateFormData("validation", {
              valid: false,
              message: resp?.payload?.message ? resp.payload.message : error,
            });
            enqueueSnackbar(error, { variant: "error" });
            scrollTopPosition();
          }
        });
      })();
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: "1180px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      mb={5}
      ref={scrollToRef}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">{fanChartFormData.formTitle}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body1">
              {fanChartFormData.formDescr}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: !fanChartFormData.formDescr ? "-48px" : "0",
            }}
          >
            <Button
              startIcon={<ArrowBackIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => {
                navigate("/fancharts");
              }}
            >
              Back to Fan charts
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            {!fanChartFormData.validation.valid && (
              <Typography variant="body1">
                <Alert severity="error">
                  {fanChartFormData.validation.message}
                </Alert>
              </Typography>
            )}
          </Box>
        </Grid>

        {fanChartFormData.editMode === false ||
        (fanChartFormData.editMode === true &&
          !fanChartFormData.fanChartNotFoundError) ? (
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">Unit Info</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={6} mt={1}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Make Display"
                  value={fanChartFormData.make_display}
                  onChange={(e) =>
                    updateFormData("make_display", e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={6} mt={1}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Make"
                  value={fanChartFormData.make}
                  onChange={(e) => updateFormData("make", e.target.value)}
                  disabled
                />
              </Grid>
            </Grid>

            {fanChartFormData.editMode === false ||
            (fanChartFormData.editMode === true &&
              !fanChartFormData.fanChartModelNotFoundError) ? (
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Grid item xs={6} mt={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Model"
                      value={fanChartFormData.model}
                      onChange={(e) => updateFormData("model", e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={3} mt={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Tonnage"
                      value={fanChartFormData.tonnage}
                      onChange={(e) =>
                        updateFormData("tonnage", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={3} mt={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Max Pressure"
                      value={fanChartFormData.max_pressure}
                      onChange={(e) =>
                        updateFormData("max_pressure", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}

            <Grid item xs={12} mt={2}>
              <Typography variant="h6">Fan Chart Data</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={10} mt={1}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Fan Chart Name"
                  value={fanChartFormData.fanchart_name}
                  onChange={(e) =>
                    updateFormData("fanchart_name", e.target.value)
                  }
                />
              </Grid>
            </Grid>

            {fanChartFormData.dataGroups.map((dataGroup, index) => (
              <Grid item xs={12} key={index} style={{ letterSpacing: "0.3px" }}>
                <Grid container spacing={2} mt={2} mb={2}>
                  <Grid
                    container
                    item
                    xs={10}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <TextField
                      variant="outlined"
                      label="Data blank values marker"
                      inputProps={{ style: { padding: "7px 14px" } }}
                      value={
                        fanChartFormData.dataGroups[index].blankValuesMarker
                      }
                      onChange={(e) =>
                        updateFormGroupsData(
                          index,
                          "blankValuesMarker",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        updateFormGroupsData(
                          index,
                          "data",
                          processDataValue(
                            index,
                            fanChartFormData.dataGroups[index].data
                          )
                        )
                      }
                    />
                    <FormControlLabel
                      style={{ paddingLeft: "24px" }}
                      control={
                        <Checkbox
                          checked={
                            fanChartFormData.dataGroups[index].dataWithWatts
                          }
                          onChange={(e) =>
                            updateFormGroupsData(
                              index,
                              "dataWithWatts",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Data with watts"
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="caption">
                      Data Group {fanChartFormData.dataGroups[index].groupIndex}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Column Headers"
                      value={fanChartFormData.dataGroups[index].columnHeaders}
                      onChange={(e) =>
                        updateFormGroupsData(
                          index,
                          "columnHeaders",
                          e.target.value
                        )
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2} mt={1}>
                    <IconButton onClick={() => handleRemoveData(index)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10} mt={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Static Pressures Headers"
                      value={fanChartFormData.dataGroups[index].pressures}
                      onChange={(e) =>
                        updateFormGroupsData(index, "pressures", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={10} mt={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      label="Data"
                      rows={15}
                      value={fanChartFormData.dataGroups[index].data}
                      onChange={(e) =>
                        updateFormGroupsData(index, "data", e.target.value)
                      }
                      onBlur={(e) =>
                        updateFormGroupsData(
                          index,
                          "data",
                          processDataValue(index, e.target.value)
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid container justifyContent="space-between" item xs={10}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveFanChartClick}
              >
                Save Data
              </Button>
              <Box ref={scrollBottomToRef}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAddData}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <input
              type="hidden"
              name="fanchart_id"
              value={fanChartFormData.fanchart_id}
            />
            <input
              type="hidden"
              name="fanchartmodel_id"
              value={fanChartFormData.fanchartmodel_id}
            />
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
    </Box>
  );
};

export default FanchartDataEditor;
