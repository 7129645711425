import { FilterItem, FiltersState, GridViewState } from "utils/types";
import {
  columnGraphQLName,
  validBool,
  validWidth,
  validIndex,
} from "components/common/gridview/gridColumns";
import { GridState, Sort } from "utils/types";

const SUPPORTED_FILTER_OPERATORS = {
  contains: "CONTAINS",
  equals: "EQUALS",
  startsWith: "STARTS_WITH",
  endsWith: "ENDS_WITH",
  isEmpty: "IS_EMPTY",
  isNotEmpty: "IS_NOT_EMPTY",
  isAnyOf: "IS_ANY_OF",
  is: "EQUALS",
  after: "GREATER_THAN",
  onOrAfter: "STARTS_WITH",
  before: "LESS_THAN",
  onOrBefore: "ENDS_WITH",
  not: "NOT_EQUALS",
  "=": "EQUALS",
  "!=": "NOT_EQUALS",
  ">": "GREATER_THAN",
  ">=": "STARTS_WITH",
  "<": "LESS_THAN",
  "<=": "ENDS_WITH",
};
const SUPPORTED_LOGIC_OPERATORS = {
  and: "AND",
  or: "OR",
};

const escapeGraphQLString = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  return value.replace(/(["\\])/g, "\\$1");
};

const escapeFilterValue = (filterValue: string, column: any = {}) => {
  const valueType = typeof filterValue;
  if (
    valueType === "string" ||
    valueType === "number" ||
    valueType === "boolean"
  ) {
    const value = escapeGraphQLString(filterValue);
    if (column?.type === "number") {
      return !isNaN(Number(value)) && String(Number(value)) === value
        ? value
        : null;
    } else if (column?.type === "boolean") {
      return typeof value === "boolean" ? value : null;
    } else {
      return `"${value}"`;
    }
  } else if (Array.isArray(filterValue)) {
    const escapedArray: any = filterValue.map((value: any) =>
      escapeFilterValue(value, column)
    );
    return escapedArray;
  }
};

const validColumnFilterItems = (
  filterItems: any,
  includeEmpty: boolean = false,
  definedColumns: any[]
) => {
  const operators = Object.keys(SUPPORTED_FILTER_OPERATORS);
  return (Array.isArray(filterItems) ? filterItems : [])
    .map((item) => {
      if (!operators.includes(item.operator)) {
        return false;
      }
      const column = definedColumns.find((col) => col.field === item.field);
      if (!column || (!column?.filteringKey && !column?.key)) {
        return false;
      }
      if (
        column?.type === "number" &&
        (isNaN(Number(item.value)) || String(Number(item.value)) !== item.value)
      ) {
        return false;
      }
      if (column?.type === "boolean" && typeof item.value !== "boolean") {
        return false;
      }
      const noValueOperators = ["isEmpty", "isNotEmpty"];
      if (!noValueOperators.includes(item.operator)) {
        if (item.value === undefined && includeEmpty !== true) {
          return false;
        }
      }
      if (item.value === "" && includeEmpty !== true) {
        return false;
      }
      return item;
    })
    .filter(Boolean);
};

const validFilterOperator = (logicOperator: "and" | "or") => {
  if (logicOperator && SUPPORTED_LOGIC_OPERATORS[logicOperator] !== undefined) {
    return SUPPORTED_LOGIC_OPERATORS[logicOperator];
  }
  return SUPPORTED_LOGIC_OPERATORS["and"];
};

const validColumnFilterModel = (
  filterModel: any,
  definedColumns: any[],
  includeEmpty: boolean = false
) => {
  const validFilterModel: any = {
    items: validColumnFilterItems(
      filterModel.items,
      includeEmpty,
      definedColumns
    ),
  };
  if (typeof filterModel?.logicOperator === "string") {
    validFilterModel.logicOperator = validFilterOperator(
      filterModel.logicOperator.toLowerCase()
    );
  }
  return validFilterModel;
};

const validSearchFilter = (searchString: string) => {
  return typeof searchString === "string" ? searchString : "";
};

const validOrgsFilter = (orgs: any) => {
  return (Array.isArray(orgs) ? orgs : []).filter(
    (org) => org?.key && org?.type && org?.label
  );
};

const validInstallationTypeFilter = (installationTypes: any) => {
  return (Array.isArray(installationTypes) ? installationTypes : []).filter(
    (installationType) =>
      installationType?.key && installationType?.type && installationType?.label
  );
};

const validFilters = (filters: FiltersState, definedColumns: any[]) => {
  const validModel: FiltersState = {
    searchField: validSearchFilter(filters?.searchField),
    orgs: validOrgsFilter(filters?.orgs),
    installationType: validInstallationTypeFilter(filters?.installationType),
    columnFilters: validColumnFilterModel(
      filters?.columnFilters,
      definedColumns
    ),
  };
  return validModel;
};

const validColumnStates = (
  columnStates: any,
  definedColumns: any[],
  initialState: GridViewState
) => {
  if (columnStates && Array.isArray(columnStates)) {
    const validColStates = (Array.isArray(columnStates) ? columnStates : [])
      .map((col) => {
        if (!columnGraphQLName(col?.field, definedColumns)) {
          return false;
        }
        return {
          field: col.field,
          hide: validBool(col?.hide),
          width: validWidth(col?.width),
          index: validIndex(col?.index),
        };
      })
      .filter(Boolean);
    return validColStates;
  }
  return initialState.grid.columnStates;
};

const validGridState = (
  grid: GridState,
  definedColumns: any[],
  initialState: GridViewState
) => {
  const sortField = columnGraphQLName(
    grid?.sortField,
    definedColumns,
    "sorting"
  )
    ? grid?.sortField
    : "";
  const sortDirection =
    (grid?.sortDirection && grid.sortDirection === Sort.ASC) ||
    grid.sortDirection === Sort.DESC
      ? grid.sortDirection
      : "";
  const validGridState: GridState = {
    columnStates: validColumnStates(
      grid.columnStates,
      definedColumns,
      initialState
    ),
    pageNumber:
      grid?.pageNumber && typeof grid.pageNumber === "number"
        ? grid?.pageNumber
        : initialState.grid.pageNumber,
    rowsPerPage:
      grid?.rowsPerPage && typeof grid.rowsPerPage === "number"
        ? grid?.rowsPerPage
        : initialState.grid.rowsPerPage,
    sortDirection: sortDirection
      ? sortDirection
      : initialState.grid.sortDirection,
    sortField: sortField ? sortField : initialState.grid.sortField,
    totalPages:
      grid?.totalPages && typeof grid.totalPages === "number"
        ? grid?.totalPages
        : initialState.grid.totalPages,
    density: grid?.density ? validDensity(grid?.density!) : undefined,
  };
  return validGridState;
};

const validDensity = (density: string) => {
  const value =
    density === "compact" || density === "standard" || density === "comfortable"
      ? density
      : "standard";
  return value;
};

const columnFiltersGraphQLParams = (
  columnFilterModel: any,
  definedColumns: any[]
) => {
  const validFilterModel = validColumnFilterModel(
    columnFilterModel,
    definedColumns
  );
  if (
    Array.isArray(validFilterModel.items) &&
    validFilterModel.items.length > 0
  ) {
    let count = 0;
    let fieldsObj: { [key: string]: any[] } = {};
    let filterItems: any[] = [];
    validFilterModel.items.map((item: any) => {
      const fieldName = columnGraphQLName(
        item.field,
        definedColumns,
        "filtering"
      );
      const column = definedColumns.find((col) => col.field === item.field);
      const key = item.operator as keyof typeof SUPPORTED_FILTER_OPERATORS;
      if (!key || !fieldName || !column) {
        return null;
      }
      if (fieldsObj[fieldName] === undefined) {
        fieldsObj[fieldName] = [];
      }
      count += 1;
      const operator = SUPPORTED_FILTER_OPERATORS[key];
      if (item.value !== undefined) {
        const value = escapeFilterValue(item.value, column);
        if (!Array.isArray(value)) {
          fieldsObj[fieldName].push(
            `{operator: ${operator}, values: ${value}}`
          );
        } else {
          fieldsObj[fieldName].push(
            `{operator: ${operator}, values: [${value.join(",")}]}`
          );
        }
      } else {
        fieldsObj[fieldName].push(`{operator: ${operator}}`);
      }
      return null;
    });
    for (const fieldName in fieldsObj) {
      if (fieldsObj.hasOwnProperty(fieldName)) {
        const values = fieldsObj[fieldName];
        if (values.length === 1) {
          filterItems.push(`${fieldName}: ${values}`);
        } else if (values.length > 1) {
          filterItems.push(`${fieldName}: [ ${values.join(",")} ]`);
        }
      }
    }
    if (count > 0) {
      const logicOp =
        count > 1 && validFilterModel?.logicOperator
          ? ", operator: " + validFilterModel.logicOperator
          : "";
      return `columnFilters: { ${filterItems.join(",")} }${logicOp}`;
    }
  }
  return "";
};

const columnSortGraphQLParams = (
  sortColumn: string,
  sortDirection: Sort,
  definedColumns: any[],
  supportedSortColumns: string[]
) => {
  if (sortDirection === Sort.ASC || sortDirection === Sort.DESC) {
    if (supportedSortColumns.includes(sortColumn)) {
      const sortField = columnGraphQLName(
        sortColumn,
        definedColumns,
        "sorting"
      );
      if (sortField) {
        return `orderBy: { ${sortField}: ${sortDirection.toUpperCase()} }`;
      }
    }
  }
  return "";
};

const mainSearchFilterGraphQLParams = (selectedSearchStr: string) => {
  if (selectedSearchStr) {
    const searchItems = (
      !Array.isArray(selectedSearchStr)
        ? [selectedSearchStr]
        : selectedSearchStr
    ).map(validSearchFilter);
    const mainSearchFilterItems = [...searchItems]
      .sort((a, b) => (a > b ? 1 : -1))
      .map((item) => {
        if (!item || typeof item !== "string") {
          return false;
        }
        return escapeFilterValue(item);
      })
      .filter(Boolean);
    if (mainSearchFilterItems.length > 0) {
      return `search: [ ${mainSearchFilterItems.join(",")} ]`;
    }
  }
  return "";
};
const orgFiltersGraphQLParams = (selectedFilterItems: FilterItem[]) => {
  if (Array.isArray(selectedFilterItems)) {
    const orgFilterItems = [...selectedFilterItems]
      .sort((a, b) => (a.key > b.key ? 1 : -1))
      .map((item) => {
        if (!item?.key) {
          return false;
        }
        return escapeFilterValue(item.key);
      })
      .filter(Boolean);
    if (orgFilterItems.length > 0) {
      return `organizations: [ ${orgFilterItems.join(",")} ]`;
    }
  }
  return "";
};

const filtersGraphQLParams = (
  searchField: string,
  orgFilterItems: FilterItem[],
  columnFilterModel: any,
  definedColumns: any[]
) => {
  const pairs: any[] = [];

  const searchFieldStr = mainSearchFilterGraphQLParams(searchField);
  if (searchFieldStr) {
    pairs.push(searchFieldStr);
  }
  const orgFiltersStr = orgFiltersGraphQLParams(orgFilterItems);
  if (orgFiltersStr) {
    pairs.push(orgFiltersStr);
  }
  const columnFiltersStr = columnFiltersGraphQLParams(
    columnFilterModel,
    definedColumns
  );
  if (columnFiltersStr) {
    pairs.push(columnFiltersStr);
  }

  if (pairs.length > 0) {
    const params = pairs.join(", ");
    return "filter: { " + params + " }";
  }
  return "";
};

const getGridQueryParams = (
  grid: GridState,
  searchField: string,
  selectedOrgFilterItems: FilterItem[],
  columnFilterModel: any,
  definedColumns: any[],
  supportedSortColumns: string[],
  page: number,
  size: number = -1
) => {
  const pairs: any[] = [];

  const columnSortStr = columnSortGraphQLParams(
    grid.sortField,
    grid?.sortDirection,
    definedColumns,
    supportedSortColumns
  );
  if (columnSortStr) {
    pairs.push(columnSortStr);
  }

  const offset = size * page;
  pairs.push(`offset: ${offset}`);
  if (size > 0) {
    pairs.push(`limit: ${size}`);
  }

  const columnFiltersStr = filtersGraphQLParams(
    searchField,
    selectedOrgFilterItems,
    columnFilterModel,
    definedColumns
  );
  if (columnFiltersStr) {
    pairs.push(columnFiltersStr);
  }

  if (pairs.length > 0) {
    const params = pairs.join(", ");
    return "( " + params + " )";
  }
  return "";
};

export {
  validGridState,
  validFilters,
  validSearchFilter,
  validOrgsFilter,
  validInstallationTypeFilter,
  validColumnFilterModel,
  validDensity,
  filtersGraphQLParams,
  columnSortGraphQLParams,
  mainSearchFilterGraphQLParams,
  orgFiltersGraphQLParams,
  columnFiltersGraphQLParams,
  getGridQueryParams,
};
