import { TplEditDataItem } from "models/response/tpleditdata";
import { useSelector } from "react-redux";
import { TemplateEditorState } from "redux/reducers/templateEditorSlice";

interface RootState {
  templateEditor: TemplateEditorState;
}

type Selector<T> = (item: TplEditDataItem) => T;

/**
 * Custom hook to select an item from the template editor state.
 *
 * @param {number} groupIndex - The index of the group containing the item.
 * @param {number} itemIndex - The index of the item within the group.
 * @param {Selector<T>} selector - A function to select and transform the item.
 * @returns {T} - The selected item after applying the selector function.
 *
 * @example const selectedItem = useItemSelector(0, 1, item => item.name);
 * 
 **/
const useItemSelector = <T>(
  groupIndex: number,
  itemIndex: number,
  selector: Selector<T>
): T => {
  return useSelector((state: RootState) => {
    return selector(
      state.templateEditor.template.groups[groupIndex].items[itemIndex]
    );
  });
};

export default useItemSelector;
