import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { enqueueSnackbar } from "notistack";
import { Grid, Button } from "@mui/material";
import InventoryItemCard from "./InventoryItemCard";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";
import FailureDateDialog from "../failureDateDialog";
import { InventoryItem } from "generated-gql-types/graphql";

const InventoryItemDetails = () => {
  const { serialNumber } = useParams();
  const { ready, hasAccess } = useAuthData();
  const navigate = useNavigate();
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>();
  const [failureDateOpen, setFailureDateOpen] = useState<boolean>(false);
  const { data, loading, error } = useQuery(
    gql`
      query fetchInventoryItem($serialNumber: String!) {
        inventoryItem(serialNumber: $serialNumber) {
          id
          ...InventoryItemCard_inventoryItem
        }
      }
      ${InventoryItemCard.fragments.inventoryItem}
    `,
    { variables: { serialNumber: serialNumber }, skip: !serialNumber || !ready }
  );

  useEffect(() => {
    if (!loading && data) {
      setInventoryItem(data.inventoryItem);
    }
    if (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [data, error, loading]);

  const handleFailureDateDialogOpen = () => {
    setFailureDateOpen(true);
  };

  const handleFailureDateClose = () => {
    setFailureDateOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        width: "100%",
        maxWidth: "1080px",
        marginX: "auto",
      }}
    >
      <Grid xs item>
        <Button
          sx={{ fontSize: 16, marginBottom: 2 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon sx={{ marginRight: 1 }} />
          Back to Inventory
        </Button>
      </Grid>
      <Grid xs="auto" item>
        {hasAccess(Constants.ACTIONS.EDIT_INVENTORY) && (
          <Button
            size="medium"
            variant="contained"
            onClick={handleFailureDateDialogOpen}
          >
            <ReportProblemOutlinedIcon style={{ marginRight: "12px" }} />
            Enter Failure
          </Button>
        )}
      </Grid>
      <Grid xs={12} item height="calc(100vh - 140px)">
        <InventoryItemCard inventoryItem={inventoryItem} />
      </Grid>
      {failureDateOpen && (
        <FailureDateDialog
          open={failureDateOpen}
          serialNumber={serialNumber}
          failureDate={inventoryItem?.warranty?.failureDt}
          onClose={handleFailureDateClose}
        />
      )}
    </Grid>
  );
};
export default InventoryItemDetails;
