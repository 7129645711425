import { CSSInterpolation } from "tss-react";
import { Colors } from "./theme/colors";

export const globalstyles: CSSInterpolation = {
  "html, body": {
    background: Colors.white,
    margin: 0,
    padding: 0,
    fontFamily: `"Barlow", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
    color: Colors.onyx,
  },

  "ul, ol, dl": {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },

  hr: {
    border: 0,
    borderBottom: `1px solid ${Colors.dust}`,
  },

  a: {
    color: "black",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",
    },
  },

  ".u-text-plain": { color: Colors.ash },

  ".u-clickable": { cursor: "pointer" },

  ".u-valign-bottom": { verticalAlign: "bottom" },

  ".u-flex": {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: "100%",
  },

  ".u-noflex": {
    flexBasis: 0,
    flexShrink: 0,
  },

  ".u-cells-pb-2 td": {
    paddingBottom: "0.5rem",
  },

  ".smc-Layout-bodyContainer": {
    paddingTop: "2rem",
    paddingBottom: "5rem",
  },
};
