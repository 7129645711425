import { Box, Grid } from "@mui/material";

import {
  mainSearchFilterGraphQLParams,
  orgFiltersGraphQLParams,
  validSearchFilter,
  validOrgsFilter,
  validInstallationTypeFilter,
} from "./assetFiltersUtil";
import MainFilter from "../../assets/filter/mainFilter";
import OrgsFilter from "../../assets/filter/orgsFilter";
import InstallationTypeFilter from "../../assets/filter/installationTypeFilter";
import ViewsFilter from "../../assets/filter/viewSelector";
import FilterChips from "../../assets/filter/filterChips";
import ExportToCSV from "../../assets/components/ExportToCSV";
import { Constants } from "utils/constants";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import {
  ColumnState,
  FilterItem,
  FiltersState,
  GridState,
  GridViewState,
} from "utils/types";
import { GridColDef } from "@mui/x-data-grid-pro";
import { DocumentNode } from "graphql";
import { forwardRef } from "react";

const FilterView = forwardRef(
  (
    props: {
      columns: any[];
      defaultColumns: GridColDef[];
      definedColumns: any[];
      fileContentLabel: string;
      filters: FiltersState;
      initialColumnStates: ColumnState[];
      initialState: GridViewState;
      resetAll: any;
      resetColumnsState: any;
      resetFiltersState: any;
      resetGridState: any;
      setDirtyState: any;
      setView: any;
      updateFiltersState: any;
      updateGridPropertyState: any;
      viewState: GridViewState;
      getQuery: (params: string) => DocumentNode;
      getQueryParams: (
        grid: GridState,
        searchField: string,
        selectedOrgFilterItems: FilterItem[],
        columnFilterModel: any,
        page: number,
        size?: number
      ) => string;
      onFiltersAction?: (action: string, data?: any) => void;
      rowsFromQueryData: (data: any) => any;
    },
    ref
  ) => {
    const {
      columns,
      defaultColumns,
      definedColumns,
      fileContentLabel,
      filters,
      initialState,
      resetAll,
      resetColumnsState,
      resetFiltersState,
      resetGridState,
      setDirtyState,
      setView,
      updateFiltersState,
      updateGridPropertyState,
      viewState,
      getQuery,
      getQueryParams,
      onFiltersAction,
      rowsFromQueryData,
    } = props;
    const dispatch = useAppDispatch();
    const searchField = filters?.searchField;
    const organizations = useAppSelector((state) => state.orgsList.data?.orgs);
    const selectedOrgFilterItems = Array.isArray(filters?.orgs)
      ? filters.orgs
      : [];
    const selectedInstallationTypes = Array.isArray(filters?.installationType)
      ? filters.installationType
      : [];

    const orgsAutocompleteOptions = (() => {
      if (!organizations || !Array.isArray(organizations)) {
        return [];
      }
      const excludedKeys = selectedOrgFilterItems.map((item: any) => item.key);
      const filteredOrganizations = organizations.filter(
        (org) =>
          !excludedKeys.includes(org.organization_id) &&
          org.organization_name.trim() !== ""
      );
      return filteredOrganizations;
    })();

    const setMainFilter = (value: string) => {
      const newValue = validSearchFilter(value);
      const oldSearchStr = mainSearchFilterGraphQLParams(searchField);
      const newSearchStr = mainSearchFilterGraphQLParams(newValue);
      dispatch(
        updateFiltersState({ property: "searchField", value: newValue })
      );
      // Reset page number if column filter changed.
      if (newSearchStr !== oldSearchStr) {
        dispatch(updateGridPropertyState({ property: "pageNumber", value: 0 }));
      }
    };

    const setOrgsFilter = (value: FilterItem[]) => {
      const newFilterItems = validOrgsFilter(value);
      const oldOrgsFilterStr = orgFiltersGraphQLParams(selectedOrgFilterItems);
      const newOrgsFilterStr = orgFiltersGraphQLParams(newFilterItems);
      dispatch(updateFiltersState({ property: "orgs", value: newFilterItems }));
      // Reset page number if column filter changed.
      if (newOrgsFilterStr !== oldOrgsFilterStr) {
        dispatch(updateGridPropertyState({ property: "pageNumber", value: 0 }));
      }
    };

    const setInstallationTypeFilter = (value: FilterItem[]) => {
      const newValue = validInstallationTypeFilter(value);
      dispatch(
        updateFiltersState({ property: "installationType", value: newValue })
      );
    };

    const handleOrgsFilterChange = (value: any[]) => {
      if (value?.length && value?.length > 0) {
        const item = value[value?.length - 1];
        if (typeof item === "string") {
          setOrgsFilter([
            ...selectedOrgFilterItems,
            { key: item, label: item, type: "orgphrase" },
          ]);
        } else {
          setOrgsFilter([
            ...selectedOrgFilterItems,
            {
              key: item.organization_id,
              label: item.organization_name,
              type: "org",
            },
          ]);
        }
      } else {
        setOrgsFilter([]);
      }
    };

    const selectAll = (() => {
      return Object.keys(Constants.STATUS_TEXT).every((key) =>
        selectedInstallationTypes.some((item: any) => item.key === key)
      );
    })();

    const handleInstallationTypeSelectionChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      key: string
    ) => {
      const _selectedTypes = [...selectedInstallationTypes];
      const index = _selectedTypes?.findIndex((item) => item.key === key);
      if (index > -1 && !event.target.checked) {
        _selectedTypes.splice(index, 1);
      } else if (event.target.checked) {
        _selectedTypes.push({
          key: key,
          label: Constants.STATUS_TEXT[key],
          type: "installation_type",
        });
      }
      setInstallationTypeFilter([..._selectedTypes]);
    };

    const handleSelectAll = () => {
      if (selectAll) {
        handleResetInstallationStatusFilter();
      } else {
        const _selectedTypes: FilterItem[] = [];
        Object.keys(Constants.STATUS_TEXT).forEach((key) => {
          _selectedTypes.push({
            key: key,
            label: Constants.STATUS_TEXT[key],
            type: "installation_type",
          });
        });
        setInstallationTypeFilter([..._selectedTypes]);
      }
    };

    const handleResetInstallationStatusFilter = () => {
      setInstallationTypeFilter([]);
    };

    const handleRemoveFilterChip = (item: FilterItem) => {
      if (item.type === "org" || item.type === "orgphrase") {
        const selected = [...selectedOrgFilterItems];
        const index = selected?.indexOf(item);
        if (index > -1) {
          selected.splice(index, 1);
        }
        setOrgsFilter(selected);
      } else if (item.type === "installation_type") {
        const selected = [...selectedInstallationTypes];
        const index = selected?.indexOf(item);

        if (index > -1) {
          selected.splice(index, 1);
        }
        setInstallationTypeFilter(selected);
      }
    };

    const setDirty = (newValue: boolean) => {
      dispatch(setDirtyState(newValue));
    };

    const handleViewSelectorAction = (action: string, data?: any) => {
      if (action === "ViewChanged") {
        if (data?.view_data?.filters) {
          const viewFilters: FiltersState = data.view_data.filters;
          setMainFilter(viewFilters.searchField);
          setOrgsFilter(viewFilters.orgs);
          setInstallationTypeFilter(viewFilters.installationType);
          onFiltersAction?.("ViewChanged", data);
        }
      } else if (action === "ResetAll") {
        dispatch(resetAll());
        setDirty(true);
      } else if (action === "ResetFilters") {
        dispatch(resetFiltersState());
      } else if (action === "ResetColumnsView") {
        dispatch(resetColumnsState());
      } else if (action === "ResetGridView") {
        dispatch(resetGridState());
      }
    };

    return (
      <Box marginBottom={2} ref={ref}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={{ xs: 2, md: 3 }}
        >
          <Grid
            item
            lg={9}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <MainFilter value={searchField} onChange={setMainFilter} />
            <OrgsFilter
              orgsAutocompleteOptions={orgsAutocompleteOptions}
              autocompleteValue={selectedOrgFilterItems}
              onChange={handleOrgsFilterChange}
            />
            {false && (
              <InstallationTypeFilter
                selectedInstallationTypes={selectedInstallationTypes}
                onChange={handleInstallationTypeSelectionChange}
                onReset={handleResetInstallationStatusFilter}
                onSelectAll={handleSelectAll}
              />
            )}
          </Grid>
          <Grid
            item
            lg={3}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ViewsFilter
              onViewSelectorAction={handleViewSelectorAction}
              initialState={initialState}
              definedColumns={definedColumns}
              setView={setView}
              viewState={viewState}
              filters={filters}
            />
            <ExportToCSV
              columns={columns}
              grid={viewState.grid}
              filters={filters}
              disabled={false}
              defaultColumns={defaultColumns}
              fileContentLabel={fileContentLabel}
              getQuery={getQuery}
              getQueryParams={getQueryParams}
              rowsFromQueryData={rowsFromQueryData}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <FilterChips
            chips={[...selectedOrgFilterItems, ...selectedInstallationTypes]}
            onDelete={handleRemoveFilterChip}
          />
        </Grid>
      </Box>
    );
  }
);

export default FilterView;
