import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { enqueueSnackbar } from "notistack";

import { UsersRow } from "models/users";
import { useAuthData } from "utils/hooks/useAuthData";
import { useAppDispatch } from "redux/store/hooks";
import { reinviteUser } from "redux/actions/users";
import { useNavigate } from "react-router-dom";
import { Constants } from "utils/constants";

const ActionsMenu = (props: { user: UsersRow }) => {
  const { user } = props;
  const dispatch = useAppDispatch();

  const { isGreensightAdmin, getRawIdToken, hasAccess } = useAuthData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleReinviteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose(event);
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        reinviteUser({
          token: idToken,
          userId: user.user_id,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(`Invite re-sent!`, {
            variant: "default",
          });
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to re-invite user!", {
            variant: "error",
          });
        }
      });
    })();
  };

  const handleManageRolesClick = () => {
    navigate("/users/roles/" + user.user_id);
  };
  if (
    user.activated_at &&
    !(isGreensightAdmin || hasAccess(Constants.ACTIONS.MANAGE_USER_ROLES))
  ) {
    return null;
  }
  return (
    <div>
      {(!user.activated_at ||
        isGreensightAdmin ||
        hasAccess(Constants.ACTIONS.MANAGE_USER_ROLES)) && (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon color="action" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {!user.activated_at && (
              <MenuItem onClick={(e: any) => handleReinviteClick(e)}>
                Resend Invite
              </MenuItem>
            )}
            {(hasAccess(Constants.ACTIONS.MANAGE_USER_ROLES) ||
              isGreensightAdmin) && (
              <MenuItem
                onClick={(e: any) => {
                  handleClose(e);
                  handleManageRolesClick();
                }}
              >
                Manage Roles
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </div>
  );
};

export default ActionsMenu;
