import DataGrid from "react-data-grid";
import { Box, IconButton } from "@mui/material";
import type { Column } from "react-data-grid";
import { useNavigate } from "react-router";
import { useOrgRelationshipsGridStyles } from "../styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";
interface OrgChannelPartnerRow {
  organization_name: string;
  organization_id: string;
}

function rowKeyGetter(row: OrgChannelPartnerRow) {
  return row.organization_id;
}

const OrgChannelPartnersDataGrid = (props: {
  rows: any;
  onRemove?: any | undefined;
  isGreensightAdmin?: boolean | undefined;
}) => {
  const { rows, onRemove, isGreensightAdmin } = props;
  const navigate = useNavigate();
  const { classes } = useOrgRelationshipsGridStyles();
  const { hasAccess } = useAuthData();
  const columns: Column<OrgChannelPartnerRow>[] = [
    {
      key: "organization_name",
      name: "Channel Partner Name",
      minWidth: 100,
      headerCellClass: classes.cellItem,
      cellClass: classes.cellItem,
    },
  ];
  const handleRowClick = (row: any, column: any) => {
    if (row.has_access && hasAccess(Constants.ACTIONS.VIEW_ORGS)) {
      navigate("/organizations/view/" + row.organization_id, { replace: true });
    }
  };
  if (isGreensightAdmin) {
    columns.push({
      key: "delete",
      name: "",
      width: 60,
      formatter(props: any) {
        return (
          <Box
            justifyContent="flex-end"
            display={"flex"}
            alignItems="center"
            height={"100%"}
          >
            <IconButton
              aria-label="delete"
              size="small"
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                onRemove(props.row);
              }}
            >
              {" "}
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        );
      },
    });
  }
  return (
    <Box
      style={{
        height: "auto",
        maxHeight: "250px",
        width: "100%",
      }}
    >
      <DataGrid
        style={{
          height: "auto",
          maxHeight: "250px",
          width: "100%",
          borderRadius: 4,
        }}
        className="rdg-light"
        columns={columns}
        rows={rows}
        rowKeyGetter={rowKeyGetter}
        defaultColumnOptions={{ resizable: true, sortable: true }}
        rowHeight={52}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};
export default OrgChannelPartnersDataGrid;
