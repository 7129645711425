import { useState } from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const TTEditableDateTime = (props: {
  editable: boolean;
  defaultValue: string;
  type: "dateTime" | "date" | "time";
  onChange?: (value: any) => void;
  onKeyDown?: (event: any) => void;
  autoFocus?: boolean;
}) => {
  const { editable, defaultValue, type, onChange, onKeyDown, autoFocus } =
    props;
  const [value, setValue] = useState<Dayjs | null>(dayjs(defaultValue));

  const renderField = () => {
    switch (type) {
      case "dateTime":
        return (
          <DateTimePicker
            value={dayjs(value)}
            onChange={handleChange}
            autoFocus={autoFocus}
            slotProps={{
              textField: {
                onKeyDown: onKeyDown,
              },
            }}
          />
        );
      case "date":
        return (
          <DatePicker
            value={dayjs(value)}
            onChange={handleChange}
            autoFocus={autoFocus}
            slotProps={{
              textField: {
                onKeyDown: onKeyDown,
              },
            }}
          />
        );
      case "time":
        return (
          <TimePicker
            value={value}
            onChange={handleChange}
            autoFocus={autoFocus}
            slotProps={{
              textField: {
                onKeyDown: onKeyDown,
              },
            }}
          />
        );
      default:
        return null;
    }
  };

  const getFormattedValueForDefaultValue = (dateTimeString: string) => {
    switch (type) {
      case "dateTime":
        return dateTimeString
          ? moment(dateTimeString, "MM/DD/YYYY hh:mm:ss A").format(
              "MM/DD/YYYY hh:mm:ss A"
            )
          : "None";
      case "date":
        return dateTimeString
          ? moment(dateTimeString, "MM/DD/YYYY").format("MM/DD/YYYY")
          : "None";
      case "time":
        return dateTimeString
          ? moment(dateTimeString, "MM/DD/YYYY HH:mm").format("hh:mm A")
          : "None";
      default:
        return null;
    }
  };

  const getFormattedValue = (dateTimeString: dayjs.Dayjs | null) => {
    switch (type) {
      case "dateTime":
        return dateTimeString
          ? dateTimeString.format("MM/DD/YYYY HH:mm:ss")
          : null;
      case "date":
        return dateTimeString ? dateTimeString.format("MM/DD/YYYY") : null;
      case "time":
        return dateTimeString ? dateTimeString.format("HH:mm") : null;
      default:
        return null;
    }
  };

  const handleChange = (newValue: dayjs.Dayjs | null) => {
    setValue(newValue);
    onChange?.(getFormattedValue(newValue));
  };

  return editable ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {renderField()}
    </LocalizationProvider>
  ) : (
    <Typography>{getFormattedValueForDefaultValue(defaultValue)}</Typography>
  );
};

export default TTEditableDateTime;
