export abstract class Constants {
  static readonly SURVEY_STATUS: { [key: string]: string } = {
    COMPLETED: "COMPLETED",
    INCOMPLETE: "INCOMPLETE",
    IN_PROGRESS: "IN_PROGRESS",
  };
  static readonly ORGANIZATION_TYPE: { [key: string]: string } = {
    CUSTOMER: "C",
    CHANNEL_PARTNER: "CP",
    INSTALLER: "I",
  };
  static readonly ORGANIZATION_TYPE_TO_LEVEL_MAP: { [key: string]: number } = {
    [Constants.ORGANIZATION_TYPE.CUSTOMER]: 3,
    [Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER]: 2,
    [Constants.ORGANIZATION_TYPE.INSTALLER]: 1,
  };
  static readonly ORGANIZATION_TYPE_TO_NAME_MAP: { [key: string]: string } = {
    [Constants.ORGANIZATION_TYPE.CUSTOMER]: "Customer",
    [Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER]: "Channel Partner",
    [Constants.ORGANIZATION_TYPE.INSTALLER]: "Installer",
  };

  static readonly STATUS_TEXT: { [key: string]: string } = {
    COMPLETED: "Completed",
    INCOMPLETE: "Incomplete",
    IN_PROGRESS: "In progress",
  };
  static readonly ROLE_CLAIM_TYPE = "https://turntide.com/roles";
  static readonly USER_ID_CLAIM_TYPE = "https://turntide.com/internal_id";
  static readonly PRODUCTS: { [key: string]: string } = {
    GLOBAL: "GLOBAL",
    BOS: "BOS",
    TTAPP: "TTAPP",
    TTADMIN: "TTADMIN",
    ROI: "ROI",
  };
  static readonly PRODUCTS_ARRAY: string[] = [
    Constants.PRODUCTS.GLOBAL,
    Constants.PRODUCTS.BOS,
    Constants.PRODUCTS.TTAPP,
    Constants.PRODUCTS.TTADMIN,
    Constants.PRODUCTS.ROI,
  ];
  static readonly ACTIONS: { [key: string]: string } = {
    VIEW_JOBS: "VIEW_JOBS",
    VIEW_JOB_DETAILS: "VIEW_JOB_DETAILS",
    EDIT_JOBS: "EDIT_JOBS",
    VIEW_USERS: "VIEW_USERS",
    INVITE_USER_TO_ORG: "INVITE_USER_TO_ORG",
    DELETE_USER_FROM_ORG: "DELETE_USER_FROM_ORG",
    PROMOTE_DEMOTE: "PROMOTE_DEMOTE",
    VIEW_ORGS: "VIEW_ORGS",
    CREATE_ORG: "CREATE_ORG",
    EDIT_ORGS: "EDIT_ORGS",
    MANAGE_SITES: "MANAGE_SITES",
    MANAGE_USER_ROLES: "MANAGE_USER_ROLES",
    VIEW_ASSET: "VIEW_ASSET",
    EDIT_ASSET: "EDIT_ASSET",
    VIEW_FAN_CHARTS: "VIEW_FAN_CHARTS",
    EDIT_FAN_CHARTS: "EDIT_FAN_CHARTS",
    CREATE_CHILD_CUSTOMER_ORG: "CREATE_CHILD_CUSTOMER_ORG",
    CREATE_INSTALLER_ORG: "CREATE_INSTALLER_ORG",
    REGENERATE_SURVEY_THUMBNAILS: "REGENERATE_SURVEY_THUMBNAILS",
    VIEW_INVENTORY: "VIEW_INVENTORY",
    EDIT_INVENTORY: "EDIT_INVENTORY",
  };

  static readonly JOB_DATA_TYPES: { [key: string]: string } = {
    TEXT: "TEXT",
    PICKLIST: "PICKLIST",
    ATTACHMENT: "ATTACHMENT",
    DATE: "DATE",
    INTEGER: "INTEGER",
    PASSFAIL: "PASSFAIL",
    DECIMAL: "DECIMAL",
    TIME: "TIME",
    DATETIME: "DATETIME",
    INFO: "INFO",
    VIDEO: "VIDEO",
    MEDIA: "MEDIA",
    PHOTO: "PHOTO",
    DRAWING: "DRAWING",
    AUDIO: "AUDIO",
    JSON: "JSON",
    COMMISSIONING: "COMMISSIONING",
    WIFI: "WIFI", //for Diagnostic - WiFi
    WIRING: "WIRING", //for Diagnostic - Wiring
    SENSOR: "SENSOR", //for Diagnostic - Sensor
    RMK: "RMK",
    EMK: "EMK",
    FINALCONNECTIVITY: "FINALCONNECTIVITY",
    MCCONNECTIVITY: "MCCONNECTIVITY",
    MCALERT: "MCALERT",
    RMKCOMMISSIONING: "RMKCOMMISSIONING",
    MOTORSETTINGS: "MOTORSETTINGS",
  };

  static readonly DATE_FORMATS: { [key: string]: string } = {
    DATE: "MM/DD/YYYY",
    DATE_TIME_AM: "MM/DD/YYYY hh:mm A",
    DATE_TIME_MIN: "MM/DD/YYYY HH:mm",
    DATE_TIME_SEC: "MM/DD/YYYY hh:mm:ss A",
    DATE_TIME_TZ: "MM/DD/YYYY hh:mm A z",
    TIME: "hh:mm A",
  };
  static readonly TABLE_PAGINATION_HEIGHT = 80;
}
