import { Box, Grid, TextField, Chip, Autocomplete } from "@mui/material";
import { useUsersListFilterStyles } from "./styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/store/hooks";
import { ListFilterTag } from "models/misc/listFilterTag";
import { filterOrgUsers } from "redux/reducers/orgDetailsSlice";

const UsersListFilter = () => {
  const { classes } = useUsersListFilterStyles();
  const dispatch = useDispatch();
  const orgs = useAppSelector((state) => state.orgDetails.data?.users);
  const [filterTagsValue, setFilterTagsValue] = useState<ListFilterTag[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<any[]>([]);

  useEffect(() => {
    doUsersSearch();
  }, [filterTagsValue]);
  const handlUserNameSelected = (value: any[]) => {
    setAutocompleteValue(value);
    if (value?.length && value?.length > 0) {
      let item = value[value?.length - 1];
      if (typeof item === "string") {
        setFilterTagsValue([
          ...filterTagsValue,
          { key: item, label: item, type: "phrase" },
        ]);
      } else {
        setFilterTagsValue([
          ...filterTagsValue,
          {
            key: item.user_id,
            label: `${item.last_name} ${item.first_name} (${item.email})`,
            type: "user",
          },
        ]);
      }
    } else {
      setFilterTagsValue([]);
    }
  };
  const handleRemoveFilterChip = (tag: ListFilterTag) => {
    let _filterTagsValue = [...filterTagsValue];
    const index = _filterTagsValue?.indexOf(tag);
    if (index > -1) {
      _filterTagsValue.splice(index, 1);
    }
    setFilterTagsValue([..._filterTagsValue]);

    handleUserNameFilterRemoved(tag.key, tag.type);
  };
  const handleUserNameFilterRemoved = (key: string, type: string) => {
    let _autocompleteValue = [...autocompleteValue];
    let item =
      type === "phrase"
        ? _autocompleteValue.find((e) => e === key)
        : _autocompleteValue.find((e) => e.user_id === key);

    const index = _autocompleteValue?.indexOf(item);
    if (index > -1) {
      _autocompleteValue.splice(index, 1);
    }

    setAutocompleteValue([..._autocompleteValue]);
  };

  const doUsersSearch = () => {
    let phrases: string[] = [];
    let userIds: any[] = [];
    filterTagsValue.forEach((tag) => {
      if (tag.type === "phrase") {
        phrases.push(tag.key);
      } else {
        userIds.push(tag.key);
      }
    });
    dispatch(filterOrgUsers({ phrases: phrases, userIds: userIds }));
  };
  return orgs && orgs.length ? (
    <Box marginBottom={1} paddingLeft={1} paddingTop={2}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Autocomplete
                multiple
                autoComplete={true}
                id="org-name-autocomplete"
                options={orgs}
                onChange={(e, newValue) => handlUserNameSelected(newValue)}
                value={autocompleteValue}
                size="small"
                getOptionLabel={(option: any) =>
                  `${option.last_name} ${option.first_name} (${option.email})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    className={classes.nameFilter}
                    label="search by user name..."
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                renderTags={() => null}
                freeSolo={true}
                filterSelectedOptions={true}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (item) =>
                      item.first_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      item.last_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      item.email
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            {filterTagsValue?.map((item) => (
              <Grid item key={item.key}>
                <Box
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <Chip
                    sx={{ marginTop: "10px" }}
                    key={item.key}
                    className={classes.userListFilterChip}
                    label={item.label}
                    size="small"
                    onDelete={() => handleRemoveFilterChip(item)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <></>
  );
};
export default UsersListFilter;
