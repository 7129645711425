import { createSlice } from "@reduxjs/toolkit";
import { listRoles } from "redux/actions/roles";
import error from "utils/error";
interface RolesListState {
  roles: Array<any>;
  loading: boolean;
  error?: string;
}

const initialState: RolesListState = {
  roles: [],
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(listRoles.pending, (state) => {
      state.loading = true;
      state.roles = [];
    });
    builder.addCase(listRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    });
    builder.addCase(listRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
