import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { isObject } from "lodash";

const StatusInfo = (props: { value: any }) => {
  const { value } = props;
  const keys = value ? Object.keys(value) : [];
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="status-info-table">
        <TableBody>
          {keys.map((key, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ verticalAlign: "top" }}
              >
                <Typography fontWeight="bold">{key}</Typography>
              </TableCell>
              {isObject(value[key]) ? (
                <>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ verticalAlign: "top" }}
                  >
                    <Typography>{value[key]?.issue}</Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ verticalAlign: "top", minWidth: "170px" }}
                  >
                    <Typography>
                      {value[key]?.result === "PASSED" ? (
                        <CheckCircleOutlineIcon
                          color="success"
                          sx={{ verticalAlign: "middle" }}
                        />
                      ) : value[key].result ? (
                        <ErrorOutlineIcon
                          color="error"
                          sx={{ verticalAlign: "middle" }}
                        />
                      ) : null}{" "}
                      {value[key]?.result}
                    </Typography>
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ verticalAlign: "top" }}
                  >
                    <Typography>{value[key]?.explanation}</Typography>
                  </TableCell>
                </>
              ) : (
                <TableCell
                  colSpan={3}
                  component="th"
                  scope="row"
                  sx={{ verticalAlign: "top" }}
                >
                  <Typography>
                    {typeof value[key] === "boolean"
                      ? value[key].toString()
                      : value[key]}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatusInfo;
