import * as React from "react";
import { useIsOverflow } from "utils/hooks/useIsOverFlow";
import { Box } from "@mui/material";
import Link from "@mui/material/Link";

const TTToolTipText = (props: {
  text: string;
  link?: string;
  underline?: "always" | "hover" | "none";
  tooltip?: string;
}) => {
  const { text, link, underline, tooltip } = props;
  const ref = React.useRef();
  const isOverflow = useIsOverflow(ref);

  return (
    <Box style={{ overflow: "hidden", textOverflow: "ellipsis" }} ref={ref}>
      <span
        data-tooltip-id={isOverflow ? "cell-tooltip" : "cell-tooltip-disabled"}
        data-tooltip-content={tooltip || text}
      >
        {link ? (
          <Link href={link} underline={underline}>
            {text}
          </Link>
        ) : (
          <span>{text}</span>
        )}
      </span>
    </Box>
  );
};

export default TTToolTipText;
