export const Logo = () => {
  return (
    <svg
      width="157"
      height="28"
      viewBox="0 0 157 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9401 9.68027L14.2266 11.5901V3.0746H18.8517L17.0931 0.0151367H8.09667L6.33801 3.0746H10.9401V9.68027Z"
        fill="#006BA6"
      />
      <path
        d="M9.8461 16.3276L13.1326 14.4178L5.79333 10.1623L8.1059 6.13867H4.58858L0.0880585 13.9635L1.84672 17.023L4.14545 13.0225L9.8461 16.3276Z"
        fill="#006BA6"
      />
      <path
        d="M15.0297 20.597V16.7866L7.68582 21.0421L5.37325 17.0184L3.61459 20.0779L8.11048 27.9027H11.6324L9.32909 23.9022L15.0297 20.597Z"
        fill="#006BA6"
      />
      <path
        d="M21.3028 18.2236L18.0163 16.3184V24.8339H13.3911L15.1498 27.8933H24.1462L25.9049 24.8339H21.3028V18.2236Z"
        fill="#006BA6"
      />
      <path
        d="M22.3968 11.5811L19.1102 13.4909L26.4495 17.7464L24.137 21.77H27.6543L32.1548 13.9452L30.3961 10.8857L28.0974 14.8862L22.3968 11.5811Z"
        fill="#006BA6"
      />
      <path
        d="M17.2131 7.3115V11.1219L24.557 6.86648L26.8696 10.8901L28.6283 7.83068L24.1324 0.00585938H20.6104L22.9138 4.00634L17.2131 7.3115Z"
        fill="#006BA6"
      />
      <path
        d="M107.159 20.3929V10.5702H102.547V7.51074H115.075V10.5702H110.445V20.3929H107.159Z"
        fill="#101820"
      />
      <path d="M121.51 7.51074H118.26V20.3976H121.51V7.51074Z" fill="#101820" />
      <path
        d="M42.3652 20.3974V10.5746H37.7585V7.51514H50.2861V10.5746H45.6563V20.3974H42.3652Z"
        fill="#101820"
      />
      <path
        d="M65.8555 7.51514V20.402H62.629H56.8268L52.8479 16.383V7.51514H56.0929V15.0525L58.3547 17.3425H61.1473H62.6105V7.51514H65.8555Z"
        fill="#101820"
      />
      <path
        d="M99.3717 7.52441V19.41L96.9899 21.4589L88.3396 12.35V20.3927H85.2562V7.52441H88.5381L96.2882 15.8499V7.52441H99.3717Z"
        fill="#101820"
      />
      <path
        d="M68.8375 20.393V7.5293H79.491L82.1405 10.1901V13.3794L79.311 16.2117L82.5144 19.3546L80.128 21.4638L75.1659 16.3832H72.1148V20.3976H68.8375V20.393ZM72.1101 13.4767H77.7877L78.7986 12.4615V11.3675L77.8293 10.4126H72.1101V13.4767Z"
        fill="#101820"
      />
      <path
        d="M156.097 17.6255H145.697V15.4329H153.346V12.4708H145.697V10.2967H156.097V7.52002H142.609V20.4069H156.097V17.6255Z"
        fill="#101820"
      />
      <path
        d="M125.032 7.52441H136.161H139.447V15.4698L134.527 20.4113H125.036V7.52441H125.032ZM128.281 17.3703H132.92L135.939 14.3201V10.5653H134.577H128.281V17.3703Z"
        fill="#101820"
      />
    </svg>
  );
};
