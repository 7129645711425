import * as React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  Link as ExternalLink,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/system";
import { isMobile } from "react-device-detect";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { Logo } from "assets/svg/logo";
import SideBar from "components/sidebar";
import { UserMenu } from "./navbar/usermenu";

const drawerWidth = 220;
const drawerWidthCollapsed = 60;

const useAppStyles: any = makeStyles<any>()((theme) => {
  return {
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
    pageContent: {
      flexGrow: 1,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 6,
      minHeight: "calc(100vh - 72px)",
    },
    appBar: {
      height: 72,
      backgroundColor: "white",
      overflow: "auto",
      color: theme.palette.text.primary,

      "& .MuiToolbar-root": {
        paddingLeft: 10,
        paddingRight: isMobile ? 40 : 20,
      },
    },
    logoContainer: {
      height: 64,
      display: "flex",
      alignItems: "center",
      marginBottom: 8,
    },
    leftToolbar: {
      flexGrow: 1,
    },
    externalLink: {
      paddingTop: 8,
      opacity: 0.54,
    },
  };
});

const MainLayoutComponent = (props: { children: any }) => {
  const { children } = props;
  const { classes } = useAppStyles();
  const { user, isLoading, error } = useAuth0();
  const [collapsed, setCollapsed] = React.useState(isMobile);
  const _drawerWidth = collapsed ? drawerWidthCollapsed : drawerWidth;

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };

  if (isLoading || error || !user) {
    return (
      <main>
        <div
          className={classes.pageContent}
          style={{ marginLeft: `${_drawerWidth}px` }}
        >
          {children}
        </div>
      </main>
    );
  }

  return (
    <React.Fragment>
      <main className={classes.content}>
        <AppBar className={classes.appBar} position="static" elevation={0}>
          <Toolbar>
            <Box className={classes.leftToolbar}>
              {/* Placeholder for Left tool bar */}
              <Stack
                direction="row"
                spacing={1}
                className={classes.logoContainer}
              >
                <IconButton onClick={toggleDrawer}>
                  <MenuIcon />
                </IconButton>
                <Link to="/">
                  <Logo />
                </Link>
              </Stack>
            </Box>
            <IconButton
              sx={{
                border: "0px",
                paddingTop: "10px",
                width: "40px",
                height: "40px",
              }}
            >
              <ExternalLink
                href="https://support.turntide.com/hc/en-us/articles/17553033726100-Turntide-Admin-Turntide-Technician-What-s-New"
                color="#000000"
                target="_blank"
                rel="noopener"
                className={classes.externalLink}
              >
                <Tooltip title="What's New?" arrow>
                  <AnnouncementOutlinedIcon />
                </Tooltip>
              </ExternalLink>
            </IconButton>
            <IconButton
              sx={{
                border: "0px",
                width: "40px",
                height: "40px",
                marginRight: 1,
              }}
            >
              <ExternalLink
                href="https://support.turntide.com/hc/en-us/sections/17570498801300-Turntide-Admin"
                color="#000000"
                target="_blank"
                rel="noopener"
                className={classes.externalLink}
              >
                <Tooltip arrow title="Turntide Knowledge Base">
                  <HelpOutlineIcon />
                </Tooltip>
              </ExternalLink>
            </IconButton>
            <UserMenu />
          </Toolbar>
        </AppBar>
        <SideBar width={_drawerWidth} collapsed={collapsed} />
        <div
          className={classes.pageContent}
          style={{ marginLeft: `${_drawerWidth}px` }}
        >
          {children}
        </div>
      </main>
    </React.Fragment>
  );
};

export const MainLayout = MainLayoutComponent;
