import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import { getOrganizationDetails, getOrganizationUsers } from "../actions/orgs";
import * as _ from "lodash";
interface OrgDetailsState {
  data: {
    org: any;
    users: any[];
  };
  filtered: { sites: any[]; users: any[] };
  loading: boolean;
  error?: string;
}

const initialState: OrgDetailsState = {
  data: { org: {}, users: [] },
  loading: false,
  error: undefined,
  filtered: { sites: [], users: [] },
};

const slice = createSlice({
  name: "orgDetails",
  initialState,
  reducers: {
    filterOrgSites: (state, action) => {
      let filtered = _.cloneDeep(state?.data?.org?.sites);
      if (action.payload?.phrases && action.payload?.phrases.length) {
        filtered = filtered.filter((s: any) => {
          return action.payload.phrases.some(
            (p: string) =>
              (s.name &&
                s.name.toLocaleLowerCase().indexOf(p.toLocaleLowerCase()) >
                  -1) ||
              (s.address &&
                s.address.toLocaleLowerCase().indexOf(p.toLocaleLowerCase()) >
                  -1)
          );
        });
      }
      if (action.payload.siteIds && action.payload.siteIds.length) {
        filtered = filtered.filter((s: any) => {
          return action.payload.siteIds.indexOf(s.site_id) > -1;
        });
      }
      state.filtered.sites = filtered;
    },
    filterOrgUsers: (state, action) => {
      let filtered = _.cloneDeep(state.data.users);
      if (action.payload.phrases && action.payload.phrases.length) {
        filtered = filtered.filter((s: any) => {
          return action.payload.phrases.some(
            (p: string) =>
              s.email.toLocaleLowerCase().indexOf(p.toLocaleLowerCase()) > -1 ||
              s.first_name.toLocaleLowerCase().indexOf(p.toLocaleLowerCase()) >
                -1 ||
              s.last_name.toLocaleLowerCase().indexOf(p.toLocaleLowerCase()) >
                -1
          );
        });
      }
      if (action.payload.userIds && action.payload.userIds.length) {
        filtered = filtered.filter((s: any) => {
          return action.payload.userIds.indexOf(s.user_id) > -1;
        });
      }
      state.filtered.users = filtered;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getOrganizationDetails.pending, (state) => {
      state.loading = true;
      if (state.data) {
        state.data.org = undefined;
        state.filtered.sites = [];
      }
    });
    builder.addCase(getOrganizationDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data.org = action.payload;
      state.filtered.sites = action.payload?.sites;
    });
    builder.addCase(getOrganizationDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
    builder.addCase(getOrganizationUsers.pending, (state) => {
      state.loading = true;
      if (state.data) {
        state.data.users = [];
        state.filtered.users = [];
      }
    });
    builder.addCase(getOrganizationUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.data.users = action.payload;
      state.filtered.users = action.payload;
    });
    builder.addCase(getOrganizationUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export const { filterOrgSites, filterOrgUsers } = slice.actions;
export default slice.reducer;
