import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

interface MenuItemOption {
  label: string;
  value: string;
}

interface ActionsMenuProps {
  options: MenuItemOption[];
  params: GridRenderCellParams<any>;
  onClick: (newValue: string, row: any) => void;
  onClose?: () => void;
}

const ActionsMenu = (props: ActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { options, params, onClick, onClose } = props;

  const handleHotdogClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    onClose && onClose();
  };

  const handleMenuItemClick = (event: any, option: MenuItemOption) => {
    onClick(option.value, params.row);
    handleClose(event);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleHotdogClick}
      >
        <MoreVertIcon color="action" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option?.value}
            onClick={(event: any) => handleMenuItemClick(event, option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionsMenu;
