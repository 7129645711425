import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ImageGallery from "react-image-gallery";
import { Box, IconButton } from "@mui/material";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import { Constants } from "utils/constants";
import getAttachmentFileExtension from "utils/getAttachmentFileExtension";

const VIDEO_FORMAT_EXT = ["mp4", "webm", "ogv", "mov", "wmv"];

const MediaViewer = (props: {
  open: boolean;
  media: any;
  type?: string;
  onClose?: () => void;
}) => {
  const { open, media, type, onClose } = props;

  /** Check for video type */
  let isVideo = false;
  const ext = getAttachmentFileExtension(media?.original);
  if (ext && VIDEO_FORMAT_EXT.includes(ext)) {
    isVideo = true;
  }
  isVideo =
    isVideo ||
    type === Constants.JOB_DATA_TYPES.VIDEO ||
    type === Constants.JOB_DATA_TYPES.AUDIO;

  const handleOnClose = () => {
    onClose?.();
  };

  const handleDialogClose = (event: any, reason: any) => {
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: "absolute",
          zIndex: 2,
          right: 5,
          top: 4,
          marginRight: 1,
          marginTop: 1,
          backgroundColor: "#ffffffbb",
          "&:hover": {
            color: "#ffffff",
            backgroundColor: "#00000066",
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ overflow: "hidden" }}>
        {isVideo ? (
          <ReactPlayer url={media?.original} controls={true} playing={true} />
        ) : (
          <ImageGallery
            items={[media]}
            showPlayButton={false}
            showNav={false}
            showThumbnails={false}
            onErrorImageURL={"/images/imageNotFound.png"}
          />
        )}
      </Box>
    </Dialog>
  );
};
export default MediaViewer;
