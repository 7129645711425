import React, { useState, useEffect } from "react";
import TTAutoComplete from "components/common/TTAutoComplete/TTAutoComplete";
import { makeStyles } from "tss-react/mui";
import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import { updateFilter } from "redux/reducers/filtersSlice";

const useAppStyles: any = makeStyles<any>()((theme) => {
  return {
    root: {
      "& .MuiInputLabel-outlined": {
        zIndex: 2,
      },
    },
    inputRoot: {
      position: "absolute",
      zIndex: 1,
      background: "#ffffff",
      "& :not(focus)": {
        marginTop: 0,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        backgroundColor: `${theme.palette.primary.main}0a`,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  };
});

const SiteFilter = (props: { onApplyFilter?: () => void }) => {
  const { onApplyFilter } = props;
  const { classes } = useAppStyles();
  const dispatch = useAppDispatch();
  const siteList = useAppSelector((state) => state.sites.data);
  const filters = useAppSelector((state) => state.filters.jobFilters);
  const [siteFilters, setSiteFilters] = useState<any>(false);

  const handleChangeFilter = (filterType: string, value: any) => {
    setSiteFilters(value);
    dispatch(updateFilter({ filterType, value }));
  };

  useEffect(() => {
    if (siteFilters) onApplyFilter?.();
  }, [siteFilters]);

  return (
    <>
      <div>
        {siteList && (
          <TTAutoComplete
            options={siteList}
            label="Search jobs by site or address"
            primaryField="address"
            secondaryField="name"
            primaryKey="site_id"
            limit={3}
            classes={classes}
            value={filters?.site}
            placeHolder="Search"
            filterSelectedOptions
            onChange={(v) => handleChangeFilter("site", v)}
          />
        )}
      </div>
    </>
  );
};

export default SiteFilter;
