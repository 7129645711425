import React, { useState, useEffect } from "react";
import { Box, Typography, TablePagination } from "@mui/material";
import {
  GridColDef,
  GridSortModel,
  GridColumnResizeParams,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridDensity,
  GridRowModesModel,
  GridEventListener,
} from "@mui/x-data-grid-pro";
import { syncColumns } from "components/common/gridview/gridColumns";
import { columnSettings } from "components/common/gridview/gridState";
import {
  validGridState,
  validDensity,
  columnSortGraphQLParams,
  columnFiltersGraphQLParams,
} from "./assetFiltersUtil";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hooks";
import {
  ColumnState,
  FiltersState,
  GridState,
  GridViewState,
  Sort,
} from "utils/types";
import {
  AssetDataGrid,
  defaultSort,
} from "../../assets/components/AssetDataGrid";
import { Backdrop, CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import { Tooltip } from "react-tooltip";
import { Constants } from "utils/constants";

export interface GridViewRef {
  updateCurrentGridState: (
    gridState: GridState,
    initialState: GridViewState
  ) => void;
  setColumnFilterModel: (model: GridFilterModel, definedColumns: any[]) => void;
}

const GridView = (props: {
  columns: GridColDef[];
  defaultColumns: any[];
  definedColumns: any[];
  filters: FiltersState;
  initialColumnStates: ColumnState[];
  loading: boolean;
  pageTitle?: string;
  resetAll: any;
  rows: Array<any> | [];
  setColumns: any;
  setDirtyState: any;
  supportedSortColumns: string[];
  totalCount: number;
  updateFiltersState: any;
  updateGridPropertyState: any;
  updateGridState: any;
  useLoadingBackdrop?: boolean;
  viewState: GridViewState;
  onRef?: (methods: GridViewRef) => void;
  onRowClick?: (
    params: any,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  yOffset?: number;
  processRowUpdate?: (updatedRow: any, originalRow: any) => any;
  onProcessRowUpdateError?: (error: any) => void;
  rowModesModel?: GridRowModesModel;
  onRowModesModelChange?: (newModel: any) => void;
  onRowEditStop?: GridEventListener<"rowEditStop">;
  onMenuItemClick?: (value: string, row: any) => void;
}) => {
  const {
    columns,
    defaultColumns,
    definedColumns,
    filters,
    initialColumnStates,
    loading,
    pageTitle,
    resetAll,
    rows,
    setColumns,
    setDirtyState,
    supportedSortColumns,
    totalCount,
    updateFiltersState,
    updateGridPropertyState,
    updateGridState,
    useLoadingBackdrop = false,
    viewState,
    onRef,
    onRowClick,
    yOffset,
    processRowUpdate,
    onProcessRowUpdateError,
    rowModesModel,
    onRowModesModelChange,
    onRowEditStop,
    onMenuItemClick,
  } = props;

  const dispatch = useAppDispatch();
  const grid = viewState.grid;

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: grid.sortField,
      sort: grid.sortDirection,
    },
  ]);

  const version = viewState.version;
  if (version !== columnSettings.VERSION) {
    console.log("Version changed. Reset.");
    // TODO: ensure we dispatch for the correct grid
    dispatch(resetAll());
  }

  const isDirty = viewState.isDirty;
  const setDirty = (newValue: boolean) => {
    // TODO: ensure we dispatch for the correct grid
    dispatch(setDirtyState(newValue));
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const updateColumnVisibilityModel = () => {
    const model: { [key: string]: boolean } = {};
    columnStates.forEach((col) => {
      if (
        typeof col.hide === "boolean" &&
        col.hide === true &&
        (typeof col.hideable !== "boolean" || col.hideable !== false)
      ) {
        model[col.field] = false;
      }
    });
    setColumnVisibilityModel(model);
  };

  const columnFilterModel = useAppSelector((state) => {
    return Array.isArray(filters?.columnFilters?.items)
      ? filters?.columnFilters
      : { items: [] };
  });
  const setColumnFilterModel = (
    model: GridFilterModel,
    definedColumns: any[]
  ) => {
    const oldFiltersStr = columnFiltersGraphQLParams(
      columnFilterModel,
      definedColumns
    );
    const newFiltersStr = columnFiltersGraphQLParams(model, definedColumns);
    if (typeof model?.logicOperator === "string") {
      model.logicOperator = (
        model.logicOperator as string
      ).toLowerCase() as GridLogicOperator;
    }
    dispatch(
      updateFiltersState({ property: "columnFilters", value: model as any })
    );
    // Reset page number if column filter changed.
    if (newFiltersStr !== oldFiltersStr) {
      dispatch(updateGridPropertyState({ property: "pageNumber", value: 0 }));
    }
  };

  const densityState = useAppSelector((state) => {
    return validDensity(grid?.density!);
  });
  const setDensityState = (density: GridDensity) => {
    const oldDensity = grid.density;
    const newDensity = validDensity(density);
    if (newDensity !== oldDensity) {
      dispatch(
        updateGridPropertyState({ property: "density", value: newDensity })
      );
    }
  };

  const updateGridSortState = (sortColumn: string, sortDirection: Sort) => {
    const oldSorStr = columnSortGraphQLParams(
      grid?.sortField,
      grid.sortDirection,
      definedColumns,
      supportedSortColumns
    );
    const newSorStr = columnSortGraphQLParams(
      sortColumn,
      sortDirection,
      definedColumns,
      supportedSortColumns
    );
    dispatch(
      updateGridPropertyState({ property: "sortField", value: sortColumn })
    );
    dispatch(
      updateGridPropertyState({
        property: "sortDirection",
        value: sortDirection,
      })
    );
    // Reset page number if sort changed.
    if (newSorStr !== oldSorStr) {
      dispatch(updateGridPropertyState({ property: "pageNumber", value: 0 }));
    }
  };

  const updateCurrentGridState = (
    gridState: GridState,
    initialState: GridViewState
  ) => {
    setDensityIsChanging(true);
    dispatch(
      updateGridState(validGridState(gridState, definedColumns, initialState))
    );
    debounceChangingDensityFlag(false);
  };
  const debounceChangingDensityFlag = debounce((flag: boolean) => {
    setDensityIsChanging(flag);
  }, 200);

  const [densityIsChanging, setDensityIsChanging] = useState<boolean>(false);
  const handleStateChange = debounce((gridState: any) => {
    if (!densityIsChanging && gridState?.density?.value !== grid?.density) {
      setDensityState(gridState?.density?.value);
    }
  }, 200);

  const columnStates: Array<ColumnState> =
    grid.columnStates && Array.isArray(grid.columnStates)
      ? [...grid.columnStates]
      : initialColumnStates;
  const setColumnStates = (columnStates: any[]) => {
    dispatch(
      updateGridPropertyState({ property: "columnStates", value: columnStates })
    );
  };

  const updateColumns = (columns: any[]) => {
    dispatch(setColumns(columns));
  };

  const initialColumnOrderedFields = (() => {
    return [...columnStates]
      .sort((a, b) => a.index - b.index)
      .map((column) => column.field);
  })();
  const [columnOrderedFields, setColumnOrderedFields] = useState(
    initialColumnOrderedFields
  );

  const handleColumnVisibilityModelChange = (
    model: GridColumnVisibilityModel
  ) => {
    setColumnVisibilityModel(model);
    if (Array.isArray(columnStates)) {
      const updatedColumnStates = columnStates.map((columnState) => {
        if (typeof model[columnState.field] === "boolean") {
          return {
            ...columnState,
            hide: !model[columnState.field],
          };
        } else {
          return {
            ...columnState,
            hide: false,
          };
        }
      });
      setColumnStates(updatedColumnStates);
    }
  };

  const handleColumnFilterModelChange = (model: GridFilterModel) => {
    setColumnFilterModel(model, definedColumns);
  };

  const resetColumnSort = () => {
    updateGridSortState(
      defaultSort[0]?.sort ?? "",
      defaultSort[0]?.field === "asc" ? Sort.ASC : Sort.DESC
    );
  };

  const handleSortChange = (sortColumns?: GridSortModel) => {
    if (sortColumns && Array.isArray(sortColumns) && sortColumns.length > 0) {
      const sortColumn = sortColumns[0];
      const { field, sort } = sortColumn;
      if (field && (sort === "asc" || sort === "desc")) {
        updateGridSortState(field, sort === "asc" ? Sort.ASC : Sort.DESC);
        return;
      }
    }
    resetColumnSort();
  };

  const onSortColumnChange = (sortColumns: GridSortModel) => {
    handleSortChange(sortColumns);
    setSortModel(sortColumns);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    dispatch(
      updateGridPropertyState({ property: "rowsPerPage", value: _rowsPerPage })
    );
    dispatch(updateGridPropertyState({ property: "pageNumber", value: 0 }));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      updateGridPropertyState({ property: "pageNumber", value: newPage })
    );
  };

  const handleColumnOrderChange = (column: GridColumnOrderChangeParams) => {
    if (column?.column?.field && column?.targetIndex !== undefined) {
      let currentStates: Array<ColumnState> = [...columnStates];
      const targetItemIndex = currentStates.findIndex(
        (item) => item.field === column.column.field
      );
      if (targetItemIndex !== -1) {
        const removedItem = currentStates.splice(targetItemIndex, 1)[0];
        currentStates.splice(column.targetIndex, 0, removedItem);
        const updatedColumnStates = currentStates.map((item, index) => ({
          ...item,
          index,
        }));
        setColumnStates(updatedColumnStates);
        let _updatedColumnStates = [...updatedColumnStates];
        const updatedColumnOrderedFields = _updatedColumnStates
          .sort((a, b) => a.index - b.index)
          .map((column) => column.field);

        setColumnOrderedFields(updatedColumnOrderedFields);
      }
    }
  };

  const handleColumnResize = debounce((column: GridColumnResizeParams) => {
    if (column?.colDef?.field && column?.colDef?.width) {
      const currentStates: Array<ColumnState> = [...columnStates];
      const foundIndex = currentStates.findIndex(
        (item) => item.field === column.colDef.field
      );
      const updatedState = Math.max(
        column.colDef.width,
        columnSettings.COLUMN_MIN_WIDTH
      );
      const updatedStates =
        foundIndex !== -1
          ? [
              ...currentStates.slice(0, foundIndex),
              { ...currentStates[foundIndex], width: updatedState },
              ...currentStates.slice(foundIndex + 1),
            ]
          : [
              ...currentStates,
              {
                field: column.colDef.field,
                hide: false,
                width: updatedState,
                index: -1,
              },
            ];
      setColumnStates(updatedStates);
    }
  }, 300);

  useEffect(() => {
    if (onRef) {
      onRef({
        updateCurrentGridState,
        setColumnFilterModel,
      });
    }
  }, []);

  useEffect(() => {
    setDirty(false);
    setSortModel([
      {
        field: grid?.sortField,
        sort: grid?.sortDirection,
      },
    ]);
    updateColumnVisibilityModel();
    updateColumns(columnStates);
  }, [grid, isDirty]);

  const syncedColumns = syncColumns(columns, defaultColumns, onMenuItemClick);

  return (
    <>
      {pageTitle && (
        <Typography variant="h4" gutterBottom>
          {pageTitle}
        </Typography>
      )}
      {!useLoadingBackdrop || (rows && rows.length > 0) ? (
        <>
          <Box
            style={{
              width: "100%",
            }}
          >
            <AssetDataGrid
              columns={syncedColumns}
              loading={(loading && !useLoadingBackdrop) || false}
              assets={rows || []}
              sortingOrder={["asc", "desc"]}
              sortModel={sortModel}
              columnVisibilityModel={columnVisibilityModel}
              filterModel={columnFilterModel}
              densityState={densityState}
              onColumnResize={handleColumnResize}
              columnOrderedFields={columnOrderedFields}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
              onFilterModelChange={handleColumnFilterModelChange}
              onStateChange={handleStateChange}
              onSortModelChange={onSortColumnChange}
              onRowClick={onRowClick}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={onProcessRowUpdateError}
              rowModesModel={rowModesModel}
              onRowModesModelChange={onRowModesModelChange}
              onRowEditStop={onRowEditStop}
              yOffset={yOffset || 0 - Constants.TABLE_PAGINATION_HEIGHT}
            />
          </Box>
          <TablePagination
            component="div"
            count={totalCount || 0}
            page={!totalCount ? 0 : grid?.pageNumber || 0}
            rowsPerPage={grid?.rowsPerPage || 50}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ width: "100%" }}
            slotProps={{
              select: { inputProps: { id: "assets-pagination" } },
            }}
          />
          <Tooltip
            id="cell-tooltip"
            place="left"
            style={{ maxWidth: "250px", zIndex: 999 }}
          />
        </>
      ) : (
        <Box sx={{ height: "100%" }}>No item matched your search</Box>
      )}
      {useLoadingBackdrop && loading && (
        <Backdrop
          open={loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

export default GridView;
