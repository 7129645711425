const DecimalIncrementValue = (input: string | number) => {
  /**
   * Calculates the smallest increment value that can be added to the
   * given decimal number without changing its value.
   */
  const splitValue = input.toString().split(".");
  return splitValue[1]?.length
    ? `.${[...Array(splitValue[1]?.length - 1).fill(0), 1].join("")}`
    : 1;
};

export default DecimalIncrementValue;
