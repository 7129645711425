import { gql, useMutation } from "@apollo/client";
import { UpdateNetSuiteRelationshipInput } from "generated-gql-types/graphql";

const UPDATE_NETSUITE_RELATIONSHIP_MUTATION = gql`
  mutation UpdateNetSuiteRelationship(
    $input: UpdateNetSuiteRelationshipInput!
  ) {
    updateNetSuiteRelationship(input: $input) {
      success
      relationship {
        id
        soldTo
        soldToSalesforceId
        techAdminOrgId
        techAdminOrgName
      }
    }
  }
`;

export function useUpdateNetSuiteRelationship() {
  const [mutate, updateResult] = useMutation(
    UPDATE_NETSUITE_RELATIONSHIP_MUTATION
  );
  const updateNetSuiteRelationship = (input: UpdateNetSuiteRelationshipInput) =>
    mutate({ variables: { input: input } });
  return { updateNetSuiteRelationship, updateResult };
}
