import { TextField, Autocomplete } from "@mui/material";
import { FilterItem } from "utils/types";
import { useAssetsStyles } from "./styles";

const OrgsFilter = (props: {
  orgsAutocompleteOptions: FilterItem[];
  autocompleteValue: any[];
  onChange?: (value: any[]) => void;
}) => {
  const { orgsAutocompleteOptions, autocompleteValue, onChange } = props;
  const { classes } = useAssetsStyles();

  return (
    <>
      <Autocomplete
        multiple
        autoComplete={true}
        options={orgsAutocompleteOptions}
        onChange={(e, value) => {
          onChange?.(value);
        }}
        value={autocompleteValue}
        size="small"
        getOptionLabel={(option: any) => option.organization_name}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            className={classes.filterField}
            label="Customer"
            style={{ width: 240 }}
            inputProps={{
              ...params.inputProps,
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
            }}
            placeholder="Search by customer..."
          />
        )}
        className={classes.autocomplete}
        renderTags={() => null}
        freeSolo={true}
        forcePopupIcon
        filterSelectedOptions={true}
      />
    </>
  );
};

export default OrgsFilter;
