import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const MCAlertInfo = (props: { value: any }) => {
  const { value } = props;

  return (
    <TableContainer>
      <Table aria-label="mcalert table">
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" sx={{ verticalAlign: "top" }}>
              <Typography fontWeight="bold">Offline</Typography>
            </TableCell>
            <TableCell component="th" scope="row" sx={{ verticalAlign: "top" }}>
              <Typography>
                {typeof value?.offline === "boolean"
                  ? value?.offline.toString()
                  : value?.offline}
              </Typography>
            </TableCell>
          </TableRow>

          {value?.faults && value?.faults.length > 0 && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ verticalAlign: "top" }}
                colSpan={2}
              >
                <Typography fontWeight="bold">Faults</Typography>
                {value.faults.map((fault: string, index: number) => (
                  <Typography key={index} m={3} color="error.main">
                    {
                      <ErrorOutlineIcon
                        color="error"
                        sx={{ verticalAlign: "middle" }}
                      />
                    }{" "}
                    {fault}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
          )}
          {value?.warnings && value?.warnings.length > 0 && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ verticalAlign: "top" }}
                colSpan={2}
              >
                <Typography fontWeight="bold">Warnings</Typography>
                {value.warnings.map((warning: string, index: string) => (
                  <Typography key={index} m={3} color="warning.main">
                    {
                      <WarningAmberIcon
                        color="warning"
                        sx={{ verticalAlign: "middle" }}
                      />
                    }{" "}
                    {warning}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MCAlertInfo;
