import React, { useState, useMemo } from "react";
import DataGrid, { SortColumn } from "react-data-grid";
import { Box } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "react-tooltip";
import { groupBy as rowGrouper, chunk } from "lodash";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";

interface OrganizationSiteRow {
  site_id: number;
  name: string;
  address: string;
}
function rowKeyGetter(row: OrganizationSiteRow) {
  return row.site_id;
}

const OrganizationSitesDataGrid = (props: {
  rows: any;
  height?: string;
  dynamicPaging?: boolean;
  onViewSite?: (site: any) => void;
}) => {
  const { isGreensightAdmin, hasAccess } = useAuthData();
  const { rows, height, onViewSite, dynamicPaging = false } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const sortedRows = useMemo((): readonly OrganizationSiteRow[] => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const _a =
          a[sort.columnKey] === undefined ||
          a[sort.columnKey] === null ||
          a[sort.columnKey] === ""
            ? undefined
            : a[sort.columnKey];
        const _b =
          b[sort.columnKey] === undefined ||
          b[sort.columnKey] === null ||
          b[sort.columnKey] === ""
            ? undefined
            : b[sort.columnKey];
        if (_a === undefined && _b === undefined) {
          return 0;
        } else if (_a === undefined) {
          return 1;
        } else if (_b === undefined) {
          return -1;
        }

        const comparator = (a: any, b: any) => {
          return a?.[sort.columnKey]?.localeCompare(b?.[sort.columnKey]);
        };
        let compResult = comparator(a, b);
        if (compResult !== undefined && compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  let chunks: Array<Array<any>> = chunk(sortedRows, rowsPerPage);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    chunks = chunk(rows, _rowsPerPage);
    setPage(0);
  };

  const columns: readonly any[] = [
    {
      key: "name",
      name: "Site Name",
      width: 250,
      maxWidth: 500,
      minWidth: 100,
    },
    {
      key: "address",
      name: "Address",
    },
  ];

  const allowModifySite = useMemo(() => {
    return isGreensightAdmin || hasAccess(Constants.ACTIONS.MANAGE_SITES);
  }, [isGreensightAdmin]);

  return (
    <Box
      style={{
        width: "100%",
      }}
      className="sites-data-grid"
    >
      <DataGrid
        style={{
          maxHeight: height ? height : "calc(100vh - 430px)",
          height: Math.min(chunks[page]?.length * 6 + 10, 80) + "vh",
          width: "100%",
          borderRadius: 4,
        }}
        className="rdg-light"
        columns={columns}
        rows={chunks[page]}
        onRowClick={allowModifySite ? (row) => onViewSite?.(row) : null}
        rowKeyGetter={rowKeyGetter}
        rowGrouper={rowGrouper}
        defaultColumnOptions={{ resizable: true, sortable: true }}
        rowHeight={52}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
      {(dynamicPaging && rows.length > 50) || !dynamicPaging ? (
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
      <Tooltip
        id="cell-tooltip"
        place="left"
        style={{ maxWidth: "250px", zIndex: 999 }}
      />
    </Box>
  );
};
export default OrganizationSitesDataGrid;
