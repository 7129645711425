import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import { Clear as ClearIcon } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { updateFilter } from "redux/reducers/filtersSlice";
import TTDatePicker from "components/common/TTDatePicker";

const MenuItems = [
  "Less than 30 days",
  "Less than 6 months",
  "Less than 1 year",
  "Custom Range",
];
const DateFilter = (props: {
  title: string;
  dateType: string;
  onApplyFilter?: () => void;
}) => {
  const { title, dateType, onApplyFilter } = props;
  const filters = useAppSelector((state) => state.filters.jobFilters);
  const selectedIndex = filters?.[dateType]?.option;
  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = React.useState<any>(-1);
  const [invalidStartDate, setInvalidStartDate] = React.useState<any>(false);
  const [invalidEndDate, seInvalidEndDate] = React.useState<any>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (currentIndex > -1) onApplyFilter?.();
  }, [currentIndex]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (applyFilter: Boolean, cIndex?: number | Boolean) => {
    if (applyFilter) {
      setCurrentIndex(cIndex);
    }
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    handleChangeFilter(index);
  };

  const isDateSelected = () => {
    return filters?.[dateType]?.range?.from || filters?.[dateType]?.range?.to;
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    seInvalidEndDate(false);
    setInvalidStartDate(false);
    if (
      filters?.[dateType]?.range.to &&
      moment(filters?.[dateType]?.range.to).isBefore(date?.format("L"))
    ) {
      setInvalidStartDate("Start date cannot be after the End Date");
    }

    dispatch(
      updateFilter({
        filterType: dateType,
        value: {
          option: filters?.[dateType]?.option,
          range: {
            from: date?.format("L"),
            to: filters?.[dateType]?.range.to,
          },
        },
      })
    );
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    seInvalidEndDate(false);
    setInvalidStartDate(false);
    if (
      filters?.[dateType]?.range.from &&
      moment(filters?.[dateType]?.range.from).isAfter(date?.format("L"))
    ) {
      seInvalidEndDate("End date cannot be before the Start Date");
    }

    dispatch(
      updateFilter({
        filterType: dateType,
        value: {
          option: filters?.[dateType]?.option,
          range: {
            from: filters?.[dateType]?.range.from,
            to: date?.format("L"),
          },
        },
      })
    );
  };

  const handleChangeFilter = (index: number) => {
    seInvalidEndDate(false);
    setInvalidStartDate(false);
    switch (index) {
      case 0: {
        // Less than 30 days
        dispatch(
          updateFilter({
            filterType: dateType,
            value: {
              option: index,
              range: {
                from: dayjs().subtract(30, "days").format("MM/DD/YY"),
                to: null,
              },
            },
          })
        );
        handleClose(selectedIndex !== index, index);
        break;
      }
      case 1: {
        // Less than 6 months
        dispatch(
          updateFilter({
            filterType: dateType,
            value: {
              option: index,
              range: {
                from: dayjs().subtract(6, "months").format("MM/DD/YY"),
                to: null,
              },
            },
          })
        );
        handleClose(selectedIndex !== index, index);
        break;
      }
      case 2: {
        // Less than 1 year"
        dispatch(
          updateFilter({
            filterType: dateType,
            value: {
              option: index,
              range: {
                from: dayjs().subtract(1, "years").format("MM/DD/YY"),
                to: null,
              },
            },
          })
        );
        handleClose(selectedIndex !== index, index);
        break;
      }
      case 3: {
        // Custom range. Range will be set by date selector
        dispatch(
          updateFilter({
            filterType: dateType,
            value: { option: index, range: { from: null, to: null } },
          })
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleResetFilter = () => {
    dispatch(
      updateFilter({
        filterType: dateType,
        value: { option: -1, range: { from: null, to: null } },
      })
    );
    handleClose(true, 0);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Button
        id="basic-button"
        variant="outlined"
        size="medium"
        sx={{
          borderColor: "#c4c4c4",
          padding: "7px",
          color: "#6f6f6f",
          textTransform: "none",
        }}
        endIcon={<ArrowDropDownIcon />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {title}
        {selectedIndex > -1 && isDateSelected() ? (
          <ClearIcon
            sx={{
              ml: "7px",
              mr: "-7px",
              padding: "1px",
              bgcolor: "#f1f1f1",
              borderRadius: "50%",
              "&:hover": { bgcolor: "#81B5EA", color: "#ffffff" },
            }}
            aria-label="delete"
            fontSize="small"
            onClick={(e) => {
              e.stopPropagation();
              handleResetFilter();
            }}
          />
        ) : (
          <i style={{ padding: "0 11px" }}></i>
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(false, -1)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {MenuItems.map((item, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}
          >
            {item}
          </MenuItem>
        ))}

        {selectedIndex === 3 && (
          <>
            <MenuItem>
              <TTDatePicker
                label="Start Date"
                value={dayjs(filters?.[dateType]?.range?.from)}
                onChange={handleStartDateChange}
                disableFuture
                errorMessage={invalidStartDate}
              />
            </MenuItem>
            <MenuItem>
              <TTDatePicker
                label="End Date"
                value={dayjs(filters?.[dateType]?.range?.to)}
                onChange={handleEndDateChange}
                disableFuture
                errorMessage={invalidEndDate}
              />
            </MenuItem>
            <ListItem>
              <Button
                variant="contained"
                fullWidth
                disabled={invalidEndDate || invalidStartDate}
                onClick={() => {
                  handleClose(true, currentIndex ? currentIndex + 4 : 4);
                }}
              >
                APPLY
              </Button>
            </ListItem>
          </>
        )}
      </Menu>
    </Box>
  );
};
export default DateFilter;
