import { createSlice } from "@reduxjs/toolkit";
import { AssignOrgToOrgItem } from "models/organizations/assignorgtoorgitem";

interface AssignOrgsToOrgState {
  open: boolean;
  availableOrgs: AssignOrgToOrgItem[];
  selectedOrgs: AssignOrgToOrgItem[];
}

const initialState: AssignOrgsToOrgState = {
  open: false,
  availableOrgs: [],
  selectedOrgs: [],
};

const slice = createSlice({
  name: "assignOrgsToOrg",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setAvailableOrgs(state, action) {
      state.availableOrgs = action.payload;
    },
    setSelectedOrgs(state, action) {
      state.selectedOrgs = action.payload;
    },
  },
});
export const { setOpen, setAvailableOrgs, setSelectedOrgs } = slice.actions;
export default slice.reducer;
