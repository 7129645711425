/** TTAutoComplete ues MUI AutoComplete to implement
 *  TTAdmin specific AutoComplete features with custom interactions
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { take } from "lodash";
import { isMobile } from "react-device-detect";

const INPUT_WIDTH = isMobile ? 240 : 450;

const TTAutoComplete = (props: {
  options: Array<any>;
  label: string;
  primaryField: string;
  primaryKey: string;
  placeHolder?: string;
  onChange?: (values: Array<any>) => void;
  secondaryField?: string;
  value?: Array<any>;
  limit?: number;
  classes?: any;
  size?: "medium" | "small" | undefined;
  filterSelectedOptions?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  clearable?: boolean;
}) => {
  const {
    options,
    primaryField,
    primaryKey,
    secondaryField,
    placeHolder,
    onChange,
    value = [],
    label,
    limit = 5,
    classes,
    size,
    filterSelectedOptions = false,
    multiple = true,
    disabled = false,
    readOnly = false,
    clearable = true,
  } = props;

  const [focused, setFocused] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => {
    setFocused(false);
    setShowAll(false);
  };
  const onTagsChange = (event: object, values: Array<any>) => {
    onChange?.(values);
  };

  const handleDelete = (item: any) => {
    const _filtered = value?.filter(
      (obj) => obj[primaryKey] !== item?.[primaryKey]
    );
    onChange?.(_filtered || []);
  };

  return (
    <Autocomplete
      multiple={multiple}
      size={size ? size : "small"}
      id="tt-auto-complete"
      sx={{ width: INPUT_WIDTH }}
      options={options}
      value={multiple ? value : value[0]}
      classes={classes}
      disabled={disabled}
      readOnly={readOnly}
      disableClearable={!clearable}
      getOptionLabel={(option: any) =>
        secondaryField
          ? `${option[primaryField]} ${option[secondaryField]}`
          : option[primaryField]
      }
      isOptionEqualToValue={(option, value) =>
        option[primaryKey].toString() === value[primaryKey].toString()
      }
      onChange={onTagsChange}
      renderTags={(tagValue: Array<any>, getTagProps) => {
        return focused && tagValue?.length ? (
          showAll ? (
            <>
              {tagValue.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag?.[primaryField]}
                  size="small"
                  onDelete={() => handleDelete(tag)}
                />
              ))}
            </>
          ) : (
            <>
              {take(tagValue, limit).map((tag, index) => (
                <Chip
                  key={index}
                  label={tag?.[primaryField]}
                  size="small"
                  onDelete={() => handleDelete(tag)}
                />
              ))}

              {tagValue.length > limit && (
                <Chip
                  label={`+${tagValue?.length - limit}`}
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => setShowAll(true)}
                />
              )}
            </>
          )
        ) : null;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            value?.length > 0 && multiple
              ? `${label} (${value?.length})`
              : label
          }
          placeholder={placeHolder || label}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={{ fontSize: 14 }}
        />
      )}
      renderOption={(props, option: any) => (
        <Box component="li" {...props} key={option[primaryKey]}>
          <Grid
            container
            direction="column"
            justifyContent="left"
            alignItems="start"
          >
            <Typography
              noWrap
              sx={{
                fontSize: 14,
                textOverflow: "ellipsis",
                width: INPUT_WIDTH,
              }}
            >
              {option[primaryField]}
            </Typography>
            {secondaryField && (
              <Typography
                noWrap
                sx={{
                  fontSize: 14,
                  textOverflow: "ellipsis",
                  width: INPUT_WIDTH,
                }}
              >
                {option[secondaryField]}
              </Typography>
            )}
          </Grid>
        </Box>
      )}
      filterSelectedOptions={filterSelectedOptions}
    />
  );
};

export default TTAutoComplete;
