import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import { getAllOrganizations } from "../actions/orgs";
import * as _ from "lodash";
interface OrgsListState {
  data: {
    orgs: Array<any>;
    filteredOrgs: Array<any>;
    filterTagsValue: Array<any>;
  };
  loading: boolean;
  error?: string;
}

const initialState: OrgsListState = {
  data: { orgs: [], filteredOrgs: [], filterTagsValue: [] },
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "orgsList",
  initialState,
  reducers: {
    filterOrgsList: (state, action) => {
      let filtered = _.cloneDeep(state.data.orgs);
      if (action.payload.phrases && action.payload.phrases.length) {
        filtered = filtered.filter((o) => {
          return action.payload.phrases.some(
            (p: string) =>
              o.organization_name.toLowerCase().indexOf(p.toLocaleLowerCase()) >
              -1
          );
        });
      }
      if (action.payload.orgIds && action.payload.orgIds.length) {
        filtered = filtered.filter((o) => {
          return action.payload.orgIds.indexOf(o.organization_id) > -1;
        });
      }
      if (action.payload.types.length) {
        filtered = filtered.filter((o) => {
          return o.types.some((t: string) => action.payload.types.includes(t));
        });
      }
      state.data.filteredOrgs = filtered;
    },
    updateFilterTagsValue: (state, action) => {
      state.data.filterTagsValue = action.payload;
    },
    clearOrgsListData(state) {
      state.data.orgs = [];
      state.data.filteredOrgs = [];
      state.data.filterTagsValue = [];
    },
  },
  extraReducers(builder): void {
    builder.addCase(getAllOrganizations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllOrganizations.fulfilled, (state, action) => {
      state.loading = false;
      state.data.orgs = action.payload.orgs;
      state.data.filteredOrgs =
        state.data?.filterTagsValue?.length > 0
          ? state.data.filteredOrgs
          : action.payload.orgs;
    });
    builder.addCase(getAllOrganizations.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});
export const { filterOrgsList, updateFilterTagsValue, clearOrgsListData } =
  slice.actions;
export default slice.reducer;
