import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FilterView from "../common/gridview/filtersView";
import GridView, { GridViewRef } from "../common/gridview/gridView";
import { useQuery } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import { usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from "react-router";
import { FilterItem, FiltersState } from "utils/types";
import { getSavedViews } from "redux/actions/savedviews";
import { getAllOrganizations } from "redux/actions/orgs";
import {
  getInventoryQuery,
  getInventoryQueryParams,
  inventoryRowsFromQueryData,
} from "./inventoryDataUtil";
import { validColumnFilterModel } from "../common/gridview/assetFiltersUtil";
import { debounce } from "lodash";
import { useAuthData } from "utils/hooks/useAuthData";
import {
  defaultColumns,
  definedColumns,
  initialColumnStates,
  supportedSortColumns,
} from "./inventoryColumns";
import {
  initialState,
  resetInventoryGrid,
  resetColumnsState,
  resetFiltersState,
  resetGridState,
  setColumns,
  setDirtyState,
  setView,
  updateFiltersState,
  updateGridPropertyState,
  updateGridState,
} from "redux/reducers/inventoryViewSlice";
import { Constants } from "utils/constants";
import FailureDateDialog from "./failureDateDialog";
import { GridRowModel } from "@mui/x-data-grid-pro";
import Measure, { BoundingRect } from "react-measure";

const emptyArray: any[] = [];

const Inventory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getRawIdToken, hasAccess, ready } = useAuthData();
  const { promiseInProgress } = usePromiseTracker();
  const viewState = useAppSelector((state) => state.inventoryView);
  const grid = useAppSelector((state) => state.inventoryView.grid);
  const filters = useAppSelector((state) => state.inventoryView.filters);
  const size: number = grid?.rowsPerPage || 50;
  const [page, setPage] = useState(grid?.pageNumber ?? 0);
  const [failureDateOpen, setFailureDateOpen] = useState<boolean>(false);

  const searchField = useAppSelector(
    (state) => state.inventoryView?.filters?.searchField
  );

  const selectedOrgFilterItems = useAppSelector((state) => {
    const orgs = state.inventoryView?.filters?.orgs;
    return Array.isArray(orgs) ? (orgs as FilterItem[]) : emptyArray;
  });

  const columns = useAppSelector((state) => {
    return Array.isArray(state.inventoryView?.columns)
      ? state.inventoryView.columns
      : [];
  });

  const [columnFilterModel, setColumnFilterModel] = useState(
    Array.isArray(filters?.columnFilters?.items) ? filters.columnFilters : []
  );

  useEffect(() => {
    debounceColumnFilterModel(
      Array.isArray(filters?.columnFilters?.items)
        ? filters.columnFilters
        : { items: [] }
    );
  }, [filters?.columnFilters]);

  const debounceColumnFilterModel = debounce((model: any) => {
    setColumnFilterModel(model);
  }, 300);

  const [inventoryItem, setInventoryItem] = useState<GridRowModel>();
  const handleFailureDateDialogOpen = (value: string, row?: GridRowModel) => {
    if (row && value === "failureDt") {
      setInventoryItem(row);
      setFailureDateOpen(true);
    }
  };

  const { data, loading } = useQuery(
    getInventoryQuery(
      getInventoryQueryParams(
        grid,
        searchField,
        selectedOrgFilterItems,
        columnFilterModel,
        page,
        size
      )
    ),
    { skip: !ready } // attempt to prevent a canceled request during initial page load or refresh
  );
  const inventory: any[] = inventoryRowsFromQueryData(data);

  const [totalCount, setTotalCount] = useState(
    data?.inventory?.totalCount || 0
  );
  useEffect(() => {
    if (loading === false) {
      if (
        data?.inventory?.totalCount === -1 &&
        data?.inventory?.edges?.length < size
      ) {
        setTotalCount(page * size + data?.inventory?.edges?.length);
      } else {
        setTotalCount(data?.inventory?.totalCount);
      }
    }
  }, [loading, data]);

  const [gridViewRef, setGridViewMethods] = useState<GridViewRef | null>(null);
  const handleFiltersAction = (action: string, data?: any) => {
    if (action === "ViewChanged") {
      if (data?.view_data?.filters) {
        const viewFilters: FiltersState = data.view_data.filters;
        gridViewRef?.setColumnFilterModel?.(
          validColumnFilterModel(viewFilters?.columnFilters, definedColumns),
          definedColumns
        );
      }
      if (data?.view_data?.grid) {
        const viewData: any = data.view_data.grid;
        gridViewRef?.updateCurrentGridState?.(viewData, initialState);
      }
    }
  };

  useEffect(() => {
    if (typeof grid?.pageNumber === "number") {
      setPage(grid?.pageNumber);
    }
  }, [grid?.pageNumber, grid?.rowsPerPage]);

  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          getSavedViews({
            token: idToken,
            page: "inventory",
          })
        );
        dispatch(
          getAllOrganizations({
            token: idToken,
          })
        );
      })();
    }
  }, [ready]);

  const handleViewItem = (params: any) => {
    if (hasAccess(Constants.ACTIONS.VIEW_INVENTORY)) {
      navigate("/inventory/view/" + params?.row?.itemSerialNumber);
    }
  };

  const handleFailureDateClose = () => {
    setFailureDateOpen(false);
  };

  const [dimensions, setDimensions] = useState<BoundingRect | undefined>();

  return (
    <Box style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Inventory
      </Typography>
      <Measure
        bounds={true}
        onResize={(contentRect) => {
          setDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <FilterView
            columns={columns}
            defaultColumns={defaultColumns}
            definedColumns={definedColumns}
            fileContentLabel="inventory"
            filters={filters}
            initialColumnStates={initialColumnStates}
            initialState={initialState}
            resetAll={resetInventoryGrid}
            resetColumnsState={resetColumnsState}
            resetFiltersState={resetFiltersState}
            resetGridState={resetGridState}
            setDirtyState={setDirtyState}
            setView={setView}
            updateFiltersState={updateFiltersState}
            updateGridPropertyState={updateGridPropertyState}
            viewState={viewState}
            getQuery={getInventoryQuery}
            getQueryParams={getInventoryQueryParams}
            onFiltersAction={handleFiltersAction}
            rowsFromQueryData={inventoryRowsFromQueryData}
            ref={measureRef}
          />
        )}
      </Measure>
      <GridView
        columns={columns}
        defaultColumns={defaultColumns}
        definedColumns={definedColumns}
        filters={filters}
        initialColumnStates={initialColumnStates}
        loading={loading && !promiseInProgress}
        resetAll={resetInventoryGrid}
        rows={inventory}
        setColumns={setColumns}
        setDirtyState={setDirtyState}
        supportedSortColumns={supportedSortColumns}
        totalCount={totalCount}
        updateFiltersState={updateFiltersState}
        updateGridPropertyState={updateGridPropertyState}
        updateGridState={updateGridState}
        useLoadingBackdrop={false}
        viewState={viewState}
        onRef={(methods: GridViewRef) => {
          setGridViewMethods(methods);
        }}
        onRowClick={(row, event) => {
          handleViewItem(row);
        }}
        onMenuItemClick={handleFailureDateDialogOpen}
        yOffset={180 + (dimensions?.height || 0)}
      />
      {failureDateOpen && (
        <FailureDateDialog
          open={failureDateOpen}
          serialNumber={inventoryItem?.itemSerialNumber}
          failureDate={inventoryItem?.failureDt}
          onClose={handleFailureDateClose}
        />
      )}
    </Box>
  );
};

export default Inventory;
