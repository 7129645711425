import * as React from "react";
import { Box } from "@mui/material";
import Map, { Marker, ViewStateChangeEvent } from "react-map-gl";
import LocationPin from "assets/svg/locationPin";
import { Site } from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const DEFAULT_VIEWPORT = {
  longitude: -50,
  latitude: 37,
  zoom: 3,
};

const SiteLocation = (props: { site: Site }) => {
  const { site } = props;
  const [viewport, setViewport] = React.useState(DEFAULT_VIEWPORT);

  React.useEffect(() => {
    setViewport({
      longitude: site?.longitude || DEFAULT_VIEWPORT.longitude,
      latitude: site?.latitude || DEFAULT_VIEWPORT.latitude,
      zoom: 16,
    });
  }, [site]);

  const handleMoveMap = (evt: ViewStateChangeEvent) => {
    setViewport(evt.viewState);
  };

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Map
        {...viewport}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        onMove={(evt) => handleMoveMap(evt)}
      >
        <Marker
          longitude={viewport.longitude}
          latitude={viewport.latitude}
          anchor="bottom"
        >
          <LocationPin width={32} height={32} />
        </Marker>
      </Map>
    </Box>
  );
};

export default createFragmentContainer(SiteLocation, {
  site: gql`
    fragment SiteLocation_site on Site {
      id
      name
      latitude
      longitude
    }
  `,
});
