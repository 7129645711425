import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import jobsSlice from "redux/reducers/jobsSlice";
import jobsGridSlice from "redux/reducers/jobsGridSlice";
import sitesSlice from "redux/reducers/sitesSlice";
import orgsSlice from "redux/reducers/orgsSlice";
import templatesSlice from "redux/reducers/templatesSlice";
import filtersSlice from "redux/reducers/filtersSlice";
import orgsListSlice from "redux/reducers/orgsListSlice";
import orgDetailsSlice from "redux/reducers/orgDetailsSlice";
import assignOrgsToOrgSlice from "redux/reducers/assignOrgsToOrgSlice";
import usersSlice from "redux/reducers/usersSlice";
import usersGridSlice from "redux/reducers/usersGridSlice";
import inviteUserSlice from "redux/reducers/inviteUserSlice";
import createOrgSlice from "redux/reducers/createOrgSlice";
import rolesListSlice from "redux/reducers/rolesListSlice";
import roleEditorSlice from "redux/reducers/roleEditorSlice";
import rolesGridSlice from "redux/reducers/rolesGridSlice";
import userTtRolesSlice from "redux/reducers/userTtRolesSlice";
import userRolesGridSlice from "redux/reducers/userRolesGridSlice";
import assignTtRolesToUserSlice from "redux/reducers/assignTtRolesToUserSlice";
import authDataSlice from "redux/reducers/authDataSlice";
import airflowFanChartSlice from "redux/reducers/airflowFanChartSlice";
import {
  assetsViewReducer,
  assetsSavedViewsReducer,
} from "redux/reducers/assetsViewSlice";
import assetConfigHistoryGridSlice from "redux/reducers/assetConfigHistoryGridSlice";
import {
  inventorySavedViewsReducer,
  inventoryViewReducer,
} from "redux/reducers/inventoryViewSlice";
import {
  netSuiteRelationshipsSavedViewsReducer,
  netSuiteRelationshipsViewReducer,
} from "redux/reducers/netSuiteRelationshipsViewSlice";
import {
  netSuiteUploadsSavedViewsReducer,
  netSuiteUploadsViewReducer,
} from "redux/reducers/netSuiteUploadsViewSlice";
import templateEditorSlice from "redux/reducers/templateEditorSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "filters",
    "orgsList",
    "assetsView",
    "jobsGrid",
    "usersGrid",
    "rolesGrid",
    "assetConfigHistoryGrid",
    "inventoryView",
    "netSuiteRelationshipsView",
    "netSuiteUploadsView",
  ],
};

const rootReducer = combineReducers({
  jobs: jobsSlice,
  jobsGrid: jobsGridSlice,
  sites: sitesSlice,
  orgs: orgsSlice,
  templates: templatesSlice,
  templateEditor: templateEditorSlice,
  filters: filtersSlice,
  orgsList: orgsListSlice,
  orgDetails: orgDetailsSlice,
  assignOrgsToOrg: assignOrgsToOrgSlice,
  users: usersSlice,
  usersGrid: usersGridSlice,
  inviteUser: inviteUserSlice,
  createOrg: createOrgSlice,
  rolesList: rolesListSlice,
  roleEditor: roleEditorSlice,
  rolesGrid: rolesGridSlice,
  userTtRoles: userTtRolesSlice,
  userRolesGrid: userRolesGridSlice,
  assignTtRolesToUser: assignTtRolesToUserSlice,
  authData: authDataSlice,
  airflowfancharts: airflowFanChartSlice,
  assetsView: assetsViewReducer,
  assetsSavedViews: assetsSavedViewsReducer,
  assetConfigHistoryGrid: assetConfigHistoryGridSlice,
  inventoryView: inventoryViewReducer,
  inventorySavedViews: inventorySavedViewsReducer,
  netSuiteRelationshipsView: netSuiteRelationshipsViewReducer,
  netSuiteRelationshipsSavedViews: netSuiteRelationshipsSavedViewsReducer,
  netSuiteUploadsView: netSuiteUploadsViewReducer,
  netSuiteUploadsSavedViews: netSuiteUploadsSavedViewsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
export default store;
