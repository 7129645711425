import { createSlice } from "@reduxjs/toolkit";
import { listActions } from "redux/actions/actions";
import { getRole, getRoleActions, getRoleOrgs } from "redux/actions/roles";
import error from "utils/error";
interface RolesEditorState {
  role: any;
  isNew: boolean;
  header: string;
  loading: boolean;
  error?: string;
  validation: any;
  availableActions: any[];
  roleActions: any[];
  roleOrgs: any[];
}

const initialState: RolesEditorState = {
  role: { role_name: "", description: "" },
  isNew: true,
  loading: false,
  error: undefined,
  header: "Add new role",
  validation: { valid: true },
  availableActions: [],
  roleActions: [],
  roleOrgs: [],
};

const slice = createSlice({
  name: "roleEditor",
  initialState,
  reducers: {
    setIsNew(state, action) {
      state.isNew = action.payload;
      if (state.isNew) {
        state.role = { role_name: "", description: "" };
        state.header = "Add new role";
      }
    },
    setValidation(state, action) {
      state.validation = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setRoleActions(state, action) {
      state.roleActions = action.payload;
    },
    setRoleOrgs(state, action) {
      state.roleOrgs = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.loading = false;
      state.role = action.payload;
      state.header = action.payload?.role_name || "";
    });
    builder.addCase(getRole.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
    builder.addCase(listActions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listActions.fulfilled, (state, action) => {
      state.loading = false;
      state.availableActions = action.payload;
    });
    builder.addCase(listActions.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
    builder.addCase(getRoleActions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRoleActions.fulfilled, (state, action) => {
      state.loading = false;
      state.roleActions = action.payload;
    });
    builder.addCase(getRoleActions.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
    builder.addCase(getRoleOrgs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRoleOrgs.fulfilled, (state, action) => {
      state.loading = false;
      state.roleOrgs = action.payload;
    });
    builder.addCase(getRoleOrgs.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export const { setIsNew, setValidation, setRole, setRoleActions, setRoleOrgs } =
  slice.actions;
export default slice.reducer;
