import * as React from "react";
import { Box, Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const ConnectivityStatusChip = (props: { connected: boolean | undefined }) => {
  const { connected } = props;
  return (
    <Box
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        verticalAlign: "middle",
      }}
    >
      <Chip
        size="small"
        variant="outlined"
        icon={<CircleIcon fontSize="small" />}
        color={connected ? "success" : "error"}
        label={connected ? "Connected" : "Not Connected"}
      />
    </Box>
  );
};

export default ConnectivityStatusChip;
