import React, { FC } from "react";
import { NotAllowed } from "components/notallowed";
import { ComponentType } from "react";
import { useAuthData } from "utils/hooks/useAuthData";
export interface WithRoleBasedRedirectOptions {
  forGreensightAdminOnly?: boolean;
  action?: string;
}
export const withRoleBasedRedirect =
  <P extends object>(
    Component: ComponentType<P>,
    options: WithRoleBasedRedirectOptions
  ): FC<P> =>
  (props: P): JSX.Element => {
    const { isGreensightAdmin, hasAccess, ready } = useAuthData();

    if (options.forGreensightAdminOnly && !isGreensightAdmin) {
      return ready ? <NotAllowed {...props} /> : <></>;
    } else if (
      options.action &&
      options.action !== "" &&
      !hasAccess(options.action)
    ) {
      return ready ? <NotAllowed {...props} /> : <></>;
    } else {
      return <Component {...props} />;
    }
  };
