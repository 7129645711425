import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { HeaderRenderer, HeaderRendererProps } from "react-data-grid";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
interface DraggableHeaderRendererProps<R> extends HeaderRendererProps<R> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
  enableColumnSearch?: boolean;
  excludeSearchKeys?: Array<string>;
  onSearch?: (columnKey: string, query: string) => void;
}

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  enableColumnSearch = false,
  excludeSearchKeys = [],
  column,
  onSearch,
  ...props
}: DraggableHeaderRendererProps<R>) {
  const [value, setValue] = React.useState("");
  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div>
      <div
        ref={(ref) => {
          drag(ref);
          drop(ref);
        }}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: isOver ? "#ececec" : undefined,
          cursor: "move",
          width: "100%",
        }}
      >
        {HeaderRenderer({ column, ...props })}
      </div>
      {enableColumnSearch && !excludeSearchKeys.includes(column.key) && (
        <div className="column-search-text-input">
          <TextField
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
              onSearch?.(column.key, event.target.value);
            }}
            InputProps={{
              endAdornment: value ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setValue("");
                    onSearch?.(column.key, "");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : null,
            }}
            sx={{
              width: "100%",
              marginY: 0.5,
            }}
          />
        </div>
      )}
    </div>
  );
}
