import { useRef } from "react";
import { IconButton } from "@mui/material";
import TTTextField from "components/common/TTTextField";
import ClearIcon from "@mui/icons-material/Clear";

const MainFilter = (props: {
  value: string;
  onChange?: (newValue: string) => void;
}) => {
  const { value, onChange } = props;
  const textRef = useRef();

  return (
    <>
      <TTTextField
        label="Search by site, address, asset..."
        value={value || ""}
        variant="outlined"
        size="small"
        fullWidth
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          onChange?.(newValue);
        }}
        inputRef={textRef}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ visibility: value ? "visible" : "hidden" }}
              onClick={() => {
                onChange?.("");
                if (textRef.current) {
                  (textRef.current as any).value = "";
                }
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
        sx={{
          "& .Mui-focused .MuiIconButton-root": {
            color: "primary.main",
          },
          maxWidth: 470,
          marginRight: "10px",
        }}
      />
    </>
  );
};

export default MainFilter;
