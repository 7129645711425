import { ColumnState } from "utils/types";
import { GridColDef } from "@mui/x-data-grid-pro";
import { columnSettings } from "components/common/gridview/gridState";
import {
  columnFilterOperators,
  renderCell,
  validBool,
  validIndex,
  validWidth,
  valueGetter,
} from "components/common/gridview/gridColumns";

const definedColumns: any[] = [
  {
    field: "id",
    object: "asset",
    key: "id",
    headerName: "Asset ID",
    filterable: true,
    hide: true,
    sortingKey: "assetId",
    sortable: true,
    filterOperators: true,
  },

  // Asset site
  {
    field: "siteName",
    object: "site",
    key: "name",
    headerName: "Site Name",
    filterable: true,
    filteringKey: "siteName",
    hideable: false,
    sortingKey: "siteName",
    sortable: true,
  },
  {
    field: "siteAddress",
    object: "site",
    key: "address",
    sortingKey: "siteAddress",
    headerName: "Site Address",
    filterable: true,
    filteringKey: "siteAddress",
    sortable: true,
  },

  // Asset organization
  {
    field: "organization",
    object: "asset",
    key: "organization",
    sortingKey: "organization",
    headerName: "Customer",
    sortable: true,
  },

  // Asset
  {
    field: "assetName",
    object: "asset",
    key: "name",
    headerName: "Asset Name",
    sortable: true,
    hideable: false,
    filterable: true,
  },
  {
    field: "assetManufacturer",
    object: "asset",
    key: "manufacturer",
    headerName: "Asset Make",
    sortable: true,
    filterable: true,
  },
  {
    field: "assetModelNumber",
    object: "asset",
    key: "modelNumber",
    headerName: "Asset Model",
    sortable: true,
    filterable: true,
  },

  {
    field: "assetSerialNumber",
    object: "asset",
    key: "serialNumber",
    headerName: "Asset Serial",
    hide: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "assetLatitude",
    object: "asset",
    key: "latitude",
    headerName: "Latitude",
    type: "number",
    align: "left",
    headerAlign: "left",
    hide: true,
    sortable: true,
    filterable: true,
    filterOperators: true,
  },
  {
    field: "assetLongitude",
    object: "asset",
    key: "longitude",
    headerName: "Longitude",
    type: "number",
    align: "left",
    headerAlign: "left",
    hide: true,
    sortable: true,
    filterable: true,
    filterOperators: true,
  },
  {
    field: "assetSource",
    object: "asset",
    key: "source",
    headerName: "Source",
    hide: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "assetCreatedDt",
    object: "asset",
    key: "createdDt",
    headerName: "Date Created",
    type: "datetime",
    sortable: true,
    filterable: true,
  },
  {
    field: "assetCreatedBy",
    object: "asset",
    key: "createdBy",
    headerName: "Created By",
    hide: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "assetUpdatedDt",
    object: "asset",
    key: "updatedDt",
    headerName: "Last Updated",
    hide: true,
    type: "datetime",
    sortable: true,
    filterable: true,
  },
  {
    field: "assetUpdatedBy",
    object: "asset",
    key: "updatedBy",
    hide: true,
    headerName: "Last Updated By",
    sortable: true,
    filterable: true,
  },

  // Asset Properties
  {
    field: "assetProperty_rtu_motor_model",
    object: "asset-properties",
    key: "rtu_motor_model",
    headerName: "RTU Motor Model",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_sn",
    object: "asset-properties",
    key: "rtu_motor_sn",
    headerName: "RTU Motor Serial Number",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_manufacturer",
    object: "asset-properties",
    key: "rtu_motor_manufacturer",
    headerName: "RTU Motor Manufacturer",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_fan_type",
    object: "asset-properties",
    key: "rtu_motor_fan_type",
    headerName: "RTU Motor Fan Type",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_efficiency",
    object: "asset-properties",
    key: "rtu_motor_efficiency",
    headerName: "RTU Motor Efficiency",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_frame",
    object: "asset-properties",
    key: "rtu_motor_frame",
    headerName: "RTU Motor Frame",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_vfd",
    object: "asset-properties",
    key: "rtu_motor_vfd",
    headerName: "RTU Motor VFD",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_voltage",
    object: "asset-properties",
    key: "rtu_motor_voltage",
    headerName: "RTU Motor Voltage",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_max_rpm",
    object: "asset-properties",
    key: "rtu_motor_max_rpm",
    headerName: "RTU Motor Max RPM",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_phase",
    object: "asset-properties",
    key: "rtu_motor_phase",
    headerName: "RTU Motor Phase",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_horsepower",
    object: "asset-properties",
    key: "rtu_motor_horsepower",
    headerName: "HP",
    hide: true,
  },
  {
    field: "assetProperty_rtu_phase",
    object: "asset-properties",
    key: "rtu_phase",
    headerName: "RTU Phase",
    hide: true,
  },
  {
    field: "assetProperty_rtu_voltage",
    object: "asset-properties",
    key: "rtu_voltage",
    headerName: "RTU Voltage",
    hide: true,
  },
  {
    field: "assetProperty_rtu_tonnage",
    object: "asset-properties",
    key: "rtu_tonnage",
    headerName: "Tonnage",
    hide: true,
  },
  {
    field: "assetProperty_rtu_heat_stages",
    object: "asset-properties",
    key: "rtu_heat_stages",
    headerName: "Heating Stages",
    hide: true,
  },
  {
    field: "assetProperty_rtu_cool_stages",
    object: "asset-properties",
    key: "rtu_cool_stages",
    headerName: "Cooling Stages",
    hide: true,
  },
  {
    field: "assetProperty_rtu_id_photo",
    object: "asset-properties",
    key: "rtu_id_photo",
    headerName: "RTU ID Photo",
    hide: true,
  },
  {
    field: "assetProperty_rtu_nameplate_photo",
    object: "asset-properties",
    key: "rtu_nameplate_photo",
    headerName: "RTU Nameplate Photo",
    hide: true,
  },
  {
    field: "assetProperty_rtu_motor_nameplate_photo",
    object: "asset-properties",
    key: "rtu_motor_nameplate_photo",
    headerName: "RTU Motor Nameplate Photo",
    hide: true,
  },

  // Asset Drive
  {
    field: "driveModel",
    object: "asset-drive",
    key: "driveModel",
    headerName: "Drive Model",
    filteringKey: "driveModel",
    filterable: true,
  },
  {
    field: "driveSerialNumber",
    object: "asset-drive",
    key: "serialNumber",
    headerName: "Drive Serial",
    filteringKey: "driveSerialNumber",
    filterable: true,
  },
  {
    field: "driveCreatedDt",
    object: "asset-drive-edges",
    key: "installedDt",
    headerName: "Drive Created Date",
    filteringKey: "driveCreatedDt",
    filterable: true,
    hide: true,
    type: "datetime",
  },
  {
    field: "driveCreatedBy",
    object: "asset-drive",
    key: "createdBy",
    headerName: "Drive Created By",
    filteringKey: "driveCreatedBy",
    filterable: true,
    hide: true,
  },
  {
    field: "driveUpdatedDt",
    object: "asset-drive-edges",
    key: "updatedDt",
    headerName: "Drive Last Updated Date",
    filteringKey: "driveUpdatedDt",
    filterable: true,
    hide: true,
    type: "datetime",
  },
  {
    field: "driveUpdatedBy",
    object: "asset-drive-edges",
    key: "updatedBy",
    headerName: "Drive Last Updated By",
    filteringKey: "driveUpdatedBy",
    filterable: true,
    hide: true,
  },

  // Asset Drive Properties
  {
    field: "driveProperty_tt_mc_maxspeed",
    object: "asset-drive-properties",
    key: "tt_mc_maxspeed",
    headerName: "Drive Max Speed Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_minspeed",
    object: "asset-drive-properties",
    key: "tt_mc_minspeed",
    headerName: "Drive Min Speed Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_ssid",
    object: "asset-drive-properties",
    key: "tt_mc_ssid",
    headerName: "Drive SSID Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_flow",
    object: "asset-drive-properties",
    key: "tt_mc_flow",
    headerName: "Drive Flow Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_logic_mode",
    object: "asset-drive-properties",
    key: "tt_mc_logic_mode",
    headerName: "Drive Control Mode Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_allow_reverse",
    object: "asset-drive-properties",
    key: "tt_mc_allow_reverse",
    headerName: "Drive Allow Reverse Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_clockwise",
    object: "asset-drive-properties",
    key: "tt_mc_clockwise",
    headerName: "Drive Run Direction Property",
    hide: true,
  },
  {
    field: "driveProperty_tt_mc_nameplate_photo",
    object: "asset-drive-properties",
    key: "tt_mc_nameplate_photo",
    headerName: "Drive Nameplate Photo Property",
    hide: true,
  },

  // Asset Motor
  {
    field: "motorModel",
    object: "asset-motor",
    key: "motorModel",
    headerName: "Motor Model",
    filteringKey: "motorModel",
    filterable: true,
  },
  {
    field: "motorSerialNumber",
    object: "asset-motor",
    key: "serialNumber",
    headerName: "Motor Serial",
    filteringKey: "motorSerialNumber",
    filterable: true,
  },
  {
    field: "motorCreatedDt",
    object: "asset-motor-edges",
    key: "installedDt",
    headerName: "Motor Created Date",
    filteringKey: "motorCreatedDt",
    filterable: true,
    type: "datetime",
    hide: true,
  },
  {
    field: "motorCreatedBy",
    object: "asset-motor",
    key: "createdBy",
    headerName: "Motor Created By",
    filterable: true,
    hide: true,
  },
  {
    field: "motorUpdatedDt",
    object: "asset-motor-edges",
    key: "updatedDt",
    headerName: "Motor Last Updated Date",
    filteringKey: "motorUpdatedDt",
    filterable: true,
    type: "datetime",
    hide: true,
  },
  {
    field: "motorUpdatedBy",
    object: "asset-motor-edges",
    key: "updatedBy",
    headerName: "Motor Last Updated By",
    filteringKey: "motorUpdatedBy",
    filterable: true,
    hide: true,
  },

  // Asset Motor Properties
  {
    field: "motorProperty_tt_motor_supply_voltage",
    object: "asset-motor-properties",
    key: "tt_motor_supply_voltage",
    headerName: "Motor Supply Voltage Property",
    hide: true,
  },
  {
    field: "motorProperty_tt_motor_voltage",
    object: "asset-motor-properties",
    key: "tt_motor_voltage",
    headerName: "Motor Voltage Property",
    hide: true,
  },
  {
    field: "motorProperty_tt_motor_nameplate_photo",
    object: "asset-motor-properties",
    key: "tt_motor_nameplate_photo",
    headerName: "Motor Nameplate Photo Property",
    hide: true,
  },

  // Asset RMK
  {
    field: "rmkSpvSerialNumber",
    object: "asset-rmk",
    key: "spvSerialNumber",
    headerName: "RMK SPV Serial Number",
    hide: true,
  },
  {
    field: "rmkModemName",
    object: "asset-rmk",
    key: "modemName",
    headerName: "RMK Modem Name",
    hide: true,
  },
  {
    field: "rmkModemMacAddress",
    object: "asset-rmk",
    key: "modemMacAddress",
    headerName: "RMK Modem Mac Address",
    hide: true,
  },
  {
    field: "rmkPlus",
    object: "asset-rmk",
    key: "rmkPlus",
    headerName: "RMK Plus",
    hide: true,
  },
  {
    field: "rmkCreatedDt",
    object: "asset-rmk",
    key: "createdDt",
    headerName: "RMK Created Date",
    type: "datetime",
    hide: true,
  },
  {
    field: "rmkCreatedBy",
    object: "asset-rmk",
    key: "createdBy",
    headerName: "RMK Created By",
    hide: true,
  },
  {
    field: "rmkUpdatedDt",
    object: "asset-rmk-edges",
    key: "updatedDt",
    headerName: "RMK Updated Date",
    type: "datetime",
    hide: true,
  },
  {
    field: "rmkUpdatedBy",
    object: "asset-rmk-edges",
    key: "updatedBy",
    headerName: "RMK Updated By",
    hide: true,
  },

  // Asset RMK Properties
  {
    field: "rmkProperty_tt_emk_count",
    object: "asset-rmk-properties",
    key: "tt_emk_count",
    headerName: "RMK EMKs installed Property",
    hide: true,
  },
  {
    field: "rmkProperty_tt_rmk_pwd",
    object: "asset-rmk-properties",
    key: "tt_rmk_pwd",
    headerName: "RMK Supervisor Password Property",
    hide: true,
  },
  {
    field: "rmkProperty_tt_rmk_photo",
    object: "asset-rmk-properties",
    key: "tt_rmk_photo",
    headerName: "RMK Photo Property",
    hide: true,
  },
  {
    field: "rmkProperty_tt_rmk_wifi_ssid",
    object: "asset-rmk-properties",
    key: "tt_rmk_wifi_ssid",
    headerName: "RMK WiFi SSID Property",
    hide: true,
  },
  {
    field: "rmkProperty_tt_rmk_wifi_pwd",
    object: "asset-rmk-properties",
    key: "tt_rmk_wifi_pwd",
    headerName: "RMK WiFi Password Property",
    hide: true,
  },
  {
    field: "rmkProperty_tt_modem_photo",
    object: "asset-rmk-properties",
    key: "tt_modem_photo",
    headerName: "RMK Modem Photo Property",
    hide: true,
  },
];

/**
  Normalize defined column items.
 */
const defaultColumns: GridColDef[] = definedColumns.map((col) => {
  const column = {
    field: col.field,
    headerName: col.headerName,
    hide: col.hide,
    width: col?.width ?? columnSettings.COLUMN_DEFAULT_WIDTH,
    renderCell: renderCell(col),
  };
  // optional properties that need to be included if exists
  if (col?.type) {
    (column as any).type = col.type;
  }
  if (col?.type === "date") {
    (column as any).valueGetter = valueGetter(col);
  }
  if (col?.align) {
    (column as any).align = col.align;
  }
  if (col?.headerAlign) {
    (column as any).headerAlign = col.headerAlign;
  }
  if (col?.filterOperators === true) {
    (column as any).filterOperators = columnFilterOperators(col);
  }
  // optional properties that need to be included if exists and is explicitly set to true.
  if (col?.sortable === true) {
    (column as any).sortable = true;
  }
  if (col?.filterable === true) {
    (column as any).filterable = true;
  }
  // optional properties that need to be included if exists and is explicitly set to false.
  if (col?.hideable === false) {
    (column as any).hideable = false;
  }
  return column;
});

const initialColumnStates: ColumnState[] = (() => {
  const initialStates: Array<ColumnState> = [];
  defaultColumns.forEach((col: any, index: number) => {
    initialStates.push({
      field: col.field,
      hide: validBool(col.hide),
      width: validWidth(col.width),
      index: validIndex(index),
    });
  });
  return initialStates;
})();

const supportedSortColumns = defaultColumns
  .filter((col) => (col as any).sortable !== undefined && (col as any).sortable)
  .map((sortableColumn) => sortableColumn.field);

export {
  supportedSortColumns,
  defaultColumns,
  definedColumns,
  initialColumnStates,
};
