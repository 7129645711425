import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import { getUserSites, getUserSitesWithParents } from "../actions/sites";

interface SitesState {
  data?: any;
  sitesWithParents?: any;
  loading: boolean;
  error?: string;
}

const initialState: SitesState = {
  data: undefined,
  sitesWithParents: undefined,
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "sites",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    /** Get User Sites */
    builder.addCase(getUserSites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserSites.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.result;
    });
    builder.addCase(getUserSites.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    /** Get User Sites WIth Parents */
    builder.addCase(getUserSitesWithParents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserSitesWithParents.fulfilled, (state, action) => {
      state.loading = false;
      state.sitesWithParents = action.payload?.result;
    });
    builder.addCase(getUserSitesWithParents.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
