import * as React from "react";
import { AppBar, Drawer, Box, Skeleton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";

import { Logo } from "assets/svg/logo";

const drawerWidth = 200;

const useAppStyles: any = makeStyles<any>()((theme) => {
  return {
    logoContainer: {
      height: 64,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 12,
    },
    content: {
      flexGrow: 1,
      marginLeft: `${drawerWidth}px`,
      backgroundColor: theme.palette.background.default,
    },
    pageContent: {
      flexGrow: 1,
      padding: 30,
      paddingTop: 6,
    },
    appBar: {
      height: 64,
      backgroundColor: "white",
      overflow: "auto",
      flexDirection: "row",
      alignItems: "center",
    },
    toolBar: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignItems: "end",
    },
  };
});

const MainLayoutSkeleton = () => {
  const { classes } = useAppStyles();

  return (
    <React.Fragment>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            zIndex: 0,
            borderRight: 0,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Link to="/" className={classes.logoContainer}>
          <Logo />
        </Link>
        <Box sx={{ width: drawerWidth, paddingX: 2 }}>
          {Array.from({ length: 5 }).map((i, k) => (
            <Skeleton key={k} height={50} />
          ))}
        </Box>
      </Drawer>
      <main className={classes.content}>
        <AppBar className={classes.appBar} position="static" elevation={0}>
          <Box className={classes.toolBar} sx={{ paddingRight: 3 }}>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ flexGrow: 0 }}
            ></Skeleton>
          </Box>
        </AppBar>
        <Box className={classes.pageContent}>
          <Skeleton height={90} width={"25%"} />
          {Array.from({ length: 16 }).map((i, k) => (
            <Skeleton key={k} height={50} />
          ))}
        </Box>
      </main>
    </React.Fragment>
  );
};

export default MainLayoutSkeleton;
