import React, { useState } from "react";
import DataGrid from "react-data-grid";
import { Box } from "@mui/material";
import { Tooltip } from "react-tooltip";
import type { Column, SortColumn } from "react-data-grid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ActionsMenu from "./actionsMenu";
import TTToolTipText from "components/common/TTToolTipText";
import { FanChartsFullRow } from "models/fancharts";
import { dataGridStyle, dataGridParentStyle } from "theme/styles";

const createColumns: readonly Column<FanChartsFullRow>[] = [
  {
    key: "make_display",
    name: "Make",
    width: "15%",
    maxWidth: 180,
    formatter(props) {
      return <TTToolTipText text={props.row.make_display} />;
    },
  },
  {
    key: "model",
    name: "Model Number",
    width: "20%",
    maxWidth: 250,
    formatter(props) {
      return <TTToolTipText text={props.row.model} />;
    },
  },
  {
    key: "fanchart_name",
    name: "Fan Chart Name",
    maxWidth: 350,
    formatter(props) {
      return <TTToolTipText text={props.row.fanchart_name} />;
    },
  },
  {
    key: "tonnage",
    name: "Tonnage",
    width: "15%",
    formatter(props) {
      return <TTToolTipText text={props.row.tonnage} />;
    },
  },
  {
    key: "max_pressure",
    name: "Max Pressure in WC",
    width: "25%",
    formatter(props) {
      return <TTToolTipText text={props.row.max_pressure} />;
    },
  },
  {
    key: "actions",
    name: "Actions",
    width: 40,
    formatter(props) {
      return <ActionsMenu fanchart={props.row} />;
    },
  },
];
const FanChartsDataGrid = (props: {
  rows: any;
  onChangeData?: any;
  sortColumn?: SortColumn;
  onRowClick: (fanchart: FanChartsFullRow) => void;
}) => {
  const { rows } = props;
  const { onRowClick } = props;
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const handleRowClick = (fanchart: FanChartsFullRow) => {
    onRowClick(fanchart);
  };

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    props.onChangeData?.(sortColumns);
  };

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    handleChangeData(sortColumns);
    setSortColumns(sortColumns);
  };

  return (
    <Box
      style={{
        height: "calc(100vh - 250px)",
        width: "100%",
        maxWidth: 1200,
      }}
      sx={dataGridParentStyle}
    >
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          style={{
            ...dataGridStyle,
            height: "calc(100vh - 250px)",
            cursor: "pointer",
            background: "#ffffff",
            color: "#2F2F2F",
          }}
          columns={createColumns}
          rows={rows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          rowHeight={40}
          headerRowHeight={44}
          onRowClick={handleRowClick}
        />
        <Tooltip
          id="cell-tooltip"
          place="left"
          style={{ maxWidth: "250px", zIndex: 999 }}
        />
      </DndProvider>
    </Box>
  );
};
export default FanChartsDataGrid;
