import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTplEditorStyles } from "./styles";
import { Box } from "@mui/system";
import {
  addNewReplacement,
  changeMinMaxValue,
  changeRegexExample,
  changeRegexExpression,
  changeRegexType,
  changeReplacementKey,
  changeReplacementUppercase,
  changeReplacementValue,
  deleteReplacement,
} from "redux/reducers/templateEditorSlice";
import { Clear } from "@mui/icons-material";
import { VALIDATION_PARAMETERS } from "models/response/tpleditdata";
import { DataType } from "models/response/datatype";
import { capitalize } from "lodash";
import useItemSelector from "utils/hooks/useItemSelector";

export interface TplEditorValidationRulesProps {
  groupIndex: number;
  itemIndex: number;
}

const TplEditorValidationRules = React.memo(
  (props: TplEditorValidationRulesProps) => {
    const dataTypeId = useItemSelector(
      props.groupIndex,
      props.itemIndex,
      (item) => item.data_type
    );
    const dataType = useSelector((state: any) =>
      state.templateEditor.dataTypes.find((dt: any) => dt.id === dataTypeId)
    ) as DataType;
    const fields = useMemo((): string[] => {
      if (dataType?.key) {
        return (
          VALIDATION_PARAMETERS?.[
            dataType.key as keyof typeof VALIDATION_PARAMETERS
          ] || []
        );
      }
      return [];
    }, [dataType]);

    if (!fields?.length) {
      return <></>;
    }

    return (
      <Paper
        sx={{ flex: 1, mt: 3, p: 3, mb: 2, ml: 1 }}
        key={`${props.groupIndex}${props.itemIndex}_valrules`}
      >
        <Typography variant="subtitle1" fontWeight={"bold"} mb={4}>
          Validation rules:
        </Typography>
        <Grid
          container
          spacing={2}
          sx={fields?.length > 2 ? { justifyContent: "center" } : undefined}
        >
          {fields.map((field: string, index: number) => {
            return (
              <Box
                borderRight={
                  index !== fields?.length - 1 ? "1px solid #dadada" : undefined
                }
                pl={1.6}
                pr={2}
                key={`${props.groupIndex}${props.itemIndex}${field}${index}_parambox`}
              >
                <ValidationParameter
                  field={field}
                  groupIndex={props.groupIndex}
                  itemIndex={props.itemIndex}
                />
              </Box>
            );
          })}
        </Grid>
      </Paper>
    );
  }
);

interface ValidationParameterProps {
  field: string;
  groupIndex: number;
  itemIndex: number;
}
const ValidationParameter = React.memo((props: ValidationParameterProps) => {
  const { classes } = useTplEditorStyles();

  const minimumValue = useSelector(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].items?.[
        props.itemIndex
      ].validation_rules?.["min_" + props.field]
  );
  const maximumValue = useSelector(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].items?.[
        props.itemIndex
      ].validation_rules?.["max_" + props.field]
  );
  const rules = useItemSelector(
    props.groupIndex,
    props.itemIndex,
    (item) => item?.validation_rules
  );
  const replacements = rules?.transforms?.replacements || {};

  const dispatch = useDispatch();
  const handleMinimumChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      changeMinMaxValue({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        field: "min_" + props.field,
        value: e.target.value,
      })
    );
  };
  const handleMaximumChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      changeMinMaxValue({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        field: "max_" + props.field,
        value: e.target.value,
      })
    );
  };
  const handleNewReplacementClick = () => {
    dispatch(
      addNewReplacement({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const handleReplacementKeyChange = (key: string, newKey: string) => {
    dispatch(
      changeReplacementKey({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        key,
        newKey,
      })
    );
  };
  const handleReplacementValueChange = (key: string, value: string) => {
    dispatch(
      changeReplacementValue({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        key,
        value,
      })
    );
  };
  const handleDeleteReplacementKey = (key: string) => {
    dispatch(
      deleteReplacement({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        key,
      })
    );
  };
  const handleUppercaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeReplacementUppercase({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        value: e.target.checked,
      })
    );
  };
  const handleRegexTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeRegexType({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        value: e.target.value,
      })
    );
  };
  const handleRegexExpressionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      changeRegexExpression({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        value: e.target.value,
      })
    );
  };
  const handleRegexExampleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeRegexExample({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        value: e.target.value,
      })
    );
  };
  const expression = rules?.regex?.exp ? atob(rules?.regex?.exp) : "";
  const example = rules?.regex?.example || "";

  if (["length", "value", "count"].includes(props.field)) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        ml={2}
        key={`${props.field}${props.groupIndex}${props.itemIndex}_validator`}
      >
        <Typography sx={{ mb: 1.5 }}>{capitalize(props.field)}</Typography>
        <TextField
          type="number"
          size="small"
          label={"Minimum"}
          onChange={handleMinimumChange}
          value={minimumValue}
          className={classes.textField}
          sx={{ width: "150px" }}
        ></TextField>
        <TextField
          type="number"
          size="small"
          label={"Maximum"}
          onChange={handleMaximumChange}
          value={maximumValue}
          className={classes.textField}
          sx={{ width: "150px" }}
        ></TextField>
      </Box>
    );
  } else if (props.field === "regex") {
    return (
      <Box key={`${props.field}${props.groupIndex}${props.itemIndex}_regex`}>
        <Typography>Regular expression</Typography>
        <Box>
          <FormControl className={classes.flexRow}>
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{ mt: 1, mr: 2 }}
            >
              Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="match"
              name="radio-buttons-group"
              onChange={handleRegexTypeChange}
              value={rules?.regex?.type || "none"}
            >
              <Box className={classes.flexRow}>
                <FormControlLabel
                  value="match"
                  control={<Radio />}
                  label="Match"
                />
                <FormControlLabel
                  value="test"
                  control={<Radio />}
                  label="Test"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        {["match", "test"].includes(rules?.regex?.type || "") && (
          <Box
            key={`${props.field}${props.groupIndex}${props.itemIndex}_matchTest`}
          >
            <Box>
              <TextField
                label="Expression"
                size="small"
                className={classes.textField}
                value={expression}
                onChange={handleRegexExpressionChange}
              ></TextField>
            </Box>
            <Box>
              <TextField
                label="Example"
                size="small"
                className={classes.textField}
                value={example}
                onChange={handleRegexExampleChange}
              ></TextField>
            </Box>
          </Box>
        )}
      </Box>
    );
  } else if (props.field === "transforms") {
    return (
      <Box
        width={450}
        key={`${props.field}${props.groupIndex}${props.itemIndex}_transforms`}
      >
        <Typography>Transforms</Typography>
        <FormControlLabel
          label="Uppercase"
          control={
            <Checkbox
              checked={!!rules?.transforms?.uppercase}
              onChange={handleUppercaseChange}
            />
          }
        />
        <Typography>Replacements</Typography>
        {Object.keys(replacements).map((key: any, index: number) => {
          return (
            <Box
              className={classes.flexRow}
              key={`${props.field}${props.groupIndex}${props.itemIndex}${index}replacements`}
            >
              <TextField
                label="Find"
                size="small"
                className={classes.textField}
                value={key}
                onChange={(e) => {
                  handleReplacementKeyChange(key, e.target.value);
                }}
              />
              <TextField
                label="Replace"
                size="small"
                className={classes.textField}
                value={replacements[key]}
                onChange={(e) => {
                  handleReplacementValueChange(key, e.target.value);
                }}
              ></TextField>
              <IconButton
                onClick={() => {
                  handleDeleteReplacementKey(key);
                }}
              >
                <Clear />
              </IconButton>
            </Box>
          );
        })}
        {Object.keys(replacements).length === 0 && (
          <Box
            border="2px dashed #dadada"
            borderRadius={3}
            m={2}
            p={3}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Typography>No replacements</Typography>
          </Box>
        )}
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <Button onClick={handleNewReplacementClick}>New replacement</Button>
        </Box>
      </Box>
    );
  }
  return <></>;
});

export default TplEditorValidationRules;
