import React, { useState, useMemo } from "react";
import DataGrid, { SortColumn } from "react-data-grid";
import { Box, Button, Chip, Link, Menu, MenuItem } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "react-tooltip";
import { groupBy as rowGrouper, chunk } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TimestampFormatter } from "components/common/TTTimeStampFormatter";
import moment from "moment";
import TTToolTipChip from "components/common/TTToolTipChip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAppDispatch } from "redux/store/hooks";
import {
  deleteMembership,
  reinviteUser,
  updateMembership,
} from "redux/actions/users";
import { enqueueSnackbar } from "notistack";
import { getOrganizationUsers } from "redux/actions/orgs";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";
import { ADMIN_USER_TOOLTIP, MEMBER_USER_TOOLTIP } from "utils/strings";
import { dataGridParentStyle } from "theme/styles";

interface OrganizationUserRow {
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  created_at: Date;
  activated_at?: Date;
}
function rowKeyGetter(row: OrganizationUserRow) {
  return row.user_id;
}

const OrganizationUsersDataGrid = (props: {
  rows: any;
  organizationId: string | undefined;
  organizationName: string | undefined;
}) => {
  const { rows, organizationId, organizationName } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  type Comparator = (a: OrganizationUserRow, b: OrganizationUserRow) => number;

  const sortedRows = useMemo((): readonly OrganizationUserRow[] => {
    function getComparator(sortColumn: string): Comparator {
      switch (sortColumn) {
        case "created_at":
        case "activated_at":
          return (a, b) => {
            return moment(a?.[sortColumn])?.diff(b?.[sortColumn]);
          };
        case "is_admin":
          return (a: any, b: any) => {
            return (
              a?.[sortColumn] === true ? "Admin" : "Member"
            ).localeCompare(b?.[sortColumn] === true ? "Admin" : "Member");
          };
        default:
          return (a: any, b: any) => {
            return a?.[sortColumn]?.localeCompare(b?.[sortColumn]);
          };
      }
    }
    if (sortColumns.length === 0) return rows;
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const _a =
          a[sort.columnKey] === undefined ||
          a[sort.columnKey] === null ||
          a[sort.columnKey] === ""
            ? undefined
            : a[sort.columnKey];
        const _b =
          b[sort.columnKey] === undefined ||
          b[sort.columnKey] === null ||
          b[sort.columnKey] === ""
            ? undefined
            : b[sort.columnKey];
        if (_a === undefined && _b === undefined) {
          return 0;
        } else if (_a === undefined) {
          return 1;
        } else if (_b === undefined) {
          return -1;
        }

        const comparator = getComparator(sort.columnKey);
        let compResult = comparator(a, b);
        if (compResult !== undefined && compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);
  let chunks: Array<Array<any>> = chunk(sortedRows, rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    chunks = chunk(rows, _rowsPerPage);
    setPage(0);
  };

  const columns: any[] = [
    {
      key: "last_name",
      name: "Last",
      minWidth: 100,
    },
    {
      key: "first_name",
      name: "First",
      minWidth: 100,
    },
    {
      key: "email",
      name: "Contact Email",
      minWidth: 100,
      formatter(props: any) {
        return (
          <Link href={`mailto:${props.row.email}`} underline="always">
            {props.row.email}
          </Link>
        );
      },
    },
    {
      key: "is_admin",
      name: "BOS Access",
      minWidth: 90,
      wifth: 100,
      formatter(props: any) {
        let text = props.row.is_admin ? "Admin" : "Member";
        return (
          <TTToolTipChip
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              background: "rgba(0, 0, 0, 0.08)",
            }}
            label={text}
            toolTipText={
              props.row.is_admin ? ADMIN_USER_TOOLTIP : MEMBER_USER_TOOLTIP
            }
            icon={<InfoOutlinedIcon />}
          />
        );
      },
    },
    {
      key: "created_at",
      name: "Created",
      minWidth: 80,
      width: 100,
      formatter(props: any) {
        return (
          <TimestampFormatter
            timestamp={props.row.created_at}
            showTime={true}
          />
        );
      },
    },
    {
      key: "activated_at",
      name: "Accepted",
      minWidth: 80,
      width: 100,
      formatter(props: any) {
        return props.row.is_active ? (
          <TimestampFormatter
            timestamp={props.row.activated_at}
            showTime={true}
          />
        ) : (
          <Chip
            sx={{
              color: "#ED6C02",
              border: "1px solid rgba(237, 108, 2, 0.5)",
              background: "transparent",
            }}
            label={"Pending"}
          />
        );
      },
    },
    {
      key: "actions",
      name: "Actions",
      width: 60,
      formatter(props: any) {
        return (
          <UserActionsMenu
            userId={props.row.user_id}
            firstName={props.row.first_name}
            lastName={props.row.last_name}
            organizationName={organizationName || ""}
            isAdmin={props.row.is_admin}
            isAccountActive={props.row.is_account_active}
            organizationId={organizationId}
          />
        );
      },
    },
  ];

  return (
    <Box
      style={{
        width: "100%",
      }}
      sx={dataGridParentStyle}
    >
      <DataGrid
        style={{
          maxHeight: "calc(100vh - 435px)",
          height: Math.min(chunks[page]?.length * 6 + 10, 80) + "vh",
          width: "100%",
          borderRadius: 4,
        }}
        className="rdg-light"
        columns={columns}
        rows={chunks[page]}
        rowKeyGetter={rowKeyGetter}
        rowGrouper={rowGrouper}
        defaultColumnOptions={{ resizable: true, sortable: true }}
        rowHeight={52}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Tooltip
        id="cell-tooltip"
        place="bottom"
        style={{
          maxWidth: "165",
          zIndex: 999,
          padding: "4px 8px",
          background: "rgba(97, 97, 97, 0.9)",
          borderRadius: "4px",
          color: "#FFFFFF",
          fontSize: "10px",
          fontWeight: 500,
        }}
      />
    </Box>
  );
};
export default OrganizationUsersDataGrid;
const UserActionsMenu = (props: {
  userId: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  isAdmin: string;
  isAccountActive: boolean;
  organizationId: string | undefined;
}) => {
  const {
    userId,
    firstName,
    lastName,
    isAdmin,
    isAccountActive,
    organizationId,
    organizationName,
  } = props;

  const { getRawIdToken, hasAccess } = useAuthData();
  const dispatch = useAppDispatch();
  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeMembershipClick = () => {
    handleClose();
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        updateMembership({
          token: idToken,
          organizationId: organizationId,
          userId: userId,
          isAdmin: !isAdmin,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(
            `User was changed to ${isAdmin ? "member" : "admin"} role`,
            { variant: "default" }
          );
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to update user membership!", {
            variant: "error",
          });
        }
        dispatch(
          getOrganizationUsers({
            token: idToken,
            organizationId: organizationId,
          })
        );
      });
    })();
  };

  const handleDeleteMembershipClick = () => {
    handleClose();
    setShowRemoveConfirmation(true);
  };

  const handleConfirmDeleteMembership = () => {
    setShowRemoveConfirmation(false);
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        deleteMembership({
          token: idToken,
          organizationId: organizationId,
          userId: userId,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(`User removed from organization!`, {
            variant: "default",
          });
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to remove user from organization!", {
            variant: "error",
          });
        }
        dispatch(
          getOrganizationUsers({
            token: idToken,
            organizationId: organizationId,
          })
        );
      });
    })();
  };
  const handleReinviteClick = () => {
    handleClose();
    (async () => {
      const idToken = await getRawIdToken();

      dispatch(
        reinviteUser({
          token: idToken,
          userId: userId,
        })
      ).then((resp) => {
        if (resp && resp.payload && resp.payload.success) {
          enqueueSnackbar(`Invite re-sent!`, {
            variant: "default",
          });
        } else if (resp && resp.payload && !resp.payload.success) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to re-invite user!", {
            variant: "error",
          });
        }
        dispatch(
          getOrganizationUsers({
            token: idToken,
            organizationId: organizationId,
          })
        );
      });
    })();
  };

  if (
    !hasAccess(Constants.ACTIONS.PROMOTE_DEMOTE) &&
    !hasAccess(Constants.ACTIONS.DELETE_USER_FROM_ORG) &&
    !(!isAccountActive && hasAccess(Constants.ACTIONS.INVITE_USER_TO_ORG))
  ) {
    return null;
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="action" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {hasAccess(Constants.ACTIONS.PROMOTE_DEMOTE) && (
          <MenuItem onClick={handleChangeMembershipClick}>
            {isAdmin ? "Make Member" : "Make Admin"}
          </MenuItem>
        )}
        {!isAccountActive &&
          hasAccess(Constants.ACTIONS.INVITE_USER_TO_ORG) && (
            <MenuItem onClick={handleReinviteClick}>Resend Invite</MenuItem>
          )}
        {hasAccess(Constants.ACTIONS.DELETE_USER_FROM_ORG) && (
          <MenuItem
            onClick={handleDeleteMembershipClick}
            style={{ color: "#B04030" }}
          >
            <DeleteOutlineIcon /> Remove
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={showRemoveConfirmation}
        onClose={() => setShowRemoveConfirmation(false)}
      >
        <DialogTitle>{`Remove ${firstName} ${lastName} from ${organizationName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing the user will disable information shared and the user will
            no longer have access to the Organization's sites.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowRemoveConfirmation(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDeleteMembership}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
