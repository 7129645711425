import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FilterView from "../common/gridview/filtersView";
import GridView, { GridViewRef } from "../common/gridview/gridView";
import { useQuery } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import { usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from "react-router";
import { FilterItem, FiltersState } from "utils/types";
import { getSavedViews } from "redux/actions/savedviews";
import { getAllOrganizations } from "redux/actions/orgs";
import {
  getAssetsQuery,
  getAssetsQueryParams,
  assetRowsFromQueryData,
} from "./assetDataUtil";
import { validColumnFilterModel } from "../common/gridview/assetFiltersUtil";
import { debounce } from "lodash";
import { useAuthData } from "utils/hooks/useAuthData";
import {
  defaultColumns,
  definedColumns,
  initialColumnStates,
  supportedSortColumns,
} from "./assetColumns";
import {
  initialState,
  resetAll,
  resetColumnsState,
  resetFiltersState,
  resetGridState,
  setColumns,
  setDirtyState,
  setView,
  updateFiltersState,
  updateGridPropertyState,
  updateGridState,
} from "redux/reducers/assetsViewSlice";
import { Constants } from "utils/constants";
import Measure, { BoundingRect } from "react-measure";

const emptyArray: any[] = [];

const Assets = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getRawIdToken, hasAccess, ready } = useAuthData();
  const { promiseInProgress } = usePromiseTracker();
  const viewState = useAppSelector((state) => state.assetsView);
  const grid = useAppSelector((state) => state.assetsView.grid);
  const filters = useAppSelector((state) => state.assetsView.filters);
  const size: number = grid?.rowsPerPage || 50;
  const [page, setPage] = useState(grid?.pageNumber ?? 0);

  const searchField = useAppSelector(
    (state) => state.assetsView?.filters?.searchField
  );

  const selectedOrgFilterItems = useAppSelector((state) => {
    const orgs = state.assetsView?.filters?.orgs;
    return Array.isArray(orgs) ? (orgs as FilterItem[]) : emptyArray;
  });

  const columns = useAppSelector((state) => {
    return Array.isArray(state.assetsView?.columns)
      ? state.assetsView.columns
      : emptyArray;
  });

  const [columnFilterModel, setColumnFilterModel] = useState(
    Array.isArray(filters?.columnFilters?.items) ? filters.columnFilters : []
  );

  useEffect(() => {
    debounceColumnFilterModel(
      Array.isArray(filters?.columnFilters?.items)
        ? filters.columnFilters
        : { items: [] }
    );
  }, [filters?.columnFilters]);

  const debounceColumnFilterModel = debounce((model: any) => {
    setColumnFilterModel(model);
  }, 300);

  const { data, loading } = useQuery(
    getAssetsQuery(
      getAssetsQueryParams(
        grid,
        searchField,
        selectedOrgFilterItems,
        columnFilterModel,
        page,
        size
      )
    ),
    { skip: !ready } // attempt to prevent a canceled request during initial page load or refresh
  );
  const assets: any[] = assetRowsFromQueryData(data);

  const [totalCount, setTotalCount] = useState(data?.assets?.totalCount || 0);
  useEffect(() => {
    if (loading === false) {
      setTotalCount(data?.assets?.totalCount);
    }
  }, [loading, data]);

  const [gridViewRef, setGridViewMethods] = useState<GridViewRef | null>(null);
  const handleFiltersAction = (action: string, data?: any) => {
    if (action === "ViewChanged") {
      if (data?.view_data?.filters) {
        const viewFilters: FiltersState = data.view_data.filters;
        gridViewRef?.setColumnFilterModel?.(
          validColumnFilterModel(viewFilters?.columnFilters, definedColumns),
          definedColumns
        );
      }
      if (data?.view_data?.grid) {
        const viewData: any = data.view_data.grid;
        gridViewRef?.updateCurrentGridState?.(viewData, initialState);
      }
    }
  };

  useEffect(() => {
    if (typeof grid?.pageNumber === "number") {
      setPage(grid?.pageNumber);
    }
  }, [grid?.pageNumber, grid?.rowsPerPage]);

  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          getSavedViews({
            token: idToken,
            page: "assets",
          })
        );
        dispatch(
          getAllOrganizations({
            token: idToken,
          })
        );
      })();
    }
  }, [ready]);

  const handleViewAsset = (params: any) => {
    if (hasAccess(Constants.ACTIONS.VIEW_ASSET)) {
      navigate("/assets/view/" + params.id);
    }
  };

  const [dimensions, setDimensions] = useState<BoundingRect | undefined>();

  return (
    <Box style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Assets
      </Typography>
      <Measure
        bounds={true}
        onResize={(contentRect) => {
          setDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <FilterView
            columns={columns}
            defaultColumns={defaultColumns}
            definedColumns={definedColumns}
            fileContentLabel="assets"
            filters={filters}
            initialColumnStates={initialColumnStates}
            initialState={initialState}
            onFiltersAction={handleFiltersAction}
            resetAll={resetAll}
            resetColumnsState={resetColumnsState}
            resetFiltersState={resetFiltersState}
            resetGridState={resetGridState}
            rowsFromQueryData={assetRowsFromQueryData}
            setDirtyState={setDirtyState}
            setView={setView}
            updateFiltersState={updateFiltersState}
            updateGridPropertyState={updateGridPropertyState}
            getQuery={getAssetsQuery}
            getQueryParams={getAssetsQueryParams}
            viewState={viewState}
            ref={measureRef}
          />
        )}
      </Measure>
      <GridView
        columns={columns}
        defaultColumns={defaultColumns}
        definedColumns={definedColumns}
        filters={filters}
        initialColumnStates={initialColumnStates}
        loading={loading && !promiseInProgress}
        resetAll={resetAll}
        rows={assets}
        setColumns={setColumns}
        setDirtyState={setDirtyState}
        supportedSortColumns={supportedSortColumns}
        totalCount={totalCount}
        updateFiltersState={updateFiltersState}
        updateGridPropertyState={updateGridPropertyState}
        updateGridState={updateGridState}
        useLoadingBackdrop={false}
        viewState={viewState}
        onRef={(methods: GridViewRef) => {
          setGridViewMethods(methods);
        }}
        onRowClick={(row, event) => {
          handleViewAsset(row);
        }}
        yOffset={180 + (dimensions?.height || 0)}
      />
    </Box>
  );
};

export default Assets;
