import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import { getOrganizationsList } from "../actions/orgs";

interface SitesState {
  data?: {
    channelPartners: Array<any>;
    customers: Array<any>;
    installers: Array<any>;
  };
  loading: boolean;
  error?: string;
}

const initialState: SitesState = {
  data: undefined,
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "orgs",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOrganizationsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrganizationsList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getOrganizationsList.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
