import { createSlice } from "@reduxjs/toolkit";

interface InviteUserState {
  open: boolean;
  roles: any[];
  email: string;
  organizations: any[];
  emailValidation: any;
}

const initialState: InviteUserState = {
  open: false,
  roles: ["technician"],
  email: "",
  organizations: [],
  emailValidation: {
    valid: true,
  },
};

const slice = createSlice({
  name: "inviteUser",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setOrganizations(state, action) {
      state.organizations = action.payload;
    },
    setEmailValidation(state, action) {
      state.emailValidation = action.payload;
    },
  },
});
export const {
  setOpen,
  setRoles,
  setEmail,
  setOrganizations,
  setEmailValidation,
} = slice.actions;
export default slice.reducer;
