import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Link,
  Stack,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { SiteItem, mapSiteItemToSite } from "models/site";
import TTStatsText from "components/common/TTStatsText";
import SiteLocation from "./SiteLocation";
import AddSite from "./AddSite";
import { gql, useQuery } from "@apollo/client";
import {
  Query,
  FetchSiteQueryVariables,
  Site,
  AssetEdge,
} from "generated-gql-types/graphql";
import AssetDataGrid from "./AssetDataGrid";
import { enqueueSnackbar } from "notistack";
import { dateToUserLocalTimezone } from "utils/datetime";
import RMKDataGrid from "./RMKDataGrid";
import { useAuthData } from "utils/hooks/useAuthData";

const ViewSite = () => {
  const { orgName, orgId, siteId } = useParams();
  const { ready } = useAuthData();

  const [site, setSite] = useState<Site | undefined>(undefined);

  const [showEditSite, setShowEditSite] = useState<boolean>(false);
  const { data, loading, error } = useQuery<Query, FetchSiteQueryVariables>(
    gql`
      query fetchSite {
        site(siteId: "${siteId}") {
          id
          name
          address
          assets {
            totalCount
            edges {
              asset {
                id
                name
                manufacturer
                modelNumber
                drives {
                  totalCount
                  edges {
                    installedDt
                    drive {
                      id
                      driveModel
                      serialNumber
                      motors {
                        totalCount
                        edges {
                          installedDt
                          motor {
                            id
                            motorModel
                            serialNumber
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          rmks {
            totalCount
          }
          ...SiteLocation_site
          ...RMKDataGrid_site
      }
      }
      ${SiteLocation.fragments.site}
      ${RMKDataGrid.fragments.site}
    `,
    { skip: !ready }
  );

  useEffect(() => {
    if (!loading && data) {
      setSite(data?.site || undefined);
    }
    if (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [data, loading, error]);

  const handleUpdateSite = (updatedSite: SiteItem) => {
    setSite(mapSiteItemToSite(updatedSite));
  };

  const handleCloseEditSite = () => {
    setShowEditSite(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginX: "auto",
      }}
    >
      <Breadcrumbs sx={{ mb: 2 }}>
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          href="/organizations"
        >
          <ChevronLeftIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Organizations
        </Link>
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          href={`/organizations/view/${orgId}`}
        >
          {orgName}
        </Link>
        {site?.name && (
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            {site.name}
          </Typography>
        )}
      </Breadcrumbs>

      {site ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={1}
              sx={{
                padding: 3,
                border: "1px solid #E0E0E0",
              }}
            >
              <Stack width="100%">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      wordWrap: "break-word",
                      paddingRight: 2,
                    }}
                  >
                    {site.name}
                  </Typography>
                  <Button onClick={() => setShowEditSite(true)}>Edit</Button>
                </Stack>

                <Typography
                  variant="body2"
                  sx={{
                    wordWrap: "break-word",
                    paddingRight: 2,
                  }}
                >
                  {site.address}
                </Typography>

                {/* Place holder for site stats. Can be multiple */}
                <Stack direction="row" spacing={4} sx={{ mt: 4 }}>
                  <TTStatsText text={site?.rmks.totalCount || 0} label="RMK" />
                  <TTStatsText
                    text={site?.assets.totalCount || 0}
                    label="Total Assets"
                  />
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 1 }}>
            <SiteLocation site={site} />
          </Grid>
          <Grid item xs={12} overflow="auto" height="calc(100vh-240px)">
            {/* RMK Grid Container */}
            {data?.site?.rmks?.edges?.length ? (
              <Box
                sx={{
                  my: 2,
                  padding: 1,
                  width: "100%",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold", my: 1 }}>
                  Remote Monitoring Kit
                </Typography>
                <RMKDataGrid site={data?.site} loading={loading} />
              </Box>
            ) : null}

            {/* Asset Grid Container */}
            {data?.site?.assets?.edges?.length ? (
              <Box
                sx={{
                  my: 2,
                  padding: 1,
                  width: "100%",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold", my: 1 }}>
                  Assets
                </Typography>
                <AssetDataGrid
                  assets={data?.site?.assets?.edges.map((edge: AssetEdge) => {
                    const driveProps = edge.asset.drives.edges[0]?.drive || {};
                    const motorProps = driveProps.motors?.edges[0]?.motor || {};
                    return {
                      id: edge.asset.id,
                      name: edge.asset.name,
                      manufacturer: edge.asset.manufacturer,
                      modelNumber: edge.asset.modelNumber,
                      motorModel: motorProps.motorModel,
                      motorSerialNumber: motorProps.serialNumber,
                      driveModel: driveProps.driveModel,
                      driveSerialNumber: driveProps.serialNumber,
                      installedDt: dateToUserLocalTimezone(
                        driveProps.motors?.edges[0]?.installedDt ||
                          edge.asset.drives.edges[0]?.installedDt
                      ),
                    };
                  })}
                  loading={loading}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      ) : error ? (
        <Typography variant="h5" sx={{ m: 1 }}>
          Site not found
        </Typography>
      ) : null}
      {/* Edit Site Dialog */}
      <AddSite
        open={showEditSite}
        organizationId={orgId}
        organizationName={orgName}
        onClose={handleCloseEditSite}
        selectedSite={site}
        editMode={true}
        onUpdateSite={handleUpdateSite}
      />
    </Box>
  );
};

export default ViewSite;
