import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useAppDispatch } from "redux/store/hooks";
import { clearAllStoredData } from "redux/reducers/filtersSlice";
import { clearOrgsListData } from "redux/reducers/orgsListSlice";
import { resetAll } from "redux/reducers/assetsViewSlice";
import { resetInventoryGrid } from "redux/reducers/inventoryViewSlice";
import { resetJobsGrid } from "redux/reducers/jobsGridSlice";
import { resetUsersGrid } from "redux/reducers/usersGridSlice";
import { resetRolesGrid } from "redux/reducers/rolesGridSlice";
import { resetUserRolesGrid } from "redux/reducers/userRolesGridSlice";
import { useApolloClient } from "@apollo/client";

export function Logout() {
  const { loginWithRedirect } = useAuth0();
  const dispatch = useAppDispatch();
  dispatch(clearAllStoredData());
  dispatch(clearOrgsListData());
  dispatch(resetAll());
  dispatch(resetJobsGrid());
  dispatch(resetUsersGrid());
  dispatch(resetRolesGrid());
  dispatch(resetUserRolesGrid());
  dispatch(resetInventoryGrid());

  useEffect(() => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}${"/oauth2/callback"}`,
    });
  }, [loginWithRedirect]);

  return null;
}
export function DoLogout() {
  const { logout } = useAuth0();
  const client = useApolloClient();

  useEffect(() => {
    logout({
      returnTo: `${window.location.origin}${"/logout"}`,
    });
    client.resetStore();
  }, [client, logout]);

  return null;
}
