import * as React from "react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { chunk } from "lodash";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "redux/store/hooks";
import { enqueueSnackbar } from "notistack";

import { JobItem } from "models/jobs";
import { Constants } from "utils/constants";
import StatusInfo from "./statusInfo";
import MCAlertInfo from "./mcAlertInfo";
import MotorSettingsInfo from "./motorSettingsInfo";
import MediaViewer from "components/mediaViewer";
import TTEditableText from "components/common/TTEditableText";
import TTEditableDateTime from "components/common/TTEditableDateTime";
import TTPickList from "components/common/TTPickList";
import { addAttachment } from "redux/actions/jobs";
import DecimalIncrementValue from "utils/getDecimalIncrementValue";
import getAttachmentFileExtension from "utils/getAttachmentFileExtension";

const JobFieldItem = (props: {
  surveyResultId: string;
  item: JobItem;
  templateItem?: any;
  editMode?: boolean;
  showNotes?: boolean;
  onChange?: (
    field: string,
    newValue: string,
    templateItemId: string,
    fieldItem?: string
  ) => void;
}) => {
  const { surveyResultId, editMode, templateItem, onChange } = props;
  const { id, attachments, data_type, notes, value, raw_value } = props.item;
  const dispatch = useAppDispatch();
  const { getIdTokenClaims } = useAuth0();

  const [currentAttachments, setCurrentAttachments] = React.useState<
    Array<any>
  >([]);
  const [showMediaDialog, setShowMediaDialog] = React.useState<boolean>(false);
  const [media, setMedia] = React.useState<any>();
  const [showNotes, setShoNotes] = React.useState<boolean>(false);

  React.useEffect(() => {
    setCurrentAttachments(attachments);
  }, [attachments]);

  const RenderMediaThumbnail = (props: { imageList: any }) => {
    const { imageList } = props;
    const photos = chunk(imageList, 3);
    return (
      <React.Fragment>
        {photos.map((row, rowIndex) => (
          <Grid key={rowIndex} container spacing={1} sx={{ marginBottom: 1 }}>
            {row.map((photo: any, photoIndex) => (
              <Grid
                key={photoIndex}
                item
                sx={{ height: "172px", width: "172px", cursor: "pointer" }}
                onClick={() =>
                  handleOpenMedia({
                    original: photo.url,
                    thumbnail: photo.thumbnail_url,
                  })
                }
              >
                <img
                  src={
                    data_type === Constants.JOB_DATA_TYPES.AUDIO
                      ? "/images/audio.png"
                      : photo.thumbnail_url
                  }
                  loading="lazy"
                  style={{ width: "100%", height: "100%", borderRadius: 4 }}
                  alt="Media Thumbnail"
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </React.Fragment>
    );
  };

  const handleOpenMedia = (mediaObj: any) => {
    /**Check for pdf and open in new window */
    if (getAttachmentFileExtension(mediaObj?.original) === "pdf") {
      window.open(mediaObj.original, "_blank");
      return;
    }

    setMedia(mediaObj);
    setShowMediaDialog(true);
  };

  const handleChangeFieldValue = (itemId: string, newValue: string) => {
    onChange?.(itemId, newValue, templateItem?.item_id);
  };

  const handleEditNote = (newValue: string) => {
    onChange?.(id, newValue, templateItem?.item_id, "notes");
  };

  const renderField = () => {
    switch (data_type) {
      case Constants.JOB_DATA_TYPES.TEXT:
        return (
          <TTEditableText
            value={value || ""}
            editable={editMode || false}
            type="text"
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.INTEGER:
        return (
          <TTEditableText
            value={value || ""}
            editable={editMode || false}
            type="number"
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.DECIMAL:
        return (
          <TTEditableText
            value={value || ""}
            editable={editMode || false}
            type="number"
            inputProps={{
              step: value ? DecimalIncrementValue(value) : 0.1,
            }}
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.PICKLIST:
      case Constants.JOB_DATA_TYPES.PASSFAIL:
        return (
          <TTPickList
            defaultValue={value}
            rawValue={raw_value}
            options={templateItem?.picklist_options}
            itemId={templateItem?.item_id}
            editable={editMode || false}
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.JSON:
        return <Typography>{value}</Typography>;
      case Constants.JOB_DATA_TYPES.PHOTO:
      case Constants.JOB_DATA_TYPES.DRAWING:
      case Constants.JOB_DATA_TYPES.VIDEO:
      case Constants.JOB_DATA_TYPES.MEDIA:
      case Constants.JOB_DATA_TYPES.AUDIO:
        return <RenderMediaThumbnail imageList={value} />;
      case Constants.JOB_DATA_TYPES.DATETIME:
        return (
          <TTEditableDateTime
            defaultValue={raw_value}
            editable={editMode || false}
            type="dateTime"
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.DATE:
        return (
          <TTEditableDateTime
            defaultValue={raw_value}
            editable={editMode || false}
            type="date"
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.TIME:
        return (
          <TTEditableDateTime
            defaultValue={moment(raw_value, "HH:mm").format("MM/DD/YYYY HH:mm")}
            editable={editMode || false}
            type="time"
            onChange={(newValue: string) =>
              handleChangeFieldValue(id, newValue)
            }
          />
        );
      case Constants.JOB_DATA_TYPES.WIFI:
        return (
          <StatusInfo
            value={{
              RSSI: {
                issue: value?.RSSI,
                result: value?.Result,
              },
            }}
          />
        );
      case Constants.JOB_DATA_TYPES.WIRING:
      case Constants.JOB_DATA_TYPES.SENSOR:
        return <StatusInfo value={value} />;
      case Constants.JOB_DATA_TYPES.COMMISSIONING:
        return <StatusInfo value={value} />;
      case Constants.JOB_DATA_TYPES.MCALERT:
        return <MCAlertInfo value={value} />;
      case Constants.JOB_DATA_TYPES.MOTORSETTINGS:
        return <MotorSettingsInfo value={value} />;
      default:
        return null;
    }
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    const formData = new FormData();
    formData.append("file", file);
    (async () => {
      const idToken = await getIdTokenClaims();
      dispatch(
        addAttachment({
          token: idToken?.__raw,
          jobId: surveyResultId,
          templateItemId: templateItem?.item_id,
          file: formData,
        })
      ).then((resp) => {
        if (resp?.payload?.error) {
          enqueueSnackbar(resp.payload.error, { variant: "error" });
        } else if (resp && resp.payload) {
          enqueueSnackbar(`Attachment added successfully!`, {
            variant: "default",
          });
          setCurrentAttachments([...currentAttachments, resp.payload.result]);
        } else {
          enqueueSnackbar("Failed to add attachment", {
            variant: "error",
          });
        }
      });
    })();
  };

  return (
    <Box sx={{ width: "100%", borderBottom: "1px solid #ccc", padding: 2 }}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="body1" sx={{ fontSize: 12, marginBottom: 1 }}>
          {templateItem.name}
        </Typography>
        {editMode && (
          <Stack flexDirection="row" justifyContent="flex-end">
            <Tooltip
              arrow
              data-tooltip-id="attach-file-cell-tooltip"
              title="Add image, video, or file"
            >
              <label htmlFor={`upload-file-for-${templateItem?.item_id}`}>
                <IconButton
                  color="primary"
                  size="small"
                  aria-label="Add image, video, or file"
                  component="span"
                >
                  <AttachFileIcon />
                </IconButton>
                <input
                  id={`upload-file-for-${templateItem?.item_id}`}
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>
            </Tooltip>
            {!notes && (
              <Tooltip
                arrow
                data-tooltip-id="add-note-cell-tooltip"
                title="Add note"
              >
                <IconButton
                  color="primary"
                  size="small"
                  aria-label="Add note"
                  onClick={() => setShoNotes(true)}
                >
                  <ContentPasteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
      </Stack>

      {renderField()}

      {/* Render Notes */}
      {notes || showNotes ? (
        <Box>
          <Typography variant="body1" sx={{ fontSize: 12, marginTop: 1 }}>
            Note:
          </Typography>
          <TTEditableText
            value={notes || ""}
            editable={editMode || false}
            type="text"
            multiline={true}
            onChange={handleEditNote}
          />
        </Box>
      ) : null}

      {/* Render Attachments */}
      {currentAttachments && currentAttachments.length > 0 && (
        <Box mt={1}>
          <Chip label="Attachment" size="small" sx={{ my: 2 }} />
          <RenderMediaThumbnail imageList={currentAttachments} />
        </Box>
      )}
      <MediaViewer
        open={showMediaDialog}
        media={media}
        type={data_type}
        onClose={() => setShowMediaDialog(false)}
      />
    </Box>
  );
};

export default JobFieldItem;
