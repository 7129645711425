import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/store/hooks";
import { useViewOrganizationStyles } from "../organizations/view/styles";
import * as assignTtRolesToUserModal from "redux/reducers/assignTtRolesToUserSlice";
import * as inviteUserDialog from "redux/reducers/inviteUserSlice";
import { getOrganizationsList } from "redux/actions/orgs";
import { inviteUser } from "redux/actions/users";
import { getRolesForOrgs } from "redux/actions/orgs";
import { useAuthData } from "utils/hooks/useAuthData";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import TTAutoComplete from "./TTAutoComplete/TTAutoComplete";
import TTTextField from "./TTTextField";
import AddIcon from "@mui/icons-material/Add";
import { Constants } from "utils/constants";
import { OrgItem } from "models/organizations/assignorgtoorgitem";
import { PayloadAction } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";

export interface InviteUserDialogProps {
  onInviteClicked?: () => void;
}

const constructOrganizations = (
  channelPartners: any[] | undefined,
  customers: any[] | undefined,
  installers: any[] | undefined
) => {
  let organizations = channelPartners || [];
  organizations = customers ? [...organizations, ...customers] : organizations;
  organizations = installers
    ? [...organizations, ...installers]
    : organizations;

  organizations = organizations.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.organization_id === value.organization_id)
  );
  return organizations.sort((a: OrgItem, b: OrgItem) =>
    a.organization_name > b.organization_name
      ? 1
      : b.organization_name > a.organization_name
      ? -1
      : 0
  );
};

export const InviteUserDialog = (props: InviteUserDialogProps) => {
  const { hasAccess, getRawIdToken } = useAuthData();
  const { classes } = useViewOrganizationStyles();
  const assignTtRolesToUser = useAppSelector(
    (state) => state.assignTtRolesToUser
  );
  const inviteUserModal = useAppSelector((state) => state.inviteUser);
  const channelPartners = useAppSelector(
    (state) => state.orgs.data?.channelPartners
  );
  const customers = useAppSelector((state) => state.orgs.data?.customers);
  const installers = useAppSelector((state) => state.orgs.data?.installers);
  const { organizationId } = useParams();
  const data = useAppSelector((state) => state.orgDetails.data.org);

  const [selectedOrgs, setSelectedOrgs] = useState<OrgItem[]>(
    organizationId ? [data] : []
  );

  const dispatch = useAppDispatch();

  const handleRoleSelected = (newValue: any) => {
    dispatch(assignTtRolesToUserModal.setSelectedRoles(newValue));
  };

  const handleInviteUserEmailChange = (event: any) => {
    const newVal = event.target.value;
    if (
      newVal !== inviteUserModal.email &&
      inviteUserModal.emailValidation?.message
    ) {
      dispatch(
        inviteUserDialog.setEmailValidation({
          valid: true,
          message: "",
        })
      );
    }
    dispatch(inviteUserDialog.setEmail(newVal));
  };

  const isInvalidEmailAddress = (email: string) => {
    return !/^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,10}\b$/i.test(
      email
    );
  };

  const validateUserEmail = (event: any) => {
    dispatch(inviteUserDialog.setEmail(event.target.value));
    const newVal = event.target.value;
    if (newVal === null || newVal === undefined || newVal === "") {
      dispatch(
        inviteUserDialog.setEmailValidation({
          valid: false,
          message: "Email address is required",
        })
      );
    } else if (isInvalidEmailAddress(newVal)) {
      dispatch(
        inviteUserDialog.setEmailValidation({
          valid: false,
          message: "Email address is not valid",
        })
      );
    } else {
      dispatch(
        inviteUserDialog.setEmailValidation({
          valid: true,
          message: "",
        })
      );
    }
  };

  const toastInviteResult = (resp: PayloadAction<any>) => {
    if (resp && resp.payload && resp.payload.success) {
      enqueueSnackbar("Invite sent!", { variant: "default" });
      return true;
    } else {
      enqueueSnackbar("Failed to invite user!", { variant: "error" });
      return false;
    }
  };

  const handleInviteUserClick = () => {
    (async () => {
      handleInviteUserDialogClose();
      const idToken = await getRawIdToken();
      dispatch(
        inviteUser({
          token: idToken,
          organizationIds: selectedOrgs.map(
            (org: OrgItem) => org.organization_id
          ),
          email: inviteUserModal.email,
          roles: inviteUserModal.roles, // actually Product access
          roleIds: assignTtRolesToUser.selectedRoles.map((r) => {
            return r.role_id;
          }),
        })
      ).then((resp) => {
        if (toastInviteResult(resp)) {
          // add roles to user
          dispatch(
            assignTtRolesToUserModal.setCurrentRoles(
              assignTtRolesToUser.selectedRoles
            )
          );
          props.onInviteClicked && props.onInviteClicked();
        }
      });
    })();
  };

  const handleInviteUserDialogOpen = async () => {
    setDefaultsForUserInvite();
    const idToken = await getRawIdToken();

    if (organizationId) {
      dispatch(
        inviteUserDialog.setOrganizations([
          {
            organization_id: data.organization_id,
            organization_name: data.organization_name,
          },
        ])
      );
      dispatch(
        getRolesForOrgs({
          token: idToken,
          organizationIds: [organizationId],
        })
      ).then(() => {
        dispatch(inviteUserDialog.setOpen(true));
      });
    } else {
      let orgs;
      if (
        (channelPartners?.length || 0) +
        (customers?.length || 0) +
        (installers?.length || 0)
      ) {
        orgs = constructOrganizations(channelPartners, customers, installers);
      } else {
        const orgsResponse = (await dispatch(
          getOrganizationsList({ token: idToken })
        )) as any;
        orgs = constructOrganizations(
          orgsResponse.payload.channelPartners,
          orgsResponse.payload.customers,
          orgsResponse.payload.installers
        );
      }
      dispatch(inviteUserDialog.setOrganizations(orgs));
      dispatch(
        getRolesForOrgs({
          token: idToken,
          organizationIds: orgs.map((org) => org.organization_id),
        })
      ).then(() => {
        dispatch(inviteUserDialog.setOpen(true));
      });
    }
  };

  const handleInviteUserDialogClose = () => {
    setDefaultsForUserInvite();
    setSelectedOrgs(organizationId ? [data] : []);
    dispatch(inviteUserDialog.setOpen(false));
  };

  const setDefaultsForUserInvite = () => {
    dispatch(assignTtRolesToUserModal.setSelectedRoles([]));
    dispatch(inviteUserDialog.setEmail(""));
    dispatch(
      inviteUserDialog.setEmailValidation({
        valid: true,
        message: "",
      })
    );
  };

  const handleSelectedOrgsChange = (value: OrgItem[]) => {
    setSelectedOrgs(value);
  };

  return (
    <>
      {hasAccess(Constants.ACTIONS.INVITE_USER_TO_ORG) && (
        <Button
          size="medium"
          variant="contained"
          onClick={handleInviteUserDialogOpen}
        >
          {" "}
          <AddIcon style={{ marginRight: "12px" }} />
          Invite user
        </Button>
      )}
      <Dialog open={inviteUserModal.open} onClose={handleInviteUserDialogClose}>
        <Box
          style={{
            width: "593px",
            padding: "16px 24px 24px 20px",
          }}
        >
          <Typography variant="h6">
            Invite User
            {organizationId &&
              inviteUserModal.organizations[0]?.organization_name &&
              ` to ${inviteUserModal.organizations[0]?.organization_name}`}
          </Typography>
          <Grid container direction="column" mt={1}>
            <Grid item mt={2}>
              Please be sure to use your company or employer's email address
              instead of a personal one.
            </Grid>
            <Grid item mt={2}>
              <TTTextField
                label="User Email"
                value={inviteUserModal.email}
                variant="outlined"
                size="normal"
                fullWidth
                onChange={handleInviteUserEmailChange}
                onBlur={validateUserEmail}
                error={!!inviteUserModal.emailValidation?.message}
                helperText={
                  inviteUserModal.emailValidation?.message ||
                  "User company or employer email address"
                }
                autoFocus
              />
            </Grid>
            {!organizationId && (
              <Grid item mt={2}>
                <TTAutoComplete
                  options={inviteUserModal.organizations}
                  label="Assign Organization"
                  primaryField="organization_name"
                  primaryKey="organization_id"
                  onChange={handleSelectedOrgsChange}
                  value={selectedOrgs}
                  classes={{ root: classes.rolesAutoComplete }}
                  size="medium"
                />
              </Grid>
            )}
            <Grid item mt={3}>
              <Typography variant="button">PRODUCT ACCESS</Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                style={{ paddingLeft: "9px" }}
                disabled
                control={<Checkbox checked />}
                label="Technician"
              />
            </Grid>
            <Grid item mt={2}>
              <Typography variant="button">ROLES</Typography>
            </Grid>
            <Grid item>
              <TTAutoComplete
                options={
                  assignTtRolesToUser?.availableRoles
                    ? assignTtRolesToUser?.availableRoles
                    : []
                }
                label="start typing to search available roles..."
                primaryField="role_name"
                primaryKey="role_id"
                onChange={handleRoleSelected}
                value={assignTtRolesToUser.selectedRoles}
                classes={{
                  root: classes.rolesAutoComplete,
                }}
                size="medium"
                filterSelectedOptions={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.assignModalButtons}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                size="medium"
                onClick={handleInviteUserDialogClose}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                onClick={handleInviteUserClick}
                disabled={
                  !inviteUserModal.email ||
                  !!inviteUserModal.emailValidation?.message ||
                  selectedOrgs.length < 1
                }
              >
                Invite
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
