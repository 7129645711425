import {
  Box,
  Button,
  Grid,
  Menu,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Chip,
  Divider,
  Autocomplete,
} from "@mui/material";
import { useOrgsListfilterStyles } from "./styles";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  filterOrgsList,
  updateFilterTagsValue,
} from "redux/reducers/orgsListSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Constants } from "utils/constants";
import { orgTypeNameFromType } from "utils/customerTypes";
import CustomerTypeChip from "components/common/customertypechip";
import { useAppSelector } from "redux/store/hooks";
import { ListFilterTag } from "models/misc/listFilterTag";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

const doOrgsSearch = (
  filterTagsValue: ListFilterTag[],
  dispatch: Dispatch<AnyAction>
) => {
  const types: any[] = [];
  const phrases: string[] = [];
  const orgIds: string[] = [];
  filterTagsValue.forEach((tag) => {
    if (tag.type === "ctype") {
      types.push(tag.key);
    } else if (tag.type === "phrase") {
      phrases.push(tag.key);
    } else {
      orgIds.push(tag.key);
    }
  });
  dispatch(filterOrgsList({ phrases: phrases, orgIds: orgIds, types: types }));
  dispatch(updateFilterTagsValue(filterTagsValue));
};

const OrgsListFilter = forwardRef((props, ref) => {
  const { classes } = useOrgsListfilterStyles();
  const dispatch = useDispatch();
  const orgs = useAppSelector((state) => state.orgsList.data?.orgs);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDirty, setDirty] = useState<Boolean>(false);
  const [filterTagsValue, setFilterTagsValue] = useState<ListFilterTag[]>([]);
  const [filterTagsInitialized, setFilterTagsInitialized] = useState(false);
  const [
    autocompleteOptionsInitialized,
    setFilteredAutocompleteOptionsInitialized,
  ] = useState(false);
  const filterTagsValuePersistent = useAppSelector(
    (state) => state.orgsList?.data?.filterTagsValue
  );
  const [filteredAutocompleteOptions, setFilteredAutocompleteOptions] =
    useState<any[]>(orgs);
  useEffect(() => {
    if (filterTagsValuePersistent && !filterTagsInitialized) {
      const orgTypesFromTags = filterTagsValuePersistent
        .filter((tag) => tag.type === "ctype")
        .map((tag) => tag.key);

      setSelectedOrgTypes(orgTypesFromTags);
      setFilterTagsValue(filterTagsValuePersistent);
      setFilterTagsInitialized(true);
    }
  }, [filterTagsValuePersistent, filterTagsInitialized]);

  useEffect(() => {
    if (orgs?.length > 0 && !autocompleteOptionsInitialized) {
      const newFilteredOptions = orgs.filter(
        (org) =>
          !filterTagsValuePersistent.some(
            (tag) => tag.key === org.organization_id
          )
      );
      setFilteredAutocompleteOptions(newFilteredOptions);
      setFilteredAutocompleteOptionsInitialized(true);
    }
  }, [orgs, autocompleteOptionsInitialized]);

  useEffect(() => {
    const removedOrgIds = filterTagsValue.map((tag) => tag.key);
    const newFilteredOptions = orgs?.filter(
      (org) => !removedOrgIds.includes(org.organization_id)
    );
    setFilteredAutocompleteOptions(newFilteredOptions);
  }, [filterTagsValue]);

  const [autocompleteValue, setAutocompleteValue] = useState<any[]>([]);
  const open = Boolean(anchorEl);

  const [selectedOrgTypes, setSelectedOrgTypes] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(
    Object.keys(Constants.ORGANIZATION_TYPE_TO_NAME_MAP).length ===
      selectedOrgTypes.length
  );

  useEffect(() => {
    doOrgsSearch(filterTagsValue, dispatch);
  }, [filterTagsValue, doOrgsSearch]);
  const handleOrgNameSelected = (value: any[]) => {
    setAutocompleteValue(value);
    if (value?.length && value?.length > 0) {
      const selectedItem = value[value.length - 1];
      const newFilteredOptions = filteredAutocompleteOptions.filter(
        (option) => option.organization_id !== selectedItem.organization_id
      );
      setFilteredAutocompleteOptions(newFilteredOptions);

      let item = value[value?.length - 1];
      if (typeof item === "string") {
        setFilterTagsValue([
          ...filterTagsValue,
          { key: item, label: item, type: "phrase" },
        ]);
      } else {
        setFilterTagsValue([
          ...filterTagsValue,
          {
            key: item.organization_id,
            label: item.organization_name,
            type: "org",
          },
        ]);
      }
    } else {
      const _filterTagsValue = [...filterTagsValue];
      const newTagValue = _filterTagsValue.filter(
        (tag) => tag.type === "ctype"
      );
      setFilterTagsValue(newTagValue);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    template: any
  ) => {
    setDirty(true);
    if (selectAll) {
      setSelectAll(false);
      setSelectedOrgTypes([]);
      let newFilterTags = filterTagsValue.filter((t) => t.type !== "ctype");
      setFilterTagsValue(newFilterTags);
    } else {
      setSelectAll(true);
      let _selectedOrgTypes = [...selectedOrgTypes];
      let _filterTagsValue = [...filterTagsValue];
      Object.keys(Constants.ORGANIZATION_TYPE_TO_NAME_MAP).forEach((key) => {
        if (!selectedOrgTypes.includes(key)) {
          _selectedOrgTypes.push(key);
          _filterTagsValue.push({
            key: key,
            label: orgTypeNameFromType(key),
            type: "ctype",
          });
        }
      });
      setSelectedOrgTypes(_selectedOrgTypes);
      setFilterTagsValue(_filterTagsValue);
    }
  };
  const handleClose = () => {
    if (isDirty) {
      setDirty(false);
    }
    setAnchorEl(null);
  };
  const handleTypeSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setDirty(true);
    let _selectedOrgTypes = [...selectedOrgTypes];
    const index = _selectedOrgTypes?.indexOf(key);
    if (index > -1 && !event.target.checked) {
      _selectedOrgTypes.splice(index, 1);

      let _filterTagsValue = [...filterTagsValue];
      let item = _filterTagsValue.find((t) => t.key === key);
      if (item) {
        const itemIndex = _filterTagsValue?.indexOf(item);
        if (itemIndex > -1) {
          _filterTagsValue.splice(itemIndex, 1);
        }
        setFilterTagsValue([..._filterTagsValue]);
      }
    } else if (event.target.checked) {
      _selectedOrgTypes.push(key);
      setFilterTagsValue([
        ...filterTagsValue,
        { key: key, label: orgTypeNameFromType(key), type: "ctype" },
      ]);
    }
    setSelectedOrgTypes([..._selectedOrgTypes]);
  };
  const handleOrgTypeRemoved = (key: string) => {
    setDirty(true);
    let _selectedOrgTypes = [...selectedOrgTypes];
    const index = _selectedOrgTypes?.indexOf(key);
    if (index > -1) {
      _selectedOrgTypes.splice(index, 1);
    }
    setSelectedOrgTypes([..._selectedOrgTypes]);
  };
  const handleRemoveFilterChip = (tag: ListFilterTag) => {
    setDirty(true);
    let _filterTagsValue = [...filterTagsValue];
    const index = _filterTagsValue?.indexOf(tag);
    if (index > -1) {
      _filterTagsValue.splice(index, 1);
    }
    setFilterTagsValue([..._filterTagsValue]);
    if (tag.type === "ctype") {
      handleOrgTypeRemoved(tag.key);
    } else {
      handleOrgNameFilterRemoved(tag.key, tag.type);
    }
  };
  const handleOrgNameFilterRemoved = (key: string, type: string) => {
    setDirty(true);
    let _autocompleteValue = [...autocompleteValue];
    let item =
      type === "phrase"
        ? _autocompleteValue.find((e) => e === key)
        : _autocompleteValue.find((e) => e.organization_id === key);

    const index = _autocompleteValue?.indexOf(item);
    if (index > -1) {
      _autocompleteValue.splice(index, 1);
    }

    setAutocompleteValue([..._autocompleteValue]);
  };

  return orgs && orgs.length ? (
    <Box marginBottom={2} ref={ref}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Autocomplete
                multiple
                autoComplete={true}
                id="org-name-autocomplete"
                options={filteredAutocompleteOptions}
                onChange={(e, newValue) => handleOrgNameSelected(newValue)}
                value={autocompleteValue}
                size="small"
                getOptionLabel={(option: any) => option.organization_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    className={classes.nameFilter}
                    label="search by name..."
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                    }}
                    placeholder="Search"
                  />
                )}
                renderTags={() => null}
                freeSolo={true}
                filterSelectedOptions={true}
              />
            </Grid>
            <Grid item>
              {" "}
              <Button
                id="basic-button"
                variant="outlined"
                size="medium"
                sx={{
                  borderColor: "#c4c4c4",
                  padding: "7px",
                  color: "#6f6f6f",
                  textTransform: "none",
                  marginLeft: "10px",
                }}
                endIcon={<ArrowDropDownIcon />}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {"Type"}
                {selectedOrgTypes && selectedOrgTypes.length > 0 && (
                  <span>{`(${selectedOrgTypes.length})`}</span>
                )}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Box sx={{ px: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          onChange={handleSelectAll}
                          checked={
                            Object.keys(Constants.ORGANIZATION_TYPE_TO_NAME_MAP)
                              .length === selectedOrgTypes.length
                          }
                          indeterminate={
                            Object.keys(Constants.ORGANIZATION_TYPE_TO_NAME_MAP)
                              .length > selectedOrgTypes.length &&
                            selectedOrgTypes.length > 0
                          }
                        />
                      }
                      label={selectAll ? "Deselect All" : "Select All"}
                    />
                    <Divider />
                    {Object.keys(Constants.ORGANIZATION_TYPE_TO_NAME_MAP).map(
                      (key) => {
                        return (
                          <FormControlLabel
                            key={key}
                            control={
                              <Checkbox
                                size="small"
                                checked={selectedOrgTypes?.indexOf(key) > -1}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => handleTypeSelectionChange(event, key)}
                              />
                            }
                            label={Constants.ORGANIZATION_TYPE_TO_NAME_MAP[key]}
                          />
                        );
                      }
                    )}
                  </FormGroup>
                </Box>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            {filterTagsValue?.map((item) => (
              <Grid item key={item.key}>
                {item.type === "ctype" ? (
                  <CustomerTypeChip
                    sx={{ marginTop: "10px" }}
                    key={item.key}
                    type={item.key}
                    onDelete={() => handleRemoveFilterChip(item)}
                  />
                ) : (
                  <Box
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    <Chip
                      sx={{ marginTop: "10px" }}
                      key={item.key}
                      className={classes.orgListFilterChip}
                      label={item.label}
                      size="small"
                      onDelete={() => handleRemoveFilterChip(item)}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <></>
  );
});
export default OrgsListFilter;
