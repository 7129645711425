import {
  Box,
  Grid,
  TextField,
  Chip,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useSitesListFilterStyles } from "./styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/store/hooks";
import { ListFilterTag } from "models/misc/listFilterTag";
import { filterOrgSites } from "redux/reducers/orgDetailsSlice";

const SitesListFilter = () => {
  const { classes } = useSitesListFilterStyles();
  const dispatch = useDispatch();
  const orgs = useAppSelector((state) => state.orgDetails.data?.org?.sites);
  const [filterTagsValue, setFilterTagsValue] = useState<ListFilterTag[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<any[]>([]);

  useEffect(() => {
    doSitesSearch();
  }, [filterTagsValue]);

  const handleSiteNameSelected = (value: any[]) => {
    setAutocompleteValue(value);
    if (value?.length && value?.length > 0) {
      let item = value[value?.length - 1];
      if (typeof item === "string") {
        setFilterTagsValue([
          ...filterTagsValue,
          { key: item, label: item, type: "phrase" },
        ]);
      } else {
        setFilterTagsValue([
          ...filterTagsValue,
          {
            key: item.site_id,
            label: item.name,
            type: "site",
          },
        ]);
      }
    } else {
      setFilterTagsValue([]);
    }
  };
  const handleRemoveFilterChip = (tag: ListFilterTag) => {
    let _filterTagsValue = [...filterTagsValue];
    const index = _filterTagsValue?.indexOf(tag);
    if (index > -1) {
      _filterTagsValue.splice(index, 1);
    }
    setFilterTagsValue([..._filterTagsValue]);

    handleSiteNameFilterRemoved(tag.key, tag.type);
  };
  const handleSiteNameFilterRemoved = (key: string, type: string) => {
    let _autocompleteValue = [...autocompleteValue];
    let item =
      type === "phrase"
        ? _autocompleteValue.find((e) => e === key)
        : _autocompleteValue.find((e) => e.site_id === key);

    const index = _autocompleteValue?.indexOf(item);
    if (index > -1) {
      _autocompleteValue.splice(index, 1);
    }

    setAutocompleteValue([..._autocompleteValue]);
  };

  const doSitesSearch = () => {
    let phrases: string[] = [];
    let siteIds: any[] = [];
    filterTagsValue.forEach((tag) => {
      if (tag.type === "phrase") {
        phrases.push(tag.key);
      } else {
        siteIds.push(tag.key);
      }
    });
    dispatch(filterOrgSites({ phrases: phrases, siteIds: siteIds }));
  };
  return orgs && orgs.length ? (
    <Box marginBottom={1} paddingLeft={2} paddingTop={2}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Autocomplete
                multiple
                autoComplete={true}
                id="org-name-autocomplete"
                options={orgs}
                onChange={(e, newValue) => handleSiteNameSelected(newValue)}
                value={autocompleteValue}
                size="small"
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    className={classes.nameFilter}
                    label="search by name, site #, address..."
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                renderTags={() => null}
                freeSolo={true}
                filterSelectedOptions={true}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (item) =>
                      (item.name &&
                        item.name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())) ||
                      (item.address &&
                        item.address
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option: any) => (
                  <Box component="li" {...props} key={option.site_id}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="left"
                      alignItems="start"
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontSize: 14,
                          textOverflow: "ellipsis",
                          width: 300,
                        }}
                      >
                        {option.name}
                      </Typography>

                      <Typography
                        noWrap
                        sx={{
                          fontSize: 14,
                          textOverflow: "ellipsis",
                          width: 300,
                        }}
                      >
                        {option.address}
                      </Typography>
                    </Grid>
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            {filterTagsValue?.map((item) => (
              <Grid item key={item.key}>
                <Box
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <Chip
                    sx={{ marginTop: "10px" }}
                    key={item.key}
                    className={classes.siteListFilterChip}
                    label={item.label}
                    size="small"
                    onDelete={() => handleRemoveFilterChip(item)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <></>
  );
};
export default SitesListFilter;
