import { Grid, Chip } from "@mui/material";
import { useAssetsStyles } from "./styles";
import { FilterItem } from "utils/types";

const FilterChips = (props: {
  chips: any[];
  onDelete?: (item: FilterItem) => void;
}) => {
  const { chips, onDelete } = props;
  const { classes } = useAssetsStyles();

  const handleRemoveChip = (item: FilterItem) => {
    onDelete?.(item);
  };

  return (
    <>
      {chips?.map((item) => (
        <Grid item key={item.key}>
          <Chip
            key={item.key}
            className={classes.filterChip}
            label={item.label}
            size="small"
            onDelete={() => handleRemoveChip(item)}
          />
        </Grid>
      ))}
    </>
  );
};

export default FilterChips;
