import { Stack, Typography, Tooltip, Link } from "@mui/material";

const PropertyDetail = (props: {
  value: string | React.ReactElement;
  label: string;
  titleTextTransform?: string;
  tooltip?: string;
  onClick?: () => void;
}) => {
  const { label, value, titleTextTransform, tooltip, onClick } = props;
  return (
    <Stack>
      <Typography
        sx={{
          textTransform: titleTextTransform || "none",
          marginTop: 2,
          fontSize: 12,
        }}
      >
        {label}
      </Typography>
      <Tooltip
        title={tooltip || props.value || ""}
        arrow
        placement="bottom-start"
        data-tooltip-id="property-detail-tooltip"
        enterDelay={700}
      >
        <Typography
          variant="subtitle1"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {onClick ? (
            <Link underline="none" onClick={onClick} sx={{ cursor: "pointer" }}>
              {value}
            </Link>
          ) : (
            value
          )}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default PropertyDetail;
