// This probably belongs in index.css, but borderRadius appears to be ignored if I put it there
export const dataGridStyle = {
  width: "100%",
  borderRadius: 4,
};

// So far, having MUI apply this style is the only way I've managed to get
// full-row hover highlights to work.
export const dataGridParentStyle = {
  "& .rdg-row:hover > div": {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
};

export const tinyToolTip: React.CSSProperties = {
  position: "absolute",
  left: "32px",
  top: "6px",
  color: "#333333",
  fontSize: "12px",
  background: "#f4f4f4",
  border: "1px solid #aaaaaa",
  lineHeight: 2,
  padding: "0 20px 0 8px",
};
