import { gql, useMutation } from "@apollo/client";
import { SetInventoryFailureDateInput } from "generated-gql-types/graphql";

const SET_FAILURE_DATE_MUTATION = gql`
  mutation SetFailureDate($input: SetInventoryFailureDateInput!) {
    setInventoryFailureDate(input: $input) {
      success
      error
      item {
        id
        warranty {
          id
          failureDt
          status
        }
      }
    }
  }
`;

export function useSetFailureDate() {
  const [mutate, updateResult] = useMutation(SET_FAILURE_DATE_MUTATION);
  const setFailureDate = (input: SetInventoryFailureDateInput) =>
    mutate({ variables: { input: input } });
  return { setFailureDate, updateResult };
}
