/*This is a custome chip to fecilitate ToolTip for chips */
import * as React from "react";
import { Constants } from "utils/constants";
import { userCustomerTypeChipStyles } from "./styles";
import { orgTypeNameFromType } from "utils/customerTypes";
import { Box, Chip } from "@mui/material";

const CustomerTypeChip = (props: any) => {
  const { classes } = userCustomerTypeChipStyles();
  const ref = React.useRef();
  let type = props?.type;
  let label = "Customer";
  let chipClass = classes.customerChip;
  if (type) {
    type = type.toUpperCase();
    label = orgTypeNameFromType(type);
    if (type === Constants.ORGANIZATION_TYPE.INSTALLER) {
      chipClass = classes.installerChip;
    } else if (type === Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER) {
      chipClass = classes.channelPartnerChip;
    }
  }
  return (
    <Box
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        verticalAlign: "middle",
      }}
      ref={ref}
    >
      <Chip
        {...props}
        className={[classes.customerTypeChip, chipClass].join(" ")}
        label={label}
        size="small"
      />
    </Box>
  );
};

export default CustomerTypeChip;
