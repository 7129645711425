import * as React from "react";
import {
  Button,
  Collapse,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  Asset,
  AssetEdge,
  InventoryItem,
  Site,
} from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";
import {
  NOT_AVAILABLE,
  PropertyDataType,
  AssetCardDetail,
} from "../../assets/view/AssetCardDetail";
import { CardSkeleton } from "components/common/CardSkeleton";
import { dateToUserLocalTimezone, utcDate } from "utils/datetime";
import { useAuthData } from "utils/hooks/useAuthData";
import { useNavigate } from "react-router";
import { Constants } from "utils/constants";
import {
  WarrantyStatusBadge,
  warrantyStatusLabel,
} from "../warrantyStatusBadge";

const SiteCard = (props: { site: Site; asset: Asset | undefined }) => {
  const { site, asset } = props;
  const { hasAccess } = useAuthData();
  const navigate = useNavigate();
  const handleViewSite = () => {
    if (
      asset?.site &&
      asset.organization &&
      asset.site.organizationId &&
      hasAccess(Constants.ACTIONS.MANAGE_SITES)
    ) {
      navigate(
        `/site/view/${asset.organization}/${asset.site.organizationId}/${asset.site.id}`
      );
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      <Stack m={1}>
        <Typography
          variant="body1"
          mt={2}
          mb={2}
          sx={{
            fontWeight: "bold",
          }}
        >
          Site
        </Typography>

        {site.name && (
          <Typography color="primary" gutterBottom>
            <Link
              underline="none"
              onClick={handleViewSite}
              sx={{ cursor: "pointer" }}
            >
              {site.name}
            </Link>
          </Typography>
        )}
        {site.address && (
          <Typography color="primary">
            <Link
              underline="none"
              onClick={handleViewSite}
              sx={{ cursor: "pointer" }}
            >
              {site.address}
            </Link>
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

const InventoryDetailsCard = (props: { inventoryItem: InventoryItem }) => {
  const { inventoryItem } = props;
  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      <Stack m={1}>
        <Typography
          variant="body1"
          mt={2}
          sx={{
            fontWeight: "bold",
          }}
        >
          Equipment
        </Typography>
        <Grid container>
          <AssetCardDetail
            key="type"
            label="Type"
            value={inventoryItem?.type || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="serialNumber"
            label="Serial Number"
            value={inventoryItem?.serialNumber || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="modelNumber"
            label="Model"
            value={inventoryItem?.modelNumber || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="channelPartner"
            label="Channel Partner"
            value={inventoryItem?.channelPartner || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="customer"
            label="Customer"
            value={inventoryItem?.customer || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="soldTo"
            label="Sold To"
            value={inventoryItem?.soldTo || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="fulfillmentSalesOrder"
            label="Sales Order"
            value={inventoryItem?.fulfillmentSalesOrder || NOT_AVAILABLE}
          />
          {inventoryItem?.properties
            ? inventoryItem.properties
                .filter(
                  (property: any) =>
                    property?.dataType !== PropertyDataType.PHOTO
                )
                .map((property: any) => (
                  <AssetCardDetail
                    key={property?.integrationKey}
                    label={property?.name || property?.integrationKey || ""}
                    value={property?.value || NOT_AVAILABLE}
                    tooltip={`${property?.integrationKey || ""}${
                      property?.integrationKey ? ": " : ""
                    }${property?.description || property?.value || ""}`}
                  />
                ))
            : null}
        </Grid>
      </Stack>
    </Paper>
  );
};

const AssetHistory = (props: { assetEdge: AssetEdge }) => {
  const { assetEdge } = props;
  const asset = assetEdge.asset;
  const { hasAccess } = useAuthData();
  const navigate = useNavigate();

  const handleViewAsset = () => {
    if (hasAccess(Constants.ACTIONS.VIEW_ASSET)) {
      navigate("/assets/view/" + asset.id);
    }
  };

  const handleViewSite = () => {
    if (
      asset?.site &&
      asset.organization &&
      asset.site.organizationId &&
      hasAccess(Constants.ACTIONS.MANAGE_SITES)
    ) {
      navigate(
        `/site/view/${asset.organization}/${asset.site.organizationId}/${asset.site.id}`
      );
    }
  };

  return (
    <Stack m={1}>
      {assetEdge.installedDt && (
        <Typography
          variant="body2"
          mt={1}
          sx={{
            fontWeight: "bold",
          }}
        >
          {`${dateToUserLocalTimezone(assetEdge.installedDt)} - ${
            assetEdge.removedDt
              ? `${dateToUserLocalTimezone(assetEdge.removedDt)}`
              : "Present"
          }`}
        </Typography>
      )}
      <Grid container>
        <AssetCardDetail
          key="assetName"
          label="Asset"
          value={asset?.name || NOT_AVAILABLE}
          onClick={handleViewAsset}
        />
        <AssetCardDetail
          key="siteName"
          label="Site"
          value={asset?.site?.name || NOT_AVAILABLE}
          onClick={handleViewSite}
        />
      </Grid>
    </Stack>
  );
};

const optionalDateValue = (date: any) =>
  date ? utcDate(date, Constants.DATE_FORMATS.DATE) : NOT_AVAILABLE;

const WarrantyCard = (props: { inventoryItem: InventoryItem }) => {
  const { inventoryItem } = props;
  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      <Stack m={1}>
        <Typography
          variant="body1"
          mt={2}
          sx={{
            fontWeight: "bold",
          }}
        >
          Warranty
        </Typography>
        <Grid container>
          <AssetCardDetail
            key="failureDt"
            label="Failure Date"
            value={optionalDateValue(inventoryItem?.warranty?.failureDt)}
          />
          <AssetCardDetail
            key="startDate"
            label="Warranty Start Date"
            value={optionalDateValue(inventoryItem?.warranty?.startDt)}
          />
          <AssetCardDetail
            key="endDate"
            label="Warranty End Date"
            value={optionalDateValue(inventoryItem?.warranty?.endDt)}
          />
          <AssetCardDetail
            key="shipDate"
            label="Ship Date"
            value={optionalDateValue(inventoryItem?.warranty?.shipDt)}
          />
          <AssetCardDetail
            key="installDate"
            label="Install Date"
            value={optionalDateValue(inventoryItem?.warranty?.installedDt)}
          />
          <AssetCardDetail
            key="status"
            label="Status"
            value={
              <WarrantyStatusBadge
                value={warrantyStatusLabel(inventoryItem?.warranty?.status)}
                alignItems="flex-start"
              />
            }
          />
        </Grid>
      </Stack>
    </Paper>
  );
};

const AssetHistoryCard = (props: { assets: any[] }) => {
  const { assets } = props;
  const [showMore, setShowMore] = React.useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      <Stack>
        <Typography
          variant="body1"
          mt={2}
          ml={1}
          sx={{
            fontWeight: "bold",
          }}
        >
          History
        </Typography>

        {assets?.slice(0, 1).map((edge: AssetEdge) => (
          <AssetHistory key={edge.asset.id} assetEdge={edge} />
        ))}
        {assets && assets.length > 1 && (
          <Grid item>
            <Collapse in={showMore}>
              {assets.slice(1).map((edge: AssetEdge) => (
                <AssetHistory key={edge.asset.id} assetEdge={edge} />
              ))}
            </Collapse>
            <Button
              style={{
                justifyContent: "flex-start",
                background: "transparent",
                maxWidth: 180,
                padding: "10px 0 0 10px",
                textTransform: "none",
                textDecoration: "underline",
              }}
              onClick={handleShowMore}
            >
              {showMore ? "Hide" : "Show More"}
            </Button>
          </Grid>
        )}
      </Stack>
    </Paper>
  );
};

const InventoryItemCard = (props: { inventoryItem?: InventoryItem }) => {
  const { inventoryItem } = props;

  if (inventoryItem) {
    const assets = inventoryItem.assets?.edges;
    return (
      <Stack spacing={3} pb={4} overflow="auto" height="calc(100vh - 140px)">
        <InventoryDetailsCard
          key={inventoryItem.id}
          inventoryItem={inventoryItem}
        />
        {inventoryItem?.site && (
          <SiteCard
            key={`${inventoryItem.id}-site`}
            site={inventoryItem?.site}
            asset={assets?.[0]?.asset}
          />
        )}
        {inventoryItem?.warranty && (
          <WarrantyCard
            key={`${inventoryItem.id}-warranty`}
            inventoryItem={inventoryItem}
          />
        )}
        {assets && assets.length > 0 && (
          <AssetHistoryCard
            key={`${inventoryItem.id}-history`}
            assets={assets}
          />
        )}
      </Stack>
    );
  }
  return <CardSkeleton />;
};

export default createFragmentContainer(InventoryItemCard, {
  inventoryItem: gql`
    fragment InventoryItemCard_inventoryItem on InventoryItem {
      id
      type
      modelNumber
      serialNumber
      customer
      channelPartner
      site {
        id
        name
        address
      }
      properties {
        integrationKey
        value
        name
        dataType
        description
        createdBy
        createdDt
        updatedBy
        updatedDt
        dataType
      }
      soldTo
      fulfillmentSalesOrder
      assets {
        totalCount
        edges {
          installedDt
          removedDt
          asset {
            id
            name
            organization
            site {
              id
              name
              address
              organizationId
            }
          }
        }
      }
      warranty {
        id
        failureDt
        status
        startDt
        endDt
        shipDt
        installedDt
      }
    }
  `,
});
