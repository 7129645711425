import dayjs from "dayjs";
import { Constants } from "utils/constants";

// Convert utc datetime to user local timezone
export const dateToUserLocalTimezone = (
  value: string,
  returnFormat: string = Constants.DATE_FORMATS.DATE_TIME_SEC
) => {
  if (!value || isNaN(new Date(value).getTime())) {
    return value;
  }
  const dateUtc = dayjs.utc(value);
  const dateLocal = dateUtc.local();
  return dateLocal.format(returnFormat);
};

export const utcDate = (
  value: string,
  returnFormat: string = Constants.DATE_FORMATS.DATE
) => {
  if (!value || isNaN(new Date(value).getTime())) {
    return value;
  }
  const dateUtc = dayjs.utc(value);
  return dateUtc.format(returnFormat);
};
