import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Constants } from "utils/constants";
import { useAuthData } from "utils/hooks/useAuthData";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/store/hooks";
import { regenerateSurveyThumbnails } from "redux/actions/jobs";
import { enqueueSnackbar } from "notistack";

const ActionsMenu = (props: {
  jobId: string;
  pdfURL: string;
  status: string;
}) => {
  const { hasAccess, getRawIdToken } = useAuthData();
  const dispatch = useAppDispatch();
  const { jobId, pdfURL, status } = props;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleViewJob = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/jobs/edit/" + jobId);
    handleClose(event);
  };

  const openPDF = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open(pdfURL, "_blank");
    handleClose(event);
  };

  const requestRegenerateSurveyThumbnails = async () => {
    const idToken = await getRawIdToken();
    dispatch(
      regenerateSurveyThumbnails({
        token: idToken,
        jobId: jobId,
      })
    ).then((resp) => {
      if (regenerateSurveyThumbnails.fulfilled.match(resp)) {
        if (resp.payload?.result?.success) {
          enqueueSnackbar(resp.payload.result.message, {
            variant: "default",
          });
        } else {
          if (resp.payload?.result?.message) {
            enqueueSnackbar(resp.payload.result.message, {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Survay thumbnails regeneration failed", {
              variant: "error",
            });
          }
        }
      }
    });
  };

  const handleRegenerateSurveyThumbnails = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleClose(event);
    requestRegenerateSurveyThumbnails();
  };

  if (
    !hasAccess(Constants.ACTIONS.VIEW_JOB_DETAILS) &&
    !hasAccess(Constants.ACTIONS.EDIT_JOBS)
  ) {
    return <></>;
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="action" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e: any) => handleViewJob(e)}>View</MenuItem>
        {status === Constants.SURVEY_STATUS.COMPLETED && (
          <MenuItem onClick={(e: any) => openPDF(e)}>Download PDF</MenuItem>
        )}
        {hasAccess(Constants.ACTIONS.REGENERATE_SURVEY_THUMBNAILS) && (
          <MenuItem onClick={(e: any) => handleRegenerateSurveyThumbnails(e)}>
            Regenerate Thumbnails
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ActionsMenu;
