import { FilterItem } from "utils/types";
import { GridState } from "utils/types";
import { definedColumns, supportedSortColumns } from "./assetColumns";
import { getGridQueryParams } from "../common/gridview/assetFiltersUtil";
import { gql } from "@apollo/client";

const getAssetsQuery = (params: string) => {
  const assetPropertiesColumns = `
      properties {
        integrationKey
        dataType
        value
        name
        description
        createdBy
        createdDt
        updatedBy
        updatedDt
      }
      `;

  const drivePropertiesColumns = `
      properties {
        integrationKey
        dataType
        value
        name
        description
        createdBy
        createdDt
        updatedBy
        updatedDt
      }
      `;

  const motorPropertiesColumns = `
      properties {
        integrationKey
        dataType
        value
        name
        description
        createdBy
        createdDt
        updatedBy
        updatedDt
      }
      `;

  const rmkPropertiesColumns = `
      properties {
        integrationKey
        dataType
        value
        name
        description
        createdBy
        createdDt
        updatedBy
        updatedDt
      }
      `;

  return gql`
    query assetGrid {
      assets ${params} {
        totalCount
        pageInfo {
          startIndex
          endIndex
          hasNextPage
          hasPreviousPage
        }
        edges {
          asset {
            id
            name
            manufacturer
            modelNumber
            serialNumber
            source
            latitude
            longitude
            createdDt
            createdBy
            updatedDt
            updatedBy
            organization
            site {
              id
              name
              address
            }
            ${assetPropertiesColumns}
            drives {
              totalCount
              edges {
                installedDt
                updatedDt
                updatedBy
                drive {
                  id
                  serialNumber
                  driveModel
                  createdDt
                  createdBy
                  updatedDt
                  updatedBy
                  ${drivePropertiesColumns}
                }
              }
            }
            motors {
              totalCount
              edges {
                installedDt
                updatedDt
                updatedBy
                motor {
                  serialNumber
                  motorModel
                  createdDt
                  createdBy
                  updatedDt
                  updatedBy
                  ${motorPropertiesColumns}
                }
              }
            }
            rmks {
              totalCount
              edges {
              installedDt
              updatedDt
              updatedBy
              rmk {
                  id
                  spvSerialNumber
                  modemName
                  modemMacAddress
                  rmkPlus
                  createdDt
                  createdBy
                  updatedDt
                  updatedBy
                  isDeleted
                  ${rmkPropertiesColumns}
                }
              }           
            }
          }
        }
      }
    }
  `;
};

const getAssetsQueryParams = (
  grid: GridState,
  searchField: string,
  selectedOrgFilterItems: FilterItem[],
  columnFilterModel: any,
  page: number,
  size: number = -1
) => {
  return getGridQueryParams(
    grid,
    searchField,
    selectedOrgFilterItems,
    columnFilterModel,
    definedColumns,
    supportedSortColumns,
    page,
    size
  );
};

const sortByInstalledDt = (edges?: any[]) => {
  return edges
    ?.slice()
    .sort((a, b) => (a.installedDt > b.installedDt ? -1 : 1));
};

const assetRowsFromQueryData = (data: any) => {
  return (data?.assets?.edges || []).map((edge: any) => {
    const driveEdges = edge.asset?.drives?.edges
      ? sortByInstalledDt(edge.asset.drives.edges)
      : [];
    const motorEdges = edge.asset?.motors?.edges
      ? sortByInstalledDt(edge.asset.motors.edges)
      : [];
    const rmkEdges = edge.asset?.rmks?.edges
      ? sortByInstalledDt(edge.asset.rmks.edges)
      : [];
    const drive = driveEdges?.[0]?.drive || {};
    const motor = motorEdges?.[0]?.motor || {};
    const rmk = rmkEdges?.[0]?.rmk || {};

    const assetProps = (edge.asset?.properties || []).reduce(
      (props: Record<string, any>, item: any) => {
        if (item.integrationKey) {
          props[item.integrationKey] = item;
        }
        return props;
      },
      {}
    );
    const driveProps = (drive?.properties || []).reduce(
      (props: Record<string, any>, item: any) => {
        if (item.integrationKey) {
          props[item.integrationKey] = item;
        }
        return props;
      },
      {}
    );
    const motorProps = (motor?.properties || []).reduce(
      (props: Record<string, any>, item: any) => {
        if (item.integrationKey) {
          props[item.integrationKey] = item;
        }
        return props;
      },
      {}
    );
    const rmkProps = (rmk?.properties || []).reduce(
      (props: Record<string, any>, item: any) => {
        if (item.integrationKey) {
          props[item.integrationKey] = item;
        }
        return props;
      },
      {}
    );

    const result: any = {};
    definedColumns.forEach((col: any, index: number) => {
      if (col.object && col.key) {
        if (col.object === "site") {
          result[col.field] = edge?.asset?.site?.[col.key];
        } else if (col.object === "asset") {
          result[col.field] = edge?.asset?.[col.key];
        } else if (col.object === "asset-drive") {
          result[col.field] = drive?.[col.key];
        } else if (col.object === "asset-motor") {
          result[col.field] = motor?.[col.key];
        } else if (col.object === "asset-rmk") {
          result[col.field] = rmk?.[col.key];
        } else if (col.object === "asset-drive-edges") {
          result[col.field] = driveEdges?.[0]?.[col.key];
        } else if (col.object === "asset-motor-edges") {
          result[col.field] = motorEdges?.[0]?.[col.key];
        } else if (col.object === "asset-properties") {
          result[col.field] = assetProps?.[col.key]?.value;
        } else if (col.object === "asset-drive-properties") {
          result[col.field] = driveProps?.[col.key]?.value;
        } else if (col.object === "asset-motor-properties") {
          result[col.field] = motorProps?.[col.key]?.value;
        } else if (col.object === "asset-rmk-properties") {
          result[col.field] = rmkProps?.[col.key]?.value;
        }
      }
    });
    return result;
  });
};

export { getAssetsQuery, getAssetsQueryParams, assetRowsFromQueryData };
