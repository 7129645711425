import * as React from "react";
import { Box } from "@mui/material";
import { GridColDef, MuiEvent } from "@mui/x-data-grid-pro";
import {
  TTDataGridPro,
  TT_SLOT_PROPS,
} from "components/datagrid/TTDataGridPro";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";

const columns: GridColDef[] = [
  { field: "name", headerName: "Asset", width: 150, flex: 1 },
  { field: "manufacturer", headerName: "Make", flex: 1 },
  { field: "modelNumber", headerName: "Asset Model", flex: 1 },
  { field: "motorModel", headerName: "Motor Model", flex: 1 },
  { field: "motorSerialNumber", headerName: "Motor Serial", flex: 1 },
  { field: "driveModel", headerName: "Drive Model", flex: 1 },
  { field: "driveSerialNumber", headerName: "Drive Serial", flex: 1.2 },
  { field: "installedDt", headerName: "Installed Date/Time", flex: 1.5 },
];

const AssetDataGrid = (props: {
  assets: Array<any> | [];
  loading: boolean;
}) => {
  const { assets, loading } = props;
  const navigate = useNavigate();
  const { hasAccess, ready } = useAuthData();

  const handleViewAsset = (params: any, event: MuiEvent) => {
    if (ready && hasAccess(Constants.ACTIONS.VIEW_ASSET)) {
      event.defaultMuiPrevented = true;
      navigate("/assets/view/" + params.id);
    }
  };

  return (
    <Box style={{ width: "100%", height: "100%" }} title={"box"}>
      {assets && (
        <TTDataGridPro
          autoHeight
          columns={columns}
          rows={assets}
          loading={loading}
          slotProps={{
            ...TT_SLOT_PROPS,
            toolbar: {
              printOptions: { hideToolbar: true, fileName: "Asset List" },
            },
          }}
          onRowClick={handleViewAsset}
        />
      )}
    </Box>
  );
};
export default AssetDataGrid;
