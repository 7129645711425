import { createAsyncThunk } from "@reduxjs/toolkit";

import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export interface ISitessQuery {
  token: string | undefined;
}

export interface ISitesQuery {
  token: string | undefined;
  siteId?: string | undefined;
  organizationId?: string | undefined;
  name: string | undefined;
  address: string | undefined;
  lat: string | undefined;
  lon: string | undefined;
}

export const getUserSites = createAsyncThunk(
  "sites/getUserSites",
  async (payload: ISitessQuery, _Thunk) => {
    const { token } = payload;

    const config = setCallConfig("/api/sites/list", "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;

    return response.data;
  }
);

export const createSite = createAsyncThunk(
  "sites/createSite",
  async (payload: ISitesQuery, _Thunk) => {
    const { token, organizationId, name, address, lat, lon } = payload;

    const config = setCallConfig("/api/site", "POST", token, {
      organization_id: organizationId,
      name,
      address,
      lon,
      lat,
    });
    const response = (await trackedApiCall(config)) as ApiResponse;

    return response.data;
  }
);

export const editSite = createAsyncThunk(
  "sites/editSite",
  async (payload: ISitesQuery, _Thunk) => {
    const { token, siteId, name, address, lat, lon } = payload;

    const config = setCallConfig(`/api/site/${siteId}`, "PATCH", token, {
      name,
      address,
      lon,
      lat,
    });
    const response = (await trackedApiCall(config)) as ApiResponse;

    return response.data;
  }
);

export const getUserSitesWithParents = createAsyncThunk(
  "sites/getUserSitesWithParents",
  async (payload: ISitessQuery, _Thunk) => {
    const { token } = payload;

    const config = setCallConfig("/api/usersites/withparents", "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;

    return response.data;
  }
);
