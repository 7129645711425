import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Menu,
  TextField,
  FormGroup,
  Autocomplete,
  Typography,
  Alert,
  Collapse,
} from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ShareIcon from "@mui/icons-material/Share";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import { saveView, shareView, deleteView } from "redux/actions/savedviews";
import { getUsers } from "redux/actions/users";
import { FilterItem, FiltersState, GridViewState } from "utils/types";
import { useAuthData } from "utils/hooks/useAuthData";
import { enqueueSnackbar } from "notistack";
import {
  validFilters,
  validGridState,
} from "../../common/gridview/assetFiltersUtil";
import { useAssetsStyles } from "./styles";

type Validation = {
  valid: boolean;
  message: string;
};

type ViewsFormData = {
  mode: string;
  processing: boolean;
  saveAsName: string;
  validation: Validation;
  targetView: any;
};

const initialFormData = {
  mode: "",
  processing: false,
  saveAsName: "",
  validation: { valid: true, message: "" },
  targetView: null,
};

const ViewsFilter = (props: {
  onViewSelectorAction?: (action: string, data?: any) => void;
  exportData?: any;
  disableExport?: boolean;
  initialState: GridViewState;
  definedColumns: any[];
  setView: any;
  viewState: GridViewState;
  filters: FiltersState;
}) => {
  const {
    onViewSelectorAction,
    initialState,
    definedColumns,
    setView,
    viewState,
    filters,
  } = props;
  const dispatch = useAppDispatch();
  const { getRawIdToken } = useAuthData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredOption, setHoveredOption] = useState(null);
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const { classes } = useAssetsStyles();

  const [selectedShareUsers, setSelectedShareUsers] = useState([]);
  const [shareUserOptions, setShareUserOptions] = useState<FilterItem[]>([]);

  const savedViews = useAppSelector(
    (state) => state.assetsSavedViews.savedViews
  );
  const [savedViewsAutocompleteOptions, setFilteredAutocompleteOptions] =
    useState<any[]>(Array.isArray(savedViews) ? savedViews : []);
  const grid = viewState.grid;

  const selectedView = useAppSelector((state) => viewState.selectedView);
  const setSelectedView = (newView: any) => {
    const oldViewId = selectedView?.id || "";
    const newViewId = newView?.id || "";
    dispatch(setView(newView));
    if (newViewId !== oldViewId) {
      updateStateFromView(newView);
    }
  };
  const open = Boolean(anchorEl);

  const formRef = useRef<HTMLDivElement | null>(null);
  const setTabIndexes = () => {
    window.requestAnimationFrame &&
      window.requestAnimationFrame(function (timeStamp) {
        if (formRef.current) {
          formRef.current.querySelectorAll("*").forEach((el, index) => {
            if (parseInt(el.getAttribute("tabindex") || "0") < 1) {
              el.setAttribute("tabindex", "-1");
            }
          });
        }
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setTabIndexes();
  };

  const closeViewsMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = (
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    if ("key" in event && event.key === "Tab") {
      event.stopPropagation();
      return false;
    }
    closeViewsMenu();
  };

  const [viewsFormData, setViewsFormData] =
    useState<ViewsFormData>(initialFormData);
  const updateFormData = (
    field: string,
    value: string | boolean | Validation | null
  ) => {
    setViewsFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const getUsersList = async () => {
    const idToken = await getRawIdToken();
    dispatch(
      getUsers({
        token: idToken,
        page: 1,
        size: 50,
        orderBy: "email",
        dir: "asc",
        filter: "",
        includeInactiveUsers: false,
        listVisibleOrgUsers: true,
      })
    ).then((resp) => {
      if (getUsers.fulfilled.match(resp)) {
        const users = resp.payload?.result?.data;
        const usersList = (() => {
          return (Array.isArray(users) ? users : []).map((item) => {
            return {
              key: item.user_id,
              label: item.email,
              type: "user",
            };
          });
        })();
        setShareUserOptions(usersList);
      }
    });
  };

  const handleExpandShareViewClick = () => {
    updateFormData("mode", "share");
    updateFormData("validation", {
      valid: true,
      message: "",
    });
    if (!Array.isArray(shareUserOptions) || shareUserOptions.length === 0) {
      getUsersList();
    }
  };

  const handleSaveView = (action: string, data: any) => {
    const result: { success: boolean; error: string } = {
      success: false,
      error: "",
    };
    updateFormData("validation", { valid: true, message: "" });
    updateFormData("targetView", null);
    if (!data?.saveAsName) {
      result.error = "Name is empty.";
    } else {
      result.success = true;
    }
    if (result.success !== true || result.error) {
      updateFormData("processing", false);
      updateFormData("validation", { valid: false, message: result.error });
    } else {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          saveView({
            token: idToken,
            viewData: {
              view_id: data?.view_id,
              view_name: data.saveAsName,
              view_data: {
                grid: validGridState(grid, definedColumns, initialState),
                filters: validFilters(filters, definedColumns),
              },
              pagename: "assets",
            },
          })
        )
          .then((resp) => {
            updateFormData("processing", false);
            if (resp?.payload?.success) {
              enqueueSnackbar("View saved successfully", {
                variant: "default",
              });
              updateFormData("validation", { valid: true, message: "" });
              updateFormData("saveAsName", "");
              updateFormData("mode", "");
            } else {
              result.error = "Failed to save view!";
              updateFormData("validation", {
                valid: false,
                message: resp?.payload?.message
                  ? resp.payload.message
                  : result.error,
              });
              enqueueSnackbar(result.error, { variant: "error" });
            }
          })
          .finally(() => {
            updateFormData("processing", false);
          });
      })();
    }
  };

  const handleAction = (action: string, data: any) => {
    if (viewsFormData.processing) {
      return;
    }
    if (action === "save_view") {
      updateFormData("processing", true);
      handleSaveView(action, data);
    }
  };

  const updateStateFromView = (view: any) => {
    onViewSelectorAction?.("ViewChanged", view);
  };

  const handleReloadViewClick = () => {
    updateStateFromView(selectedView);
    closeViewsMenu();
  };

  const updateSavedVievsOptions = (newViewsList: any) => {
    const viewsList = (Array.isArray(newViewsList) ? newViewsList : [])
      .filter((view) => view?.id && view.id !== "0")
      .sort((a, b) => (a?.view_name > b?.view_name ? 1 : -1));
    const newSavedViews = [...viewsList];
    const defaultView = {
      view_name: "Default View",
      id: "0",
      is_owner: false,
      view_data: {
        grid: validGridState(initialState.grid, definedColumns, initialState),
        filters: validFilters(initialState.filters, definedColumns),
      },
    };
    newSavedViews.unshift(defaultView);
    const updatedView = viewsList.find(
      (view: any) => view?.id === selectedView?.id
    );
    setFilteredAutocompleteOptions(newSavedViews);
    setSelectedView(updatedView || defaultView);
  };

  const handleShareViewClick = (view: any) => {
    const result: { success: boolean; error: string } = {
      success: false,
      error: "",
    };
    updateFormData("validation", { valid: true, message: "" });
    const selectedUsers = (
      Array.isArray(selectedShareUsers) ? selectedShareUsers : []
    ).map((item: any) => item?.key);
    if (!view?.id) {
      result.error = "View not selected!";
    } else if (view?.is_owner !== true) {
      result.error = "You are not this 'view' owner. First save it as...";
    } else if (selectedUsers.length < 1) {
      result.error = "User not selected!";
    } else {
      result.success = true;
    }
    if (result.success !== true || result.error) {
      updateFormData("validation", { valid: false, message: result.error });
    } else {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          shareView({
            token: idToken,
            viewData: {
              view_id: view.id,
              users: selectedUsers,
              pagename: "assets",
            },
          })
        ).then((resp) => {
          if (resp?.payload?.success) {
            enqueueSnackbar("View shared successfully", {
              variant: "default",
            });
            updateFormData("validation", { valid: true, message: "" });
            updateFormData("mode", "");
          } else {
            result.error = "Failed to share view!";
            updateFormData("validation", {
              valid: false,
              message: resp?.payload?.message
                ? resp.payload.message
                : result.error,
            });
            enqueueSnackbar(result.error, { variant: "error" });
          }
        });
      })();
    }
  };

  const handleRemoveViewClick = (view: any) => {
    const result: { success: boolean; error: string } = {
      success: false,
      error: "",
    };
    updateFormData("validation", { valid: true, message: "" });
    updateFormData("mode", "");
    updateFormData("saveAsName", "");
    updateFormData("targetView", null);
    if (!view?.id) {
      result.error = "View not selected";
    } else if (view.id.length <= 1) {
      result.error = "Default view cannot be removed";
    } else {
      result.success = true;
    }
    if (result.success !== true || result.error) {
      updateFormData("validation", { valid: false, message: result.error });
    } else {
      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          deleteView({
            token: idToken,
            viewData: {
              deleteview_ids: [view.id],
              pagename: "assets",
            },
          })
        ).then((resp) => {
          if (resp?.payload?.success) {
            enqueueSnackbar("View deleted successfully", {
              variant: "default",
            });
            updateFormData("validation", { valid: true, message: "" });
          } else {
            result.error = "Failed to delete view!";
            updateFormData("validation", {
              valid: false,
              message: resp?.payload?.message
                ? resp.payload.message
                : result.error,
            });
            enqueueSnackbar(result.error, { variant: "error" });
          }
        });
      })();
    }
  };

  const handleCancelButtonClick = () => {
    setViewsFormData(initialFormData);
    setTabIndexes();
  };

  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const formTitle =
    viewsFormData.mode === "share"
      ? "Share "
      : viewsFormData.mode === "saveRename"
      ? "Rename "
      : viewsFormData.mode === "saveAs"
      ? "Save as "
      : viewsFormData.mode === "removeView"
      ? "Delete "
      : "Views ";

  const formDescription =
    (viewsFormData.mode === "share" || viewsFormData.mode === "saveRename") &&
    selectedView?.view_name
      ? selectedView.view_name
      : "";

  const confirmRemoveView =
    viewsFormData.mode === "removeView" &&
    viewsFormData?.targetView &&
    viewsFormData.targetView?.id &&
    viewsFormData.targetView.id.length > 1
      ? viewsFormData.targetView
      : null;

  const selectedViewIsOk = selectedView?.id && selectedView.id.length > 1;
  const selectedViewIsOwn = selectedViewIsOk && selectedView?.is_owner === true;
  const alreadySharedTo = (() => {
    if (selectedViewIsOwn && Array.isArray(selectedView?.shared_to)) {
      return selectedView.shared_to
        .map((user_id: number) => {
          return shareUserOptions.find((option) => option.key === user_id);
        })
        .filter(Boolean);
    }
    return [];
  })();

  useEffect(() => {
    if (!confirmRemoveView) {
      setViewsFormData(initialFormData);
    }
  }, [confirmRemoveView]);

  useEffect(() => {
    if (savedViews) {
      updateSavedVievsOptions(savedViews);
    }
  }, [savedViews]);

  return (
    <>
      {savedViewsAutocompleteOptions &&
        savedViewsAutocompleteOptions.length > 0 && (
          <Grid item>
            <Button
              id="menu-view-button"
              variant="text"
              className={classes.viewSelectorMenuButton}
              endIcon={<ArrowDropDownIcon />}
              aria-controls={open ? "menu-view" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              {selectedView ? selectedView.view_name : "Default View"}{" "}
            </Button>
            <Menu
              id="menu-view"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                style: { width: 475 },
              }}
              tabIndex={-1}
            >
              <Box
                sx={{ px: 2 }}
                style={{ marginBottom: viewsFormData.mode === "" ? 6 : 0 }}
                ref={formRef}
                onKeyDown={(event) => {
                  if (event.key === "Tab") {
                    event.stopPropagation();
                  }
                }}
                tabIndex={-1}
              >
                <FormGroup>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography
                        className={classes.viewActionFormTitle}
                        variant="h6"
                      >
                        {formTitle}
                        {formDescription && <span>{formDescription}</span>}
                        {confirmRemoveView && (
                          <span>{confirmRemoveView.view_name}?</span>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container justifyContent="flex-end">
                        <Button
                          style={{
                            opacity: viewsFormData.mode === "" ? 1 : 0,
                            transition: "opacity 0.2s",
                            height: 28,
                            minWidth: 28,
                            padding: "2px",
                            marginTop: 14,
                          }}
                          onClick={handleReloadViewClick}
                          disabled={
                            viewsFormData.processing ||
                            viewsFormData.mode !== ""
                          }
                          tabIndex={1}
                        >
                          <RefreshIcon style={{ color: "#778" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {viewsFormData.mode !== "removeView" && (
                    <Autocomplete
                      multiple={false}
                      autoComplete={true}
                      id="views-autocomplete"
                      options={savedViewsAutocompleteOptions}
                      onChange={(e, value) => {
                        setSelectedView(value);
                      }}
                      value={selectedView}
                      size="small"
                      style={{
                        transition: "margin 0.2s",
                        marginTop: viewsFormData.mode === "" ? 12 : 17,
                      }}
                      getOptionLabel={(option: any) => option.view_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Saved Views"
                          onKeyDown={(event) => {
                            if (event.key !== "Tab") {
                              event.stopPropagation();
                            }
                          }}
                          inputProps={{
                            ...params.inputProps,
                            tabIndex: 2,
                          }}
                          InputLabelProps={{
                            ...params.InputLabelProps,
                          }}
                          placeholder="Select..."
                        />
                      )}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          onMouseEnter={() => setHoveredOption(option)}
                          onMouseLeave={() => setHoveredOption(null)}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            position: "relative",
                          }}
                        >
                          {option?.is_owner === false &&
                            option?.id &&
                            option.id.length > 1 && (
                              <ShareIcon
                                className={`${classes.viewIsSharedIcon} ${classes.viewIsSharedToIcon}`}
                              />
                            )}
                          {option?.is_owner === true &&
                            option?.id &&
                            option.id.length > 1 &&
                            option?.share_dt && (
                              <ShareIcon
                                className={`${classes.viewIsSharedIcon} ${classes.viewIsSharedFromIcon}`}
                              />
                            )}
                          {option.view_name}
                          {option?.id &&
                            option.id.length > 1 &&
                            hoveredOption === option && (
                              <div style={{ display: "flex" }}>
                                <IconButton
                                  className={classes.viewDeletedIcon}
                                  onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                  ) => {
                                    e.stopPropagation();
                                    updateFormData("mode", "removeView");
                                    updateFormData("targetView", option);
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </div>
                            )}
                        </li>
                      )}
                      disabled={
                        viewsFormData.processing || viewsFormData.mode !== ""
                      }
                    />
                  )}
                  <Grid
                    item
                    style={{
                      maxHeight: !viewsFormData.validation.valid ? 100 : 0,
                      overflow: "hidden",
                      transition: "max-height 0.3s",
                    }}
                  >
                    <Box sx={{ my: 1 }}>
                      <Alert severity="error">
                        <Typography variant="body1">
                          {viewsFormData.validation.message}
                        </Typography>
                      </Alert>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    style={{
                      maxHeight: viewsFormData.mode === "share" ? 380 : 0,
                      overflow: "hidden",
                      transition: "max-height 0.3s",
                    }}
                  >
                    <Grid item my={1}>
                      <Typography>Share view to</Typography>
                      <Grid item>
                        <Autocomplete
                          multiple
                          autoComplete={true}
                          id="views-users"
                          options={shareUserOptions}
                          onChange={(e, value: any) => {
                            setSelectedShareUsers(value);
                          }}
                          value={selectedShareUsers}
                          size="small"
                          getOptionLabel={(option: any) => option.label}
                          renderInput={(params) => (
                            <div
                              style={{
                                paddingTop: 10,
                                maxHeight: 240,
                                overflowY: "auto",
                              }}
                            >
                              <TextField
                                {...params}
                                size="small"
                                label="Share view with other people...."
                                onKeyDown={(event) => {
                                  event.stopPropagation();
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  tabIndex:
                                    viewsFormData.mode === "share" ? 15 : 0,
                                }}
                                InputLabelProps={{
                                  ...params.InputLabelProps,
                                }}
                                placeholder="Select..."
                              />
                            </div>
                          )}
                          filterSelectedOptions={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" mb={1}>
                      <Button
                        className={classes.viewActionCancelButton}
                        onClick={handleCancelButtonClick}
                        disabled={viewsFormData.processing}
                        tabIndex={viewsFormData.mode === "share" ? 17 : 0}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginLeft: "4px", padding: "2px 18px" }}
                        onClick={() => {
                          handleShareViewClick(selectedView);
                        }}
                        size="small"
                        disabled={viewsFormData.processing}
                        tabIndex={viewsFormData.mode === "share" ? 16 : 0}
                      >
                        Share
                      </Button>
                    </Grid>
                    {alreadySharedTo.length > 0 && (
                      <Grid container mt={1}>
                        <Typography>Already Shared To:</Typography>
                      </Grid>
                    )}
                  </Grid>

                  {alreadySharedTo.length > 0 && (
                    <Grid
                      item
                      style={{
                        maxHeight: viewsFormData.mode === "share" ? 205 : 0,
                        overflow: "hidden",
                        overflowY: "auto",
                        transition: "max-height 0.3s",
                      }}
                    >
                      <Grid container my={1}>
                        <Grid container style={{ color: "#1976D2" }}>
                          {alreadySharedTo.slice(0, 5).map((user: any) => (
                            <Grid container>
                              <Typography key={user.key}>
                                {user.label}
                              </Typography>
                            </Grid>
                          ))}
                          {alreadySharedTo.length > 5 && (
                            <Collapse in={showMore}>
                              {alreadySharedTo.slice(5).map((user: any) => (
                                <Grid container>
                                  <Typography key={user.key}>
                                    {user.label}
                                  </Typography>
                                </Grid>
                              ))}
                            </Collapse>
                          )}
                        </Grid>
                        {alreadySharedTo.length > 5 && (
                          <Grid container>
                            <Button
                              style={{
                                justifyContent: "flex-start",
                                background: "transparent",
                                maxWidth: 180,
                                padding: "5px 0 0 0",
                                textTransform: "none",
                                textDecoration: "underline",
                              }}
                              onClick={handleShowMore}
                            >
                              {showMore ? "Hide" : "Show More"}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    style={{
                      height:
                        viewsFormData.mode === "saveAs" ||
                        viewsFormData.mode === "saveRename"
                          ? 100
                          : 0,
                      overflow: "hidden",
                      transition: "height 0.2s",
                    }}
                  >
                    <Grid item mt={2} mb={1}>
                      <TextField
                        inputRef={textFieldRef}
                        variant="outlined"
                        fullWidth
                        label={
                          viewsFormData.mode === "saveRename"
                            ? "Rename view"
                            : "View name"
                        }
                        value={viewsFormData.saveAsName}
                        onChange={(e) =>
                          updateFormData("saveAsName", e.target.value)
                        }
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                        size="small"
                        inputProps={{
                          tabIndex:
                            viewsFormData.mode === "saveAs" ||
                            viewsFormData.mode === "saveRename"
                              ? 10
                              : 0,
                        }}
                      />
                    </Grid>
                    <Grid container justifyContent="flex-end" mb={1}>
                      <Button
                        className={classes.viewActionCancelButton}
                        onClick={handleCancelButtonClick}
                        disabled={viewsFormData.processing}
                        tabIndex={
                          viewsFormData.mode === "saveAs" ||
                          viewsFormData.mode === "saveRename"
                            ? 12
                            : 0
                        }
                      >
                        Cancel
                      </Button>

                      {viewsFormData.mode === "saveRename" &&
                        selectedViewIsOwn && (
                          <Button
                            variant="contained"
                            className={classes.viewActionSaveButton}
                            onClick={() => {
                              handleAction?.("save_view", {
                                view_id: selectedView?.id,
                                saveAsName: viewsFormData.saveAsName,
                              });
                            }}
                            size="small"
                            disabled={viewsFormData.processing}
                            tabIndex={
                              viewsFormData.mode === "saveRename" ? 11 : 0
                            }
                          >
                            Save
                          </Button>
                        )}

                      {viewsFormData.mode === "saveAs" && (
                        <Button
                          variant="contained"
                          className={classes.viewActionSaveButton}
                          onClick={() => {
                            handleAction?.("save_view", {
                              view_id: null,
                              saveAsName: viewsFormData.saveAsName,
                            });
                          }}
                          size="small"
                          disabled={viewsFormData.processing}
                          tabIndex={viewsFormData.mode === "saveAs" ? 11 : 0}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    style={{
                      height: viewsFormData.mode === "removeView" ? 100 : 0,
                      overflow: "hidden",
                      transition: "height 0.2s",
                    }}
                  >
                    <Grid item mt={2} mb={3}>
                      <Typography variant="body1" fontSize={13}>
                        Deleting this view will remove it from your view list
                        and can’t be recovered.
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-end" mb={1}>
                      <Button
                        className={classes.viewActionCancelButton}
                        onClick={handleCancelButtonClick}
                        disabled={viewsFormData.processing}
                      >
                        Cancel
                      </Button>
                      {viewsFormData.mode === "removeView" &&
                        viewsFormData?.targetView?.id &&
                        viewsFormData?.targetView?.id.length > 1 && (
                          <Button
                            variant="contained"
                            color="error"
                            className={classes.viewActionSaveButton}
                            onClick={() => {
                              handleRemoveViewClick(viewsFormData.targetView);
                            }}
                            size="small"
                            disabled={viewsFormData.processing}
                          >
                            Delete
                          </Button>
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    container={true}
                    justifyContent="space-between"
                    style={{
                      height: viewsFormData.mode === "" ? 40 : 0,
                      overflow: "hidden",
                      transition: "height 0.2s",
                      marginTop: 7,
                    }}
                  >
                    <Grid item>
                      {selectedViewIsOwn && (
                        <Button
                          className={classes.viewActionDefaultButton}
                          style={{ margin: "4px 8px 4px 0" }}
                          onClick={() => {
                            updateFormData("mode", "saveRename");
                            updateFormData(
                              "saveAsName",
                              selectedView.view_name
                            );
                            updateFormData("validation", {
                              valid: true,
                              message: "",
                            });
                            setTimeout(() => {
                              if (textFieldRef.current) {
                                textFieldRef.current.focus();
                                textFieldRef.current.setSelectionRange(
                                  0,
                                  textFieldRef.current.value.length
                                );
                              }
                            }, 100);
                            setTabIndexes();
                          }}
                          disabled={viewsFormData.processing}
                          tabIndex={viewsFormData.mode === "" ? 3 : 0}
                        >
                          <EditIcon
                            style={{
                              marginLeft: -2,
                              marginRight: 5,
                              fontSize: 16,
                            }}
                          />
                          Rename
                        </Button>
                      )}
                      {selectedViewIsOwn && (
                        <Button
                          className={classes.viewActionDefaultButton}
                          onClick={() => {
                            handleExpandShareViewClick();
                            setTabIndexes();
                          }}
                          disabled={viewsFormData.processing}
                          tabIndex={viewsFormData.mode === "" ? 4 : 0}
                        >
                          <ShareIcon style={{ marginRight: 5, fontSize: 16 }} />
                          Share
                        </Button>
                      )}
                    </Grid>
                    <Grid>
                      {selectedViewIsOwn && selectedView?.view_name && (
                        <Button
                          className={classes.viewActionDefaultButton}
                          style={{ margin: "4px 8px 4px 0" }}
                          onClick={() => {
                            handleAction?.("save_view", {
                              view_id: selectedView?.id,
                              saveAsName: selectedView?.view_name,
                            });
                            closeViewsMenu();
                          }}
                          disabled={viewsFormData.processing}
                          tabIndex={viewsFormData.mode === "" ? 5 : 0}
                        >
                          Save Changes
                        </Button>
                      )}
                      <Button
                        className={classes.viewActionDefaultButton}
                        onClick={() => {
                          updateFormData("mode", "saveAs");
                          updateFormData("validation", {
                            valid: true,
                            message: "",
                          });
                          setTimeout(() => {
                            if (textFieldRef.current) {
                              textFieldRef.current.focus();
                            }
                          }, 100);
                          setTabIndexes();
                        }}
                        disabled={viewsFormData.processing}
                        tabIndex={viewsFormData.mode === "" ? 6 : 0}
                      >
                        Save As
                        <ArrowDropDownOutlinedIcon
                          style={{ marginLeft: 4, marginRight: -10 }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            </Menu>
          </Grid>
        )}
    </>
  );
};

export default ViewsFilter;
