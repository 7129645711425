import { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  TablePagination,
  IconButton,
} from "@mui/material";
import { snakeCase } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector, useAppDispatch } from "../../redux/store/hooks";
import { getFanCharts, getFanChartDetails } from "redux/actions/fancharts";
import { FanChartsFullRow, FanChartsDetails } from "models/fancharts";
import FanChartsDataGrid from "./dataGrid";
import TTTextField from "components/common/TTTextField";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "utils/hooks/useAuthData";
import { Constants } from "utils/constants";
import {
  setFilter,
  setSortOrder,
  openModal,
  closeModal,
} from "redux/reducers/airflowFanChartSlice";
import { Sort, SortOrder } from "utils/types";
import { SortColumn, SortDirection } from "react-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import { FanCharts, FanChartModels } from "models/fancharts";

import { enqueueSnackbar } from "notistack";
import { makeStyles } from "tss-react/mui";

const useAppStyles: any = makeStyles<any>()((theme) => {
  return {
    dialogTextarea: {
      "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &:focus-visited .MuiOutlinedInput-notchedOutline":
        {
          backgroundColor: `transparent`,
          border: `1px solid ${theme.palette.primary.main}`,
        },
      "& textarea": {
        minHeight: 440,
      },
    },
  };
});

const DEFAULT_SORT_ORDER: SortOrder = {
  by: "make",
  dir: Sort.ASC,
};

const columnNameToField = (name: string) => {
  switch (name) {
    case "channelPartner":
      return "organizations";
    default:
      return snakeCase(name);
  }
};

const sortOrderForColumn = (sortColumn: SortColumn) => {
  const sortOrder = {
    by: columnNameToField(sortColumn.columnKey),
    dir: sortColumn.direction === "ASC" ? Sort.ASC : Sort.DESC,
  };
  return sortOrder;
};

const sortColumnForOrder = (sortOrder: SortOrder): SortColumn => {
  const sortColumn = {
    columnKey: sortOrder.by,
    direction: (sortOrder.dir === Sort.ASC ? "ASC" : "DESC") as SortDirection,
  };
  return sortColumn;
};

const Fancharts = () => {
  const { getRawIdToken, hasAccess, ready } = useAuthData();
  const dispatch = useAppDispatch();
  const fancharts = useAppSelector((state) => state.airflowfancharts.fancharts);
  const totalCount = useAppSelector(
    (state) => state.airflowfancharts.totalCount
  );
  const filter = useAppSelector((state) => state.airflowfancharts.filter);
  const sortOrderSelector = useAppSelector((state) => ({
    by: state.airflowfancharts.sortField,
    dir: state.airflowfancharts.sortDirection,
  }));
  const [sortOrderState, setSortOrderState] =
    useState<SortOrder>(sortOrderSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState<string>(filter);
  const navigate = useNavigate();
  const queryRef = useRef({
    page: page,
    size: rowsPerPage,
    orderBy: sortOrderState.by,
    dir: sortOrderState.dir,
    filter: filter,
  });

  const getFanChartsList = async () => {
    const idToken = await getRawIdToken();
    dispatch(
      getFanCharts({
        token: idToken,
        page: queryRef.current.page + 1,
        size: queryRef.current.size,
        orderBy: queryRef.current.orderBy,
        dir: queryRef.current.dir,
        filter: queryRef.current.filter,
      })
    );
  };

  useEffect(() => {
    if (ready) {
      const updateFanChartsList = () => {
        getFanChartsList();
      };

      queryRef.current = {
        page: page,
        size: rowsPerPage,
        orderBy: sortOrderState.by,
        dir: sortOrderState.dir,
        filter: query,
      };
      updateFanChartsList();
    }
  }, [page, rowsPerPage, sortOrderState, query, ready]);

  const updateFilterQuery = (q: string) => {
    setPage(0);
    setQuery(q);
    dispatch(setFilter(q));
  };

  const handleChangeFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const q = event.target.value;
    updateFilterQuery(q);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    setPage(0);
  };

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    if (sortColumns) {
      const sort = sortColumns[0]
        ? sortOrderForColumn(sortColumns[0])
        : DEFAULT_SORT_ORDER;
      setSortOrderState(sort);
      dispatch(setSortOrder(sort));
    }
  };

  const textRef = useRef();

  const transformedRows = fancharts
    ? fancharts.flatMap((fanchart: FanCharts) =>
        fanchart.fanchartmodels.map((model: FanChartModels) => ({
          fanchart_id: fanchart.id,
          make: fanchart.make,
          make_display: fanchart.make_display,
          fanchart_name: fanchart.fanchart_name,
          model_id: model.id,
          model: model.model,
          max_pressure: model.max_pressure,
          tonnage: model.tonnage,
        }))
      )
    : [];

  const isModalOpen = useAppSelector(
    (state) => state.airflowfancharts.isModalOpen
  );
  const [modalFanChartDetails, setModalFanChartDetails] = useState("");
  const [currentFanchart, setCurrentFanchart] =
    useState<FanChartsFullRow | null>(null);
  const [modalFanChartDetailsTitle, setModalFanChartDetailsTitle] =
    useState("");

  const handleOpenFanChartDetailsModal = (fanchart: FanChartsFullRow) => {
    (async () => {
      const idToken = await getRawIdToken();
      const resp = await dispatch(
        getFanChartDetails({
          token: idToken,
          fanchartId: fanchart.fanchart_id || "",
        })
      );
      setModalFanChartDetailsTitle(
        fanchart.make_display + " - " + fanchart.fanchart_name
      );
      if (resp?.payload?.result?.success) {
        const content = toPlainFormat(
          resp.payload.result.fanchart.fanchartdetails
        );
        setModalFanChartDetails(content);
        setCurrentFanchart(fanchart);
        dispatch(openModal());
      } else {
        const content = "Failed to load chart details!";
        setModalFanChartDetails(content);
        setCurrentFanchart(null);
        dispatch(openModal());
        enqueueSnackbar(content, {
          variant: "error",
        });
      }
    })();
  };

  const handleCloseFanChartDetailsModal = () => {
    dispatch(closeModal());
    setCurrentFanchart(null);
  };

  const handleEditFanChartDetailsClick = () => {
    if (currentFanchart?.fanchart_id) {
      navigate(
        "/fancharts/edit/" +
          currentFanchart?.fanchart_id +
          "/" +
          currentFanchart?.model_id
      );
    }
    dispatch(closeModal());
    setCurrentFanchart(null);
  };

  const handleAddFanData = () => {
    navigate("/fancharts/edit");
  };

  const styles = useAppStyles();

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Grid container direction="row">
        <Grid xs={6} item>
          <Typography variant="h4" gutterBottom>
            Fan Charts
          </Typography>
        </Grid>
        <Grid xs={6} item alignItems="flex-end">
          <Box marginTop="5px" justifyContent="flex-end" display={"flex"}>
            {hasAccess(Constants.ACTIONS.EDIT_FAN_CHARTS) && (
              <Button
                size="medium"
                variant="contained"
                onClick={handleAddFanData}
              >
                <AddIcon style={{ marginRight: "12px" }} />
                Add Fan Data
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={handleCloseFanChartDetailsModal}
          fullWidth
          maxWidth="lg"
        >
          <Grid container direction="column">
            <DialogTitle>{modalFanChartDetailsTitle}</DialogTitle>
            <Grid item>
              <Box
                style={{
                  padding: "8px 24px 8px 24px",
                  overflow: "auto",
                  maxHeight: "600px",
                  letterSpacing: "0.3px",
                }}
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Fan Data"
                  fullWidth
                  multiline
                  defaultValue={modalFanChartDetails}
                  variant="outlined"
                  className={styles.classes.dialogTextarea}
                />
              </Box>
            </Grid>
            <DialogActions style={{ padding: "0 24px 16px 24px" }}>
              <Button size="medium" onClick={handleCloseFanChartDetailsModal}>
                Close
              </Button>
              <Button
                size="medium"
                variant="contained"
                onClick={handleEditFanChartDetailsClick}
              >
                Edit Data
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      )}

      <Grid
        container
        spacing={2}
        columns={{ xs: 2, sm: 2, md: 12 }}
        sx={{ mb: 1 }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <TTTextField
            label="Search by model number..."
            defaultValue={query}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChangeFilter}
            autoFocus
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: query ? "visible" : "hidden" }}
                  onClick={() => {
                    updateFilterQuery("");
                    if (textRef.current) {
                      (textRef.current as any).value = "";
                    }
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            sx={{
              "& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
              maxWidth: 370,
            }}
          />
        </Grid>
      </Grid>
      {transformedRows && transformedRows?.length > 0 ? (
        <>
          <FanChartsDataGrid
            rows={transformedRows}
            onChangeData={handleChangeData}
            sortColumn={sortColumnForOrder(sortOrderState)}
            onRowClick={handleOpenFanChartDetailsModal}
          />
          <TablePagination
            component="div"
            count={totalCount || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{
              width: "100%",
              maxWidth: 1200,
            }}
          />
        </>
      ) : (
        <Box sx={{ height: "100%" }}>No model matched your search</Box>
      )}
    </Box>
  );
};

export default Fancharts;

export interface PlainArrayResult {
  pressures: string[];
  headerRow: string;
  data: string[];
}

export const toPlainFormatArray = (
  data: FanChartsDetails[]
): PlainArrayResult => {
  const result: PlainArrayResult = {
    pressures: [],
    headerRow: "",
    data: [],
  };

  if (!data || data.length === 0) {
    return result;
  }

  const pressureSet = data
    .map((item) => item?.pressure)
    .filter((pressure) => pressure !== undefined);
  const uniquePressures =
    pressureSet.length > 0 ? Array.from(new Set(pressureSet)).sort() : [];

  const numColumns = uniquePressures.length;
  result.pressures = uniquePressures;

  let headerRow = "CFM ";
  for (let i = 0; i < numColumns; i++) {
    headerRow += `RPM BHP WATTS `;
  }
  result.headerRow = headerRow.trim();

  const groupedData = data.reduce((acc, item) => {
    const cfm = /^-?\d*\.?\d+$/.test(item?.cfm) ? item?.cfm : "--";
    const rpm = /^-?\d*\.?\d+$/.test(item?.rpm) ? item?.rpm : "--";
    const pressure = /^-?\d*\.?\d+$/.test(item?.pressure)
      ? item?.pressure
      : "--";
    const bph = /^-?\d*\.?\d+$/.test(item?.bph) ? item?.bph : "--";
    const watts = /^-?\d*\.?\d+$/.test(item?.watts) ? item?.watts : "--";
    if (!acc[cfm]) acc[cfm] = [];
    acc[cfm].push({ rpm, pressure, bph, watts });
    acc[cfm].sort((a, b) => Number(a.pressure) - Number(b.pressure));
    return acc;
  }, {} as Record<string, { rpm: string; pressure: string; bph: string; watts: string }[]>);

  const sortedCFM = Object.keys(groupedData).sort(
    (a, b) => Number(a) - Number(b)
  );

  for (const cfm of sortedCFM) {
    let rowString = `${cfm} `;
    const groups = groupedData[cfm];
    for (let i = 0; i < numColumns; i++) {
      const group = groups.find((item) => item.pressure === uniquePressures[i]);
      rowString += group
        ? `${group.rpm} ${group.bph} ${group.watts} `
        : "-- -- -- ";
    }
    result.data.push(rowString.trim());
  }

  return result;
};

export const toPlainFormat = (
  fanChartsDetailsArray: FanChartsDetails[],
  withHeaderRow?: boolean
): string => {
  const dataObject: PlainArrayResult = toPlainFormatArray(
    fanChartsDetailsArray
  );
  if (withHeaderRow) {
    return dataObject.headerRow + "\n" + dataObject.data.join("\n");
  } else {
    return dataObject.data.join("\n");
  }
};
