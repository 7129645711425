import { makeStyles } from "tss-react/mui";

export const useAssetsStyles = makeStyles()((theme) => {
  return {
    filterField: {
      width: 280,
      "&:hover .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "rgba(2, 136, 209, 0.04)!important",
        border: "1px solid rgb(2, 136, 209)!important",
      },
    },
    filterChip: {
      display: "flex",
      lineHeight: "16px",
      marginRight: "5px",
      marginTop: "10px",
      borderRadius: "16px",
      padding: " 3px 4px",
      color: "#1976D2",
      background: "transparent",
      border: "1px solid #1976D2",
      ".MuiChip-deleteIcon": {
        color: "#1976D2",
      },
    },
    autocomplete: {
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        visibility: "visible",
        backgroundColor: "#f1f1f1",
        padding: "1px",
        marginRight: "4px",
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator:hover": {
        backgroundColor: "#81B5EA",
        color: "#ffffff",
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator .mui-ptiqhd-MuiSvgIcon-root ":
        {
          fontSize: "1.15rem;",
        },
    },
    viewSelectorMenuButton: {
      borderColor: "#c4c4c4",
      padding: "7px 0",
      color: "#1976D2",
      marginRight: "15px",
      fontSize: "0.875rem",
      textAlign: "right",
      "&:hover, &:active": {
        backgroundColor: "transparent",
      },
    },
    viewIsSharedIcon: {
      position: "absolute",
      fontSize: 14,
      bottom: 2,
      left: 2,
      opacity: 0.65,
      padding: 2,
      background: "#ebb",
      borderRadius: 15,
    },
    viewIsSharedToIcon: { background: "#ebb" },
    viewIsSharedFromIcon: { background: "#bbf" },
    viewDeletedIcon: {
      lineHeight: 12,
      padding: "2px 3px",
      "& > svg": {
        fontSize: 20,
      },
    },
    viewActionDefaultButton: {
      fontSize: "0.875rem",
      "&:hover, &:active": {
        backgroundColor: "transparent",
      },
    },
    viewActionCancelButton: {
      paddingLeft: 12,
      paddingRight: 12,
      color: "#557",
    },
    viewActionSaveButton: {
      paddingLeft: 12,
      paddingRight: 12,
      marginLeft: 4,
    },
    viewActionFormTitle: {
      color: "#5f5f5f",
      fontSize: 20,
      "& span": {
        display: "inline",
        marginLeft: 4,
      },
    },
  };
});
