import { useAuth0 } from "@auth0/auth0-react";
import { Logout, DoLogout, ProtectedRoute } from "components/common/auth";
import Home from "components/home";
import { MainLayout } from "components/layout/";
import { NotFound } from "components/notfound";
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { SurveyTemplateOrganizationsSummary } from "./components/surveytemplate";
import { SurveyTemplateEditor } from "components/surveytemplate/editor";
import Jobs from "components/jobs";
import MainLayoutSkeleton from "components/loader/layout";
import Organizations from "components/organizations";
import ViewOrganization from "components/organizations/view";
import Users from "components/users";
import UserDetails from "components/users/view";
import { Roles } from "components/roles";
import { RoleEditor } from "components/roles/edit";
import UserRoles from "components/users/roles";
import Fancharts from "components/fancharts";
import FanchartDataEditor from "./components/fancharts/fanchartdataeditor";
import { useAppDispatch } from "redux/store/hooks";
import { getCurrentUserActions } from "redux/actions/users";
import {
  setIsGreensightAdmin,
  setIdToken,
  setRawIdToken,
} from "redux/reducers/authDataSlice";
import { Constants } from "utils/constants";
import { NotAllowed } from "components/notallowed";
import EditJob from "components/jobs/edit";
import ViewSite from "components/site";
import Assets from "components/assets";
import AssetDetails from "components/assets/view";
import Inventory from "components/inventory";
import InventoryItemDetails from "components/inventory/view";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import NetSuite from "components/netsuite";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjAen6eORw9qvQEMIEyV5a37FW1W1lOHE",
  authDomain: "technician-admin.firebaseapp.com",
  projectId: "technician-admin",
  storageBucket: "technician-admin.appspot.com",
  messagingSenderId: "681159978245",
  appId: "1:681159978245:web:e8615a8c9d6e4cd29d1b6c",
  measurementId: "G-5WPCLTLJZ5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AuthDataProvider = () => {
  const { getIdTokenClaims } = useAuth0();

  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const claims: any = await getIdTokenClaims();
      const claimsToCheck =
        claims !== undefined ? claims[Constants.ROLE_CLAIM_TYPE] : [];
      dispatch(
        setIsGreensightAdmin(claimsToCheck.includes("greensight-admin"))
      );
      dispatch(setIdToken(claims));
      dispatch(setRawIdToken(claims?.__raw));

      dispatch(getCurrentUserActions({ token: claims?.__raw }));
    })();
  });

  return <></>;
};

const App = () => {
  const { isLoading } = useAuth0();
  const location = useLocation();
  if (isLoading) {
    return (
      <div data-testid="loader">
        <MainLayoutSkeleton />;
      </div>
    );
  }
  return (
    <React.Fragment>
      {/* don't try to load auth data if user logs out */}
      {location.pathname?.indexOf("logout") === -1 && <AuthDataProvider />}
      <MainLayout>
        <Routes>
          <Route path="/" element={<ProtectedRoute component={Home} />} />
          <Route
            path="/templates"
            element={
              <ProtectedRoute
                component={SurveyTemplateOrganizationsSummary}
                forGreensightAdminOnly={true}
              />
            }
          />
          <Route
            path="/jobs"
            element={
              <ProtectedRoute
                component={Jobs}
                action={Constants.ACTIONS.VIEW_JOBS}
              />
            }
          />
          <Route
            path="/jobs/edit/:jobId"
            element={
              <ProtectedRoute
                component={EditJob}
                action={Constants.ACTIONS.VIEW_JOBS}
              />
            }
          />
          <Route
            path="/organizations"
            element={
              <ProtectedRoute
                component={Organizations}
                action={Constants.ACTIONS.VIEW_ORGS}
              />
            }
          />
          <Route
            path="/organizations/view/:organizationId"
            element={
              <ProtectedRoute
                component={ViewOrganization}
                action={Constants.ACTIONS.VIEW_ORGS}
              />
            }
          />
          {/*<Route
            path="/tplorg/assignorg/:templateId"
            element={<ProtectedRoute component={AssignOrgToTemplate} />}
          /> */}
          <Route
            path="/template/edit"
            element={
              <ProtectedRoute
                component={SurveyTemplateEditor}
                forGreensightAdminOnly={true}
              />
            }
          />{" "}
          <Route
            path="/template/edit/:templateId"
            element={
              <ProtectedRoute
                component={SurveyTemplateEditor}
                forGreensightAdminOnly={true}
              />
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute
                component={Users}
                action={Constants.ACTIONS.VIEW_USERS}
              />
            }
          />
          <Route
            path="/users/view/:userId"
            element={
              <ProtectedRoute
                component={UserDetails}
                action={Constants.ACTIONS.VIEW_USERS}
              />
            }
          />
          <Route
            path="/users/roles/:userId"
            element={
              <ProtectedRoute
                component={UserRoles}
                forGreensightAdminOnly={false}
              />
            }
          />
          <Route
            path="/roles"
            element={
              <ProtectedRoute component={Roles} forGreensightAdminOnly={true} />
            }
          />
          <Route
            path="/roles/edit"
            element={
              <ProtectedRoute
                component={RoleEditor}
                forGreensightAdminOnly={true}
              />
            }
          />
          <Route
            path="/roles/edit/:roleId"
            element={
              <ProtectedRoute
                component={RoleEditor}
                forGreensightAdminOnly={true}
              />
            }
          />
          <Route
            path="/site/view/:orgName/:orgId/:siteId"
            element={
              <ProtectedRoute
                component={ViewSite}
                action={Constants.ACTIONS.MANAGE_SITES}
              />
            }
          />
          <Route
            path="/assets"
            element={
              <ProtectedRoute
                component={Assets}
                action={Constants.ACTIONS.VIEW_ASSET}
              />
            }
          />
          <Route
            path="/assets/view/:assetId"
            element={
              <ProtectedRoute
                component={AssetDetails}
                action={Constants.ACTIONS.VIEW_ASSET}
              />
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute
                component={Inventory}
                action={Constants.ACTIONS.VIEW_INVENTORY}
              />
            }
          />
          <Route
            path="/inventory/view/:serialNumber"
            element={
              <ProtectedRoute
                component={InventoryItemDetails}
                action={Constants.ACTIONS.VIEW_INVENTORY}
              />
            }
          />
          <Route
            path="/fancharts"
            element={
              <ProtectedRoute
                component={Fancharts}
                forGreensightAdminOnly={true}
                action={Constants.ACTIONS.VIEW_FAN_CHARTS}
              />
            }
          />
          <Route
            path="/fancharts/edit/:fanchartId?/:fanchartmodelId?"
            element={
              <ProtectedRoute
                component={FanchartDataEditor}
                forGreensightAdminOnly={true}
                action={Constants.ACTIONS.VIEW_FAN_CHARTS}
              />
            }
          />
          <Route
            path="/netsuite/:tab"
            element={
              <ProtectedRoute
                component={NetSuite}
                forGreensightAdminOnly={true}
                action={Constants.ACTIONS.EDIT_NETSUITE}
              />
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dologout" element={<DoLogout />} />
          <Route path="/notallowed" element={<NotAllowed />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
    </React.Fragment>
  );
};

export default App;
