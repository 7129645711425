// Generic-looking comparator for sorting based on a map of known column names to sort comparator methods.
import { SortColumn } from "react-data-grid";
import { Comparator } from "./types";
import dayjs from "dayjs";

type ComparatorConstructor = (sortColumn: string) => Comparator;

export const stringComparator: ComparatorConstructor = (sortColumn: string) => {
  return (a?: any, b?: any): number => {
    return a?.[sortColumn]?.localeCompare(b?.[sortColumn]) || 0;
  };
};

// For future consideration; not currently used
// const numberComparator: ComparatorConstructor = (sortColumn: string) => {
//   return (a?: any, b?: any): number => {
//     return a?.[sortColumn] - b?.[sortColumn] || 0;
//   };
// };

const dateComparator: ComparatorConstructor = (sortColumn: string) => {
  return (a?: any, b?: any): number => {
    return dayjs(a?.[sortColumn]).diff(dayjs(b?.[sortColumn])) || 0;
  };
};

const boolComparator: ComparatorConstructor = (sortColumn: string) => {
  return (a?: any, b?: any): number => {
    const _a = a?.[sortColumn] || false;
    const _b = b?.[sortColumn] || false;
    return _a === _b ? 0 : _a ? 1 : -1;
  };
};

const maybeTrim = (a: any) => {
  if (a?.trim) {
    return a.trim();
  }
  return a;
};

function arrayComparator(fieldName: string): ComparatorConstructor {
  return (sortColumn: string) => {
    return (a: any, b: any) => {
      const arrayA = a?.[sortColumn];
      const arrayB = b?.[sortColumn];
      if (arrayA?.length && arrayB?.length) {
        const _a = a?.[sortColumn]?.map((item: any) =>
          maybeTrim(item[fieldName])
        );
        const _b = b?.[sortColumn]?.map((item: any) =>
          maybeTrim(item[fieldName])
        );
        return _a?.join(",")?.localeCompare(_b?.join(","));
      }
      if (arrayA?.length) {
        return 1;
      }
      return -1;
    };
  };
}

type ComparatorMapType = {
  [id: string]: ComparatorConstructor;
};

const COLUMN_TYPE_MAP: ComparatorMapType = {
  role_name: stringComparator,
  updatedDt: dateComparator,
  updated_dt: dateComparator,
  createdDt: dateComparator,
  created_dt: dateComparator,
  updatedBy: stringComparator,
  createdBy: stringComparator,
  name: stringComparator,
  email: stringComparator,
  phone: stringComparator,
  oldValue: stringComparator,
  newValue: stringComparator,
  completedDt: dateComparator,
  completed_dt: dateComparator,
  organizations: arrayComparator("organization_name"),
  is_system: boolComparator,
};

export function getComparator(sortColumn: string): Comparator {
  const comp = COLUMN_TYPE_MAP[sortColumn];
  return (comp || stringComparator)(sortColumn);
}

export const getCompareFn =
  (sortColumns: readonly SortColumn[]) => (a: any, b: any) => {
    for (const sort of sortColumns) {
      const _a =
        a[sort.columnKey] === undefined ||
        a[sort.columnKey] === null ||
        a[sort.columnKey] === ""
          ? undefined
          : a[sort.columnKey];
      const _b =
        b[sort.columnKey] === undefined ||
        b[sort.columnKey] === null ||
        b[sort.columnKey] === ""
          ? undefined
          : b[sort.columnKey];
      if (_a === undefined && _b === undefined) {
        return 0;
      } else if (_a === undefined) {
        return 1;
      } else if (_b === undefined) {
        return -1;
      }

      const comparator = getComparator(sort.columnKey);
      const compResult = comparator(a, b);
      if (compResult !== undefined && compResult !== 0) {
        return sort.direction === "ASC" ? compResult : -compResult;
      }
    }
    return 0;
  };
