import { ColumnState } from "utils/types";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { columnSettings } from "components/common/gridview/gridState";
import {
  columnFilterOperators,
  renderCell,
  valueGetter,
} from "components/common/gridview/gridColumns";
import {
  WarrantyStatusBadge,
  warrantyStatusLabel,
} from "./warrantyStatusBadge";

const definedColumns: any[] = [
  {
    field: "id",
    object: "item",
    key: "id",
    headerName: "Inventory Item ID",
    filterable: true,
    hide: true,
    sortingKey: "itemId",
    sortable: true,
    filterOperators: true,
  },
  {
    field: "itemSerialNumber",
    object: "item",
    key: "serialNumber",
    headerName: "Serial",
    hide: false,
    sortable: true,
    filterable: true,
  },

  // item site
  {
    field: "siteName",
    object: "site",
    key: "name",
    headerName: "Site Name",
    filterable: true,
    filteringKey: "siteName",
    hideable: true,
    sortingKey: "siteName",
    sortable: true,
  },
  {
    field: "siteAddress",
    object: "site",
    key: "address",
    sortingKey: "siteAddress",
    headerName: "Site Address",
    filterable: true,
    filteringKey: "siteAddress",
    hideable: true,
    sortable: true,
  },

  // Inventory Item
  {
    field: "itemType",
    object: "item",
    key: "type",
    headerName: "Equipment Type",
    sortable: true,
    hideable: true,
    filterable: true,
  },
  {
    field: "itemModelNumber",
    object: "item",
    key: "modelNumber",
    headerName: "Model",
    sortable: true,
    hideable: true,
    filterable: true,
  },

  // Customer organization
  {
    field: "customer",
    object: "item",
    key: "customer",
    sortingKey: "customer",
    headerName: "Customer",
    sortable: true,
    filterable: true,
  },

  // Channel Partner organization
  {
    field: "itemChannelPartner",
    object: "item",
    key: "channelPartner",
    sortingKey: "channelPartner",
    headerName: "Channel Partner",
    hideable: true,
    sortable: false,
    filterable: false,
  },
  // Warranty
  {
    field: "status",
    object: "warranty",
    key: "status",
    sortingKey: "warrantyStatus",
    filteringKey: "warrantyStatus",
    headerName: "Warranty Status",
    sortable: true,
    hide: false,
    hideable: true,
    filterable: true,
    valueGetter: (params: any) => warrantyStatusLabel(params?.value),
    renderCell: (params: GridRenderCellParams) => {
      return <WarrantyStatusBadge value={params?.value} />;
    },
  },
  {
    field: "failureDt",
    object: "warranty",
    key: "failureDt",
    sortingKey: "failureDt",
    filteringKey: "failureDt",
    headerName: "Failure Date",
    type: "date",
    hide: true,
    hideable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "startDt",
    object: "warranty",
    key: "startDt",
    sortingKey: "warrantyStartDt",
    filteringKey: "warrantyStartDt",
    headerName: "Warranty Start Date",
    type: "date",
    hide: true,
    hideable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "endDt",
    object: "warranty",
    key: "endDt",
    sortingKey: "warrantyEndDt",
    filteringKey: "warrantyEndDt",
    headerName: "Warranty End Date",
    type: "date",
    hide: true,
    hideable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "shipDt",
    object: "warranty",
    key: "shipDt",
    sortingKey: "warrantyShipDt",
    filteringKey: "warrantyShipDt",
    headerName: "Ship Date",
    type: "date",
    hide: true,
    hideable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "installedDt",
    object: "warranty",
    key: "installedDt",
    sortingKey: "warrantyInstalledDt",
    filteringKey: "warrantyInstalledDt",
    headerName: "Install Date",
    type: "date",
    hide: true,
    hideable: true,
    sortable: true,
    filterable: true,
  },

  // NetSuite sold-to Channel Partner organization
  {
    field: "fulfillmentSoldTo",
    object: "item",
    key: "soldTo",
    sortingKey: "soldTo",
    filteringKey: "soldTo",
    headerName: "Sold To",
    hide: true,
    hideable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: "fulfillmentSalesOrder",
    object: "item",
    key: "fulfillmentSalesOrder",
    sortingKey: "fulfillmentSalesOrder",
    filteringKey: "fulfillmentSalesOrder",
    headerName: "Sales Order",
    hide: false,
    hideable: true,
    sortable: true,
    filterable: true,
  },

  {
    field: "actions",
    object: "menu",
    key: "menu",
    type: "menu",
    headerName: "Action",
    hide: false,
    hideable: false,
    sortable: false,
    filterable: false,
    options: [{ label: "Enter Failure...", value: "failureDt" }],
  },
];

/**
  Normalize defined column items.
 */
const defaultColumns: GridColDef[] = definedColumns.map((col) => {
  const column = {
    field: col.field,
    headerName: col.headerName,
    hide: col.hide,
    width: col.width ?? columnSettings.COLUMN_DEFAULT_WIDTH,
    renderCell: col.renderCell || renderCell(col),
    valueGetter: col.valueGetter,
  };
  // optional properties that need to be included if exists
  if (col?.type) {
    (column as any).type = col.type;
  }
  if (col?.type === "date") {
    (column as any).valueGetter = valueGetter(col);
  }
  if (col?.align) {
    (column as any).align = col.align;
  }
  if (col?.headerAlign) {
    (column as any).headerAlign = col.headerAlign;
  }
  if (col?.filterOperators === true) {
    (column as any).filterOperators = columnFilterOperators(col);
  }
  // optional properties that need to be included if exists and is explicitly set to true.
  if (col?.sortable === true) {
    (column as any).sortable = true;
  }
  if (col?.filterable === true) {
    (column as any).filterable = true;
  }
  // optional properties that need to be included if exists and is explicitly set to false.
  if (col?.hideable === false) {
    (column as any).hideable = false;
  }
  if (col?.options) {
    (column as any).options = col.options;
  }
  return column;
});

const validWidth = (width: any, defaultValue: number = -1) => {
  return typeof width === "number" && width > columnSettings.COLUMN_MIN_WIDTH
    ? width
    : defaultValue;
};
const validIndex = (index: any, defaultValue: number = -1) => {
  return typeof index === "number" && index > -1 ? index : defaultValue;
};
const validBool = (visibility: any, defaultValue: boolean = false) => {
  return typeof visibility === "boolean" ? visibility : defaultValue;
};

const initialColumnStates: ColumnState[] = (() => {
  const initialStates: Array<ColumnState> = [];
  defaultColumns.forEach((col: any, index: number) => {
    initialStates.push({
      field: col.field,
      hide: validBool(col.hide),
      width: validWidth(col.width),
      index: validIndex(index),
    });
  });
  return initialStates;
})();

const supportedSortColumns = defaultColumns
  .filter((col) => (col as any).sortable !== undefined && (col as any).sortable)
  .map((sortableColumn) => sortableColumn.field);

export {
  supportedSortColumns,
  defaultColumns,
  definedColumns,
  initialColumnStates,
};
