import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  Alert,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../redux/store/hooks";
import { createOrganization, getAllOrganizations } from "redux/actions/orgs";
import TTAutoComplete from "components/common/TTAutoComplete/TTAutoComplete";
import { createOrgStyles } from "./orgsListFilter/styles";

import * as createOrgDialog from "redux/reducers/createOrgSlice";
import OrganizationsDataGrid from "./dataGrid";
import OrgsListFilter from "./orgsListFilter";
import { useAuthData } from "utils/hooks/useAuthData";
import AddIcon from "@mui/icons-material/Add";
import { enqueueSnackbar } from "notistack";
import TTTextField from "components/common/TTTextField";
import { Constants } from "utils/constants";
import { orgTypeNameFromType } from "utils/customerTypes";
import Measure, { BoundingRect } from "react-measure";

const localConstants: { [key: string]: string } = {
  NOT_ALLOWED: "You are not allowed to perform this action",
  NAME_EXISTS: "organization with same name already exists.",
  NO_CP_ASSIGNED: "You are not assigned to any Channel Partner Organization.",
  NO_CP_SELECTED: "Select Channel Partner where to create an organization",
  NO_C_ASSIGNED: "You are not assigned to any Customer organization.",
  NO_C_SELECTED: "Select Customer where to create an organization",
  PARENT_CP_FOR_CUSTOMER:
    "Channel partner where the customer organization will be created:",
  PARENT_C_FOR_INSTALLER:
    "Customer where the installer organization will be created:",
};

const Organizations = () => {
  const filteredOrgs = useAppSelector(
    (state) => state.orgsList.data?.filteredOrgs
  );
  const loading = useAppSelector((state) => state.orgsList.loading);
  const createOrgModal = useAppSelector((state) => state.createOrg);
  const dispatch = useAppDispatch();

  const { isGreensightAdmin, getRawIdToken, hasAccess, ready } = useAuthData();
  const [dimensions, setDimensions] = useState<BoundingRect | undefined>();
  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();

        dispatch(
          getAllOrganizations({
            token: idToken,
          })
        );
      })();
    }
  }, [ready]);
  const handleCreateOrgDialogOpen = () => {
    dispatch(createOrgDialog.setOpen(true));
    setDefaultForCreateOrg();
  };
  const handleCreateOrgDialogClose = () => {
    dispatch(createOrgDialog.setOpen(false));
    setDefaultForCreateOrg();
  };
  const setDefaultForCreateOrg = () => {
    dispatch(createOrgDialog.setName(""));
    dispatch(createOrgDialog.setType(""));
    if (
      channelPartners.length === 1 &&
      channelPartners[0]?.organization_id &&
      channelPartners[0]?.organization_name
    ) {
      dispatch(createOrgDialog.setChannelPartner(channelPartners));
    } else {
      dispatch(createOrgDialog.setChannelPartner([]));
    }
    if (
      customers.length === 1 &&
      customers[0]?.organization_id &&
      customers[0]?.organization_name
    ) {
      dispatch(createOrgDialog.setCustomer(customers));
    } else {
      dispatch(createOrgDialog.setCustomer([]));
    }
    dispatch(
      createOrgDialog.setValidation({
        valid: true,
        message: "",
      })
    );
  };
  const handleOrgNameChange = (event: any) => {
    const newVal = event.target.value;
    if (newVal === null || newVal === undefined || newVal === "") {
      dispatch(
        createOrgDialog.setValidation({
          valid: false,
          message: "Organization name is required",
        })
      );
    } else {
      dispatch(
        createOrgDialog.setValidation({
          valid: true,
          message: "",
        })
      );
    }
    dispatch(createOrgDialog.setName(event.target.value));
  };
  const handleCreateOrgClick = () => {
    if (
      createOrgModal.type === null ||
      createOrgModal.type === undefined ||
      createOrgModal.type === ""
    ) {
      dispatch(
        createOrgDialog.setValidation({
          valid: false,
          message: "Organization type is required",
        })
      );
    } else if (
      createOrgModal.name === null ||
      createOrgModal.name === undefined ||
      createOrgModal.name === ""
    ) {
      dispatch(
        createOrgDialog.setValidation({
          valid: false,
          message: "Organization name is required",
        })
      );
    } else {
      if (!isGreensightAdmin) {
        if (
          createOrgModal.type !== Constants.ORGANIZATION_TYPE.CUSTOMER &&
          createOrgModal.type !== Constants.ORGANIZATION_TYPE.INSTALLER
        ) {
          return validationAlert(localConstants.NOT_ALLOWED, false);
        }

        if (createOrgModal.type === Constants.ORGANIZATION_TYPE.CUSTOMER) {
          if (!hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG)) {
            return validationAlert(localConstants.NOT_ALLOWED, false);
          }
          if (createOrgInChannelPartner() === null) {
            return validationAlert(localConstants.NO_CP_SELECTED, false);
          }
          dispatch(createOrgDialog.setCustomer([]));
        }

        if (createOrgModal.type === Constants.ORGANIZATION_TYPE.INSTALLER) {
          if (!hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)) {
            return validationAlert(localConstants.NOT_ALLOWED, false);
          }
          if (createOrgInCustomer() === null) {
            return validationAlert(localConstants.NO_C_SELECTED, false);
          }
          dispatch(createOrgDialog.setChannelPartner([]));
        }
      }

      (async () => {
        const idToken = await getRawIdToken();
        dispatch(
          createOrganization({
            token: idToken,
            name: createOrgModal.name,
            type: createOrgModal.type,
            channelPartner: createOrgInChannelPartner(),
            customer: createOrgInCustomer(),
          })
        ).then((resp) => {
          if (resp?.payload?.success) {
            enqueueSnackbar("Organization created!", { variant: "default" });
          } else {
            if (resp?.payload?.code === "C5") {
              return validationAlert(
                resp?.payload?.description
                  ? resp.payload.description
                  : localConstants.NAME_EXISTS,
                false
              );
            } else {
              enqueueSnackbar("Failed to create organization!", {
                variant: "error",
              });
            }
          }
          dispatch(createOrgDialog.setOpen(false));
          dispatch(
            getAllOrganizations({
              token: idToken,
            })
          );
        });
      })();
    }
  };
  const handleOrgTypeChange = (event: any) => {
    dispatch(createOrgDialog.setType(event.target.value));
  };

  useEffect(() => {
    let error_message = "";
    if (!isGreensightAdmin) {
      if (
        createOrgModal.type === Constants.ORGANIZATION_TYPE.CUSTOMER &&
        channelPartners.length < 1
      ) {
        error_message = localConstants.NO_CP_ASSIGNED;
      } else if (
        createOrgModal.type === Constants.ORGANIZATION_TYPE.INSTALLER &&
        customers.length < 1
      ) {
        error_message = localConstants.NO_C_ASSIGNED;
      }
    }
    if (error_message) {
      validationAlert(error_message, false);
    } else {
      validationAlert("", true);
    }
  }, [createOrgModal.type]);

  const { classes } = createOrgStyles();
  const orgs = useAppSelector((state) => state.orgsList.data?.orgs);

  const channelPartnersList = () => {
    if (orgs && Array.isArray(orgs)) {
      const channelPartnersOrgs = orgs.filter((org) =>
        org.types.includes("CP")
      );
      return channelPartnersOrgs;
    }
    return [];
  };
  const customersList = () => {
    if (orgs && Array.isArray(orgs)) {
      const customerOrgs = orgs.filter((org) => org.types.includes("C"));
      return customerOrgs;
    }
    return [];
  };

  const channelPartners = channelPartnersList();
  const customers = customersList();

  const handleChannelPartnerSelected = (newChannelPartner: any) => {
    if (!Array.isArray(newChannelPartner)) {
      newChannelPartner = [newChannelPartner];
    }
    dispatch(createOrgDialog.setChannelPartner(newChannelPartner));
  };
  const handleCustomerSelected = (newCustomer: any) => {
    if (!Array.isArray(newCustomer)) {
      newCustomer = [newCustomer];
    }
    dispatch(createOrgDialog.setCustomer(newCustomer));
  };

  const createOrgInChannelPartner = () => {
    if (
      createOrgModal.channelPartner &&
      Array.isArray(createOrgModal.channelPartner) &&
      createOrgModal.channelPartner.length > 0
    ) {
      return [createOrgModal.channelPartner[0].organization_id];
    }
    return null;
  };
  const createOrgInCustomer = () => {
    if (
      createOrgModal.customer &&
      Array.isArray(createOrgModal.customer) &&
      createOrgModal.customer.length > 0
    ) {
      return createOrgModal.customer.map((obj) => obj.organization_id);
    }
    return null;
  };

  const validationAlert = (msg: string, type: boolean): boolean => {
    dispatch(createOrgDialog.setValidation({ valid: type, message: msg }));
    return type;
  };

  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "1080px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Grid container direction="row">
        <Grid xs={6} item>
          <Typography variant="h4" gutterBottom>
            Organizations
          </Typography>
        </Grid>
        <Grid xs={6} item alignItems="flex-end">
          <Box marginTop="5px" justifyContent="flex-end" display={"flex"}>
            {(hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) ||
              hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)) && (
              <Button
                size="medium"
                variant="contained"
                onClick={handleCreateOrgDialogOpen}
              >
                <AddIcon style={{ marginRight: "12px" }} />
                Create organization
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Stack pt={1}>
        <Measure
          bounds={true}
          onResize={(contentRect) => {
            setDimensions(contentRect.bounds);
          }}
        >
          {({ measureRef }) => <OrgsListFilter ref={measureRef} />}
        </Measure>
        <Box>
          {filteredOrgs?.length ? (
            <OrganizationsDataGrid
              rows={filteredOrgs}
              headerHeight={134 + (dimensions?.height || 0)}
            />
          ) : loading ? (
            <></>
          ) : (
            <Box sx={{ height: "100%" }}>No organizations found</Box>
          )}
        </Box>
      </Stack>
      {(hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) ||
        hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)) && (
        <Dialog open={createOrgModal.open} onClose={handleCreateOrgDialogClose}>
          <Box
            style={{
              width: "593px",
              maxHeight: !createOrgModal.validation?.valid ? "513px" : "464px",
              padding: "16px 24px 24px 20px",
            }}
          >
            <Typography variant="h6">Create Organization</Typography>
            <div
              className={
                !createOrgModal.validation?.valid
                  ? classes.slideUp
                  : classes.slideDown
              }
            >
              <Alert severity="error">
                {createOrgModal.validation?.message}
              </Alert>
            </div>
            <TTTextField
              label="Name"
              value={createOrgModal.name}
              variant="outlined"
              size="normal"
              fullWidth
              style={{ marginTop: "24px" }}
              onChange={handleOrgNameChange}
            />

            <Grid container direction="column" style={{ marginTop: "21px" }}>
              {!isGreensightAdmin &&
                hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) && (
                  <Box>
                    <div
                      className={
                        createOrgModal.type ===
                        Constants.ORGANIZATION_TYPE.CUSTOMER
                          ? classes.slideUp
                          : classes.slideDown
                      }
                    >
                      {channelPartners.length > 0 && (
                        <Grid item>
                          <Typography style={{ marginBottom: "6px" }}>
                            {localConstants.PARENT_CP_FOR_CUSTOMER}
                          </Typography>
                          {channelPartners.length === 1 && (
                            <Typography
                              style={{
                                padding: "12px 8px",
                                color: "#000000de",
                                border: "1px solid #0000003b",
                                borderRadius: "4px",
                                maxWidth: "450px",
                              }}
                            >
                              {channelPartners[0]?.organization_id &&
                              channelPartners[0]?.organization_name
                                ? channelPartners[0]?.organization_name
                                : null}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {channelPartners.length > 1 && (
                        <TTAutoComplete
                          multiple={false}
                          options={channelPartners}
                          label="Select channel partner..."
                          primaryField="organization_name"
                          primaryKey="organization_id"
                          onChange={handleChannelPartnerSelected}
                          value={
                            createOrgModal.channelPartner &&
                            Array.isArray(createOrgModal.channelPartner)
                              ? createOrgModal.channelPartner
                              : []
                          }
                          size="medium"
                          filterSelectedOptions={true}
                        />
                      )}
                    </div>
                  </Box>
                )}
              {!isGreensightAdmin &&
                hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG) && (
                  <Box>
                    <div
                      className={
                        createOrgModal.type ===
                        Constants.ORGANIZATION_TYPE.INSTALLER
                          ? classes.slideUp
                          : classes.slideDown
                      }
                    >
                      {customers.length > 0 && (
                        <Grid item>
                          <Typography style={{ marginBottom: "6px" }}>
                            {localConstants.PARENT_C_FOR_INSTALLER}
                          </Typography>
                          {customers.length === 1 && (
                            <Typography
                              style={{
                                padding: "12px 8px",
                                color: "#000000de",
                                border: "1px solid #0000003b",
                                borderRadius: "4px",
                                maxWidth: "450px",
                              }}
                            >
                              {customers[0]?.organization_id &&
                              customers[0]?.organization_name
                                ? customers[0]?.organization_name
                                : null}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {customers.length > 1 && (
                        <TTAutoComplete
                          multiple={true}
                          options={customers}
                          label="Select customer..."
                          primaryField="organization_name"
                          primaryKey="organization_id"
                          onChange={handleCustomerSelected}
                          value={
                            createOrgModal.customer &&
                            Array.isArray(createOrgModal.customer)
                              ? createOrgModal.customer
                              : []
                          }
                          size="medium"
                          filterSelectedOptions={true}
                        />
                      )}
                    </div>
                  </Box>
                )}

              <Grid item>
                <Typography variant="button">Organization type</Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup>
                    {isGreensightAdmin && (
                      <FormControlLabel
                        style={{ paddingLeft: "9px" }}
                        value={Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER}
                        control={<Radio />}
                        label={orgTypeNameFromType(
                          Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER
                        )}
                        checked={
                          createOrgModal.type ===
                          Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER
                        }
                        onChange={handleOrgTypeChange}
                      />
                    )}
                    {hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) && (
                      <FormControlLabel
                        style={{ paddingLeft: "9px" }}
                        value={Constants.ORGANIZATION_TYPE.CUSTOMER}
                        control={<Radio />}
                        label={orgTypeNameFromType(
                          Constants.ORGANIZATION_TYPE.CUSTOMER
                        )}
                        checked={
                          createOrgModal.type ===
                          Constants.ORGANIZATION_TYPE.CUSTOMER
                        }
                        onChange={handleOrgTypeChange}
                      />
                    )}
                    {hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG) && (
                      <FormControlLabel
                        style={{ paddingLeft: "9px" }}
                        value={Constants.ORGANIZATION_TYPE.INSTALLER}
                        control={<Radio />}
                        label={orgTypeNameFromType(
                          Constants.ORGANIZATION_TYPE.INSTALLER
                        )}
                        checked={
                          createOrgModal.type ===
                          Constants.ORGANIZATION_TYPE.INSTALLER
                        }
                        onChange={handleOrgTypeChange}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent={"flex-end"}
              marginTop="33px"
            >
              <Grid item>
                <Button
                  size="medium"
                  onClick={handleCreateOrgDialogClose}
                  style={{ marginRight: "8px" }}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={handleCreateOrgClick}
                  disabled={
                    !isGreensightAdmin &&
                    ((createOrgModal.type ===
                      Constants.ORGANIZATION_TYPE.CUSTOMER &&
                      channelPartners.length === 0) ||
                      (createOrgModal.type ===
                        Constants.ORGANIZATION_TYPE.INSTALLER &&
                        customers.length === 0))
                  }
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </Stack>
  );
};

export default Organizations;
