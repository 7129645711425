import { Box, Typography, Grid, IconButton } from "@mui/material";
import { Constants } from "utils/constants";
import OrgCustomers from "../customers";
import OrgChannelPartnersDataGrid from "./dataGrid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const OrgChannelPartners = (props: {
  rows: any[];
  mode: string;
  className?: any;
  onRemove?: any | undefined;
  isGreensightAdmin?: boolean | undefined;
}) => {
  const { rows, mode, className, onRemove, isGreensightAdmin } = props;
  return (
    <Box
      sx={{ width: "100%", marginBottom: "32px" }}
      className={
        mode === Constants.ORGANIZATION_TYPE.CUSTOMER ? className : undefined
      }
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={
          mode === Constants.ORGANIZATION_TYPE.CUSTOMER
            ? {}
            : { paddingLeft: "16px!important" }
        }
      >
        Channel Partners
      </Typography>
      {rows?.length ? (
        mode === Constants.ORGANIZATION_TYPE.CUSTOMER ? (
          <OrgChannelPartnersDataGrid
            rows={rows}
            onRemove={onRemove}
            isGreensightAdmin={isGreensightAdmin}
          ></OrgChannelPartnersDataGrid>
        ) : (
          <>
            {rows.map((r) => {
              return (
                <Box key={r.organization_id} className={className}>
                  <Grid container direction="row">
                    <Grid item xs={9}>
                      <Typography variant="subtitle2" gutterBottom>
                        {r.organization_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        justifyContent="flex-end"
                        display={"flex"}
                        alignItems="center"
                        height={"100%"}
                        paddingRight={"8px"}
                      >
                        {isGreensightAdmin && (
                          <IconButton
                            aria-label="delete"
                            size="small"
                            style={{ padding: 0 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onRemove(r);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <OrgCustomers
                    rows={r.customers}
                    showTitle={false}
                  ></OrgCustomers>
                </Box>
              );
            })}
          </>
        )
      ) : (
        <Box sx={{ height: "100%", paddingLeft: "11px", paddingBottom: "5px" }}>
          No assigned channel partners.
        </Box>
      )}
    </Box>
  );
};
export default OrgChannelPartners;
