import { DriveEdge, MotorEdge, RmkEdge } from "generated-gql-types/graphql";

export const sortDeviceEdges = (
  edges?: DriveEdge[] | MotorEdge[] | RmkEdge[]
) => {
  return edges
    ?.slice()
    .sort((a, b) => (a.installedDt > b.installedDt ? -1 : 1));
};

const dateTimeFormatter = new Intl.DateTimeFormat("en-US", {
  timeZoneName: "short",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

// Displays the date in browser-local time, with the timezone abbreviation
export function formateDateWithTimezone(date: string) {
  // TODO: there doesn't seem to be a convenient, reliable way to display the timezone abbreviation
  // for the original timezone provided by the server, so this shows browser local time+zone
  return `${dateTimeFormatter.format(new Date(date))}`;
}

export const DETAIL_GRID_ROW_HEIGHT = 48;
export const DETAIL_GRID_HEADER_HEIGHT = 56;
