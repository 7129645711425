import * as React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Asset } from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";
import {
  NOT_AVAILABLE,
  PropertyDataType,
  AssetCardDetail,
} from "./AssetCardDetail";

const AssetPropertiesCard = (props: { asset?: Asset }) => {
  const { asset } = props;

  return (
    <Paper
      elevation={1}
      sx={{
        padding: 3,
        border: "1px solid #E0E0E0",
      }}
    >
      <Stack>
        <Typography
          variant="body1"
          sx={{
            wordWrap: "break-word",
            paddingRight: 2,
            fontWeight: "bold",
          }}
        >
          Asset Properties
        </Typography>
        <Grid m={1} container>
          <AssetCardDetail
            key="manufacturer"
            label="Manufacturer Name"
            value={asset?.manufacturer || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="serialNumber"
            label="Serial Number"
            value={asset?.serialNumber || NOT_AVAILABLE}
          />
          <AssetCardDetail
            key="modelNumber"
            label="Model Name"
            value={asset?.modelNumber || NOT_AVAILABLE}
          />
          {asset?.properties
            ? asset.properties
                .filter(
                  (property) => property?.dataType !== PropertyDataType.PHOTO
                )
                .map((property) => (
                  <AssetCardDetail
                    key={property?.integrationKey}
                    label={property?.name || property?.integrationKey || ""}
                    value={property?.value || NOT_AVAILABLE}
                    tooltip={`${property?.integrationKey || ""}${
                      property?.integrationKey ? ": " : ""
                    }${property?.description || property?.value || ""}`}
                  />
                ))
            : null}
        </Grid>
      </Stack>
    </Paper>
  );
};

export default createFragmentContainer(AssetPropertiesCard, {
  asset: gql`
    fragment AssetPropertiesCard_asset on Asset {
      id
      name
      manufacturer
      modelNumber
      latitude
      longitude
      serialNumber
      source
      createdDt
      createdBy
      updatedDt
      properties {
        integrationKey
        value
        name
        dataType
        description
        createdBy
        createdDt
        updatedBy
        updatedDt
      }
    }
  `,
});
