import { useMemo } from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  TTDataGridPro,
  TT_SLOT_PROPS,
} from "components/datagrid/TTDataGridPro";
import { Property, RmkEdge, Site } from "generated-gql-types/graphql";
import { dateToUserLocalTimezone } from "utils/datetime";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";
import { PropertyDataType } from "utils/types";

const defaultColumns: GridColDef[] = [
  { field: "sku", headerName: "SKU", width: 150, flex: 0.5 },
  { field: "spvSerialNumber", headerName: "Supervisor Serial", flex: 1 },
  { field: "modemName", headerName: "Modem Device Name", flex: 1 },
  { field: "modemMacAddress", headerName: "Modem MAC Address", flex: 1 },
];

// Return a Map of all the unique RMK dynamic properties, keyed by integrationKey
const collectRmkProps = (properties: Property[]) => {
  const result = new Map<string, Property>();
  properties.forEach((p) => {
    if (p && p.dataType !== PropertyDataType.PHOTO) {
      result.set(p.integrationKey, p);
    }
  });
  return result;
};

// Generate a list of columns, including the column columns and
// a dynamic set of columns inferred by inspecting the RMKs' properties
const getColumns = (site: Site) => {
  const columns: GridColDef[] = defaultColumns.slice();
  const properties = site?.rmks?.edges?.map((e) => e.rmk?.properties);
  if (properties) {
    const allProps: Property[] = properties.reduce<Property[]>(
      (accumulator, value) => {
        if (value && value !== undefined) {
          return accumulator.concat(value);
        }
        return accumulator;
      },
      [] as Property[]
    );

    const props = collectRmkProps(allProps);
    props.forEach((p, key) => {
      columns.push({
        field: key,
        headerName: p.name,
        flex: 1,
      } as GridColDef);
    });
  }
  return columns;
};

const RMKDataGrid = (props: { site: Site; loading: boolean }) => {
  const { site, loading } = props;
  const columns = useMemo(() => getColumns(site), [site]);
  const rows = site?.rmks?.edges?.map((edge: RmkEdge) => {
    const props: any = {
      ...edge.rmk,
      sku: edge.rmk.rmkPlus ? "RMK+" : "RMK",
      installedDt: dateToUserLocalTimezone(edge.installedDt),
    };
    edge?.rmk?.properties?.forEach((p: Property) => {
      props[p.integrationKey] = p.value;
    });
    return props;
  });

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      {rows && (
        <TTDataGridPro
          autoHeight
          columns={columns}
          rows={rows}
          loading={loading}
          slotProps={{
            ...TT_SLOT_PROPS,
            toolbar: {
              printOptions: { hideToolbar: true },
            },
          }}
        />
      )}
    </Box>
  );
};

export default createFragmentContainer(RMKDataGrid, {
  site: gql`
    fragment RMKDataGrid_site on Site {
      id
      rmks {
        edges {
          rmk {
            id
            spvSerialNumber
            modemName
            modemMacAddress
            rmkPlus
            properties {
              integrationKey
              value
              name
              dataType
              description
              createdBy
              createdDt
              updatedBy
              updatedDt
            }
          }
          installedDt
        }
      }
    }
  `,
});
