import React, { useMemo, useState } from "react";
import DataGrid, { SortColumn } from "react-data-grid";
import { Box } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "react-tooltip";
import type { Column } from "react-data-grid";
import { chunk } from "lodash";
import { useNavigate } from "react-router";
import TTToolTipText from "components/common/TTToolTipText";
import CustomerTypeChip from "components/common/customertypechip";
import { orgTypeNameFromType } from "utils/customerTypes";
import { Constants } from "utils/constants";
import { useAuthData } from "utils/hooks/useAuthData";
import { dataGridParentStyle } from "theme/styles";

interface OrganizationListRow {
  organization_name: string;
  organization_id: string;
  types: string[];
}

const columns: readonly Column<OrganizationListRow>[] = [
  {
    key: "organization_name",
    name: "Customer Name",
    minWidth: 100,
    formatter(props) {
      return <TTToolTipText text={props.row.organization_name} />;
    },
  },
  {
    key: "types",
    name: "Type(s)",
    minWidth: 100,
    formatter(props) {
      let type_tags = [];
      let org_types = props.row.types;
      type_tags = org_types.map((type) => {
        return <CustomerTypeChip type={type} key={type} />;
      });
      return <>{type_tags}</>;
    },
  },
];

function rowKeyGetter(row: OrganizationListRow) {
  return row.organization_id;
}
type Comparator = (a: OrganizationListRow, b: OrganizationListRow) => number;
function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case "organization_name":
      return (a, b) => {
        return a?.[sortColumn]?.localeCompare(b?.[sortColumn]);
      };
    case "types":
      return (a, b) => {
        return a?.[sortColumn]
          ?.map((v) => orgTypeNameFromType(v))
          .join(",")
          .localeCompare(
            b?.[sortColumn].map((v) => orgTypeNameFromType(v)).join(",")
          );
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

const ROW_HEIGHT = 52;

const OrganizationListDataGrid = (props: {
  rows: any;
  headerHeight: number;
}) => {
  const { rows, headerHeight } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const navigate = useNavigate();
  const { hasAccess } = useAuthData();

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const sortedRows = useMemo((): readonly OrganizationListRow[] => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const _a =
          a[sort.columnKey] === undefined ||
          a[sort.columnKey] === null ||
          a[sort.columnKey] === ""
            ? undefined
            : a[sort.columnKey];
        const _b =
          b[sort.columnKey] === undefined ||
          b[sort.columnKey] === null ||
          b[sort.columnKey] === ""
            ? undefined
            : b[sort.columnKey];
        if (_a === undefined && _b === undefined) {
          return 0;
        } else if (_a === undefined) {
          return 1;
        } else if (_b === undefined) {
          return -1;
        }

        const comparator = getComparator(sort.columnKey);
        let compResult = comparator(a, b);
        if (compResult !== undefined && compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);
  let chunks: Array<Array<any>> = chunk(sortedRows, rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    chunks = chunk(rows, _rowsPerPage);
    setPage(0);
  };
  const handleRowClick = (row: any, column: any) => {
    if (hasAccess(Constants.ACTIONS.VIEW_ORGS)) {
      navigate("/organizations/view/" + row.organization_id);
    }
  };

  return (
    <Box sx={dataGridParentStyle}>
      <DataGrid
        style={{
          width: "100%",
          borderRadius: 4,
          height: `calc(100vh - (${
            headerHeight + Constants.TABLE_PAGINATION_HEIGHT
          }px))`,
        }}
        className="rdg-light"
        columns={columns}
        rows={chunks[page]}
        rowKeyGetter={rowKeyGetter}
        defaultColumnOptions={{ resizable: true, sortable: true }}
        rowHeight={ROW_HEIGHT}
        onRowClick={handleRowClick}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Tooltip
        id="cell-tooltip"
        place="left"
        style={{ maxWidth: "250px", zIndex: 999 }}
      />
    </Box>
  );
};
export default OrganizationListDataGrid;
