import { makeStyles } from "tss-react/mui";

export const useViewOrganizationStyles = makeStyles()((theme) => {
  return {
    orgsAutoComplete: {
      marginTop: "40px",
      width: "100%",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "rgba(2, 136, 209, 0.04)!important",
        border: "1px solid rgb(2, 136, 209)!important",
      },
    },
    rolesAutoComplete: {
      width: "100%",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "rgba(2, 136, 209, 0.04)!important",
        border: "1px solid rgb(2, 136, 209)!important",
      },
    },
    assignModalButtons: {
      marginTop: "38px",
    },
    headerBox: {
      boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    },
    headerGrid: {
      filter: "none",
      boxShadow: "0px 0px 0px 1px #E0E0E0",
      borderRadius: "4px",
      padding: "24px 40px",
    },
    relatedOrgs: {
      borderTop: "1px solid #ddd",
      borderRight: "1px solid #ddd",
      borderLeft: "1px solid #ddd",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      h6: {
        paddingLeft: "16px",
        marginTop: "7px",
        fontSize: "20px",
        fontWeight: "500",
      },
      ".rdg-light": {
        border: "none",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
    },
    tabContent: {
      borderRight: "1px solid #ddd",
      borderLeft: "1px solid #ddd",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",

      ".rdg-light": {
        border: "none",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
    },
  };
});
export const useOrgRelationshipsGridStyles = makeStyles()((theme) => {
  return {
    headerItem: {
      paddingLeft: "16px!important",
    },
    cellItem: {
      paddingLeft: "16px!important",
      outline: "none !important",
      '&[role="gridcell"]': {
        color: "#1976D2",
      },
    },
  };
});
