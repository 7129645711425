import { ColumnState } from "utils/types";
import { GridColDef } from "@mui/x-data-grid-pro";
import { columnSettings } from "components/common/gridview/gridState";
import {
  columnFilterOperators,
  renderCell,
  valueGetter,
} from "components/common/gridview/gridColumns";

const definedColumns: any[] = [
  {
    field: "id",
    object: "relationship",
    key: "id",
    headerName: "Relationship ID",
    filterable: true,
    hide: true,
    sortingKey: "relationshipId",
    sortable: true,
    filterOperators: true,
  },
  {
    field: "soldTo",
    object: "relationship",
    key: "soldTo",
    headerName: "Sold To",
    sortingKey: "soldTo",
    hide: false,
    sortable: true,
    filterable: true,
  },
  {
    field: "soldToSalesforceId",
    object: "relationship",
    key: "soldToSalesforceId",
    headerName: "Sold To Salesforce ID",
    filterable: true,
    filteringKey: "soldToSalesforceId",
    hideable: true,
    sortingKey: "soldToSalesforceId",
    sortable: true,
  },
  {
    field: "techAdminOrgId",
    object: "relationship",
    key: "techAdminOrgId",
    sortingKey: "techAdminOrgId",
    headerName: "Tech Admin Org ID",
    filterable: true,
    filteringKey: "techAdminOrgId",
    hide: true,
    hideable: true,
    sortable: true,
  },
  {
    field: "techAdminOrgName",
    object: "relationship",
    key: "techAdminOrgName",
    headerName: "Tech Admin Org Name",
    sortable: true,
    hideable: true,
    filterable: true,
    editable: true,
    type: "singleSelect",
    valueOptions: [],
  },
  {
    field: "createdDt",
    object: "relationship",
    key: "createdDt",
    headerName: "Date Created",
    type: "datetime",
    sortable: true,
    filterable: true,
  },
  {
    field: "updatedDt",
    object: "relationship",
    key: "updatedDt",
    headerName: "Last Updated",
    hide: true,
    type: "datetime",
    sortable: true,
    filterable: true,
  },
];

/**
  Normalize defined column items.
 */
const defaultColumns: GridColDef[] = definedColumns.map((col) => {
  const column = {
    field: col.field,
    headerName: col.headerName,
    hide: col.hide,
    width: col?.width ?? columnSettings.COLUMN_DEFAULT_WIDTH,
    renderCell: renderCell(col),
  };
  // optional properties that need to be included if exists
  if (col?.type) {
    (column as any).type = col.type;
  }
  if (col?.type === "dateTime") {
    (column as any).valueGetter = valueGetter(col);
  }
  if (col?.align) {
    (column as any).align = col.align;
  }
  if (col?.headerAlign) {
    (column as any).headerAlign = col.headerAlign;
  }
  if (col?.filterOperators === true) {
    (column as any).filterOperators = columnFilterOperators(col);
  }
  // optional properties that need to be included if exists and is explicitly set to true.
  if (col?.sortable === true) {
    (column as any).sortable = true;
  }
  if (col?.filterable === true) {
    (column as any).filterable = true;
  }
  // optional properties that need to be included if exists and is explicitly set to false.
  if (col?.hideable === false) {
    (column as any).hideable = false;
  }
  if (col?.editable) {
    (column as any).editable = col.editable;
  }
  if (col?.valueOptions) {
    (column as any).valueOptions = col.valueOptions;
  }
  return column;
});

const validWidth = (width: any, defaultValue: number = -1) => {
  return typeof width === "number" && width > columnSettings.COLUMN_MIN_WIDTH
    ? width
    : defaultValue;
};
const validIndex = (index: any, defaultValue: number = -1) => {
  return typeof index === "number" && index > -1 ? index : defaultValue;
};
const validBool = (visibility: any, defaultValue: boolean = false) => {
  return typeof visibility === "boolean" ? visibility : defaultValue;
};

const initialColumnStates: ColumnState[] = (() => {
  const initialStates: Array<ColumnState> = [];
  defaultColumns.forEach((col: any, index: number) => {
    initialStates.push({
      field: col.field,
      hide: validBool(col.hide),
      width: validWidth(col.width),
      index: validIndex(index),
    });
  });
  return initialStates;
})();

const supportedSortColumns = defaultColumns
  .filter((col) => (col as any).sortable !== undefined && (col as any).sortable)
  .map((sortableColumn) => sortableColumn.field);

export {
  supportedSortColumns,
  defaultColumns,
  definedColumns,
  initialColumnStates,
};
