export const normalizeJobList = (jobs: Array<any>): Array<any> => {
  /**react-data-grid does not support nested keys
   * Extract required nested keys to root
   */
  return jobs?.map((job) => {
    job.customerName = job.customer?.organization_name;
    job.siteName = job.site?.name;
    job.siteAddress = job.site?.address;
    job.equipment = job.attributes?.eqmnt_id;
    job.installer = job.installer?.organization_name;
    job.channelPartner = job.channel_partner?.organization_name;
    return job;
  });
};

export const getGroupedTemplateList = (templateList: Array<any>) => {
  return templateList?.reduce(function (r, a) {
    r[a.template_group.name] = r[a.template_group.name] || [];
    r[a.template_group.name].push(a);
    return r;
  }, Object.create(null));
};
