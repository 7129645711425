import { TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useAppStyles: any = makeStyles<any>()((theme) => {
  return {
    input: {
      "&:hover .MuiOutlinedInput-notchedOutline ": {
        backgroundColor: `${theme.palette.primary.main}0a`,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  };
});

const TTTextField = (props: any) => {
  const { classes } = useAppStyles();
  return (
    <TextField
      {...props}
      InputProps={{
        className: classes.input,
        ...props.InputProps,
      }}
    />
  );
};

export default TTTextField;
