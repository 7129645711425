import { makeStyles } from "tss-react/mui";

export const userCustomerTypeChipStyles = makeStyles()((theme) => {
  return {
    customerTypeChip: {
      display: "flex",
      lineHeight: "16px",
      marginRight: "5px",
      borderRadius: "16px",
      padding: " 3px 4px",
    },
    customerChip: {
      color: "#9C27B0",
      background: "rgba(156, 39, 176, 0.04)",
      border: "1px solid rgba(156, 39, 176, 0.5)",
      ".MuiChip-deleteIcon": {
        color: "#9C27B0",
      },
    },
    channelPartnerChip: {
      color: "#0288D1",
      background: "rgba(2, 136, 209, 0.04)",
      border: "1px solid rgba(2, 136, 209, 0.5)",
      ".MuiChip-deleteIcon": {
        color: "#0288D1",
      },
    },
    installerChip: {
      color: "#2E7D32",
      background: "rgba(46, 125, 50, 0.04)",
      border: "1px solid rgba(46, 125, 50, 0.5)",
      ".MuiChip-deleteIcon": {
        color: "#2E7D32",
      },
    },
    mutedChip: {
      background: "rgba(0, 0, 0, 0.08)",
      border: "none",
    },
  };
});
