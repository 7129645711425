import { Site } from "generated-gql-types/graphql";
export interface SiteItem {
  name: string;
  address: string;
  site_id: string;
  encoded_site_id?: string;
  lat: number;
  lon: number;
  installers: Array<any>;
  customer: Array<any>;
  channel_partners: Array<any>;
}

export const mapSiteItemToSite = (site: SiteItem) => {
  const siteItem: Site = {
    __typename: "Site",
    id: site.site_id,
    name: site.name,
    address: site.address || "",
    latitude: site.lat || 0,
    longitude: site.lon || 0,
    assets: {
      edges: [],
      pageInfo: { hasNextPage: false, hasPreviousPage: false },
    },
    drives: { edges: [] },
    motors: { edges: [] },
    rmks: { edges: [] },
  };
  return siteItem;
};
