import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Stack, Typography } from "@mui/material";

const TTDatePicker = (props: any) => {
  return (
    <Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker {...props} />
      </LocalizationProvider>
      {props?.errorMessage && (
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          color="error"
        >
          {props.errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default TTDatePicker;
