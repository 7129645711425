import { useEffect, useState, SyntheticEvent, useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  Tab,
  Tabs,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { AssignOrgToOrgItem } from "models/organizations/assignorgtoorgitem";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import {
  changeOrgRelationships,
  getAllOrganizations,
  getOrganizationDetails,
  getOrganizationUsers,
} from "redux/actions/orgs";
import { useNavigate, useParams } from "react-router-dom";
import CustomerTypeChip from "components/common/customertypechip";
import { orgTypeLevelFromType, orgTypeNameFromType } from "utils/customerTypes";
import TTAutoComplete from "components/common/TTAutoComplete/TTAutoComplete";
import { sortBy } from "lodash";
import { useViewOrganizationStyles } from "./styles";
import {
  setOpen,
  setAvailableOrgs,
  setSelectedOrgs,
} from "redux/reducers/assignOrgsToOrgSlice";
import { Constants } from "utils/constants";
import { TabPanel, a11yProps } from "components/layout/tabpanel";
import OrganizationSitesDataGrid from "./sitesDataGrid";
import SitesListFilter from "./sitesListFilter";
import OrganizationUsersDataGrid from "./usersDataGrid";
import UsersListFilter from "./usersListFilter";
import OrgCustomers from "./customers";
import OrgInstallers from "./installers";
import OrgChannelPartners from "./channelpartners";
import { useAuthData } from "utils/hooks/useAuthData";
import AddSite from "components/site/AddSite";
import { InviteUserDialog } from "components/common/InviteUserDialog";
import { SiteItem } from "models/site";
import { Site } from "generated-gql-types/graphql";

const ViewOrganization = () => {
  const { isGreensightAdmin, getRawIdToken, hasAccess, ready } = useAuthData();
  const { classes } = useViewOrganizationStyles();
  let { organizationId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const assignOrgsToOrgModal = useAppSelector((state) => state.assignOrgsToOrg);
  const data = useAppSelector((state) => state.orgDetails.data.org);
  const allOrgs = useAppSelector((state) => state.orgsList.data?.orgs);
  const loading = useAppSelector((state) => state.orgsList.loading);
  const filteredData = useAppSelector((state) => state.orgDetails.filtered);

  const allSites = data?.sites || [];

  const [tabIndex, setTabIndex] = useState(0);
  const [removeOrgRelationShip, setRemoveOrgRelationShip] = useState<{
    open: boolean;
    org: any | undefined;
  }>({ open: false, org: {} });
  const [showAddSite, setShowAddSite] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<Site | undefined>(undefined);

  const handleAssignDialogOpen = () => {
    dispatch(setOpen(true));
    const skipOrgs: string[] = [];
    const orgLevels: number[] = data.types.map((type: string) => {
      skipOrgs.push(`${data.organization_id}-${type}`);
      return orgTypeLevelFromType(type);
    });
    data.channel_partners.forEach((item: any) => {
      skipOrgs.push(
        `${item.organization_id}-${Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER}`
      );
    });
    data.customers.forEach((item: any) => {
      skipOrgs.push(
        `${item.organization_id}-${Constants.ORGANIZATION_TYPE.CUSTOMER}`
      );
    });
    data.installers.forEach((item: any) => {
      skipOrgs.push(
        `${item.organization_id}-${Constants.ORGANIZATION_TYPE.INSTALLER}`
      );
    });
    let availableOrgsList: AssignOrgToOrgItem[] = [];
    orgLevels.forEach((lvl) => {
      allOrgs?.forEach((org: any) => {
        org.types.forEach((type: string) => {
          let orgLvl: number = orgTypeLevelFromType(type);
          if (
            !isGreensightAdmin &&
            data?.types?.indexOf(Constants.ORGANIZATION_TYPE.INSTALLER) > -1 &&
            type === Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER
          ) {
            return;
          }
          if (data.organization_id !== org.organization_id && orgLvl !== lvl) {
            let item = {
              organization_id: org.organization_id,
              organization_name: `${
                org.organization_name
              } - ${orgTypeNameFromType(type)}`,
              level: orgLvl,
              type: type,
              combo_key: `${org.organization_id}-${type}`,
              description: orgLvl > lvl ? "As child" : "As parent",
            } as AssignOrgToOrgItem;
            if (
              availableOrgsList.filter((i) => {
                return i.combo_key === item.combo_key;
              }).length === 0 &&
              skipOrgs.indexOf(item.combo_key) === -1
            ) {
              availableOrgsList.push(item);
            }
          }
        });
      });
    });

    dispatch(
      setAvailableOrgs(
        sortBy(availableOrgsList, (o: AssignOrgToOrgItem) => {
          return o.organization_name.toUpperCase();
        })
      )
    );
  };

  const handleAssignDialogClose = () => {
    dispatch(setOpen(false));
    dispatch(setAvailableOrgs([]));
    dispatch(setSelectedOrgs([]));
  };

  const handleRemoveOrgRelationshipPromptOpen = (org: any) => {
    const rp = { ...removeOrgRelationShip };
    rp.open = true;
    rp.org = org;
    setRemoveOrgRelationShip(rp);
  };
  const handleRemoveOrgRelationshipPromptClose = () => {
    const rp = { ...removeOrgRelationShip };
    rp.open = false;
    rp.org = undefined;
    setRemoveOrgRelationShip(rp);
  };
  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();

        dispatch(
          getOrganizationDetails({
            token: idToken,
            organizationId: organizationId,
          })
        );
        dispatch(
          getAllOrganizations({
            token: idToken,
          })
        );
        dispatch(
          getOrganizationUsers({
            token: idToken,
            organizationId: organizationId,
          })
        );
      })();
    }
  }, [organizationId, ready]);
  const handleSelectedOrgsChange = (value: AssignOrgToOrgItem[]) => {
    dispatch(setSelectedOrgs(value));
  };
  const handleAssignOrgsToOrg = () => {
    const parents: string[] = [];
    const children: string[] = [];
    const orgLevels: number[] = data.types.map((type: string) => {
      return orgTypeLevelFromType(type);
    });
    orgLevels.forEach((lvl) => {
      assignOrgsToOrgModal.selectedOrgs.forEach((org) => {
        if (org.level > lvl) {
          children.push(org.organization_id);
        } else {
          parents.push(org.organization_id);
        }
      });
    });
    (async () => {
      const idToken = await getRawIdToken();
      dispatch(setOpen(false));
      dispatch(
        changeOrgRelationships({
          token: idToken,
          organizationId: organizationId,
          parents: { add: parents },
          children: { add: children },
        })
      ).then((resp) => {
        if (resp?.payload?.success) {
          enqueueSnackbar("Organization(s) assigned!", { variant: "default" });
        } else {
          enqueueSnackbar("Failed to assign organizations!", {
            variant: "error",
          });
        }
        dispatch(
          getOrganizationDetails({
            token: idToken,
            organizationId: organizationId,
          })
        );

        dispatch(setAvailableOrgs([]));
        dispatch(setSelectedOrgs([]));
      });
    })();
  };
  const handleUnassignOrgFromOrg = () => {
    (async () => {
      const idToken = await getRawIdToken();
      const org = { ...removeOrgRelationShip.org };
      handleRemoveOrgRelationshipPromptClose();
      dispatch(
        changeOrgRelationships({
          token: idToken,
          organizationId: organizationId,
          parents: { remove: [org.organization_id] },
          children: { remove: [org.organization_id] },
        })
      )
        .then((resp) => {
          if (resp?.payload?.success) {
            enqueueSnackbar("Relationship removed!", { variant: "default" });
          } else {
            if (resp?.payload?.code === "C3") {
              enqueueSnackbar(
                resp?.payload?.description?.[0]?.message
                  ? resp.payload.description[0].message
                  : "Failed to remove relation!",
                {
                  variant: "error",
                }
              );
            } else {
              enqueueSnackbar("Failed to remove relation!", {
                variant: "error",
              });
            }
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to remove membership", { variant: "error" });
        })
        .finally(() => {
          dispatch(
            getOrganizationDetails({
              token: idToken,
              organizationId: organizationId,
            })
          );

          dispatch(setAvailableOrgs([]));
          dispatch(setSelectedOrgs([]));
        });
    })();
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleViewSite = (site: SiteItem) => {
    navigate(
      `/site/view/${data?.organization_name}/${organizationId}/${site?.site_id}`
    );
  };

  const handleCloseAddSite = () => {
    setSelectedSite(undefined);
    setShowAddSite(false);
  };

  // const handleInviteUserDialogOpen = () => {
  //   setDefaultsForUserInvite();
  //   dispatch(inviteUserDialog.setOpen(true));

  //   dispatch(
  //     inviteUserDialog.setOrganization({
  //       organization_id: data.organization_id,
  //       organization_name: data.organization_name,
  //     })
  //   );
  // };

  const handleInvitedUser = async () => {
    const idToken = await getRawIdToken();
    dispatch(
      getOrganizationUsers({
        token: idToken,
        organizationId: organizationId,
      })
    );
  };

  const allowModifySite = useMemo(() => {
    return isGreensightAdmin || hasAccess(Constants.ACTIONS.MANAGE_SITES);
  }, [isGreensightAdmin, hasAccess]);

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: "1080px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Button
        size="small"
        onClick={() => {
          navigate("/organizations");
        }}
        className={"back-to-list-button"}
      >
        {" "}
        <ArrowBackIcon />
        Back to Organizations
      </Button>
      <Box component={"div"} className={classes.headerBox}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.headerGrid}
        >
          <Grid item>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginBottom: "0px",
              }}
            >
              {data?.organization_name}
            </Typography>
          </Grid>
          <Grid item>
            {data?.types?.map((type: string) => {
              return <CustomerTypeChip type={type} key={type} />;
            })}
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={5}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Relationships" {...a11yProps(0)}></Tab>
          <Tab label="Sites" {...a11yProps(1)}></Tab>
          <Tab label="Users" {...a11yProps(2)}></Tab>
        </Tabs>
      </Box>
      <TabPanel
        value={tabIndex}
        index={0}
        style={{ height: "calc(100vh - 294px)", overflow: "auto" }}
      >
        <Box style={{ width: "100%" }}>
          <Grid container direction="row">
            <Grid xs={6} item></Grid>
            <Grid xs={6} item alignItems="flex-end">
              <Box
                marginBottom={1}
                marginTop={2}
                justifyContent="flex-end"
                display={"flex"}
              >
                {isGreensightAdmin && (
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleAssignDialogOpen}
                    sx={{ mb: 2 }}
                  >
                    {" "}
                    <AddIcon style={{ marginRight: "12px" }} />
                    Assign organization
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          {!loading ? (
            <>
              {data?.types?.indexOf(
                Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER
              ) > -1 ||
              data?.types?.indexOf(Constants.ORGANIZATION_TYPE.INSTALLER) >
                -1 ? (
                <OrgCustomers
                  rows={data?.customers}
                  className={classes.relatedOrgs}
                  canDelete={true}
                  onRemove={handleRemoveOrgRelationshipPromptOpen}
                  isGreensightAdmin={
                    hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) ||
                    hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)
                  }
                ></OrgCustomers>
              ) : (
                <></>
              )}{" "}
              {data?.types?.indexOf(Constants.ORGANIZATION_TYPE.CUSTOMER) >
                -1 ||
              data?.types?.indexOf(Constants.ORGANIZATION_TYPE.INSTALLER) >
                -1 ? (
                <OrgChannelPartners
                  onRemove={handleRemoveOrgRelationshipPromptOpen}
                  rows={data?.channel_partners}
                  className={classes.relatedOrgs}
                  mode={
                    data?.types?.indexOf(
                      Constants.ORGANIZATION_TYPE.INSTALLER
                    ) > -1
                      ? Constants.ORGANIZATION_TYPE.INSTALLER
                      : Constants.ORGANIZATION_TYPE.CUSTOMER
                  }
                  isGreensightAdmin={
                    hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) ||
                    hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)
                  }
                ></OrgChannelPartners>
              ) : (
                <></>
              )}
              {data?.types?.indexOf(
                Constants.ORGANIZATION_TYPE.CHANNEL_PARTNER
              ) > -1 ||
              data?.types?.indexOf(Constants.ORGANIZATION_TYPE.CUSTOMER) >
                -1 ? (
                <OrgInstallers
                  rows={data?.installers}
                  className={classes.relatedOrgs}
                  onRemove={handleRemoveOrgRelationshipPromptOpen}
                  isGreensightAdmin={
                    hasAccess(Constants.ACTIONS.CREATE_CHILD_CUSTOMER_ORG) ||
                    hasAccess(Constants.ACTIONS.CREATE_INSTALLER_ORG)
                  }
                ></OrgInstallers>
              ) : (
                <></>
              )}{" "}
            </>
          ) : (
            <></>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box style={{ width: "100%" }} className={classes.tabContent}>
          <Grid container direction="row" alignItems="center">
            <Grid xs={6} item>
              <SitesListFilter></SitesListFilter>
            </Grid>
            <Grid xs={6} item alignItems="flex-end">
              <Box
                justifyContent="flex-end"
                display={"flex"}
                marginBottom={1}
                marginTop={2}
                paddingRight={2}
              >
                {allowModifySite && (
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setShowAddSite(true)}
                    sx={{ marginRight: 1 }}
                  >
                    {" "}
                    <AddIcon style={{ marginRight: "12px" }} />
                    Create Site
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <>
            {filteredData.sites?.length ? (
              <OrganizationSitesDataGrid
                rows={filteredData.sites}
                onViewSite={handleViewSite}
              />
            ) : loading ? (
              <></>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  paddingLeft: "15px",
                  paddingBottom: "5px",
                }}
              >
                No sites found
              </Box>
            )}
          </>
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Box
          style={{ width: "100%", paddingBottom: "5px" }}
          className={classes.tabContent}
        >
          <Grid container direction="row">
            <Grid xs={6} item>
              <UsersListFilter />
            </Grid>
            <Grid xs={6} item alignItems="flex-end">
              <Box
                marginBottom={1}
                marginTop={2}
                marginRight={2}
                justifyContent="flex-end"
                display={"flex"}
              >
                <InviteUserDialog onInviteClicked={handleInvitedUser} />
              </Box>
            </Grid>
          </Grid>
          <>
            {filteredData.users?.length ? (
              <OrganizationUsersDataGrid
                rows={filteredData.users}
                organizationId={organizationId}
                organizationName={data?.organization_name}
              />
            ) : loading ? (
              <></>
            ) : (
              <Box sx={{ height: "100%", padding: 3 }}>No users found</Box>
            )}
          </>
        </Box>
      </TabPanel>

      <Dialog
        open={assignOrgsToOrgModal.open}
        onClose={handleAssignDialogClose}
      >
        <Box
          style={{
            width: "553px",
            minHeight: "236px",
            padding: "16px 24px 24px 20px",
          }}
        >
          <Typography variant="h6">
            Assign Organization to {data?.organization_name}
          </Typography>
          <TTAutoComplete
            options={assignOrgsToOrgModal.availableOrgs}
            label="start typing to search existing organizations..."
            primaryField="organization_name"
            primaryKey="combo_key"
            secondaryField="description"
            onChange={handleSelectedOrgsChange}
            value={assignOrgsToOrgModal.selectedOrgs}
            classes={{ root: classes.orgsAutoComplete }}
            size="medium"
          />
          <Grid
            container
            direction="row"
            className={classes.assignModalButtons}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button size="small" onClick={handleAssignDialogClose}>
                Close
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleAssignOrgsToOrg}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        open={removeOrgRelationShip.open}
        onClose={handleRemoveOrgRelationshipPromptClose}
      >
        <Box
          style={{
            width: "486px",
            height: "265px",
            padding: "16px 24px 24px 20px",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 500 }}>
            Remove relationship
          </Typography>{" "}
          <Typography variant="h6" style={{ fontWeight: 500 }}>
            {data?.organization_name} and{" "}
            {removeOrgRelationShip.org?.organization_name}?
          </Typography>
          <Typography variant="body1" style={{ marginTop: "24px" }}>
            This will remove the organization relationship and disable
            information shared with the other organization. Removing the
            organization will not delete it.
          </Typography>
          <Grid
            container
            direction="row"
            className={classes.assignModalButtons}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                size="medium"
                onClick={handleRemoveOrgRelationshipPromptClose}
                style={{ marginRight: "8px", color: "#000", fontWeight: 500 }}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                color="error"
                onClick={handleUnassignOrgFromOrg}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      {/* Add Site Dialog */}
      <AddSite
        open={showAddSite}
        organizationId={organizationId}
        organizationName={data?.organization_name}
        onClose={handleCloseAddSite}
        selectedSite={selectedSite}
        allSites={allSites.map((site: SiteItem) => site.name)}
      />
    </Box>
  );
};
export default ViewOrganization;
