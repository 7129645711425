import * as React from "react";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  Button,
  Collapse,
} from "@mui/material";
import { Asset, DriveEdge } from "generated-gql-types/graphql";
import { createFragmentContainer } from "components/common/createFragmentContainer";
import { gql } from "@apollo/client";
import {
  NOT_AVAILABLE,
  PropertyDataType,
  AssetCardDetail,
} from "./AssetCardDetail";
import { dateToUserLocalTimezone } from "utils/datetime";
import { sortDeviceEdges } from "./AssetDetailsUtil";

const DriveCard = (props: { driveEdge: DriveEdge }) => {
  const { driveEdge } = props;
  const drive = driveEdge.drive;
  return (
    <Stack m={1}>
      <Typography
        variant="body2"
        mt={2}
        sx={{
          fontWeight: "bold",
        }}
      >
        {`${dateToUserLocalTimezone(driveEdge.installedDt)} - ${
          driveEdge.removedDt
            ? `${dateToUserLocalTimezone(driveEdge.removedDt)}`
            : "Present"
        }`}
      </Typography>
      <Grid container>
        <AssetCardDetail
          key="driveModel"
          label="Drive Model"
          value={drive?.driveModel || NOT_AVAILABLE}
        />
        <AssetCardDetail
          key="serialNumber"
          label="Serial Number"
          value={drive?.serialNumber || NOT_AVAILABLE}
        />
        {drive?.properties
          ? drive.properties
              .filter(
                (property) => property?.dataType !== PropertyDataType.PHOTO
              )
              .map((property) => (
                <AssetCardDetail
                  key={property?.integrationKey}
                  label={property?.name || property?.integrationKey || ""}
                  value={property?.value || NOT_AVAILABLE}
                  tooltip={`${property?.integrationKey || ""}${
                    property?.integrationKey ? ": " : ""
                  }${property?.description || property?.value || ""}`}
                />
              ))
          : null}
      </Grid>
    </Stack>
  );
};

const DrivePropertiesCard = (props: { asset?: Asset }) => {
  const { asset } = props;

  const [showMore, setShowMore] = React.useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  // sort the asset's drives by installed date
  const drives = sortDeviceEdges(asset?.drives?.edges) as DriveEdge[];

  if (drives?.length) {
    return (
      <Paper
        elevation={1}
        sx={{
          padding: 3,
          border: "1px solid #E0E0E0",
        }}
      >
        <Stack>
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
              paddingRight: 2,
              fontWeight: "bold",
            }}
          >
            Drive Properties
          </Typography>
          {drives.slice(0, 1).map((driveEdge) => (
            <DriveCard key={driveEdge.drive.id} driveEdge={driveEdge} />
          ))}
          {drives.length > 1 && (
            <Grid item>
              <Collapse in={showMore}>
                {drives.slice(1).map((driveEdge) => (
                  <DriveCard key={driveEdge.drive.id} driveEdge={driveEdge} />
                ))}
              </Collapse>
              <Button
                style={{
                  justifyContent: "flex-start",
                  background: "transparent",
                  maxWidth: 180,
                  padding: "10px 0 0 10px",
                  textTransform: "none",
                  textDecoration: "underline",
                }}
                onClick={handleShowMore}
              >
                {showMore ? "Hide" : "Show More"}
              </Button>
            </Grid>
          )}
        </Stack>
      </Paper>
    );
  }
  return null;
};

export default createFragmentContainer(DrivePropertiesCard, {
  asset: gql`
    fragment DrivePropertiesCard_asset on Asset {
      id
      drives {
        edges {
          installedDt
          updatedDt
          removedDt
          position
          drive {
            id
            serialNumber
            driveModel
            properties {
              integrationKey
              value
              name
              dataType
              description
              createdBy
              createdDt
              updatedBy
              updatedDt
              dataType
            }
          }
        }
      }
    }
  `,
});
