import React from "react";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Map, { Marker, ViewStateChangeEvent } from "react-map-gl";
import LocationPin from "assets/svg/locationPin";
import CloseIcon from "@mui/icons-material/Close";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const DEFAULT_VIEWPORT = {
  longitude: -50,
  latitude: 37,
  zoom: 16,
};

interface LocationMapProps {
  open: boolean;
  locationData: { lat: number; lon: number };
  onClose: () => void;
}

const LocationMap: React.FC<LocationMapProps> = ({
  open,
  locationData,
  onClose,
}) => {
  const [viewport, setViewport] = React.useState(DEFAULT_VIEWPORT);

  React.useEffect(() => {
    if (locationData) {
      setViewport((prevViewport) => ({
        ...prevViewport,
        longitude: locationData.lon,
        latitude: locationData.lat,
      }));
    }
  }, [locationData]);

  const handleMoveMap = (evt: ViewStateChangeEvent) => {
    setViewport(evt.viewState);
  };

  const handleMoveEnd = async (evt: ViewStateChangeEvent) => {
    setViewport(evt.viewState);
  };

  return (
    <Dialog fullScreen open={open}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
          position: "relative",
        }}
      >
        <CloseIcon
          onClick={onClose}
          sx={{
            cursor: "pointer",
            position: "absolute",
            fontSize: "40px",
            top: "10px",
            zIndex: 2,
          }}
        />
        <Map
          {...viewport}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onMove={(evt) => handleMoveMap(evt)}
          onMoveEnd={(evt) => handleMoveEnd(evt)}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        >
          <Marker
            longitude={locationData.lon}
            latitude={locationData.lat}
            anchor="bottom"
          >
            <LocationPin />
          </Marker>
        </Map>
      </Box>
    </Dialog>
  );
};

export default LocationMap;
