import { createSlice } from "@reduxjs/toolkit";
import { getDefaultVisibleColumns } from "./jobsColumns";

/* "version" key prevents any conflicts in localstorage especially in case of any
changes in source data. Change like change of column, filter etc
Use version key to forcibly delete any local storage if conflicts exists */

interface FiltersState {
  version: Number;
  jobFilters: any;
  reorderedColumns: Array<any> | null;
  jobColumns: Array<any> | null;
  jobSelectedColumns: object;
}

const initialState: FiltersState = {
  version: 1,
  jobFilters: {},
  reorderedColumns: null,
  jobColumns: null,
  jobSelectedColumns: getDefaultVisibleColumns(),
};

const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateFilter(state, action) {
      const { filterType, value } = action.payload;
      const _filters = { ...state.jobFilters, [filterType]: value };
      state.jobFilters = _filters;
    },
    clearFilter(state) {
      state.jobFilters = {};
    },
    setColumnOrders(state, action) {
      state.reorderedColumns = action.payload;
    },
    setDefaultColumns(state, action) {
      state.jobColumns = action.payload;
    },
    setSelectedColumns(state, action) {
      state.jobSelectedColumns = action.payload;
    },
    clearAllStoredData(state) {
      state.jobFilters = {};
      state.reorderedColumns = null;
      state.jobColumns = null;
      state.jobSelectedColumns = getDefaultVisibleColumns();
    },
  },
});

export const {
  updateFilter,
  clearFilter,
  setColumnOrders,
  setDefaultColumns,
  setSelectedColumns,
  clearAllStoredData,
} = slice.actions;
export default slice.reducer;
