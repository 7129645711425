function LocationPin(props: { width?: number; height?: number }) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 72}
      height={height || 72}
      fill="none"
      viewBox="0 0 72 64"
    >
      <g>
        <path
          fill="#C62828"
          d="M36 5.333C24.39 5.333 15 13.68 15 24c0 14 21 34.667 21 34.667S57 38 57 24c0-10.32-9.39-18.666-21-18.666zm0 25.334c-4.14 0-7.5-2.987-7.5-6.667 0-3.68 3.36-6.666 7.5-6.666 4.14 0 7.5 2.986 7.5 6.666s-3.36 6.667-7.5 6.667z"
        ></path>
      </g>
    </svg>
  );
}

export default LocationPin;
