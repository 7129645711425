import { createAsyncThunk } from "@reduxjs/toolkit";

import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export interface IOrgsQuery {
  token: string | undefined;
}
export interface IOrgQuery extends IOrgsQuery {
  organizationId: string | undefined;
}
export interface IChangeOrgRelationships extends IOrgQuery {
  children: any | undefined;
  parents: any | undefined;
}
export const getOrganizationsList = createAsyncThunk(
  "sites/getOrganizationsList",
  async (payload: IOrgsQuery) => {
    const { token } = payload;

    const config = setCallConfig(
      "/api/organizations/list?type=CP,C,I",
      "GET",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;

    return {
      channelPartners: response.data?.result?.filter((item: any) =>
        item.types?.includes("CP")
      ),
      customers: response.data?.result?.filter((item: any) =>
        item.types?.includes("C")
      ),
      installers: response.data?.result?.filter((item: any) =>
        item.types?.includes("I")
      ),
    };
  }
);
export const getAllOrganizations = createAsyncThunk(
  "orgs/getAllOrganizations",
  async (payload: IOrgsQuery) => {
    const { token } = payload;

    const allOrgsResponse = (await trackedApiCall(
      setCallConfig("/api/organizations/list", "GET", token)
    )) as ApiResponse;

    return {
      orgs: allOrgsResponse.data?.result,
    };
  }
);
export const getOrganizationDetails = createAsyncThunk(
  "orgs/getOrganizationDetails",
  async (payload: IOrgQuery) => {
    const { token, organizationId } = payload;

    const allOrgsResponse = (await trackedApiCall(
      setCallConfig(
        `api/organization/${organizationId}/withrelationships/true`,
        "GET",
        token
      )
    )) as ApiResponse;

    return allOrgsResponse.data?.result;
  }
);
export const changeOrgRelationships = createAsyncThunk(
  "orgs/changeOrgRelationships",
  async (payload: IChangeOrgRelationships) => {
    const { token, organizationId, children, parents } = payload;

    const changeOrgRelationshipsResponse = (await trackedApiCall(
      setCallConfig(
        `api/organization/${organizationId}/relationships`,
        "POST",
        token,
        { children: children, parents: parents }
      )
    )) as ApiResponse;
    return changeOrgRelationshipsResponse?.data?.result;
  }
);

export const getOrganizationUsers = createAsyncThunk(
  "orgs/getOrganizationUsers",
  async (payload: IOrgQuery) => {
    const { token, organizationId } = payload;

    const orgUsersResponse = (await trackedApiCall(
      setCallConfig(`api/organization/${organizationId}/users`, "GET", token)
    )) as ApiResponse;

    return orgUsersResponse.data?.result;
  }
);

export const createOrganization = createAsyncThunk(
  "user/reinviteUser",
  async (payload: any) => {
    const { token, name, type, channelPartner, customer } = payload;

    const params: {
      name: any;
      type: any;
      channelPartner?: any;
      customer?: any;
    } = {
      name: name,
      type: type,
    };

    if (channelPartner) {
      params.channelPartner = channelPartner;
    }

    if (customer) {
      params.customer = customer;
    }

    const createOrgResult = (await trackedApiCall(
      setCallConfig(`api/organization`, "POST", token, params)
    )) as ApiResponse;
    return createOrgResult?.data?.result;
  }
);
export const getRolesForOrgs = createAsyncThunk(
  "user/getRolesForOrgs",
  async (payload: any) => {
    const { token, organizationIds } = payload;

    const getRolesForOrgsResult = (await trackedApiCall(
      setCallConfig(`api/organizations/roles`, "POST", token, {
        organization_ids: organizationIds,
      })
    )) as ApiResponse;
    return getRolesForOrgsResult?.data?.result;
  }
);
