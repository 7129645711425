import { Box, Typography } from "@mui/material";
import OrgInstallersDataGrid from "./dataGrid";

const OrgInstallers = (props: {
  rows: any[];
  className?: any;
  onRemove?: any | undefined;
  isGreensightAdmin?: boolean | undefined;
}) => {
  const { rows, className, onRemove, isGreensightAdmin } = props;
  return (
    <Box sx={{ width: "100%", marginBottom: "12px" }} className={className}>
      <Typography variant="h6" gutterBottom>
        Installers
      </Typography>
      {rows?.length ? (
        <OrgInstallersDataGrid
          rows={rows}
          onRemove={onRemove}
          isGreensightAdmin={isGreensightAdmin}
        ></OrgInstallersDataGrid>
      ) : (
        <Box sx={{ height: "100%", paddingLeft: "10px", paddingBottom: "5px" }}>
          No assigned installers.
        </Box>
      )}
    </Box>
  );
};
export default OrgInstallers;
