import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "../../services/base/models/apiresponse";

export const getSavedViews = createAsyncThunk(
  "savedviews/getSavedViews",
  async (payload: any, thunkAPI) => {
    const { token, page } = payload;
    const config = setCallConfig(`/api/savedviews/${page}`, "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const saveView = createAsyncThunk(
  "savedviews/saveView",
  async (payload: any) => {
    const { token, viewData } = payload;
    const createOrgResult = (await trackedApiCall(
      setCallConfig(`api/savedviews`, "POST", token, viewData)
    )) as ApiResponse;
    return createOrgResult?.data?.result;
  }
);

export const shareView = createAsyncThunk(
  "savedviews/share",
  async (payload: any) => {
    const { token, viewData } = payload;
    const createOrgResult = (await trackedApiCall(
      setCallConfig(`api/savedviews/share`, "POST", token, viewData)
    )) as ApiResponse;
    return createOrgResult?.data?.result;
  }
);

export const deleteView = createAsyncThunk(
  "savedviews/delete",
  async (payload: any) => {
    const { token, viewData } = payload;
    const createOrgResult = (await trackedApiCall(
      setCallConfig(`api/savedviews/delete`, "POST", token, viewData)
    )) as ApiResponse;
    return createOrgResult?.data?.result;
  }
);

export const getUsers = createAsyncThunk(
  "savedviews/getUsers",
  async (payload: any) => {
    const { token, data } = payload;
    const createOrgResult = (await trackedApiCall(
      setCallConfig(`api/savedviews/delete`, "POST", token, data)
    )) as ApiResponse;
    return createOrgResult?.data?.result;
  }
);
