import { createTheme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#0288d1",
    },
  },
};

export const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#006BA6",
        },
        colorSecondary: {
          "&$checked": {
            color: "#006BA6",
          },
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
      },
    },

    MuiImageListItem: {
      styleOverrides: {
        root: {
          img: {
            height: "160px!important",
            width: "155px!important",
            wordWrap: "anywhere",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1976D2",
    },
  },
  typography: {
    fontFamily: [
      "Barlow",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});
