import { ColumnState } from "utils/types";

const defaultColumns: readonly ColumnState[] = [
  {
    field: "role_name",
    hide: false,
    index: 0,
  },
  {
    field: "actions",
    hide: false,
    index: 0,
    sortable: false,
  },
];

export default defaultColumns;
