import { createAsyncThunk } from "@reduxjs/toolkit";

import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "../../services/base/models/apiresponse";

export interface IUserListQuery {
  token: string | undefined;
  page: number | undefined;
  size: number | undefined;
  orderBy: string | undefined;
  dir: string | undefined;
  filter: string | undefined;
  includeInactiveUsers: boolean | undefined;
  listVisibleOrgUsers?: boolean;
}

export interface IUserDetailsQuery {
  token: string | undefined;
  userId: string | undefined;
}

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (payload: IUserListQuery, thunkAPI) => {
    const {
      token,
      page,
      size,
      orderBy,
      dir,
      filter,
      includeInactiveUsers,
      listVisibleOrgUsers,
    } = payload;

    let url = `/api/users/list?page=${page}&size=${size}&orderBy=${orderBy}&dir=${dir}&filter=${filter}&includeInactiveUsers=${
      includeInactiveUsers ?? false
    }`;
    if (listVisibleOrgUsers !== undefined) {
      url += `&listVisibleOrgUsers=${listVisibleOrgUsers}`;
    }
    const config = setCallConfig(url, "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);
export const getUserDetails = createAsyncThunk(
  "users/getUserDetails",
  async (payload: IUserDetailsQuery, thunkAPI) => {
    const { token, userId } = payload;

    const config = setCallConfig(`/api/user/${userId}`, "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);
export const inviteUser = createAsyncThunk(
  "user/inviteUser",
  async (payload: any) => {
    const { token, email, roles, organizationIds, roleIds } = payload;

    const inviteUserResult = (await trackedApiCall(
      setCallConfig(`api/user/invite`, "POST", token, {
        email: email,
        roles: roles,
        organization_ids: organizationIds,
        role_ids: roleIds,
      })
    )) as ApiResponse;
    return inviteUserResult?.data?.result;
  }
);
export const updateMembership = createAsyncThunk(
  "user/updateMembership",
  async (payload: any) => {
    const { token, userId, organizationId, isAdmin } = payload;

    const updateMembershipResult = (await trackedApiCall(
      setCallConfig(
        `api/user/${userId}/membership/${organizationId}`,
        "PUT",
        token,
        {
          is_admin: isAdmin,
        }
      )
    )) as ApiResponse;
    return updateMembershipResult?.data?.result;
  }
);
export const deleteMembership = createAsyncThunk(
  "user/deleteMembership",
  async (payload: any) => {
    const { token, userId, organizationId } = payload;

    const deleteMembershipResult = (await trackedApiCall(
      setCallConfig(
        `api/user/${userId}/membership/${organizationId}`,
        "DELETE",
        token,
        {}
      )
    )) as ApiResponse;
    return deleteMembershipResult?.data?.result;
  }
);
export const reinviteUser = createAsyncThunk(
  "user/reinviteUser",
  async (payload: any) => {
    const { token, userId } = payload;

    const reinviteUserResult = (await trackedApiCall(
      setCallConfig(`api/user/reinvite`, "POST", token, {
        user_id: userId,
      })
    )) as ApiResponse;
    return reinviteUserResult?.data?.result;
  }
);
export const updateRoles = createAsyncThunk(
  "user/updateRoles",
  async (payload: any) => {
    const { token, userId, roles } = payload;

    const reinviteUserResult = (await trackedApiCall(
      setCallConfig(`api/user/${userId}/roles`, "PUT", token, {
        remove: roles,
      })
    )) as ApiResponse;
    return reinviteUserResult?.data?.result;
  }
);
export const getUserTtRoles = createAsyncThunk(
  "users/getUserTtRoles",
  async (payload: IUserDetailsQuery, thunkAPI) => {
    const { token, userId } = payload;

    const config = setCallConfig(`/api/user/${userId}/ttroles`, "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response?.data?.result;
  }
);
export const addUserTtRoles = createAsyncThunk(
  "users/addUserTtRoles",
  async (payload: any, thunkAPI) => {
    const { token, userId, roleIds } = payload;

    const config = setCallConfig(`/api/user/${userId}/ttroles`, "POST", token, {
      role_ids: roleIds,
    });
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response?.data?.result;
  }
);
export const removeUserTtRole = createAsyncThunk(
  "users/removeUserTtRole",
  async (payload: any, thunkAPI) => {
    const { token, userId, roleId } = payload;

    const config = setCallConfig(
      `/api/user/${userId}/ttroles/${roleId}`,
      "DELETE",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response?.data?.result;
  }
);
export const getCurrentUserActions = createAsyncThunk(
  "users/getCurrentUserActions",
  async (payload: any, thunkAPI) => {
    const { token } = payload;
    const config = setCallConfig(`/api/user/actions`, "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response?.data?.result;
  }
);
export const getCurrentUserDetails = createAsyncThunk(
  "users/getCurrentUserDetails",
  async (payload: IUserDetailsQuery, thunkAPI) => {
    const { token, userId } = payload;

    const config = setCallConfig(`/api/user/${userId}`, "GET", token);
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);
