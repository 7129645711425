import { createAsyncThunk } from "@reduxjs/toolkit";

import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "../../services/base/models/apiresponse";

export interface IFanChartsQuery {
  token: string | undefined;
  page: number | undefined;
  size: number | undefined;
  orderBy: string | undefined;
  dir: string | undefined;
  filter: string | undefined;
}

export interface IFanChartModelsQuery {
  token: string | undefined;
  fanchartmodelId: string | undefined;
}

export interface IFanChartDetailsQuery {
  token: string | undefined;
  fanchartId: string | undefined;
}

export const getFanCharts = createAsyncThunk(
  "chartdata/airflowfan/getFanCharts",
  async (payload: IFanChartsQuery, thunkAPI) => {
    const { token, page, size, orderBy, dir, filter } = payload;
    const config = setCallConfig(
      `/api/chartdata/airflowfan/fancharts?page=${page}&size=${size}&orderBy=${orderBy}&dir=${dir}&filter=${filter}`,
      "GET",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const getFanChartDetails = createAsyncThunk(
  "chartdata/airflowfan/getFanChartDetails",
  async (payload: IFanChartDetailsQuery, thunkAPI) => {
    const { token, fanchartId } = payload;

    const config = setCallConfig(
      `/api/chartdata/airflowfan/chartdetails/full/${fanchartId}`,
      "GET",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const getFanChartsModel = createAsyncThunk(
  "chartdata/airflowfan/getFanChartsModel",
  async (payload: IFanChartModelsQuery, thunkAPI) => {
    const { token, fanchartmodelId } = payload;
    const config = setCallConfig(
      `/api/chartdata/airflowfan/fanchartmodels/${fanchartmodelId}`,
      "GET",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response.data;
  }
);

export const createFanChart = createAsyncThunk(
  "airflowfan/createFanChart",
  async (payload: any) => {
    const { token, fanChartFormData } = payload;

    const createOrgResult = (await trackedApiCall(
      setCallConfig(
        `api/chartdata/airflowfan/fancharts`,
        "POST",
        token,
        fanChartFormData
      )
    )) as ApiResponse;
    return createOrgResult?.data?.result;
  }
);
