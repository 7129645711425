import {
  Button,
  Box,
  Grid,
  Chip,
  Typography,
  Alert,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  Tabs,
  Tab,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getRole,
  updateRole,
  addRole,
  getRoleActions,
  getRoleOrgs,
} from "redux/actions/roles";
import * as roleEditForm from "redux/reducers/roleEditorSlice";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { useRoleEditorStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TTTextField from "components/common/TTTextField";
import { enqueueSnackbar } from "notistack";
import { listActions } from "redux/actions/actions";
import { TabPanel, a11yProps } from "components/layout/tabpanel";
import { Constants } from "utils/constants";
import { getAllOrganizations } from "redux/actions/orgs";
import { useAuthData } from "utils/hooks/useAuthData";

export const RoleEditor = () => {
  let { roleId } = useParams();
  const roleEditorData = useAppSelector((state) => state.roleEditor);
  const allOrgs = useAppSelector((state) => state.orgsList.data.orgs);
  const [tabIndex, setTabIndex] = useState(
    Constants.PRODUCTS_ARRAY.indexOf(Constants.PRODUCTS.TTADMIN)
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isGreensightAdmin, getRawIdToken, ready } = useAuthData();
  const { classes } = useRoleEditorStyles();

  useEffect(() => {
    if (ready) {
      (async () => {
        const idToken = await getRawIdToken();
        if (roleId) {
          dispatch(roleEditForm.setIsNew(false));
          dispatch(
            getRole({
              token: idToken,
              roleId: roleId,
            })
          );
          dispatch(
            getRoleActions({
              token: idToken,
              roleId: roleId,
            })
          );
          dispatch(
            getRoleOrgs({
              token: idToken,
              roleId: roleId,
            })
          );
        } else {
          dispatch(roleEditForm.setIsNew(true));
          dispatch(roleEditForm.setRoleActions([]));
          dispatch(roleEditForm.setRoleOrgs([]));
        }
        dispatch(
          listActions({
            token: idToken,
          })
        );
        dispatch(
          getAllOrganizations({
            token: idToken,
          })
        );
      })();
    }
  }, [roleId, ready]);
  const handleRoleNameChange = (event: any) => {
    const newVal = event.target.value;
    if (newVal === null || newVal === undefined || newVal === "") {
      dispatch(
        roleEditForm.setValidation({
          valid: false,
          message: "Role name is required",
        })
      );
    } else {
      dispatch(
        roleEditForm.setValidation({
          valid: true,
          message: "",
        })
      );
    }
    dispatch(
      roleEditForm.setRole({ ...roleEditorData.role, role_name: newVal })
    );
  };
  const handleRoleDescriptionChange = (event: any) => {
    const newVal = event.target.value;
    dispatch(
      roleEditForm.setRole({ ...roleEditorData.role, description: newVal })
    );
  };
  const handleSaveRoleClick = () => {
    if (
      roleEditorData.role.role_name === null ||
      roleEditorData.role.role_name === undefined ||
      roleEditorData.role.role_name === ""
    ) {
      dispatch(
        roleEditForm.setValidation({
          valid: false,
          message: "Role name is required",
        })
      );
    } else {
      if (roleEditorData.isNew) {
        (async () => {
          const idToken = await getRawIdToken();

          dispatch(
            addRole({
              token: idToken,
              addData: {
                ...roleEditorData.role,
                actions: roleEditorData.roleActions,
                organizations: roleEditorData.roleOrgs,
              },
            })
          )
            .then((resp) => {
              if (resp.payload?.role_id) {
                enqueueSnackbar("Role added!", { variant: "default" });
                navigate("/roles");
              }
            })
            .catch(() => {
              enqueueSnackbar("Failed to add role", { variant: "error" });
            });
        })();
      } else {
        (async () => {
          const idToken = await getRawIdToken();

          dispatch(
            updateRole({
              token: idToken,
              editData: {
                ...roleEditorData.role,
                actions: roleEditorData.roleActions,
                organizations: roleEditorData.roleOrgs,
              },
              roleId: roleId,
            })
          )
            .then((resp) => {
              if (resp.payload?.role_id) {
                enqueueSnackbar("Role updated!", { variant: "default" });
                navigate("/roles");
              }
            })
            .catch(() => {
              enqueueSnackbar("Failed to update role", { variant: "error" });
            });
        })();
      }
    }
  };
  const handleRoleCheckChange = (act: any) => {
    const actions = [...roleEditorData.roleActions];
    const actIndex = actions.indexOf(act.action_id);
    if (actIndex > -1) {
      actions.splice(actIndex, 1);
    } else {
      actions.push(act.action_id);
    }
    dispatch(roleEditForm.setRoleActions([...actions]));
  };
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const handleOrgCheckChange = (o: any) => {
    const orgs = [...roleEditorData.roleOrgs];
    const orgIndex = orgs.indexOf(o.organization_id);
    if (orgIndex > -1) {
      orgs.splice(orgIndex, 1);
    } else {
      orgs.push(o.organization_id);
    }
    dispatch(roleEditForm.setRoleOrgs([...orgs]));
  };
  return (
    <Box
      style={{
        width: "100%",
        maxWidth: "1080px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Button
        size="small"
        onClick={() => {
          navigate("/roles");
        }}
        className={"back-to-list-button"}
      >
        {" "}
        <ArrowBackIcon />
        Back to Roles
      </Button>
      <Box component={"div"} className={classes.headerBox}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.headerGrid}
        >
          <Grid item>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginBottom: "0px",
              }}
            >
              {roleEditorData?.header}
            </Typography>
          </Grid>
          {roleEditorData?.role?.is_system && (
            <Grid item>
              <Chip
                label="System role"
                color="primary"
                style={{
                  display: "inline-block",
                  lineHeight: "32px",
                  marginRight: "10px",
                }}
              />
            </Grid>
          )}{" "}
          {roleEditorData?.role &&
            (!roleEditorData?.role?.is_system || isGreensightAdmin) && (
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleSaveRoleClick}
                >
                  Save
                </Button>
              </Grid>
            )}
        </Grid>
      </Box>
      {roleEditorData?.isNew ||
      (!roleEditorData?.loading && !roleEditorData?.isNew) ? (
        <>
          {" "}
          <Grid container direction={"column"}>
            <Grid item>
              <Grid container direction={"row"} spacing={1}>
                <Grid item>
                  {!roleEditorData?.validation?.valid ? (
                    <Alert severity="error">
                      {roleEditorData?.validation?.message}
                    </Alert>
                  ) : (
                    <></>
                  )}
                  <TTTextField
                    label="Name"
                    value={
                      roleEditorData?.role?.role_name
                        ? roleEditorData?.role?.role_name
                        : ""
                    }
                    variant="outlined"
                    size="normal"
                    fullWidth
                    style={{ marginTop: "24px", width: "300px" }}
                    onChange={handleRoleNameChange}
                    disabled={roleEditorData?.role?.is_system}
                  />
                </Grid>
                <Grid item>
                  <TTTextField
                    multiline
                    label="Description"
                    value={
                      roleEditorData?.role?.description
                        ? roleEditorData?.role?.description
                        : ""
                    }
                    rows={4}
                    variant="outlined"
                    size="normal"
                    style={{ marginTop: "24px", width: "300px" }}
                    onChange={handleRoleDescriptionChange}
                    disabled={
                      roleEditorData?.role?.is_system && !isGreensightAdmin
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                style={{ marginLeft: "15px", marginTop: "15px" }}
              >
                <Grid item xs={6}>
                  <Grid item>
                    {" "}
                    <Typography variant="button">Actions</Typography>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Tabs value={tabIndex} onChange={handleTabChange}>
                        {Constants.PRODUCTS_ARRAY.map((product: string) => {
                          return (
                            <Tab
                              key={product}
                              label={product}
                              {...a11yProps(
                                Constants.PRODUCTS_ARRAY.indexOf(product)
                              )}
                            ></Tab>
                          );
                        })}
                      </Tabs>
                    </Box>
                    {Constants.PRODUCTS_ARRAY.map((product: string) => {
                      return (
                        <TabPanel
                          key={product}
                          value={tabIndex}
                          index={Constants.PRODUCTS_ARRAY.indexOf(product)}
                          style={{
                            overflow: "auto",
                            maxHeight: "calc(100vh - 475px)",
                          }}
                        >
                          <FormControl>
                            <FormGroup>
                              {roleEditorData?.availableActions?.map(
                                (act: any) => {
                                  return act.product === product ? (
                                    <FormControlLabel
                                      key={act.action_id}
                                      style={{ paddingLeft: "9px" }}
                                      control={<Checkbox />}
                                      label={act.action_name}
                                      checked={
                                        roleEditorData?.roleActions?.indexOf(
                                          act.action_id
                                        ) > -1
                                      }
                                      onChange={() =>
                                        handleRoleCheckChange(act)
                                      }
                                      disabled={
                                        roleEditorData?.role?.is_system &&
                                        !isGreensightAdmin
                                      }
                                    />
                                  ) : null;
                                }
                              )}
                            </FormGroup>
                          </FormControl>
                        </TabPanel>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="button">Organizations</Typography>
                  <Grid
                    container
                    direction={"row"}
                    style={{
                      overflow: "auto",
                      maxHeight: "calc(100vh - 450px)",
                    }}
                  >
                    <FormControl>
                      <FormGroup row={true}>
                        {allOrgs?.map((org: any) => {
                          return (
                            <FormControlLabel
                              key={org.organization_id}
                              style={{ paddingLeft: "9px" }}
                              control={<Checkbox />}
                              label={org.organization_name}
                              checked={
                                roleEditorData?.roleOrgs?.indexOf(
                                  org.organization_id
                                ) > -1
                              }
                              onChange={() => handleOrgCheckChange(org)}
                              disabled={
                                roleEditorData?.role?.is_system &&
                                !isGreensightAdmin
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};
