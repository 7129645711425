import { createSlice } from "@reduxjs/toolkit";

import error from "../../utils/error";
import {
  getFanCharts,
  getFanChartDetails,
  getFanChartsModel,
} from "../actions/fancharts";
import {
  FanChartsFullRow,
  FanChartModels,
  FanChartsDetails,
} from "models/fancharts";
import { Sort } from "utils/types";

interface FanChartsState {
  fancharts?: any;
  FanChartDetail?: any;
  FanChartsModel?: any;
  totalCount?: number;
  loading: boolean;
  error?: string;
  sortField: string;
  sortDirection: Sort;
  filter: string;
  isModalOpen: boolean;
  currentFanChart: FanChartsFullRow | null;
  currentFanChartDetails: FanChartsDetails[] | null;
}

const initialFanChart: FanChartsFullRow = {
  fanchart_id: "",
  make: "",
  make_display: "",
  fanchart_name: "",
  model_id: "",
  model: "",
  max_pressure: "",
  tonnage: "",
  fanchartmodels: [] as FanChartModels[],
};

const initialState: FanChartsState = {
  fancharts: undefined,
  totalCount: 0,
  FanChartDetail: undefined,
  loading: false,
  error: undefined,
  sortField: "make_display",
  sortDirection: Sort.ASC,
  filter: "",
  isModalOpen: false,
  currentFanChart: initialFanChart,
  currentFanChartDetails: [] as FanChartsDetails[],
};

const slice = createSlice({
  name: "fancharts",
  initialState,
  reducers: {
    setSortOrder(state, action) {
      state.sortField = action.payload?.by ?? "";
      state.sortDirection = action.payload?.dir ?? "";
    },
    setFilter(state, action) {
      state.filter = action.payload ?? "";
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
    },
    setCurrentFanchart(state, action) {
      state.currentFanChart = action.payload ?? null;
    },
    setCurrentFanchartDetails(state, action) {
      state.currentFanChartDetails = action.payload ?? null;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getFanCharts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFanCharts.fulfilled, (state, action) => {
      state.loading = false;
      state.fancharts = action.payload?.result.fancharts;
      state.totalCount = action.payload?.result.total_count;
    });
    builder.addCase(getFanCharts.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    builder.addCase(getFanChartDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFanChartDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.FanChartDetail = action.payload?.result;
    });
    builder.addCase(getFanChartDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });

    builder.addCase(getFanChartsModel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFanChartsModel.fulfilled, (state, action) => {
      state.loading = false;
      state.FanChartsModel = action.payload?.result;
    });
    builder.addCase(getFanChartsModel.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export const {
  setSortOrder,
  setFilter,
  openModal,
  closeModal,
  setCurrentFanchart,
} = slice.actions;
export default slice.reducer;
