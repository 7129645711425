import { createAsyncThunk } from "@reduxjs/toolkit";

import { setCallConfig, trackedApiCall } from "../../services/base/baseapi";
import { ApiResponse } from "services/base/models/apiresponse";

export interface IOrgsQuery {
  token: string | undefined;
}

export const getSimpleTemplateList = createAsyncThunk(
  "sites/getSimpleTemplateList",
  async (payload: IOrgsQuery) => {
    const { token } = payload;
    const config = setCallConfig(
      "/api/surveytemplates/list/simple",
      "GET",
      token
    );
    const response = (await trackedApiCall(config)) as ApiResponse;
    return response?.data?.result;
  }
);
