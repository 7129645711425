import { TplEditDataInstruction } from "./tpleditdatainstruction";

export interface TplEditDataItem {
  item_id?: string;
  name?: string;
  data_type?: string;
  sort_order?: number;
  validation_rules?: ValidationRules;
  store_gps?: boolean;
  alias?: string;
  display_condition?: string;
  description?: string;
  ocr_settings?: OcrSettings;
  picklist_options?: object[];
  instruction?: TplEditDataInstruction;
  airtable_mapping?: string;
  input_source?: string[];
  integration_key?: string;
  prerequisite?: string;
}

export interface OcrSettings {
  is_enabled: boolean;
  source?: string;
}

export interface PicklistOption {
  id?: string;
  display_value?: string;
  alias?: string;
  sort_order?: number;
}

export interface ValidationRules {
  is_required?: boolean;
  min_value?: number;
  max_value?: number;
  min_length?: number;
  max_length?: number;
  min_count?: number;
  max_count?: number;
  regex?: RegexValidation;
  transforms?: TransformsValidation;
}

export interface RegexValidation {
  example?: string;
  exp?: string;
  type?: string;
}

export interface TransformsValidation {
  replacements?: StringKeyValue;
  uppercase?: boolean;
}

type StringKeyValue = {
  [key: string]: string;
};

export const VALIDATION_PARAMETERS = {
  INTEGER: ["value"],
  DECIMAL: ["value"],
  DATETIME: ["value"],
  DATE: ["value"],
  TIME: ["value"],
  TEXT: ["length", "regex", "transforms"],
  MEDIA: ["count"],
  DRAWING: ["count"],
  VIDEO: ["count"],
  AUDIO: ["count"],
  ATTACHMENT: ["count"],
};
