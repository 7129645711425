import * as React from "react";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Button, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";

type FileInputProps = {
  value: File | null;
  onChange: (f: File | null) => void;
  name: string;
  sx: SxProps;
  disabled: boolean;
  title?: string;
};
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    maxWidth: 300,
    fontFamily: "Barlow",
    fontSize: 15,
    border: `1px solid ${theme.palette.warning.dark}`,
  },
}));

export function FileUpload({ onChange, sx, disabled, title }: FileInputProps) {
  return (
    <>
      <input
        hidden
        accept="text/csv"
        id="button-file"
        type="file"
        onChange={(e) => {
          onChange(e.target.files![0]);
        }}
        disabled={disabled}
      />
      <HtmlTooltip enterDelay={title ? 0 : 100} title={title || ""}>
        <label htmlFor="button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={sx}
            disabled={disabled}
          >
            <UploadFileOutlinedIcon style={{ marginRight: "12px" }} />
            Upload
          </Button>
        </label>
      </HtmlTooltip>
    </>
  );
}
