import { Column, SortColumn, SortDirection } from "react-data-grid";
import { ColumnState, GridState, Sort, SortOrder } from "../../../utils/types";

const sortOrderForColumn = (
  sortColumn: SortColumn,
  columnNameToField: (name: string) => string
) => {
  const sortOrder = {
    by: columnNameToField(sortColumn.columnKey),
    dir: sortColumn.direction === "ASC" ? Sort.ASC : Sort.DESC,
  };
  return sortOrder;
};

const updateGridState = (
  gridState: GridState,
  columnNameToField: (name: string) => string,
  defaultSortOrder: SortOrder,
  orderedColumns?: any[],
  sortColumns?: readonly SortColumn[]
) => {
  const updatedGridState = { ...gridState };
  if (orderedColumns) {
    updatedGridState.columnStates = orderedColumns.map(
      (column: any, index: number) => {
        const columnState: ColumnState = {
          field: column.key,
          width: column.width || column.minWidth,
          hide: !column.visible,
          index: index,
        };
        return columnState;
      }
    );
  }
  if (sortColumns) {
    const sort = sortColumns[0]
      ? sortOrderForColumn(sortColumns[0], columnNameToField)
      : defaultSortOrder;
    updatedGridState.sortDirection = sort.dir;
    updatedGridState.sortField = sort.by;
  }
  return updatedGridState;
};

const sortColumnForGridState = (
  gridState: GridState,
  fieldToColumnName: (field: string) => string
): SortColumn => {
  const sortColumn = {
    columnKey: fieldToColumnName(gridState.sortField),
    direction: (gridState.sortDirection === Sort.ASC
      ? "ASC"
      : "DESC") as SortDirection,
  };
  return sortColumn;
};

// Creates a Column definition from the given columnState and Column, prioritizing ColumnState fields where available
function mergeColDefWithColumnState<T>(
  columnState: ColumnState,
  colDef: Column<T>
): Column<T> {
  if (columnState.field !== colDef.key) {
    console.error(
      `ColumnState field ${columnState.field} does not match Column key ${colDef.key}`
    );
  }
  const colDefWithState = {
    ...colDef,
    key: columnState.field,
    sortable: columnState.sortable,
  };
  if (columnState.width! || colDef.minWidth) {
    colDefWithState.width = Math.max(
      columnState.width || 0,
      colDef.minWidth || 0
    );
  }
  return colDefWithState;
}

// Sort the original columns to match the order of the given ColumnStates
function reorderColumns<T>(
  createColumns: readonly Column<T>[],
  columnStates: ColumnState[]
): readonly Column<T>[] {
  if (!columnStates) {
    return createColumns;
  }

  // find where each original column is in columnStates and insert it at that position.
  const reorderedColumns: Column<T>[] = [];
  for (let i = 0; i < createColumns.length; i++) {
    const column = createColumns[i];
    const index = columnStates.findIndex((c) => c.field === column.key);
    if (index !== undefined) {
      reorderedColumns[index] = mergeColDefWithColumnState<T>(
        columnStates[index],
        column
      );
    }
  }

  return reorderedColumns;
}

const createInitialColumnStates = (defaultColumns: readonly ColumnState[]) => {
  const initialStates: Array<ColumnState> = [];
  defaultColumns.forEach((col: any, index: number) => {
    initialStates.push({ ...col, index: index });
  });
  return initialStates;
};

const columnSettings = {
  VERSION: "1.1.8",
  COLUMN_DEFAULT_WIDTH: 290,
  COLUMN_MIN_WIDTH: 60,
};

export {
  sortColumnForGridState,
  sortOrderForColumn,
  updateGridState,
  reorderColumns,
  createInitialColumnStates,
  columnSettings,
};
