import { FilterItem } from "utils/types";
import { GridState } from "utils/types";
import { definedColumns, supportedSortColumns } from "./uploadColumns";
import {
  filtersGraphQLParams,
  columnSortGraphQLParams,
} from "../common/gridview/assetFiltersUtil";
import { gql } from "@apollo/client";

const getUploadsQuery = (params: string) => {
  return gql`
    query netSuiteUploadsGrid {
      netSuiteUploads ${params} {
        totalCount
        pageInfo {
          startIndex
          endIndex
          hasNextPage
          hasPreviousPage
        }
        edges {
          uploadRecord {
            id
            uploadDt
            uploadedBy
            fileName
            importStatus
          }
        }
      }
    }
  `;
};

const getUploadsParams = (
  grid: GridState,
  searchField: string,
  selectedOrgFilterItems: FilterItem[],
  columnFilterModel: any,
  page: number,
  size: number = -1
) => {
  const pairs: any[] = [];

  const columnSortStr = columnSortGraphQLParams(
    grid.sortField,
    grid?.sortDirection,
    definedColumns,
    supportedSortColumns
  );
  if (columnSortStr) {
    pairs.push(columnSortStr);
  }

  const offset = size * page;
  pairs.push(`offset: ${offset}`);
  if (size > 0) {
    pairs.push(`limit: ${size}`);
  }

  const columnFiltersStr = filtersGraphQLParams(
    searchField,
    selectedOrgFilterItems,
    columnFilterModel,
    definedColumns
  );
  if (columnFiltersStr) {
    pairs.push(columnFiltersStr);
  }

  if (pairs.length > 0) {
    const params = pairs.join(", ");
    return "( " + params + " )";
  }
  return "";
};

const uploadRowsFromQueryData = (data: any) => {
  return (data?.netSuiteUploads?.edges || []).map((edge: any) => {
    const result: any = {};
    definedColumns.forEach((col: any, index: number) => {
      if (col.object && col.key) {
        if (col.object === "uploadRecord") {
          result[col.field] = edge?.uploadRecord?.[col.key];
        }
      }
    });
    return result;
  });
};

export { getUploadsQuery, getUploadsParams, uploadRowsFromQueryData };
