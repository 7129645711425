import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  TablePagination,
  IconButton,
} from "@mui/material";
import { cloneDeep, debounce, snakeCase } from "lodash";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppSelector, useAppDispatch } from "../../redux/store/hooks";
import { getUsers } from "redux/actions/users";
import UsersDataGrid from "./dataGrid";
import TTTextField from "components/common/TTTextField";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "utils/hooks/useAuthData";
import { setFilter, setIncludeInactiveUsers } from "redux/reducers/usersSlice";
import { SortColumn } from "react-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import { InviteUserDialog } from "components/common/InviteUserDialog";
import {
  sortColumnForGridState,
  updateGridState,
} from "components/common/gridview/gridState";
import { setGrid, DEFAULT_SORT } from "redux/reducers/usersGridSlice";

const columnNameToField = (name: string) => {
  switch (name) {
    case "channelPartner":
      return "organizations";
    default:
      return snakeCase(name);
  }
};

const fieldToColumnName = (name: string) => {
  switch (name) {
    case "organizations":
      return "channelPartner";
    default:
      return name;
  }
};

const Users = () => {
  const { getRawIdToken, ready } = useAuthData();
  const dispatch = useAppDispatch();

  // Redux state
  const data = useAppSelector((state) => state.users.data);
  const totalCount = useAppSelector((state) => state.users.totalCount);
  const filter = useAppSelector((state) => state.users.filter);

  const includeInactiveUsers = useAppSelector(
    (state) => state.users.includeInactiveUsers
  );

  // Local UI state (some is persisted by redux above)
  const gridState = useAppSelector((state) => state.usersGrid.grid);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState<string>(filter);
  const [includeInactiveUsersChecked, setIncludeInactiveUsersChecked] =
    useState<boolean>(includeInactiveUsers);

  // Reference to all query params, for debouncing getUsersList
  const queryRef = useRef({
    page: page,
    size: rowsPerPage,
    orderBy: gridState.sortField,
    dir: gridState.sortDirection,
    filter: filter,
    includeInactiveUsers: includeInactiveUsersChecked,
  });

  const getUsersList = async () => {
    const idToken = await getRawIdToken();
    dispatch(
      getUsers({
        token: idToken,
        page: queryRef.current.page + 1,
        size: queryRef.current.size,
        orderBy: queryRef.current.orderBy,
        dir: queryRef.current.dir,
        filter: queryRef.current.filter,
        includeInactiveUsers: queryRef.current.includeInactiveUsers,
      })
    );
  };

  const lazyGetUsersList = useMemo(
    () =>
      debounce(() => {
        if (ready) {
          getUsersList();
        }
      }, 500),
    [ready]
  );

  useEffect(() => {
    queryRef.current = {
      page: page,
      size: rowsPerPage,
      orderBy: gridState.sortField,
      dir: gridState.sortDirection,
      filter: query,
      includeInactiveUsers: includeInactiveUsersChecked,
    };
    lazyGetUsersList();
  }, [
    page,
    rowsPerPage,
    gridState,
    query,
    includeInactiveUsersChecked,
    lazyGetUsersList,
  ]);

  const updateFilterQuery = (q: string) => {
    setPage(0);
    setQuery(q);
    dispatch(setFilter(q));
  };

  const handleChangeQuery = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const q = event.target.value;
    updateFilterQuery(q);
  };

  const handleInvitedUser = async () => {
    setPage(0);
    getUsersList();
  };

  const handleIncludeInactiveUsersCheckChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    dispatch(setIncludeInactiveUsers(checked));
    setIncludeInactiveUsersChecked(checked);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    setPage(0);
  };

  const handleChangeData = (
    rows: any,
    orderedColumns: any,
    sortColumns?: readonly SortColumn[]
  ) => {
    if (orderedColumns || sortColumns) {
      const updatedGridState = updateGridState(
        gridState,
        columnNameToField,
        DEFAULT_SORT,
        orderedColumns,
        sortColumns
      );
      dispatch(setGrid(updatedGridState));
    }
  };

  const handleChangeView = (index: number, width: number) => {
    const updatedGridState = cloneDeep(gridState);
    updatedGridState.columnStates[index].width = width;
    dispatch(setGrid(updatedGridState));
  };

  const textRef = useRef();

  return (
    <Box style={{ width: "100%" }}>
      <Grid container direction="row">
        <Grid xs={6} item>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Grid>
        <Grid xs={6} item alignItems="flex-end">
          <Box marginTop="5px" justifyContent="flex-end" display={"flex"}>
            <InviteUserDialog onInviteClicked={handleInvitedUser} />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        columns={{ xs: 2, sm: 2, md: 12 }}
        sx={{ mb: 1 }}
      >
        <Grid item xs={2} sm={2} md={6}>
          <TTTextField
            label="Search by user name or email..."
            defaultValue={query}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChangeQuery}
            autoFocus
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: query ? "visible" : "hidden" }}
                  onClick={() => {
                    updateFilterQuery("");
                    if (textRef.current) {
                      (textRef.current as any).value = "";
                    }
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            sx={{
              "& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
            }}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <FormControlLabel
            key={"includeInactiveUsers"}
            control={<Checkbox />}
            label="Include Inactive Users"
            style={{ paddingLeft: "9px" }}
            checked={includeInactiveUsersChecked}
            onChange={handleIncludeInactiveUsersCheckChange}
          />
        </Grid>
      </Grid>
      {data && data?.length > 0 ? (
        <>
          <UsersDataGrid
            rows={data}
            onChangeData={handleChangeData}
            onChangeView={handleChangeView}
            sortColumn={sortColumnForGridState(gridState, fieldToColumnName)}
            reorderedColumns={gridState.columnStates}
          />
          <TablePagination
            component="div"
            count={totalCount || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box sx={{ height: "100%" }}>No users matched your search</Box>
      )}
    </Box>
  );
};

export default Users;

interface RenderBackToUsersListButtonProps {
  path?: string;
  label?: string;
}

export const RenderBackToUsersListButton = ({
  path = "/users",
  label = "Users",
}: RenderBackToUsersListButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ArrowBackIcon />}
      sx={{ textTransform: "none" }}
      onClick={() => {
        navigate(path);
      }}
    >
      Back to {label}
    </Button>
  );
};
