import { makeStyles } from "tss-react/mui";

export const useUsersListFilterStyles = makeStyles()((theme) => {
  return {
    nameFilter: {
      width: 300,
      "&:hover .MuiOutlinedInput-notchedOutline": {
        backgroundColor: "rgba(2, 136, 209, 0.04)!important",
        border: "1px solid rgb(2, 136, 209)!important",
      },
    },
    userListFilterChip: {
      display: "flex",
      lineHeight: "16px",
      marginRight: "5px",
      borderRadius: "16px",
      padding: " 3px 4px",
      color: "#1976D2",
      background: "transparent",
      border: "1px solid #1976D2",
      ".MuiChip-deleteIcon": {
        color: "#1976D2",
      },
    },
  };
});
