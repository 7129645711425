import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Asset } from "generated-gql-types/graphql";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AssetPropertiesCard from "./AssetPropertiesCard";
import MotorPropertiesCard from "./MotorPropertiesCard";
import { enqueueSnackbar } from "notistack";
import { Box, Button, Stack } from "@mui/material";
import DrivePropertiesCard from "./DrivePropertiesCard";
import RmkPropertiesCard from "./RMKPropertiesCard";
import AssetTitleCard from "./AssetTitleCard";
import JobsListCard from "./JobsListCard";
import AssetConfigHistoryCard from "./AssetConfigHistoryCard";
import { useAuthData } from "utils/hooks/useAuthData";

const AssetDetails = () => {
  const { assetId } = useParams();
  const { ready } = useAuthData();
  const navigate = useNavigate();
  const [asset, setAsset] = useState<Asset>();
  const { data, loading, error } = useQuery(
    gql`
      query fetchAsset($assetId: ID!) {
        asset(assetId: $assetId) {
          id
          ...AssetPropertiesCard_asset
          ...DrivePropertiesCard_asset
          ...MotorPropertiesCard_asset
          ...RmkPropertiesCard_asset
          ...AssetTitleCard_asset
          ...JobsListCard_asset
          ...AssetConfigHistoryCard_asset
        }
      }
      ${AssetPropertiesCard.fragments.asset}
      ${DrivePropertiesCard.fragments.asset}
      ${MotorPropertiesCard.fragments.asset}
      ${RmkPropertiesCard.fragments.asset}
      ${AssetTitleCard.fragments.asset}
      ${JobsListCard.fragments.asset}
      ${AssetConfigHistoryCard.fragments.asset}
    `,
    { variables: { assetId: assetId }, skip: !ready }
  );

  useEffect(() => {
    if (!loading && data) {
      setAsset(data.asset);
    }
    if (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [data, error, loading]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1080px",
        marginX: "auto",
      }}
    >
      <Button
        sx={{ fontSize: 16, marginBottom: 2 }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIcon sx={{ marginRight: 1 }} />
        Back to Assets
      </Button>
      <Stack spacing={3} pb={4} overflow="auto" height="calc(100vh - 140px)">
        <AssetTitleCard asset={asset} />
        <RmkPropertiesCard asset={asset} />
        <DrivePropertiesCard asset={asset} />
        <MotorPropertiesCard asset={asset} />
        <AssetPropertiesCard asset={asset} />
        <JobsListCard asset={asset} />
        <AssetConfigHistoryCard asset={asset} />
      </Stack>
    </Box>
  );
};
export default AssetDetails;
