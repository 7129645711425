import React from "react";

export const RolesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      fill="none"
      viewBox="0 0 24 21"
      {...props}
    >
      <path
        stroke="#212121"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M1 5.5h21.75"
      ></path>
      <path
        stroke="#212121"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M20.875 1h-18C1.839 1 1 1.84 1 2.875v15c0 1.035.84 1.875 1.875 1.875h18c1.035 0 1.875-.84 1.875-1.875v-15C22.75 1.839 21.91 1 20.875 1z"
      ></path>
      <path
        fill="#212121"
        d="M3.625 4a.75.75 0 100-1.5.75.75 0 000 1.5zM6.625 4a.75.75 0 100-1.5.75.75 0 000 1.5z"
      ></path>
    </svg>
  );
};
