import { ColumnState } from "utils/types";
import { GridColDef } from "@mui/x-data-grid-pro";
import { columnSettings } from "components/common/gridview/gridState";
import {
  columnFilterOperators,
  renderCell,
  valueGetter,
} from "components/common/gridview/gridColumns";

const definedColumns: any[] = [
  {
    field: "id",
    object: "uploadRecord",
    key: "id",
    headerName: "Upload ID",
    filterable: true,
    hide: true,
    sortingKey: "uploadRecordId",
    sortable: true,
    filterOperators: true,
  },
  {
    field: "uploadDt",
    object: "uploadRecord",
    key: "uploadDt",
    headerName: "Upload Date",
    sortingKey: "uploadDt",
    hide: false,
    sortable: true,
    filterable: true,
    type: "datetime",
  },
  {
    field: "uploadedBy",
    object: "uploadRecord",
    key: "uploadedBy",
    headerName: "User",
    filterable: true,
    filteringKey: "uploadedBy",
    hideable: true,
    sortingKey: "uploadedBy",
    sortable: true,
  },
  {
    field: "fileName",
    object: "uploadRecord",
    key: "fileName",
    sortingKey: "fileName",
    headerName: "Filename",
    filterable: true,
    filteringKey: "fileName",
    hide: false,
    hideable: true,
    sortable: true,
  },
  {
    field: "importStatus",
    object: "uploadRecord",
    key: "importStatus",
    sortingKey: "importStatus",
    headerName: "EW Status",
    filterable: true,
    filteringKey: "importStatus",
    hide: true,
    hideable: true,
    sortable: true,
  },
];

/**
  Normalize defined column items.
 */
const defaultColumns: GridColDef[] = definedColumns.map((col) => {
  const column = {
    field: col.field,
    headerName: col.headerName,
    hide: col.hide,
    width: col?.width ?? columnSettings.COLUMN_DEFAULT_WIDTH,
    renderCell: renderCell(col),
  };
  // optional properties that need to be included if exists
  if (col?.type) {
    (column as any).type = col.type;
  }
  if (col?.type === "date") {
    (column as any).valueGetter = valueGetter(col);
  }
  if (col?.align) {
    (column as any).align = col.align;
  }
  if (col?.headerAlign) {
    (column as any).headerAlign = col.headerAlign;
  }
  if (col?.filterOperators === true) {
    (column as any).filterOperators = columnFilterOperators(col);
  }
  // optional properties that need to be included if exists and is explicitly set to true.
  if (col?.sortable === true) {
    (column as any).sortable = true;
  }
  if (col?.filterable === true) {
    (column as any).filterable = true;
  }
  // optional properties that need to be included if exists and is explicitly set to false.
  if (col?.hideable === false) {
    (column as any).hideable = false;
  }
  if (col?.editable) {
    (column as any).editable = col.editable;
  }
  if (col?.valueOptions) {
    (column as any).valueOptions = col.valueOptions;
  }
  return column;
});

const validWidth = (width: any, defaultValue: number = -1) => {
  return typeof width === "number" && width > columnSettings.COLUMN_MIN_WIDTH
    ? width
    : defaultValue;
};
const validIndex = (index: any, defaultValue: number = -1) => {
  return typeof index === "number" && index > -1 ? index : defaultValue;
};
const validBool = (visibility: any, defaultValue: boolean = false) => {
  return typeof visibility === "boolean" ? visibility : defaultValue;
};

const initialColumnStates: ColumnState[] = (() => {
  const initialStates: Array<ColumnState> = [];
  defaultColumns.forEach((col: any, index: number) => {
    initialStates.push({
      field: col.field,
      hide: validBool(col.hide),
      width: validWidth(col.width),
      index: validIndex(index),
    });
  });
  return initialStates;
})();

const supportedSortColumns = defaultColumns
  .filter((col) => (col as any).sortable !== undefined && (col as any).sortable)
  .map((sortableColumn) => sortableColumn.field);

export {
  supportedSortColumns,
  defaultColumns,
  definedColumns,
  initialColumnStates,
};
