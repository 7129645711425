import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { RootState } from "redux/store";
import { Colors } from "theme/colors";

type Template = {
  name: string;
  translation?: {
    [key: string]: {
      [language: string]: string;
    };
  };
  [key: string]: any;
};

type TemplateSelector = (state: RootState) => Template;

const useTranslatableSelector = (
  templateSelector: TemplateSelector,
  key: string,
  language: string
) => {
  const template = useSelector(templateSelector);
  const value = useMemo(() => {
    if (language !== "en") {
      const translatedValue = template.translation?.[key]?.[language];
      return {
        value: translatedValue ?? template[key],
        found: !!translatedValue,
      };
    }
    return { value: template[key], found: true };
  }, [template, key, language]);

  return value;
};

const TranslatableTextField = (props: any) => {
  const { selector, field, onChange, sx, InputProps, ...rest } = props;
  const language = useSelector(
    (state: RootState) => state.templateEditor.selectedLanguage
  );
  const tran = useTranslatableSelector(selector, field, language);
  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChange(e, language);
  };
  return (
    <TextField
      value={tran.value}
      onChange={handleTextChange}
      {...rest}
      sx={{
        backgroundColor: !tran.found ? Colors.sunflowerLightest : "white",
        ...sx,
      }}
    />
  );
};

export default TranslatableTextField;
