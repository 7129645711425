export const ADMIN_USER_TOOLTIP =
  "User can modify BOS site data associated with an organization";
export const MEMBER_USER_TOOLTIP =
  "User can view BOS site data associated with an organization";
export const AUTH0_ROLES_LABEL = "Product Access";
export const UNDER_WARRANTY = "In Warranty";
export const EXPIRED = "Expired";
export const FAILED = "Failed";
export const REPLACED = "Replaced under warranty";
export const UNKNOWN = "Unknown";
