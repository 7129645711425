import React, { useState, useMemo, useEffect } from "react";
import DataGrid from "react-data-grid";
import { Box, IconButton } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "react-tooltip";
import type { Column, SortColumn } from "react-data-grid";
import { groupBy as rowGrouper, chunk, debounce } from "lodash";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { UserTtRole } from "models/users";
import { dataGridStyle, dataGridParentStyle } from "theme/styles";
import { getComparator, getCompareFn } from "utils/comparator";
import { GridStateProps } from "utils/types";
import { reorderColumns } from "components/common/gridview/gridState";

function rowKeyGetter(row: UserTtRole) {
  return `${row.organization_id}-${row.role_id}`;
}

const isSearchEnabled: boolean = false;
const createColumns = (props: GridStateProps) => {
  const { onRemove } = props;
  const columns: readonly Column<UserTtRole>[] = [
    {
      key: "role_name",
      name: "Role",
    },
    {
      key: "actions",
      name: "Actions",
      width: 40,
      formatter(props) {
        return (
          <IconButton
            aria-label="delete"
            size="small"
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(props.row);
            }}
          >
            {" "}
            <DeleteOutlineIcon />
          </IconButton>
        );
      },
    },
  ];
  return columns;
};

const getInitialColumns = (props: GridStateProps) => {
  const defaultColumns = createColumns(props);
  const reorderedColumns = props.reorderedColumns;
  if (reorderedColumns && reorderedColumns.length >= createColumns.length) {
    return reorderColumns<UserTtRole>(defaultColumns, reorderedColumns);
  }
  return defaultColumns;
};

const UsersTtRolesGrid = (props: GridStateProps) => {
  const { rows, onRemove } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>(
    props.sortColumn ? [props.sortColumn] : []
  );
  const [columns, setColumns] = useState(getInitialColumns(props));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    setChunks(chunk(rows, _rowsPerPage));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
    setChunks(chunk(rows, rowsPerPage));
  }, [rows, rowsPerPage]);

  const sortedRows = useMemo((): readonly UserTtRole[] => {
    const userRows = rows.slice();
    if (sortColumns.length === 0) {
      // by default, sort the role name, ascending
      return userRows.sort((a: any, b: any) =>
        getComparator("role_name")(a, b)
      );
    }
    return userRows.sort(getCompareFn(sortColumns));
  }, [rows, sortColumns]);

  const [chunks, setChunks] = useState<Array<Array<UserTtRole>>>(
    chunk(sortedRows, rowsPerPage)
  );

  const handleChangeData = (sortColumns?: readonly SortColumn[]) => {
    props.onChangeData?.(rows, null, sortColumns);
  };

  const onSortColumnsChange = (sortColumns: readonly SortColumn[]) => {
    handleChangeData(sortColumns);
    setSortColumns(sortColumns);
    setChunks(chunk(sortedRows, rowsPerPage));
  };

  const onColumnResize = debounce((idx: number, width: number) => {
    props.onChangeView?.(idx, width);
  }, 500);

  return (
    <Box
      style={{
        width: "100%",
        height: "calc(100vh - 220px)",
      }}
      sx={dataGridParentStyle}
    >
      <DataGrid
        style={{
          ...dataGridStyle,
          height: "calc(100vh - 220px)",
        }}
        className={
          isSearchEnabled
            ? "rdg-light data-grid-with-column-search"
            : "rdg-light"
        }
        columns={columns}
        rows={chunks[page] ? chunks[page] : chunks[0]}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        rowKeyGetter={rowKeyGetter}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
        onColumnResize={onColumnResize}
        rowGrouper={rowGrouper}
        rowHeight={40}
        headerRowHeight={44}
      />
      {rows?.length > 50 && (
        <TablePagination
          component="div"
          count={rows?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Tooltip
        id="cell-tooltip"
        place="left"
        style={{ maxWidth: "250px", zIndex: 999 }}
      />
    </Box>
  );
};
export default UsersTtRolesGrid;
