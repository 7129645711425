import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Menu,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Constants } from "utils/constants";
import { FilterItem } from "utils/types";

const InstallationTypeFilter = (props: {
  selectedInstallationTypes: FilterItem[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onReset?: () => void;
  onSelectAll?: () => void;
}) => {
  const { selectedInstallationTypes, onChange, onReset, onSelectAll } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const selectAll = (() => {
    return Object.keys(Constants.STATUS_TEXT).every((key) =>
      selectedInstallationTypes.some((item) => item.key === key)
    );
  })();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid item>
        <Button
          id="menu-installation-status-button"
          variant="outlined"
          sx={{
            borderColor: "#c4c4c4",
            padding: "7px 14px",
            color: "#6f6f6f",
            textTransform: "none",
            marginLeft: "10px",
            fontSize: "16px",
            justifyContent: "flex-start",
            minWidth: 210,
          }}
          endIcon={<ArrowDropDownIcon />}
          aria-controls={open ? "menu-installation-status" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {"Installation Status"}
          {selectedInstallationTypes && selectedInstallationTypes.length > 0 ? (
            <ClearIcon
              sx={{
                ml: "7px",
                padding: "1px",
                bgcolor: "#f1f1f1",
                borderRadius: "50%",
                "&:hover": { bgcolor: "#81B5EA", color: "#ffffff" },
              }}
              aria-label="delete"
              fontSize="small"
              onClick={(e) => {
                e.stopPropagation();
                onReset?.();
              }}
            />
          ) : (
            <i style={{ padding: "0 14px" }}></i>
          )}
        </Button>
        <Menu
          id="menu-installation-status"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box sx={{ px: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={() => {
                      onSelectAll?.();
                    }}
                    checked={
                      Object.keys(Constants.STATUS_TEXT).length ===
                      selectedInstallationTypes.length
                    }
                    indeterminate={
                      Object.keys(Constants.STATUS_TEXT).length >
                        selectedInstallationTypes.length &&
                      selectedInstallationTypes.length > 0
                    }
                  />
                }
                label={selectAll ? "Deselect All" : "Select All"}
              />
              <Divider />
              {Object.keys(Constants.STATUS_TEXT).map((key) => {
                return (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedInstallationTypes?.some(
                          (item) => item.key === key
                        )}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          onChange?.(event, key);
                        }}
                      />
                    }
                    label={Constants.STATUS_TEXT[key]}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </Menu>
      </Grid>
    </>
  );
};

export default InstallationTypeFilter;
