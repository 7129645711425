import { Backdrop, CircularProgress } from "@mui/material";
import { usePromiseTracker } from "react-promise-tracker";
const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <>
      {promiseInProgress && (
        <Backdrop
          open={promiseInProgress}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
export default Loader;
