import { Box } from "@mui/material";
import {
  GridColDef,
  GridSortModel,
  GridColumnResizeParams,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPreferencePanelParams,
  MuiEvent,
  GridCallbackDetails,
  GridDensity,
  GridRowModesModel,
  GridEventListener,
} from "@mui/x-data-grid-pro";
import {
  TTDataGridPro,
  TT_SLOT_PROPS,
} from "components/datagrid/TTDataGridPro";
import { Sort } from "utils/types";

const defaultSort: GridSortModel = [
  {
    field: "assetUpdatedDt",
    sort: Sort.DESC,
  },
];

const AssetDataGrid = (props: {
  assets: Array<any> | [];
  loading: boolean;
  columns: GridColDef[] | [];
  sortModel?: any;
  columnVisibilityModel?: any;
  filterModel?: any;
  densityState?: GridDensity;
  sortingOrder?: Array<any>;
  columnOrderedFields?: Array<string> | [];
  onSortModelChange?: (sortModel: GridSortModel) => void;
  onColumnResize?: (column: GridColumnResizeParams) => void;
  onColumnOrderChange?: (column: GridColumnOrderChangeParams) => void;
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  onStateChange?: (gridState: any) => void;
  onPreferencePanelOpen?: (
    params: GridPreferencePanelParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => void;
  onPreferencePanelClose?: (
    params: GridPreferencePanelParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => void;
  onRowClick?: (params: any, event: React.MouseEvent<HTMLElement>) => void;
  processRowUpdate?: (updatedRow: any, originalRow: any) => any;
  onProcessRowUpdateError?: (error: any) => void;
  rowModesModel?: GridRowModesModel;
  onRowModesModelChange?: (newModel: any) => void;
  onRowEditStop?: GridEventListener<"rowEditStop">;
  yOffset?: Number;
}) => {
  const {
    assets,
    loading,
    columns,
    sortModel = defaultSort,
    filterModel = {},
    densityState = "standard",
    columnVisibilityModel = {},
    sortingOrder = ["asc", "desc", null],
    columnOrderedFields = [],
    onSortModelChange,
    onFilterModelChange,
    onStateChange,
    onColumnResize,
    onColumnOrderChange,
    onColumnVisibilityModelChange,
    onPreferencePanelOpen,
    onPreferencePanelClose,
    onRowClick,
    processRowUpdate,
    onProcessRowUpdateError,
    rowModesModel,
    onRowModesModelChange,
    onRowEditStop,
    yOffset = 0,
  } = props;

  const combinedStyle = { height: `calc(100vh - ${yOffset}px)` };

  const handlePreferencePanelOpen = (
    params: GridPreferencePanelParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    onPreferencePanelOpen?.(params, event, details);
  };

  const handlePreferencePanelClose = (
    params: GridPreferencePanelParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    onPreferencePanelClose?.(params, event, details);
  };

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      {assets && (
        <TTDataGridPro
          sx={combinedStyle}
          columns={columns}
          rows={assets}
          loading={loading}
          slotProps={{
            ...TT_SLOT_PROPS,
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          disableMultipleColumnsSorting
          disableAutosize
          disableColumnPinning
          editMode="row"
          sortModel={sortModel}
          columnVisibilityModel={columnVisibilityModel}
          filterModel={filterModel}
          density={densityState}
          filterMode="server"
          sortingMode="server"
          sortingOrder={sortingOrder}
          onSortModelChange={onSortModelChange}
          onColumnResize={onColumnResize}
          onColumnOrderChange={onColumnOrderChange}
          onFilterModelChange={onFilterModelChange}
          onStateChange={onStateChange}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          onPreferencePanelOpen={handlePreferencePanelOpen}
          onPreferencePanelClose={handlePreferencePanelClose}
          onRowClick={onRowClick}
          initialState={{
            columns: {
              orderedFields: columnOrderedFields || [],
            },
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onProcessRowUpdateError}
          rowModesModel={rowModesModel}
          onRowModesModelChange={onRowModesModelChange}
          onRowEditStop={onRowEditStop}
        />
      )}
    </Box>
  );
};

export { AssetDataGrid, defaultSort };
