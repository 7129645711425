import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import TplEditorInstruction from "./TplEditorInstruction";
import { useTplEditorStyles } from "./styles";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import {
  changeValidationRules,
  deleteItem,
  setBarcode,
  setDisplayCondition,
  setIntegrationKey,
  setItemDataType,
  setItemDescription,
  setItemName,
  setItemSortOrder,
  setItemStoreGPS,
  setOCR,
  setOCRSource,
} from "redux/reducers/templateEditorSlice";
import { DataType } from "models/response/datatype";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import TplEditorPicklistOptions from "./TplEditorPicklistOptions";
import TplEditorValidationRules from "./TplEditorValidationRules";
import useItemSelector from "utils/hooks/useItemSelector";
import TranslatableTextField from "./TranslatableTextField";

export interface TplEditorItemProps {
  isNew: boolean;
  groupIndex: number;
  itemIndex: number;
}

const TplEditorItem = (props: TplEditorItemProps) => {
  const { classes } = useTplEditorStyles();

  const { groupIndex, itemIndex } = props;
  const item_id = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.item_id
  );
  const name = useItemSelector(groupIndex, itemIndex, (item) => item.name);
  const sort_order = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.sort_order
  );
  const store_gps = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.store_gps
  );
  const display_condition = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.display_condition
  );
  const integration_key = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.integration_key
  );
  const data_type = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.data_type
  );
  const instruction = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.instruction
  );
  const isRequired = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.validation_rules?.is_required
  );
  const ocrSettings = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.ocr_settings
  );
  const inputSource = useItemSelector(
    groupIndex,
    itemIndex,
    (item) => item.input_source
  );

  const dispatch = useDispatch();
  const handleItemNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    language: string
  ) => {
    dispatch(
      setItemName({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        language,
      })
    );
  };
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      setItemSortOrder({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    language: string
  ) => {
    dispatch(
      setItemDescription({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        language,
      })
    );
  };
  const dataTypes = useSelector((state: any) => state.templateEditor.dataTypes);
  const handleDataTypeChange = (e: SelectChangeEvent) => {
    dispatch(
      setItemDataType({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const handleRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeValidationRules({
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
        value: { is_required: e.target.checked || null },
      })
    );
  };
  const handleStoreGPSChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setItemStoreGPS({
        value: e.target.checked,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const handleDisplayConditionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setDisplayCondition({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const handleIntegrationKeyChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setIntegrationKey({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const handleOcrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setOCR({
        value: e.target.checked,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const showOcrSource = !!ocrSettings?.is_enabled;
  const handleOcrSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setOCRSource({
        value: e.target.value,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };
  const isBarcodeEnabled = !!inputSource?.includes?.("B");
  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setBarcode({
        value: e.target.checked,
        groupIndex: props.groupIndex,
        itemIndex: props.itemIndex,
      })
    );
  };

  const [deleteVisible, setDeleteVisible] = useState(false);
  const showDeleteModal = () => setDeleteVisible(true);
  const hideDeleteModal = () => setDeleteVisible(false);
  const handleDeleteConfirm = () => {
    dispatch(
      deleteItem({ groupIndex: props.groupIndex, itemIndex: props.itemIndex })
    );
    hideDeleteModal();
  };

  const pickistDataType = useMemo(
    () => dataTypes.find((dt: DataType) => dt.key === "PICKLIST"),
    [dataTypes]
  );

  const itemSelector = useCallback(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].items?.[
        props.itemIndex
      ],
    [props.groupIndex, props.itemIndex]
  );
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls={`panel-content-${item_id}`}
        id={`panel-${item_id}`}
      >
        {!item_id && <Typography>New item</Typography>}
        {!!item_id && (
          <>
            <Chip label={sort_order} variant="outlined" />
            <Typography ml={1} mt={0.3}>
              {name}
            </Typography>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <>
            <Grid container direction={"column"}>
              <Grid item>
                <Grid container direction={"row"} mb={1}>
                  {!props.isNew && (
                    <TextField
                      disabled
                      size="small"
                      label="Item id"
                      value={item_id}
                      className={classes.editorInput}
                    ></TextField>
                  )}
                  <TextField
                    type="number"
                    size="small"
                    label="Sort order"
                    onChange={handleSortOrderChange}
                    value={sort_order}
                    className={classes.editorInputSmall}
                  ></TextField>

                  <FormControl size="small" className={classes.editorInput}>
                    <InputLabel id="data-type-select-label">
                      Data type
                    </InputLabel>
                    <Select
                      labelId="data-type-select-label"
                      value={data_type || ""}
                      label="Data type"
                      onChange={handleDataTypeChange}
                    >
                      {dataTypes.map((dt: DataType) => {
                        return (
                          <MenuItem key={dt.id} value={dt.id}>
                            {dt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <IconButton
                    size="small"
                    aria-label="Add note"
                    sx={{ marginLeft: 1, marginTop: "5px" }}
                    onClick={showDeleteModal}
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                </Grid>

                <TranslatableTextField
                  selector={itemSelector}
                  field={"name"}
                  required
                  size="small"
                  label="Item name"
                  onChange={handleItemNameChange}
                  className={classes.editorInputFullWidth}
                ></TranslatableTextField>

                <Grid container direction={"row"} ml={2} mt={1} mb={0}>
                  <FormControlLabel
                    label="Required"
                    control={
                      <Checkbox
                        onChange={handleRequiredChange}
                        checked={isRequired}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Store GPS"
                    control={
                      <Checkbox
                        onChange={handleStoreGPSChange}
                        checked={store_gps}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Barcode"
                    control={
                      <Checkbox
                        onChange={handleBarcodeChange}
                        checked={isBarcodeEnabled}
                      />
                    }
                  />
                  <FormControlLabel
                    label="OCR"
                    control={<Checkbox onChange={handleOcrChange} />}
                    checked={!!ocrSettings?.is_enabled}
                  />
                  {showOcrSource && (
                    <TextField
                      type="text"
                      size="small"
                      label="Source"
                      onChange={handleOcrSourceChange}
                      value={ocrSettings?.source}
                      className={classes.editorInputSmall}
                    ></TextField>
                  )}
                </Grid>

                <Grid container direction={"row"}>
                  <TextField
                    type="text"
                    size="small"
                    label="Display condition"
                    onChange={handleDisplayConditionChange}
                    value={display_condition}
                    className={classes.editorInput}
                    sx={{ fontFamily: "monospace", letterSpacing: "0.1em" }}
                  ></TextField>
                  <TextField
                    type="text"
                    size="small"
                    label="Integration key"
                    onChange={handleIntegrationKeyChange}
                    value={integration_key}
                    className={classes.editorInput}
                  ></TextField>
                </Grid>

                <Grid container direction={"row"}>
                  <TplEditorValidationRules
                    groupIndex={props.groupIndex}
                    itemIndex={props.itemIndex}
                  />
                  {data_type === pickistDataType?.id && (
                    <TplEditorPicklistOptions
                      groupIndex={props.groupIndex}
                      itemIndex={props.itemIndex}
                    />
                  )}
                  <TranslatableTextField
                    selector={itemSelector}
                    field={"description"}
                    multiline
                    size="small"
                    rows={1}
                    label="Description"
                    onChange={handleDescriptionChange}
                    className={classes.editorInputFullWidth}
                  ></TranslatableTextField>
                </Grid>
                {instruction && (
                  <TplEditorInstruction
                    isNew={props.isNew}
                    instruction={instruction}
                    groupIndex={props.groupIndex}
                    itemIndex={props.itemIndex}
                  ></TplEditorInstruction>
                )}
              </Grid>
            </Grid>
            {deleteVisible && (
              <ConfirmDialog
                open={deleteVisible}
                onClose={hideDeleteModal}
                onConfirm={handleDeleteConfirm}
              />
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TplEditorItem;
