import { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import { CSVLink } from "react-csv";
import { FilterItem, FiltersState, GridState } from "utils/types";
import { DocumentNode, useLazyQuery } from "@apollo/client";
import { syncColumns } from "components/common/gridview/gridColumns";
import { dateToUserLocalTimezone } from "utils/datetime";
import { enqueueSnackbar } from "notistack";

const ExportToCSV = (props: {
  columns: any[];
  grid: GridState;
  filters: FiltersState;
  disabled?: boolean;
  defaultColumns: any[];
  fileContentLabel: string;
  getQuery: (params: string) => DocumentNode;
  getQueryParams: (
    grid: GridState,
    searchField: string,
    selectedOrgFilterItems: FilterItem[],
    columnFilterModel: any,
    page: number,
    size?: number
  ) => string;
  rowsFromQueryData: (data: any) => any;
}) => {
  const {
    columns,
    grid,
    filters,
    disabled,
    defaultColumns,
    fileContentLabel,
    getQuery,
    getQueryParams,
    rowsFromQueryData,
  } = props;
  const csvInstance = useRef<any | null>(null);

  const [rows, setRows] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);

  const columnFilterModel = Array.isArray(filters?.columnFilters?.items)
    ? filters.columnFilters
    : [];
  const searchField = filters?.searchField;
  const selectedOrgFilterItems = Array.isArray(filters?.orgs)
    ? filters?.orgs
    : [];

  const [queryAssets, { data, loading, error }] = useLazyQuery(
    getQuery(
      getQueryParams(
        grid,
        searchField,
        selectedOrgFilterItems,
        columnFilterModel,
        0,
        0
      )
    ),
    {}
  );

  const [doingExport, setDoingExport] = useState(false);

  useEffect(() => {
    if (doingExport && !loading) {
      if (data && !error) {
        const assets: any[] = rowsFromQueryData(data);

        const syncedColumns = syncColumns(columns, defaultColumns);
        const exportColumns = (
          Array.isArray(syncedColumns) ? syncedColumns : []
        )
          .map((col) => {
            if ((col as any).hide !== undefined && (col as any).hide === true) {
              return false;
            }
            return {
              key: col.field,
              label: col.headerName,
              type: col?.type,
            };
          })
          .filter(Boolean);

        const exportRows = assets.map((row: any) => {
          const _row = { ...row };
          exportColumns.forEach((col: any) => {
            if (col?.type === "dateTime" && _row[col.key]) {
              _row[col.key] = dateToUserLocalTimezone(_row[col.key]);
            }
          });
          return _row;
        });

        setHeaders(exportColumns);
        setRows(exportRows);
        setTimeout(() => {
          csvInstance.current.link.click();
        });
        enqueueSnackbar("Completed CSV export", { variant: "success" });
      }
      if (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else if (!data) {
        enqueueSnackbar("Nothing to export", { variant: "warning" });
      }
      setDoingExport(false);
    }
  }, [
    data,
    loading,
    error,
    doingExport,
    rowsFromQueryData,
    columns,
    defaultColumns,
  ]);

  const handleExportClick = async () => {
    enqueueSnackbar("Starting CSV export...", { variant: "success" });
    setDoingExport(true);
    await queryAssets();
  };

  const filename = `technician-${fileContentLabel}-${dateToUserLocalTimezone(
    new Date().toString(),
    "MMDDYYYY-HHmm"
  )}`;

  return (
    <>
      <Button
        startIcon={<ArrowCircleUpOutlinedIcon />}
        onClick={handleExportClick}
        disabled={disabled}
      >
        EXPORT
      </Button>
      <CSVLink
        data={rows}
        headers={headers}
        filename={filename}
        style={{ display: "none" }}
        ref={csvInstance}
      ></CSVLink>
    </>
  );
};

export default ExportToCSV;
