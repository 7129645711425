import { FilterItem } from "utils/types";
import { GridState } from "utils/types";
import { definedColumns, supportedSortColumns } from "./inventoryColumns";
import { getGridQueryParams } from "../common/gridview/assetFiltersUtil";
import { gql } from "@apollo/client";

const getInventoryQuery = (params: string) => {
  return gql`
    query inventoryGrid {
      inventory ${params} {
        totalCount
        pageInfo {
          startIndex
          endIndex
          hasNextPage
          hasPreviousPage
        }
        edges {
          item {
            id
            type
            modelNumber
            serialNumber
            customer
            channelPartner
            site {
              id
              name
              address
            }
            soldTo
            fulfillmentSalesOrder
            warranty {
              id
              failureDt
              status
              startDt
              endDt
              shipDt
              installedDt
            }
          }
        }
      }
    }
  `;
};

const getInventoryQueryParams = (
  grid: GridState,
  searchField: string,
  selectedOrgFilterItems: FilterItem[],
  columnFilterModel: any,
  page: number,
  size: number = -1
) => {
  return getGridQueryParams(
    grid,
    searchField,
    selectedOrgFilterItems,
    columnFilterModel,
    definedColumns,
    supportedSortColumns,
    page,
    size
  );
};

const inventoryRowsFromQueryData = (data: any) => {
  return (data?.inventory?.edges || []).map((edge: any) => {
    const inventoryProps = (edge.item?.properties || []).reduce(
      (props: Record<string, any>, item: any) => {
        if (item.integrationKey) {
          props[item.integrationKey] = item;
        }
        return props;
      },
      {}
    );

    const result: any = {};
    definedColumns.forEach((col: any, index: number) => {
      if (col.object && col.key) {
        if (col.object === "site") {
          result[col.field] = edge?.item?.site?.[col.key];
        } else if (col.object === "item") {
          result[col.field] = edge?.item?.[col.key];
        } else if (col.object === "warranty") {
          result[col.field] = edge?.item?.warranty?.[col.key];
        } else if (col.object === "inventory-properties") {
          result[col.field] = inventoryProps?.[col.key]?.value;
        }
      }
    });
    return result;
  });
};

export {
  getInventoryQuery,
  getInventoryQueryParams,
  inventoryRowsFromQueryData,
};
