import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ListItem from "@mui/material/ListItem";
import { Clear as ClearIcon } from "@mui/icons-material";

import { useAppSelector, useAppDispatch } from "../../../redux/store/hooks";
import TTAutoComplete from "../../common/TTAutoComplete/TTAutoComplete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { updateFilter } from "redux/reducers/filtersSlice";

const OrgsFilter = (props: { onApplyFilter?: () => void }) => {
  const { onApplyFilter } = props;
  const dispatch = useAppDispatch();
  const channelPartners = useAppSelector(
    (state) => state.orgs.data?.channelPartners
  );
  const customers = useAppSelector((state) => state.orgs.data?.customers);
  const installers = useAppSelector((state) => state.orgs.data?.installers);
  const filters = useAppSelector((state) => state.filters.jobFilters);

  let totalFilters = 0;
  totalFilters = totalFilters + (filters?.customer?.length || 0);
  totalFilters = totalFilters + (filters?.installer?.length || 0);
  totalFilters = totalFilters + (filters?.channelPartner?.length || 0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDirty, setDirty] = React.useState<Boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (isDirty) {
      setDirty(false);
      onApplyFilter?.();
    }

    setAnchorEl(null);
  };

  const handleChangeFilter = (filterType: string, value: any) => {
    setDirty(true);
    dispatch(updateFilter({ filterType, value }));
  };

  const handleResetFilter = () => {
    setDirty(true);
    dispatch(updateFilter({ filterType: "channelPartner", value: [] }));
    dispatch(updateFilter({ filterType: "customer", value: [] }));
    dispatch(updateFilter({ filterType: "installer", value: [] }));
    onApplyFilter?.();
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Button
        id="basic-button"
        variant="outlined"
        size="medium"
        sx={{
          borderColor: "#c4c4c4",
          padding: "7px",
          color: "#6f6f6f",
          textTransform: "none",
        }}
        endIcon={<ArrowDropDownIcon />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {"Organization "}
        {totalFilters > 0 ? (
          <ClearIcon
            sx={{
              ml: "7px",
              mr: "-7px",
              padding: "1px",
              bgcolor: "#f1f1f1",
              borderRadius: "50%",
              "&:hover": { bgcolor: "#81B5EA", color: "#ffffff" },
            }}
            aria-label="delete"
            fontSize="small"
            onClick={(e) => {
              e.stopPropagation();
              handleResetFilter();
            }}
          />
        ) : (
          <i style={{ padding: "0 11px" }}></i>
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ListItem>
          {channelPartners && (
            <TTAutoComplete
              options={channelPartners || {}}
              label="Channel Partner"
              primaryField="organization_name"
              primaryKey="organization_id"
              value={filters?.channelPartner}
              onChange={(v) => handleChangeFilter("channelPartner", v)}
            />
          )}
        </ListItem>
        <ListItem>
          {customers && (
            <TTAutoComplete
              options={customers || {}}
              label="Customer"
              primaryField="organization_name"
              primaryKey="organization_id"
              value={filters?.customer}
              onChange={(v) => handleChangeFilter("customer", v)}
            />
          )}
        </ListItem>
        <ListItem>
          {installers && (
            <TTAutoComplete
              options={installers || {}}
              label="Installer"
              primaryField="organization_name"
              primaryKey="organization_id"
              value={filters?.installer}
              onChange={(v) => handleChangeFilter("installer", v)}
            />
          )}
        </ListItem>
      </Menu>
    </Box>
  );
};

export default OrgsFilter;
