import { createSlice } from "@reduxjs/toolkit";
import { UserTtRole } from "models/users";
import { getUserTtRoles } from "redux/actions/users";
import error from "utils/error";
interface UserTtRolesState {
  roles: UserTtRole[];
  loading: boolean;
  error?: string;
}

const initialState: UserTtRolesState = {
  roles: [],
  loading: false,
  error: undefined,
};

const slice = createSlice({
  name: "userTtRoles",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getUserTtRoles.pending, (state) => {
      state.roles = [];
      state.loading = true;
    });
    builder.addCase(getUserTtRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    });
    builder.addCase(getUserTtRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
