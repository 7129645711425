import { ColumnState } from "utils/types";

export const defaultColumns: readonly ColumnState[] = [
  {
    field: "customerName",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "siteName",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "siteAddress",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "equipment",
    width: 120,
    hide: false,
    index: 0,
  },
  {
    field: "survey_template_name",
    width: 100,
    hide: false,
    index: 0,
  },

  {
    field: "installer",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "channelPartner",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "completed_dt",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "status",
    width: 120,
    hide: false,
    index: 0,
  },
  {
    field: "location",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "created_dt",
    width: 120,
    hide: false,
    index: 0,
  },
  {
    field: "last_updated_dt",
    width: 120,
    hide: false,
    index: 0,
  },
  {
    field: "created_by",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "last_updated_by",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "completed_by",
    width: 100,
    hide: false,
    index: 0,
  },
  {
    field: "id",
    width: 275,
    hide: true,
    index: 0,
  },
  {
    field: "actions",
    width: 60,
    hide: false,
    index: 0,
    sortable: false,
  },
];

export const getDefaultVisibleColumns = () => {
  const columns = defaultColumns.reduce((prev: any, col: ColumnState) => {
    prev[col.field] = !col.hide;
    return prev;
  }, {});
  return columns;
};
