import { createSlice } from "@reduxjs/toolkit";
import { getRolesForOrgs } from "redux/actions/orgs";

interface AssignTtRolesToUserState {
  open: boolean;
  availableRoles: any[];
  currentRoles: any[];
  selectedRoles: any[];
}

const initialState: AssignTtRolesToUserState = {
  open: false,
  availableRoles: [],
  currentRoles: [],
  selectedRoles: [],
};

const slice = createSlice({
  name: "assignTtRolesToUser",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setAvailableRoles(state, action) {
      state.availableRoles = action.payload;
    },
    setSelectedRoles(state, action) {
      state.selectedRoles = action.payload;
    },
    setCurrentRoles(state, action) {
      state.currentRoles = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getRolesForOrgs.fulfilled, (state, action) => {
      state.availableRoles = [];
      action.payload?.forEach((r: any) => {
        if (state.currentRoles.indexOf(r.role_id) === -1) {
          state.availableRoles.push(r);
        }
      });
    });
  },
});
export const { setOpen, setAvailableRoles, setSelectedRoles, setCurrentRoles } =
  slice.actions;
export default slice.reducer;
